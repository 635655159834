import { Actions, perksFailure, PerksRequestAction, perksSuccess } from '../actions/perk';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, Perk } from '../types/Tymbe';

export function* fetchPerks(action: PerksRequestAction): SagaIterator {
  try {
    const response: AxiosResponse<Perk[]> = yield call(api.getPerks);
    yield put(perksSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(perksFailure(error));
    }
  }
}

export function* watchPerksRequest() {
  yield takeEvery(Actions.PERKS_REQUEST, fetchPerks);
}
