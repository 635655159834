import { getWagesRequest } from '../actions/wages';
import { Error } from '../components/Error';
import Spinner from '../components/Spinner';
import { Wages } from '../components/Wages';
import withError from '../components/withError';
import withFetch from '../components/withFetch';
import { withLoaderNew } from '../components/withLoading';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { wagesSelector } from '../reducers/components';
import { compose } from 'redux';

const selectWages = compose(wagesSelector, componentsSelector);

const WagesConnect = connect(
  (state: StoreState) => ({
    ...selectWages(state),
    force: true,
  }),
  { fetch: getWagesRequest },
);

export default WagesConnect(
  withFetch(
    withLoaderNew(selectWages, <Spinner position="relative" hideBackground />)(
      withError(Error)(
        withMapData(data => ({ wages: data }))(
          Wages,
        ),
      ),
    ),
  ),
);
