import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { SalaryCalculationData } from '@tymbe/schema/salary-calculation.interface';
import { CzechSalaryType } from '@tymbe/legislatives/czechia/CzechSalaryType';
import { Button } from './Forms/Button';
import { useModalError } from './Modal/ModalError';
import { PasswordModal } from './Modal/PasswordModal';
import { H3 } from './Styles/BasicElements';
import moment from 'moment-timezone';
import React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '../styledComponents';
import { ApiError } from '../types/Tymbe';
import { WageDetailModal } from './Modal/WageDetailModal';
import WageDetail from './WageDetail';
import { useMutation } from 'react-query';
import api from '../services/api';
import { useDispatch } from 'react-redux';
import { getWagesRequest } from '../actions/wages';


const Wage = styled.div`
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Heading = styled(H3)`
  color: ${({ theme }) => theme.colors.steel};
  margin-bottom: 1rem;
`;

const Left = styled.div`
  float: left;
`;

const Right = styled.div`
  float: right;
`;

export type WageItemProps = {
  wage: PayoutInfoData;
  error: ApiError;
  loading: boolean;
  payout: (date_time: string, company_id: number, salary_type: CzechSalaryType, password: string) => void;
};

export const WageItem = ({ wage, loading, error, payout }: WageItemProps) => {
  const intl = useIntl();
  const [confirm, setConfirm] = React.useState(false);
  const [detailModal, setDetailModal] = React.useState(false);
  const [wageDetailInfo, setWageDetailInfo] = React.useState<SalaryCalculationData>();
  const showConfirm = () => setConfirm(true);
  const hideConfirm = () => setConfirm(false);
  const dispatch = useDispatch();
  const showError = useModalError();

  const { mutateAsync: getWageDetail } = useMutation(
    ['getWageDetail', wage.start, wage.company_id, wage.type],
    async () => (await api.getWageDetail(wage.start, wage.company_id, wage.type)).data,
  );

  React.useEffect(
    () => {
      if (error) {
        if (error.message === 'Payout amount must be bigger than zero!') {
          showError(intl.formatMessage({
            defaultMessage: 'Částka musí být větší než nula!',
            description: 'Text chyby',
          }));
          dispatch(getWagesRequest());
        } else {
          showError(intl.formatMessage({
            defaultMessage: 'Zadali jste špatné heslo',
            description: 'Text chyby',
          }));
        }
      }
    },
    [error, intl, showError],
  );

  const payoutWage = (password: string) => {
    payout(wage.start, wage.company_id, wage.type, password);
    hideConfirm();
  };

  const showWageDetail = async () => {
    const wagesDetails = await getWageDetail();
    setDetailModal(true);
    setWageDetailInfo(wagesDetails);
  };

  return (
    <Wage>
      <Heading>
        {intl.formatMessage(
          {
            defaultMessage: '{company_name}',
            description: 'Titulek položky v seznamu brigád k proplacení. Např: "Zásilkovna a.s."',
          },
          { company_name: wage.company?.name ?? '...' },
        )}
        {' '}
        {wage.type === CzechSalaryType.DPP && intl.formatMessage({
          defaultMessage: '(DPP)',
          description: 'Přívlastek výplaty za DPP',
        })}
        {wage.type === CzechSalaryType.DPC_HPP && intl.formatMessage({
          defaultMessage: '(DPČ+HPP)',
          description: 'Přívlastek výplaty za DPČ+HPP',
        })}
      </Heading>
      <div className={'clearFix'}>
        <Left>
          <div>{intl.formatMessage(
            {
              defaultMessage: '<strong>Datum:</strong> {date_start, date, ::MMMyyyy}',
              description: 'Datum začátku směny, např: "Datum: 20. 11. 2021"',
            },
            { date_start: moment.tz(wage.start, 'europe/prague') },
          )}</div>
        </Left>
        <Right>
          <div>
            {intl.formatMessage(
              {
                defaultMessage: '<strong>K vyplacení:</strong> {net_wage}',
                description: 'Výdělek za směnu. Napr: "58,50 Kč/směna"',
              },
              {
                net_wage: intl.formatNumber(wage.payableAmount, {
                  style: 'currency',
                  currency: 'CZK',
                  minimumFractionDigits: wage.payableAmount % 1 ? 2 : 0,
                  maximumFractionDigits: wage.payableAmount % 1 ? 2 : 0,
                }),
              },
            )}
          </div>
        </Right>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1rem' }}>
        <Button style={{ display: 'flex' }} onClick={showWageDetail} disabled={loading}>{intl.formatMessage({
          defaultMessage: 'Detail',
          description: 'Tlačítko pro zobrazení detailu výpočtu odměny',
        })}
        </Button>
        {wage.payableAmount > 0 &&
        <Button style={{ display: 'flex' }} onClick={showConfirm} disabled={loading} data-cy="payoutButton" id='payout_button'>{intl.formatMessage({
          defaultMessage: 'Vyplatit',
          description: 'Tlačítko pro vyplacení odpracované brigády',
        })}</Button>
      }
      </div>
      <PasswordModal
        open={confirm}
        onSubmit={payoutWage}
        onCancel={hideConfirm}
        title={intl.formatMessage({
          defaultMessage: 'Potvrdit heslem',
          description: 'Titulek dialogu pro potvrzení žádosti o vyplacení brigády',
        })}
        submitText={intl.formatMessage({
          defaultMessage: 'Vyplatit',
          description: 'Potvrzovací tlačítko dialogu pro potvrzení žádosti o vyplacení brigády',
        })}
        disable={loading}
      />
      <WageDetailModal
        onCancel={() => setDetailModal(false)}
        disable={loading}
        title={intl.formatMessage({
          defaultMessage: 'Detail odměny k vyplacení',
          description: 'Titulek dialogu pro zobrazení detailu odměnu k vyplacení'
        })}
        open={detailModal}
      >
        {wageDetailInfo &&
          <WageDetail payoutInfoData={wage} salaryCalculation={wageDetailInfo} />
        }
      </WageDetailModal>
    </Wage>
  );
};
