import { perksRequest } from '../actions/perk';
import { tymberDataRequest } from '../actions/tymberData';
import Spinner from '../components/Spinner';
import { TymberPerkList } from '../components/TymberPerkList';
import withFetch, { withFetchNew } from '../components/withFetch';
import { withLoaderNew } from '../components/withLoading';
import withMapData from '../components/withMapData';
import React from 'react';
import { connect } from 'react-redux';
import { dataSelector, StoreState, tymberSelector } from '../reducers';
import { dataPerksSelector } from '../reducers/data';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { Tymber } from '../types/Tymbe';

const selectPerks = compose(dataPerksSelector, dataSelector);

const TymberPerksListConnect = connect(
  (state: StoreState) => ({
    ...selectPerks(state),
    tymber: tymberSelector(state).data as Tymber,
  }),
  {
    fetch: perksRequest,
  },
);

export default TymberPerksListConnect(
  withFetch(
    withMapData((data, status) => ({
      perks: data || [],
      loading: status === STATUS_PENDING,
    }))(
      withFetchNew(tymberSelector, tymberDataRequest)(
        withLoaderNew(tymberSelector, <Spinner />, true)(
          TymberPerkList,
        ),
      ),
    ),
  ),
);
