import { call, put, takeEvery } from '@redux-saga/core/effects';
import {
  Actions,
  DownloadPayrollAction,
  getPayrollsFailure,
  GetPayrollsRequest,
  getPayrollsSuccess,
} from '../actions/payrolls';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { handleApiError, initDownload } from './utils';
import api from '../services/api';
import { ApiError, CompanyRef } from '../types/Tymbe';
import { DocumentType } from '@tymbe/schema/enums';

export function* fetchPayrolls(action: GetPayrollsRequest): SagaIterator {
  const { month, year } = action;
  try {
    const response: AxiosResponse<CompanyRef[]> =
      yield call(api.getPayrolls, { month, year, contractTypes: [DocumentType.CONTRACT_DPP] }); // TODO - add contract_dpc when the computation is fixed (TD-1122)
    yield put(getPayrollsSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getPayrollsFailure(error));
    }
  }
}

export function* fetchPayrollPdf(action: DownloadPayrollAction): SagaIterator {
  const { month, year, companyId, oz } = action;
  try {
    const response: AxiosResponse<Blob> = yield call(api.getPayrollPdf, companyId, year, month, oz || 0);
    initDownload(response, `výplatní_lístek_${companyId}_${year}_${month}.pdf`);
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getPayrollsFailure(error));
    }
  }
}

export function* watchGetPayrollsRequest() {
  yield takeEvery(Actions.GET_PAYROLLS_REQUEST, fetchPayrolls);
}

export function* watchDownloadPayroll() {
  yield takeEvery(Actions.DOWNLOAD_PAYROLL, fetchPayrollPdf);
}
