import { action } from './utils';
import { ReactNode } from 'react';
import { Action } from 'redux';

export enum Actions {
  ADD_MODAL_ERROR = 'SHOW_MODAL_ERROR',
  CLEAR_MODAL_ERROR = 'HIDE_MODAL_ERROR',
}

export interface AddModalErrorAction extends Action<Actions.ADD_MODAL_ERROR> {
  message: ReactNode;
  title?: string;
  onClose?: () => void;
}

export interface ClearModalErrorAction extends Action<Actions.CLEAR_MODAL_ERROR> {
  id: number;
}

export type ModalErrorActions = AddModalErrorAction | ClearModalErrorAction;

export const addModalError = (message: ReactNode, title?: string, onClose?: () => void): AddModalErrorAction =>
  action(Actions.ADD_MODAL_ERROR, { message, onClose, title });
export const clearModalError = (id: number): ClearModalErrorAction =>
  action(Actions.CLEAR_MODAL_ERROR, { id });
