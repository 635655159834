import { Card } from '../Card';
import { CardContent } from '../Card/CardContent';
import { CardFooter } from '../Card/CardFooter';
import { CardHeaderCentered } from '../Card/CardHeader';
import { Button } from '../Forms/Button';
import { LabeledInput } from '../Forms/LabeledInput';
import Modal, { ModalProps } from './index';
import { H2 } from '../Styles/BasicElements';
import { Form } from 'informed';
import React from 'react';
import { useIntl } from 'react-intl';

export type PasswordModalProps = ModalProps & {
  onSubmit: (password: string) => void;
  onCancel: () => void;
  title?: string;
  submitText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  disable: boolean;
};

export const PasswordModal = ({
  children, onSubmit, onCancel, title, submitText, cancelText, disable, ...rest
}: PasswordModalProps) => {
  const intl = useIntl();
  // tslint:disable-next-line:no-parameter-reassignment
  submitText = submitText || intl.formatMessage({
    defaultMessage: 'Odeslat',
    description: 'Tlačítko pro ověření zadaného hesla',
  });
  // tslint:disable-next-line:no-parameter-reassignment
  cancelText = cancelText || intl.formatMessage({
    defaultMessage: 'Zrušit',
    description: 'Tlačítko zruší ověření hesla a zavře dialog',
  });
  return (
    <Modal {...rest}>
      <Card>
        <CardHeaderCentered><H2>{title}</H2></CardHeaderCentered>
        <Form onSubmit={({ values: { password } }) => onSubmit(String(password))}>
          <CardContent>
            {children}
            <LabeledInput id={'confirmPass'} data-cy="confirmPassword" name={'password'} type={'password'} label={'Heslo'} />
          </CardContent>
          <CardFooter>
            <Button onClick={onCancel} type={'button'}>{cancelText}</Button>
            <Button type={'submit'} data-cy="modalSubmitButton" id='modal_submit_button' disabled={disable}>{submitText}</Button>
          </CardFooter>
        </Form>
      </Card>
    </Modal>
  );
};
