import { moreClaimedFailure, moreClaimedSuccess } from '../actions/claimed';
import { StoreState, componentsSelector } from '../reducers';
import { call, put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import {
  Actions, cancelClaimedFailure,
  CancelClaimedRequestAction, cancelClaimedSuccess,
  getClaimedFailure,
  GetClaimedRequestAction,
  getClaimedSuccess,
  MoreClaimedRequestAction,
} from '../actions/claimed';
import { AxiosResponse } from 'axios';
import { claimedSelector } from '../reducers/components';
import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, Ring } from '../types/Tymbe';

export function* fetchClaimed(action: GetClaimedRequestAction): SagaIterator {
  const { filters } = action;
  try {
    const rings: AxiosResponse<Ring[]> = yield call(api.getClaimed, filters);
    yield put(getClaimedSuccess(rings.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getClaimedFailure(error));
    }
  }
}

export function* fetchMoreClaimed(action: MoreClaimedRequestAction): SagaIterator {
  const { filters } = action;
  const ringIds = yield select((state: StoreState) => claimedSelector(componentsSelector(state)));
  const from = ringIds?.claimed.data?.length || 0;
  try {
    const rings: AxiosResponse<Ring[]> = yield call(api.getClaimed, { ...filters, $skip: from });
    yield put(moreClaimedSuccess(rings.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(moreClaimedFailure(error));
    }
  }
}

export function* cancelClaimed(action: CancelClaimedRequestAction) {
  const { ringId, isLastMinute } = action;
  try {
    const response: AxiosResponse<Ring> = yield call(api.cancelClaimed, ringId, isLastMinute);
    yield put(cancelClaimedSuccess(response.data));
  } catch (e) {
    const error: ApiError = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(cancelClaimedFailure(error));
    }
  }
}

export function* watchGetClaimedRequest() {
  yield takeLatest(Actions.GET_CLAIMED_REQUEST, fetchClaimed);
}

export function* watchCancelClaimedRequest() {
  yield takeEvery(Actions.CANCEL_CLAIMED_REQUEST, cancelClaimed);
}

export function* watchMoreClaimedRequest() {
  yield takeLatest(Actions.MORE_CLAIMED_REQUEST, fetchMoreClaimed);
}
