import { Actions, getSurveyFailure, GetSurveyRequestAction, getSurveySuccess } from '../actions/survey';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, Survey } from '../types/Tymbe';

export function* fetchSurvey(action: GetSurveyRequestAction): SagaIterator {
  const { surveyId } = action;
  try {
    const response: AxiosResponse<Survey> = yield call(api.getSurvey, surveyId);
    yield put(getSurveySuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getSurveyFailure(surveyId, error));
    }
  }
}

export function* watchGetSurveyRequest() {
  yield takeEvery(Actions.GET_SURVEY_REQUEST, fetchSurvey);
}
