import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import { Perk } from './Perk';
import Spinner from './Spinner';
import { H2, H4 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import React from 'react';
import { useIntl } from 'react-intl';
import { Perk as PerkData, Perks, Tymber } from '../types/Tymbe';
import { hasPerkFulfilled } from '../utils';

export type TymberPerkListProps = {
  tymber: Tymber;
  perks: PerkData[];
  loading: boolean;
} & GetProps<typeof Card>;

export const TymberPerkList = ({ tymber, perks, loading, ...props }: TymberPerkListProps) => {
  const intl = useIntl();
  const visiblePerks = perks.filter(perk => perk.is_visible);

  const fulfilledPerks = visiblePerks
    .filter(perk => hasPerkFulfilled(tymber, perk.id, undefined, perk.isDocument));
  const unfulfilledPerks = visiblePerks
    .filter(perk => !hasPerkFulfilled(tymber, perk.id, undefined, perk.isDocument))
    .filter(perk => perk.group !== 'QUALIFICATION')
    // when is_visible is set to false for these, we can remove this part of the code
    .filter(perk => ![Perks.MAN_ONLY, Perks.WOMAN_ONLY, Perks.NON_EU_ONLY, Perks.EU_ONLY].includes(perk.id));
  return (
    <Card {...props}>
      <CardHeader>
        <Icon style={{ float: 'right' }} id={'perks'} color={'white'} size={'2em'} />
        <H2>{intl.formatMessage({
          defaultMessage: 'Podmínky',
          description: 'Titulek sekce v šatně',
        })}</H2>
      </CardHeader>
      <CardContent>
        {loading && <Spinner position={'absolute'} />}
        {!loading && (<>
          {fulfilledPerks.length > 0 && (<>
            <H4>{intl.formatMessage({
              defaultMessage: 'Splněné:',
              description: 'Seznam splněných podmínek',
            })}</H4>
            {fulfilledPerks.map(perk => <Perk key={perk.id} {...perk} tymber={tymber} />)}
          </>)}
          {unfulfilledPerks.length > 0 && (<>
            <H4>{intl.formatMessage({
              defaultMessage: 'Nesplněné:',
              description: 'Seznam nesplněných podmínek',
            })}</H4>
            {unfulfilledPerks.map(perk => <Perk key={perk.id} {...perk} tymber={tymber} />)}
          </>)}
        </>)}
      </CardContent>
      <CardFooter />
    </Card>
  );
};
