import { Actions, GetMainContractsActions } from '../../actions/mainContracts';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_SUCCESS } from '../../types/StoreUtils';
import { RingDocument } from '../../types/Tymbe';

export type MainContractsState = DataStoreState<RingDocument[]>;

const initialState: MainContractsState = initDataStoreState<RingDocument[]>(null);

export const mainContractsReducer: Reducer<MainContractsState, GetMainContractsActions> =
  (state = initialState, action): MainContractsState => {
    switch (action.type) {
      case Actions.GET_MAIN_CONTRACTS_REQUEST:
        return initDataStoreState<RingDocument[]>();
      case Actions.GET_MAIN_CONTRACTS_SUCCESS:
        return initDataStoreState<RingDocument[]>(STATUS_SUCCESS, action.documents);
      case Actions.GET_MAIN_CONTRACTS_FAILURE:
        return initDataStoreState<RingDocument[]>(STATUS_ERROR, null, action.error);
      default:
        return state;
    }
  };
