import { ContractToShorten } from '@tymbe/legislatives/ContractToShorten';
import { Action } from 'redux';

import { ApiError, Ring, RingDocument } from '../../../types/Tymbe';

export const enum ActionTypes {
  DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
  DOCUMENT_SUCCESS = 'DOCUMENT_SUCCESS',
  DOCUMENT_FAILURE = 'DOCUMENT_FAILURE',
  DOCUMENT_SIGN_REQUEST = 'DOCUMENT_SIGN_REQUEST',
  DOCUMENT_SIGN_SUCCESS = 'DOCUMENT_SIGN_SUCCESS',
  DOCUMENT_SIGN_ERROR = 'DOCUMENT_SIGN_ERROR',
}

export interface DocumentRequestAction extends Action<ActionTypes.DOCUMENT_REQUEST> {
  documentId: RingDocument['id'];
  tymbe: boolean;
}

export interface DocumentSuccessAction extends Action<ActionTypes.DOCUMENT_SUCCESS> {
  document: RingDocument;
}

export interface DocumentFailureAction extends Action<ActionTypes.DOCUMENT_FAILURE> {
  documentId: RingDocument['id'];
  tymbe: boolean;
  error: ApiError;
}

export interface DocumentSignRequestAction extends Action<ActionTypes.DOCUMENT_SIGN_REQUEST> {
  ringId: Ring['id'];
  documentId: RingDocument['id'];
  tymbe: boolean;
  signature: string;
  passed?: string;
  shorten?: ContractToShorten;
}

export interface DocumentSignSuccessAction extends Action<ActionTypes.DOCUMENT_SIGN_SUCCESS> {
  document: RingDocument;
}

export interface DocumentSignFailureAction extends Action<ActionTypes.DOCUMENT_SIGN_ERROR> {
  documentId: RingDocument['id'];
  tymbe: boolean;
  error: string;
}

export type DocumentActions = DocumentRequestAction | DocumentSuccessAction | DocumentFailureAction;
export type DocumentSignActions = DocumentSignRequestAction | DocumentSignSuccessAction | DocumentSignFailureAction;

export const documentRequest = (documentId: RingDocument['id'], tymbe: boolean): DocumentRequestAction => ({
  type: ActionTypes.DOCUMENT_REQUEST,
  tymbe,
  documentId,
});

export const documentSuccess = (document: RingDocument): DocumentSuccessAction => ({
  type: ActionTypes.DOCUMENT_SUCCESS,
  document,
});

export const documentFailure =
  (documentId: RingDocument['id'], tymbe: boolean, error: ApiError): DocumentFailureAction => ({
    type: ActionTypes.DOCUMENT_FAILURE,
    documentId,
    tymbe,
    error,
  });

export const documentSignRequest =
  (
    ringId: Ring['id'],
    documentId: RingDocument['id'],
    tymbe: boolean,
    signature: string,
    passed?: string,
    shorten?: ContractToShorten,
  ): DocumentSignRequestAction =>
    ({
      type: ActionTypes.DOCUMENT_SIGN_REQUEST,
      ringId,
      documentId,
      tymbe,
      signature,
      passed,
      shorten,
    });

export const documentSignSuccess = (document: RingDocument): DocumentSignSuccessAction => ({
  type: ActionTypes.DOCUMENT_SIGN_SUCCESS,
  document,
});

export const documentSignFailure =
  (documentId: RingDocument['id'], tymbe: boolean, error: string): DocumentSignFailureAction => ({
    type: ActionTypes.DOCUMENT_SIGN_ERROR,
    documentId,
    tymbe,
    error,
  });
