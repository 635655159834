import moment from 'moment-timezone';
import { constantConfig } from '../config/ConstantConfig';
import { ConstantType } from './ConstantType';
import { ConstantValueNotFound } from './ConstantValueNotFoundError';

export function getConst(name: ConstantType, date: string, timezone = 'Europe/Prague') {
  const targetTime = moment.tz(date, timezone);

  const records = constantConfig[name]
    .filter((record) => moment.tz(record.validSince, timezone) <= targetTime)
    .sort(
      (recordA, recordB) =>
        new Date(recordB.validSince).getTime() - new Date(recordA.validSince).getTime()
    );

  if (records.length <= 0) {
    throw new ConstantValueNotFound(name, targetTime.toDate());
  }

  return records[0].value;
}
