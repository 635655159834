import { Card } from '../components/Card';
import { CardContent } from '../components/Card/CardContent';
import { CardFooter } from '../components/Card/CardFooter';
import { CardHeaderCentered } from '../components/Card/CardHeader';
import { H2, H5 } from '../components/Styles/BasicElements';
import React from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import api from '../services/api';
import moment from 'moment';
import { Toggleable } from '../components/Toggleable';
import { RingToggleIcon } from '../components/Ring';
import { CzechHolidayCalculator } from '@tymbe/legislatives/czechia/CzechHolidayCalculator';

type PayoutStatusEstimateMap = Record<string, number[]>;

const PayoutStatus = () => {
  const intl = useIntl();

  const { data: payoutStatusData } = useQuery(['payoutStatus'],
    async () => api.getPayoutStatus(),
  );

  const payoutStatus = payoutStatusData?.data[0]?.value || 10;

  const PayoutStatusEstimate: PayoutStatusEstimateMap = {
    1: [1],
    5: [2, 5],
    10: [6, 10],
    15: [11, 15],
    20: [16, 20],
  }

  const { data: unpaidWagesData } = useQuery(['unpaidWages'],
    async () => api.getUnpaidWages(),
  );

  const unpaidWages = unpaidWagesData?.data;
  const [expanded, changeExpanded] = React.useState(false);

  const toggle = () => {
    changeExpanded(!expanded)
  };

  const skipWeekendsAndHolidays = (date: string, estimatedPayOutEndDay: number) => {
    const startDate = moment(date);
    const endDate = startDate.clone().add(estimatedPayOutEndDay, 'days');
    const weekDays: string[] = [];

    for (const currentDate = startDate.clone(); currentDate.isSameOrBefore(endDate); currentDate.add(1, 'day')) {
      if (currentDate.day() === 6 || currentDate.day() === 0 || new CzechHolidayCalculator().isDayHoliday(currentDate)) {
        endDate.add(1, 'day');
      } else {
        weekDays.push(currentDate.format('DD.MM.YYYY'));
      }
    }
    return weekDays;
  };

  const setPayOutDates = (date: string, estimatedPayOutDays: number[]) => {
    const weekDays = skipWeekendsAndHolidays(date, estimatedPayOutDays[1]);

    return moment(weekDays[estimatedPayOutDays[0]], 'DD.MM.YYYY').format('DD.MM.') + ' - ' + weekDays[weekDays.length - 1];
  };

  const getPayoutDescription = () => {
    const delayWord = payoutStatus + " " + (payoutStatus === '1' ? intl.formatMessage({
      defaultMessage: 'pracovního dne',
      description: 'Skloňuje slovo den pro číslo 1',
    }) : intl.formatMessage({
      defaultMessage: 'pracovních dní',
      description: 'Skloňuje slovo den pro číslo 2 a vyšší',
    }));

    return intl.formatMessage({
      defaultMessage: 'Vyplácíme do {delayWord}',
      description: 'Skloňuje slovo den pro číslo 2 a vyšší',
    }, { delayWord });
  }

  return (
    <Card color={'gold'} onClick={toggle}>
      <CardHeaderCentered>
        <div className='flex items-center'>
          <RingToggleIcon toggled={expanded} />
          <H2>{intl.formatMessage({
            defaultMessage: 'Výplatní termíny',
            description: 'Nadpis sekce na strance výplat',
          })}</H2>
        </div>
      </CardHeaderCentered>
      <CardContent>
        <div className='flex justify-around items-center p-2 text-xl'>
          <strong>{getPayoutDescription()}</strong>
        </div>
        <hr />

        <Toggleable toggled={!!expanded}>
          {!unpaidWages?.length ? <div>{intl.formatMessage({
            defaultMessage: 'Žádné aktivní žádosti o platby.',
            description: 'Žádné aktivní žádosti o platby.',
          })}</div> :
            <div>
              <div className='flex justify-between pb-6'>
                <H5 style={{ display: 'inline' }}>{intl.formatMessage({
                  defaultMessage: 'Žádosti o platby',
                  description: 'Žádosti o platby',
                })}</H5>
                <H5 style={{ display: 'inline' }}>{intl.formatMessage({
                  defaultMessage: 'Splatnost',
                  description: 'Splatnost',
                })}</H5>
              </div>
              <div>{unpaidWages?.map((val) =>
                <div className='flex justify-between pb-5' key={val.id}>
                  <div>
                    <div>{moment(val.paymentRequest?.created_at).format("DD.MM.YYYY")}</div>
                    <div>{val.company?.name}</div>
                  </div>
                  <div>
                    {val.paymentRequest?.created_at && (
                      <>
                        {PayoutStatusEstimate[payoutStatus]?.length > 1 ?
                          <div>
                            {setPayOutDates(val.paymentRequest?.created_at, PayoutStatusEstimate[payoutStatus])}
                          </div> :
                          <div>
                            {skipWeekendsAndHolidays(val.paymentRequest?.created_at, PayoutStatusEstimate[payoutStatus]?.[0])?.[1] ?? '-'}
                          </div>}
                      </>
                    )}
                  </div>
                </div>
              )}
              </div>
            </div>}
        </Toggleable>
      </CardContent>
      <CardFooter />
    </Card>
  )
}

export default PayoutStatus;
