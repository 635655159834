import { Card } from '../Card';
import { CardContent } from '../Card/CardContent';
import { CardFooter } from '../Card/CardFooter';
import { CardHeaderCentered } from '../Card/CardHeader';
import { Button } from '../Forms/Button';
import Modal, { ModalProps } from './index';
import { H2 } from '../Styles/BasicElements';
import React from 'react';
import { useIntl } from 'react-intl';

export type PasswordModalProps = ModalProps & {
  onCancel: () => void;
  title?: string;
  submitText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  disable: boolean;
};

export const WageDetailModal = ({
  children, onCancel, title, submitText, cancelText, disable, ...rest
}: PasswordModalProps) => {

  const intl = useIntl();
  // tslint:disable-next-line:no-parameter-reassignment
  cancelText = cancelText || intl.formatMessage({
    defaultMessage: 'Zavřít detail',
    description: 'Tlačítko zavře okno detailu odměny',
  });
  return (
    <Modal {...rest}>
      <Card>
        <CardHeaderCentered><H2>{title}</H2></CardHeaderCentered>
        <CardContent>
          {children}
        </CardContent>
        <CardFooter>
          <Button onClick={onCancel} type={'button'}>{cancelText}</Button>
        </CardFooter>
      </Card>
    </Modal>
  );
};
