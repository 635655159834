import { action } from '../actions/utils';
import React, { DependencyList } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';

export const CLEAR_STATE = 'CLEAR_STATE';
export interface ClearStateAction extends Action<typeof CLEAR_STATE> {
  state: string;
}
export const clearState = (state: string): ClearStateAction => action(CLEAR_STATE, { state });

export const useClearStateOnUnmount = (state: string, deps?: DependencyList) => {
  const dispatch = useDispatch();
  React.useEffect(
    () => () => {
      dispatch(clearState(state));
    },
    deps,
  );
};
