import { Action } from 'redux';
import { ApiError, BucketFile } from '../../types/Tymbe';

export const enum ActionTypes {
  BUCKET_FILE_REQUEST = 'BUCKET_FILE_REQUEST',
  BUCKET_FILE_SUCCESS = 'BUCKET_FILE_SUCESS',
  BUCKET_FILE_FAILURE = 'BUCKET_FILE_FAILURE',
}

export interface BucketFileRequestAction extends Action<ActionTypes.BUCKET_FILE_REQUEST> {
  path: string;
}
export interface BucketFileSucessAction extends Action<ActionTypes.BUCKET_FILE_SUCCESS> {
  bucketFile: BucketFile;
}
export interface BucketFileFailureAction extends Action<ActionTypes.BUCKET_FILE_FAILURE> {
  path: string;
  error: ApiError;
}

export type BucketFileActions = BucketFileRequestAction | BucketFileSucessAction | BucketFileFailureAction;

export const bucketFileRequest = (path: string): BucketFileRequestAction => ({
  type: ActionTypes.BUCKET_FILE_REQUEST,
  path
});

export const bucketFileSuccess = (bucketFile: BucketFile): BucketFileSucessAction => ({
  type: ActionTypes.BUCKET_FILE_SUCCESS,
  bucketFile
});

export const bucketFileFailure = (path: string, error: ApiError): BucketFileFailureAction => ({
  type: ActionTypes.BUCKET_FILE_FAILURE,
  path,
  error
});
