import { useAddFootNote } from './FootNotesContext';
import { WageDetailRow, WageRowProps } from './WageDetailRow';

type WageDetailRowWithNoteProps = WageRowProps & {
  note: string;
};

export const WageDetailRowWithNote = ({ note, ...otherArgs }: WageDetailRowWithNoteProps) => {
  const footNoteIndex = useAddFootNote(note, note);

  return (
    <WageDetailRow
      footNoteIndex={footNoteIndex}
      {...otherArgs}
    />
  );
};
