import { CzechSalaryType } from '@tymbe/legislatives/czechia/CzechSalaryType';
import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { Action } from 'redux';

import { action } from './utils';
import { ApiError } from '../types/Tymbe';

export enum Actions {
  GET_WAGES_REQUEST = 'GET_WAGES_REQUEST',
  GET_WAGES_SUCCESS = 'GET_WAGES_SUCCESS',
  GET_WAGES_FAILURE = 'GET_WAGES_FAILURE',
  PAYOUT_WAGE_REQUEST = 'PAYOUT_WAGE_REQUEST',
  PAYOUT_WAGE_SUCCESS = 'PAYOUT_WAGE_SUCCESS',
  PAYOUT_WAGE_FAILURE = 'PAYOUT_WAGE_FAILURE',
}

export interface GetWagesRequestAction extends Action<Actions.GET_WAGES_REQUEST> {
}

export interface GetWagesSuccessAction extends Action<Actions.GET_WAGES_SUCCESS> {
  wages: PayoutInfoData[];
}

export interface GetWagesFailureAction extends Action<Actions.GET_WAGES_FAILURE> {
  error: ApiError;
}

export interface PayoutWageRequestAction extends Action<Actions.PAYOUT_WAGE_REQUEST> {
  date_time: string;
  company_id: number;
  salary_type: CzechSalaryType;
  password: string;
}

export interface PayoutWageSuccessAction extends Action<Actions.PAYOUT_WAGE_SUCCESS> {
}

export interface PayoutWageFailureAction extends Action<Actions.PAYOUT_WAGE_FAILURE> {
  error: ApiError;
}

export type GetWagesActions = GetWagesRequestAction | GetWagesSuccessAction | GetWagesFailureAction;
export type PayoutWageActions = PayoutWageRequestAction | PayoutWageSuccessAction | PayoutWageFailureAction;

export const getWagesRequest = (): GetWagesRequestAction =>
  action(Actions.GET_WAGES_REQUEST);
export const getWagesSuccess = (wages: PayoutInfoData[]): GetWagesSuccessAction =>
  action(Actions.GET_WAGES_SUCCESS, { wages });
export const getWagesFailure = (error: ApiError): GetWagesFailureAction =>
  action(Actions.GET_WAGES_FAILURE, { error });

export const payoutWageRequest = (date_time: string, company_id: number, salary_type: CzechSalaryType, password: string): PayoutWageRequestAction =>
  action(Actions.PAYOUT_WAGE_REQUEST, { date_time, company_id, salary_type, password });
export const payoutWageSuccess = (): PayoutWageSuccessAction =>
  action(Actions.PAYOUT_WAGE_SUCCESS, {});
export const payoutWageFailure = (error: ApiError): PayoutWageFailureAction =>
  action(Actions.PAYOUT_WAGE_FAILURE, { error });
