import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

import Spinner from './Spinner';
import { withFetchNew } from './withFetch';
import { withLoaderNew } from './withLoading';
import { tymberDataRequest } from '../actions/tymberData';
import { tymberSelector } from '../reducers';
import { TymberStates } from '../types/Tymbe';

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const tymber = useSelector(tymberSelector).data;
  return tymber?.state === TymberStates.ACTIVE
    ? children
    : <Navigate to="/locker" />;
};

export default withFetchNew(tymberSelector, tymberDataRequest)(
  withLoaderNew(tymberSelector, <Spinner />)(
    ProtectedRoute,
  ),
);
