import Logo from './Logo';
import { MainNavigation } from './MainNavigation';
import MainSideBar from './MainSideBar';
import HorizontalMenu from './Menu/HorizontalMenu';
import MenuItem from './Menu/MenuItem';
import MenuSpacer from './Menu/MenuSpacer';
import MenuToggler from './Menu/MenuToggler';
import * as React from 'react';
import { styled } from '../styledComponents';

const MainTopBar = styled(HorizontalMenu)`
  position: fixed;
  height: 100px;
  margin: 0;
  padding: 0 4rem;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.bodyBg};
  z-index: 100;

  > *:first-child {
    margin-left: 0;
    padding-left: 0;
  }

  > *:last-child {
    margin-right: 0;
    padding-right: 0;
  }

  @media (min-width: 1435px) {
    & > ${MenuSpacer}:first-of-type, ${MenuToggler} {
      display: none;
    }
  }
`;

const Navigation = styled(MainNavigation)`
  @media (max-width: 1434px) {
    display: none;
  }
`;

export type MainMenuProps = {};

export default () => {
  const [menuShown, setMenuShow] = React.useState(false);

  const toggleMenu = (_e: React.MouseEvent) => {
    setMenuShow(!menuShown);
  };
  return (
    <MainTopBar>
      <MenuItem to={'/'}><Logo css={'height: 50px'} /></MenuItem>
      <MenuSpacer />
      <MenuToggler onClick={toggleMenu} />
      <MainSideBar toggled={menuShown} onClose={toggleMenu} />
      <Navigation />
    </MainTopBar>
  );
};
