import 'core-js';
import { init as initSentry, BrowserTracing } from '@sentry/react';
import { render } from 'react-dom';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { Root } from './containers/Root';
import root from './sagas/root';
import { configureStore } from './store/rootStore';
import { ThemeProvider } from './styledComponents';
import theme from './themes/default';

import 'moment/locale/cs';
import 'moment/locale/uk';
import './index.scss';

const { store, persistor } = configureStore();
store.runSaga(root);

let baseName = '';
const base = document.querySelector('base');
if (base) {
  baseName = base.attributes.getNamedItem('href')?.value || '';
}

if (['production', 'stage'].includes(import.meta.env.MODE)) {
  initSentry({
    dsn: 'https://fee8483128894628835426a543a37800@o4503999020531712.ingest.sentry.io/4503999343820800',
    environment: import.meta.env.MODE,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient();

ReactGA.initialize('G-QPEV9FKYH4', {
  // Uncomment to enable debug mode
  // gaOptions: {
  //   debug_mode: true,
  // },
  // gtagOptions: {
  //   debug_mode: true,
  // },
});

render(
  <QueryClientProvider contextSharing client={queryClient}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename={baseName}>
            <Root />
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
    {import.meta.env.MODE !== 'test' && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>,
  document.getElementById('tymbe'),
);
