import { MessageFormatElement } from '@formatjs/icu-messageformat-parser/types';
import { action } from './utils';
import { Action } from 'redux';

export enum Actions {
  GET_LOCALIZATION_REQUEST = 'GET_LOCALIZATION_REQUEST',
  GET_LOCALIZATION_SUCCESS = 'GET_LOCALIZATION_SUCCESS',
}

export interface GetLocalizationRequest extends Action<Actions.GET_LOCALIZATION_REQUEST> {
  locale: string;
}

export interface GetLocalizationSuccess extends Action<Actions.GET_LOCALIZATION_SUCCESS> {
  messages: Record<string, MessageFormatElement[]>;
}

export type LocalizationActions = GetLocalizationRequest | GetLocalizationSuccess;

export const getLocalizationRequest = (locale: string) => action(Actions.GET_LOCALIZATION_REQUEST, { locale });
export const getLocalizationSuccess = (messages: Record<string, MessageFormatElement[]>) =>
  action(Actions.GET_LOCALIZATION_SUCCESS, { messages });
