type JsonValue = string | number | boolean | null | JsonObject;

interface JsonObject {
  [key: string | number]: JsonValue;
}

const getQueryArrayLength = (obj: JsonObject, maxAllowedLength: number): string[] => {
  const result: string[] = [];

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (['$in', '$nin', '$or', '$and', '$select'].includes(key) && typeof value === 'object' && value !== null) {
      // Validate length for dictionaries with numeric keys
      if (Object.keys(value).includes(maxAllowedLength.toString())) {
        result.push(key);
      }
    }

    if (typeof value === 'object' && value !== null) {
      const subResult = getQueryArrayLength(value as JsonObject, maxAllowedLength);
      if (subResult.length > 0) {
        result.push(key);
      }
    }

    if (value && Array.isArray(value)) {
      const arrayResult = value.map((item) => {
        if (typeof item === 'object' && item !== null) {
          return getQueryArrayLength(item as JsonObject, maxAllowedLength);
        }
        return [];
      });

      if (arrayResult.some((subResult) => subResult.length > 0)) {
        result.push(key);
      }
    }
  });

  return result;
};
export default getQueryArrayLength;
