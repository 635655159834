import { tymberDataRequest } from '../actions/tymberData';
import Spinner from '../components/Spinner';
import { withFetchNew } from '../components/withFetch';
import { withLoaderNew } from '../components/withLoading';
import { connect } from 'react-redux';
import { StoreState, tymberSelector } from '../reducers';
import { Payments } from '../components/Payments';
import withMapData from '../components/withMapData';

const PaymentsConnect = connect(
  (state: StoreState) => ({
    ...tymberSelector(state),
  })
);

export default PaymentsConnect(
  withFetchNew(tymberSelector, tymberDataRequest)(
    withLoaderNew(tymberSelector, <Spinner position="absolute" />)(
      withMapData((data) => ({ tymber: data }))(
        Payments,
      ),
    ),
  ),
);

