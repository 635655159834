import { call, takeLatest } from '@redux-saga/core/effects';
import { Actions, RequestPasswordResetAction } from '../actions/forgotPassword';
import api from '../services/api';

export function* requestPasswordReset({ email }: RequestPasswordResetAction) {
  try {
    yield call(api.forgotPassword, email);
  } catch {
  }
}

export function* watchRequestPasswordReset() {
  yield takeLatest(Actions.REQUEST_PASSWORD_RESET, requestPasswordReset);
}
