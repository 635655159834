import { useModalError } from './Modal/ModalError';
import Spinner from './Spinner';
import { Icon } from './Styles/Icon';
import React from 'react';
import { styled } from '../styledComponents';
import { ApiError, TymberDocumentPart } from '../types/Tymbe';

export type TymberDocumentFileProps = {
  file: TymberDocumentPart;
  download: (id: TymberDocumentPart['id']) => void;
  loading: boolean;
  error: ApiError;
};

export const TymberDocumentFile = ({ file, download, loading, error }: TymberDocumentFileProps) => {
  const showError = useModalError();
  if (error) showError(error.message);
  return (
    <FileItem>
      <Button onClick={() => download(file.id)} disabled={loading}>
        {loading &&
        <Spinner position={'relative'} style={{ width: '3rem', minHeight: '3rem' }} /> ||
        <Icon id={'download'} size={'3rem'} background={'gray4'} color={'secondary'} />
        }
      </Button>
      {file.title}
    </FileItem>
  );
};

const FileItem = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: center;
  margin-bottom: .5rem;
  overflow-wrap: anywhere;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Button = styled.button`
  margin: 0 1rem 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
`;
