import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Ring } from '../types/Tymbe';

export enum Actions {
  INVITATIONS_REQUEST = 'INVITATIONS_REQUEST',
  INVITATIONS_SUCCESS = 'INVITATIONS_SUCCESS',
  INVITATIONS_FAILURE = 'INVITATIONS_FAILURE',

  INVITATION_REJECT_REQUEST = 'INVITATION_REJECT_REQUEST',
  INVITATION_REJECT_SUCCESS = 'INVITATION_REJECT_SUCCESS',
  INVITATION_REJECT_FAILURE = 'INVITATION_REJECT_FAILURE',

  MORE_INVITATIONS_REQUEST = 'MORE_INVITATIONS_REQUEST',
  MORE_INVITATIONS_SUCCESS = 'MORE_INVITATIONS_SUCCESS',
  MORE_INVITATIONS_FAILURE = 'MORE_INVITATIONS_FAILURE',
}

export interface InvitationsRequestAction extends Action<Actions.INVITATIONS_REQUEST> {
  filters?: object;
}

export interface InvitationsSuccessAction extends Action<Actions.INVITATIONS_SUCCESS> {
  rings: Ring[];
}

export interface InvitationsFailureAction extends Action<Actions.INVITATIONS_FAILURE> {
  error: ApiError;
}

export interface InvitationRejectRequestAction extends Action<Actions.INVITATION_REJECT_REQUEST> {
  ringId: Ring['id'];
}

export interface InvitationRejectSuccessAction extends Action<Actions.INVITATION_REJECT_SUCCESS> {
  ring: Ring;
}

export interface InvitationRejectFailureAction extends Action<Actions.INVITATION_REJECT_FAILURE> {
  error: ApiError;
}

export interface MoreInvitationsRequestAction extends Action<Actions.MORE_INVITATIONS_REQUEST> {
  filters?: object;
}

export interface MoreInvitationsSuccessAction extends Action<Actions.MORE_INVITATIONS_SUCCESS> {
  rings: Ring[];
}

export interface MoreInvitationsFailureAction extends Action<Actions.MORE_INVITATIONS_FAILURE> {
  error: ApiError;
}

export type InvitationsActions =
  InvitationsRequestAction
  | InvitationsSuccessAction
  | InvitationsFailureAction;

export type InvitationRejectActions =
  InvitationRejectRequestAction
  | InvitationRejectSuccessAction
  | InvitationRejectFailureAction;

export type MoreInvitationsActions =
  MoreInvitationsRequestAction
  | MoreInvitationsSuccessAction
  | MoreInvitationsFailureAction;

export const invitationsRequest = (filters?: object): InvitationsRequestAction =>
  action(Actions.INVITATIONS_REQUEST, { filters });
export const invitationsSuccess = (rings: Ring[]): InvitationsSuccessAction =>
  action(Actions.INVITATIONS_SUCCESS, { rings });
export const invitationsFailure = (error: ApiError): InvitationsFailureAction =>
  action(Actions.INVITATIONS_FAILURE, { error });

export const invitationRejectRequest = (ringId: Ring['id']): InvitationRejectRequestAction =>
  action(Actions.INVITATION_REJECT_REQUEST, { ringId });
export const invitationRejectSuccess = (ring: Ring): InvitationRejectSuccessAction =>
  action(Actions.INVITATION_REJECT_SUCCESS, { ring });
export const invitationRejectFailure = (error: ApiError): InvitationRejectFailureAction =>
  action(Actions.INVITATION_REJECT_FAILURE, { error });

export const moreInvitationsRequest = (filters?: object): MoreInvitationsRequestAction =>
  action(Actions.MORE_INVITATIONS_REQUEST, { filters });

export const moreInvitationsSuccess = (rings: Ring[]): MoreInvitationsSuccessAction =>
  action(Actions.MORE_INVITATIONS_SUCCESS, { rings });

export const moreInvitationsFailure = (error: ApiError): MoreInvitationsFailureAction =>
  action(Actions.MORE_INVITATIONS_FAILURE, { error });
