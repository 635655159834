import { CzechSalaryType } from '@tymbe/legislatives/czechia/CzechSalaryType';
import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { payoutWageRequest } from '../actions/wages';
import { WageItem } from '../components/WageItem';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { dataSelector, StoreState } from '../reducers';
import { dataWageSelector } from '../reducers/data';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';

export type WageItemProps = {
  wage: PayoutInfoData;
};

const selectWages = compose(dataWageSelector, dataSelector);

const WageItemConnect = connect(
  (state: StoreState) => selectWages(state),
  dispatch => ({
    payout: (date_time: string, company_id: number, salary_type: CzechSalaryType, password: string) =>
      dispatch(payoutWageRequest(date_time, company_id, salary_type, password)),
  }),
);

export default WageItemConnect(
  withMapData((data, status, error) => ({ wages: data, error, loading: status === STATUS_PENDING }))(
    WageItem,
  ),
);
