import { FormItem } from './FormItem';
import { Error, Input } from './LabeledInput';
import { ToggleableComponent } from '../Toggleable';
import { FieldProps, useField } from 'informed';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '../../styledComponents';
import { InputHTMLAttributes } from 'react';

const DropDownList = styled(ToggleableComponent)`
  display: flex;
  flex-flow: column;
  border: 2px solid ${({ theme }) => theme.colors.gray3};
  ${({ toggled }) => !toggled && 'border-bottom-width: 0'};
  ${({ toggled }) => !toggled && 'border-top-width: 0'};
  transition: border-width 0.3s ${({ toggled }) => toggled ? 'step-start' : 'step-end'};
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: -2px;
  overflow: auto !important;
  overflow-scrolling: auto;
  z-index: 10;
  & > .last:not(:only-child) {
    display: none;
  }
`;

const DropDownListItem = styled.button.attrs({ type: 'button' })`
  border: none;
  background: ${({ theme }) => theme.bodyBg};
  padding: 1rem 2rem;
  font-size: 2rem;
  border-bottom: 1px dashed black;
  &:last-child {
    border-bottom: none;
  }
  &:not(.last):hover {
    background: ${({ theme }) => theme.colors.gray2};
  }
`;

export type DropDownData = { [id: string]: DropDownDataValue };
export type DropDownDataValue = string | [number, string];

export type DropDownProps = {
  label: string;
  id: string;
  data: DropDownData,
} & InputHTMLAttributes<HTMLInputElement>;

export const DropDown = (props: FieldProps<DropDownProps>) => {
    const { fieldApi, fieldState, userProps, ref } = useField<DropDownProps, number | string>(props);
    const { setValue, setTouched, setError } = fieldApi;
    const { value } = fieldState;
    const { label, id, data: list, ...rest } = userProps;

    const intl = useIntl();
    const invalidValueMessage = intl.formatMessage({
      defaultMessage: 'Neplatná hodnota',
      description: 'Zobrazená chyba při zadaní neplatné hodnoty',
    });

    const getItemValue = (val: DropDownDataValue) =>
      Array.isArray(val) ? val[1] : val || '';
    const getOrder = ([, val]: [string, DropDownDataValue]) =>
      Array.isArray(val) ? val[0] : 0;

    const getTextFromList = (val: string) => {
      const v = list[val];
      return Array.isArray(v) ? v[1] : v || '';
    };
    const getKeyFromList = (str: string) => {
      return Object.entries(list)
        .find(
          ([, val]) => getItemValue(val).toLocaleLowerCase() === (str.toLocaleLowerCase()),
        )?.[0] || '';
    };

    function getInputValue() {
      return inputValue;
    }

    const setFieldValue = (str: string) => {
      !!str ? setValue(getKeyFromList(str)) : setValue('');
    };

    const [showList, changeList] = React.useState(false);
    const [showError, changeShowError] = React.useState(true);
    const [inputValue, changeInputValue] = React.useState(getTextFromList(String(value)));
    const [filter, changeFilter] = React.useState('');

    return (
      <FormItem onBlur={() => changeList(false)}>
        <Input
          {...rest}
          id={id}
          ref={ref}
          value={getInputValue()}
          placeholder={label}
          onFocus={(e) => {
            (e.target as HTMLInputElement)?.select();
            changeList(true);
            changeShowError(false);
          }}
          onClick={() => changeList(true)}
          onChange={(e) => {
            changeInputValue(e.target.value);
            !!e.target.value && !getKeyFromList(e.target.value) ? setError(invalidValueMessage) : setError(undefined);
            setFieldValue(e.target.value);
            changeFilter(e.target.value);
          }}
          onBlur={(e) => {
            const key = !!e.target.value ? getKeyFromList(e.target.value) : '';
            setTouched(true);
            !!e.target.value && !key ? setError(invalidValueMessage) : setError(undefined);
            setFieldValue(e.target.value);
            changeInputValue(getTextFromList(key) || e.target.value);
            changeShowError(true);
          }}
          onKeyDown={(e) => {
            // hide on ESC key
            if (e.keyCode === 27) {
              changeList(false);
            }
          }}
        />
        {fieldState.error && showError && <Error>{fieldState.error as string}</Error>}
        <DropDownList toggled={showList} maxHeight={200} onClose={() => changeFilter('')}>
          <DropDownListItem className={'last'} disabled>Nic</DropDownListItem>
          {Object.entries(list)
            .filter(([, val]) =>
              getItemValue(val).toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            .sort((a, b) => getOrder(a) - getOrder(b))
            .map(([key, val]) =>
              <DropDownListItem
                key={key}
                tabIndex={-1}
                onMouseDown={() => {
                  setValue(key);
                  changeInputValue(getItemValue(val));
                  changeList(false);
                }}
              >
                {getItemValue(val)}
              </DropDownListItem>)}
        </DropDownList>
      </FormItem>
    );
  };
