import {
  DataStoreState,
  initDataStoreState,
  STATUS_ERROR,
  STATUS_PENDING,
  STATUS_SUCCESS,
} from '../../types/StoreUtils';
import { ApiError, BucketFile } from '../../types/Tymbe';
import { ActionTypes, BucketFileActions } from '../../actions/data/bucket';
import { combineReducers, Reducer } from 'redux';

type BucketFileByNameState = Record<string, DataStoreState<BucketFile>>;

type BucketFileAllNamesState = BucketFile['name'][];

export type BucketFileState = {
  byName: BucketFileByNameState,
  allNames: BucketFileAllNamesState,
}

const requestingBucketFile = (state: BucketFileByNameState, bucketFileName: BucketFile['name']): BucketFileByNameState => ({
    ...state,
    [bucketFileName]: initDataStoreState<BucketFile>(STATUS_PENDING),
  }
);

const failBucketFile = (state: BucketFileByNameState, bucketFileName: BucketFile['name'], error: ApiError): BucketFileByNameState => ({
  ...state,
  [bucketFileName]: state[bucketFileName] ?
    initDataStoreState<BucketFile>(STATUS_ERROR, state[bucketFileName].data, error) :
    initDataStoreState<BucketFile>(STATUS_ERROR, null, error),
});

const addBucketFileData = (state: BucketFileByNameState, bucketFile: BucketFile): BucketFileByNameState => ({
  ...state,
  [bucketFile.name]: initDataStoreState<BucketFile>(STATUS_SUCCESS, bucketFile),
});

const addBucketFileName = (state: BucketFileAllNamesState, name: BucketFile['name']): BucketFileAllNamesState =>
  state.indexOf(name) + 1 ? state : state.concat(name);

type BucketFileByNameActions = BucketFileActions;

const bucketFileByNameReducer: Reducer<BucketFileByNameState, BucketFileByNameActions> =
  (state = {}, action): BucketFileByNameState => {
    switch (action.type) {
      case ActionTypes.BUCKET_FILE_REQUEST:
        return requestingBucketFile(state, action.path);
      case ActionTypes.BUCKET_FILE_SUCCESS:
        return addBucketFileData(state, action.bucketFile);
      case ActionTypes.BUCKET_FILE_FAILURE:
        return failBucketFile(state, action.path, action.error);
      default:
        return state;
    }
  };

const bucketFileAllNamesReducer: Reducer<BucketFileAllNamesState, BucketFileByNameActions> =
  (state = [], action): BucketFileAllNamesState => {
    switch (action.type) {
      case ActionTypes.BUCKET_FILE_SUCCESS:
        return addBucketFileName(state, action.bucketFile.name);
      default:
        return state;
    }
  };

export const bucketFileReducer = combineReducers<BucketFileState>(
  {
    byName: bucketFileByNameReducer,
    allNames: bucketFileAllNamesReducer,
  },
);

export const bucketFileSelector = (state: BucketFileState, bucketFileName: BucketFile['name'] = '') =>
  state.byName[bucketFileName] || initDataStoreState<BucketFile>(null);
