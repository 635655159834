import { clearFix } from 'polished';
import { createGlobalStyle } from '../../styledComponents';

const FrontGlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  :root {
    font-size: 12px;
  }
  html, body {
    //font-size: 12px;
    margin: 0;
    padding: 0;
    height: 100%;
    color: ${({ theme }) => theme.bodyColor};
    background: ${({ theme }) => theme.bodyBg};
    font-family: 'Roboto', sans-serif;
  }
  a {
    cursor: pointer;
  }
  hr {
    margin: 1rem 0;
    border: 1px solid ${({ theme }) => theme.colors.gray3};
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  #tymbe {
    height: 100%;
  }
  .clearFix {
    ${clearFix()}
  }

  ${({ theme }) => Object.keys(theme.colors).map((color: keyof typeof theme.colors) =>
    `.${color}_color { color: ${theme.colors[color]} }`,
  )};
`;

export default FrontGlobalStyles;
