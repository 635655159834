import { Actions, GetTymberUtilitiesActions } from '../../actions/tymberUtilities';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { TymberUtility } from '../../types/Tymbe';

type Data = TymberUtility[];
export type TymberUtilitiesState = DataStoreState<Data>;

const initialState: TymberUtilitiesState = initDataStoreState<Data>(null);

type ReducerActions = GetTymberUtilitiesActions;

export const tymberUtilitiesReducer: Reducer<TymberUtilitiesState, ReducerActions> =
  (state = initialState, action) => {
    switch (action.type) {
      case Actions.GET_TYMBER_UTILITIES_REQUEST:
        return initDataStoreState<Data>(STATUS_PENDING);
      case Actions.GET_TYMBER_UTILITIES_SUCCESS:
        return initDataStoreState<Data>(STATUS_SUCCESS, action.utilities);
      case Actions.GET_TYMBER_UTILITIES_FAILURE:
        return initDataStoreState<Data>(STATUS_ERROR, state.data, action.error);
      default:
        return state;
    }
  };
