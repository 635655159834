import { intl } from '../I18n';
import moment from 'moment';

export const validateRequired = (text: string) => (value?: unknown) => {
  return !value ? text : undefined;
};
export const validatePattern = (pattern: RegExp | string, text: string) => (value: unknown) => {
  return typeof value === 'string' && !value.match(pattern) ? text : undefined;
};

export const validateDate = (format: string, text: string) => (value?: string) => {
  try {
    const date = moment(value, format, true);
    return !date.isValid() ? text : undefined;
  } catch (err) {
    return text;
  }
};

export const validateEmail = (value: unknown) =>
  validateRequired(intl.formatMessage({
    defaultMessage: 'Vyplňte prosím e-mail',
    description: 'Zobrazená chyba při nevyplnění email adresy',
  }))(value) ||
  validatePattern(/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/g, intl.formatMessage({
    defaultMessage: 'E-mail není ve správném formátu',
    description: 'Zobrazená chyba při vyplnění neplatné email adresy',
  }))(value);

export const validatePassword = <D extends {[K: string]: unknown}>(against?: keyof D) => (value: unknown, values: D) =>
  validateRequired(intl.formatMessage({
    defaultMessage: 'Vyplňte prosím heslo',
  }))(value) ||
  validatePattern(/^[a-zA-Z0-9]+$/, intl.formatMessage({
    defaultMessage: 'Heslo může obsahovat pouze malá/velká písmena a čísla',
  }))(value) ||
  validatePattern(/^[a-zA-Z0-9]{6,}$/, intl.formatMessage({
    defaultMessage: 'Heslo musí mít alespoň 6 znaků',
  }))(value) ||
  (against && value !== values[against]
    ? intl.formatMessage({ defaultMessage: 'Hesla se musí shodovat' })
    : undefined);
