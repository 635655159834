import { ShiftType } from '@tymbe/schema/enums';

import { cancelClaimedRequest } from '../../actions/claimed';
import { ringRequest } from '../../actions/data/rings';
import { Button } from '../Forms/Button';
import { Main } from '../Main';
import Spinner from '../Spinner';
import { H1 } from '../Styles/BasicElements';
import { Icon } from '../Styles/Icon';
import { WellBox } from '../Styles/WellBox';
import withFetch from '../withFetch';
import withLoading from '../withLoading';
import withMapData from '../withMapData';
import Ring from '../../containers/Ring';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { NavLink, useParams } from 'react-router-dom';
import { StoreState } from '../../reducers';
import { ringSelector } from '../../reducers/data/rings';
import { createGlobalStyle, styled } from '../../styledComponents';
import { LAST_MINUTE_CANCELLATION_MSECS } from '../../types/consts';
import { Ring as IRing } from '../../types/Tymbe';

const OverideStyles = createGlobalStyle`
  ${Main} {
    max-height: 100%;
  }
`;

const LeftCol = styled.div``;

const RingDetailContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  flex-grow: 1;

  & > * {
    padding: 2rem 3rem;
    overflow: auto;
  }

  @media (max-width: 755px) {
    ${LeftCol} {
      padding: 1rem !important;
    }
  }

  @media (max-width: 655px) {
    grid-template-columns: 1fr;
    ${LeftCol} {
      display: none;
    }
  }
`;

const RingCancelContainer = styled.div`
  //display: flex;
  //flex-flow: column;
  //align-items: center;
  display: flex;
  flex-flow: column;
  background-color: ${({ theme }) => theme.colors.gray2};
  @media (max-width: 755px) {
    padding: 1rem 1rem 0 !important;
  }
`;

const WellBoxExt = styled(WellBox)`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
`;

const CloseButton = styled(Button).attrs({ background: 'gray2' })`
  position: absolute;
  padding: 0.5rem;
  top: 1rem;
  right: 1rem;
`;

const SNavLink = styled(NavLink)`
  text-decoration: none;
`;

export type RingDetailProps = {
  ring: IRing;
  cancelClaimed: (isLastMinute: boolean) => void;
};

export const RingCancel = ({ ring, cancelClaimed }: RingDetailProps) => {
  const intl = useIntl();
  const timeToRingStart = moment(ring.time.start).diff(moment());
  const isNotVacation = ring.type !== ShiftType.VACATION;
  const [isLastMinute, setIsLastMinute] =
    React.useState(() => {
      return timeToRingStart <= LAST_MINUTE_CANCELLATION_MSECS;
    });
  const [hasAlreadyStarted, setHasAlreadyStarted] =
    React.useState(() => {
      return timeToRingStart <= 0;
    });

  React.useEffect(() => {
    const timeout = timeToRingStart - LAST_MINUTE_CANCELLATION_MSECS;
    /*
       setTimeout has implementation flaw resulting in immediate execution of delayed function
       when delay is bigger than SIGN_INT32
    */
    if (isLastMinute || timeout > 0x7FFFFFFF) return undefined;

    const timer = setTimeout(() => setIsLastMinute(true), timeout);
    return () => clearTimeout(timer);
  });

  React.useEffect(() => {
    /*
       setTimeout has implementation flaw resulting in immediate execution of delayed function
       when delay is bigger than SIGN_INT32
    */
    if (hasAlreadyStarted || timeToRingStart > 0x7FFFFFFF) return undefined;

    const timer = setTimeout(() => setHasAlreadyStarted(true), timeToRingStart);
    return () => clearTimeout(timer);
  });

  if (!ring.claimed) {
    return (<Navigate to="/before-fight" />);
  }

  return (
    <RingDetailContainer>
      <OverideStyles />
      <LeftCol>
        <Ring id={ring.id} />
      </LeftCol>
      <RingCancelContainer>
        <WellBoxExt color="gray4">
          <CloseButton as={SNavLink} to="/before-fight">
            <Icon id="false" color="gray4" background="gray2" size="2em" />
          </CloseButton>
          <Icon
            id="cancelShift"
            style={{ margin: '8% auto', maxWidth: '40%' }}
            size="50%"
            background="secondary"
          />
          <H1 style={{ marginBottom: '3rem' }}>
            {!hasAlreadyStarted
            && (isLastMinute && isNotVacation
              ? intl.formatMessage({
                defaultMessage: 'Pozor! Rušíš směnu příliš pozdě!',
                description: 'Nadpis stránky s potvrzením o zrušení přihlášení na směnu po termínu',
              }) : intl.formatMessage(
                {
                  defaultMessage: 'Opravdu chceš směnu zrušit?',
                  description: 'Nadpis stránky s potvrzením o zrušení přihlášení na směnu',
                },
              )
            )}
            {hasAlreadyStarted && intl.formatMessage({
              defaultMessage: 'Nelze zrušit směnu',
              description: 'Nadpis stránky s potvrzením o zrušení přihlášení na směnu pokud směna již začala',
            })}
          </H1>
          {isLastMinute && !hasAlreadyStarted && isNotVacation && (
            <WellBox color="danger" style={{ marginBottom: '3rem', fontSize: '1.5rem', padding: '2rem' }}>
              {intl.formatMessage(
                {
                  defaultMessage: 'Snažíš se zrušit směnu po termínu, je tedy nutné dodat omluvenku (vysvětlení absence) přes <A>mobilní aplikaci</A>.',
                  description: 'Doplňující informace na stránce s potvrzením o zrušení přihlášení na směnu',
                },
                {
                  A: (link) => (
                    <a
                      href="https://app.tymbe.com/shifts"
                      target="_blank"
                      rel="noreferrer"
                    ><u>{link}</u>
                    </a>
                  ),
                },
              )}<br />
              {intl.formatMessage({
                defaultMessage: 'Pokud omluvenku nedodáš, hrozí ti BAN - budeš mít omezený přístup do aplikace a nebudeš si moct přihlásit další směny! Opravdu chceš směnu zrušit?',
                description: 'Doplňující informace na stránce s potvrzením o zrušení přihlášení na směnu',
              })}
            </WellBox>
          )}
          {hasAlreadyStarted && (
            <WellBox color="danger" style={{ marginBottom: '3rem', fontSize: '1.5rem', padding: '2rem' }}>
              {intl.formatMessage({
                defaultMessage: 'Tato směna již začala a proto se z ní nemůžeš odhlásit',
                description: 'Doplňující informace na stránce s potvrzením o zrušení přihlášení na směnu pokud směnu nelze zrušit',
              })}
            </WellBox>
          )}
          {!hasAlreadyStarted && (
            <Button onClick={() => cancelClaimed(isLastMinute)} background="secondary">
              {isLastMinute && isNotVacation
                ? intl.formatMessage({
                  defaultMessage: 'Zrušit s omluvenkou',
                  description: 'Tlačítko potvrzující zrušení přihlášení na směnu s nutnosti dodat omluvenku',
                })
                : intl.formatMessage({
                  defaultMessage: 'Zrušit',
                  description: 'Tlačítko potvrzující zrušení přihlášení na směnu',
                })
              }
            </Button>
          )}
        </WellBoxExt>
      </RingCancelContainer>
    </RingDetailContainer>
  );
};

const RingCancelCont = connect(
  (state: StoreState, { id }: { id: IRing['id'] }) => ({
    ...ringSelector(state.data.rings, id),
  }),
  (dispatch, { id }) => ({
    fetch: () => dispatch(ringRequest(id)),
    cancelClaimed: (isLastMinute: boolean) => dispatch(cancelClaimedRequest(id, isLastMinute)),
  }),
)(
  withFetch(
    withLoading(Spinner)(
      withMapData((data) => ({ ring: data }))(
        RingCancel,
      ),
    ),
  ),
);

export default <P, >(props: Omit<P, 'id'>) => {
  const { ringId } = useParams();
  return <RingCancelCont {...props} id={Number(ringId)} />;
};
