import { MoreClaimedActions } from '../../actions/claimed';
import { Actions, CancelClaimedActions, ClearClaimedResultAction, GetClaimedActions } from '../../actions/claimed';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { Ring } from '../../types/Tymbe';

export type ClaimedState = {
  claimed: DataStoreState<Ring['id'][]>;
  cancelResult: Ring | null;
};

const initialState: ClaimedState = {
  claimed: initDataStoreState<Ring['id'][]>(null),
  cancelResult: null,
};

type ClaimedReducerActions = GetClaimedActions | CancelClaimedActions | ClearClaimedResultAction | MoreClaimedActions;

export const claimedReducer = (state: ClaimedState = initialState, action: ClaimedReducerActions): ClaimedState => {
  switch (action.type) {
    case Actions.GET_CLAIMED_REQUEST:
      return { ...state, claimed: { ...state.claimed, status: STATUS_PENDING } };
    case Actions.GET_CLAIMED_SUCCESS:
      return {
        ...state,
        claimed: { ...initialState.claimed, status: STATUS_SUCCESS, data: action.rings.map(ring => ring.id) },
      };
    case Actions.GET_CLAIMED_FAILURE:
      return { ...state, claimed: { ...initialState.claimed, status: STATUS_ERROR, error: action.error } };
    case Actions.CANCEL_CLAIMED_SUCCESS:
      return {
        ...state,
        claimed: { ...state.claimed, data: state.claimed.data?.filter(id => id !== action.ring.id) || null },
        cancelResult: action.ring,
      };
    case Actions.CLEAR_CANCEL_CLAIMED_RESULT:
      return { ...state, cancelResult: null };
    case Actions.MORE_CLAIMED_REQUEST:
      return { ...state, claimed: { ...state.claimed, status: STATUS_PENDING }};
    case Actions.MORE_CLAIMED_SUCCESS:
      return {
        ...state,
        claimed: {
          ...initialState.claimed,
          status: STATUS_SUCCESS,
          data: [...state.claimed.data!, ...action.rings.map(ring => ring.id)]
        }
      }
    case Actions.MORE_CLAIMED_FAILURE:
      return {...state, claimed: { ...initialState.claimed, status: STATUS_ERROR, error: action.error }}
    default:
      return state;
  }
};
