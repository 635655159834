import ContractPreview from './ContractPreview';
import SignedContractPreview from './SignedContractPreview';
import React from 'react';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../../../reducers';
import { ringDocumentsSelector } from '../../../reducers/components';
import { ringDocumentSelector } from '../../../reducers/components/ringDocuments';
import { compose } from 'redux';
import { Ring, RingDocument } from '../../../types/Tymbe';

export type ShowRingDocumentProps = {
  document: RingDocument;
  ringId: Ring['id'];
};

export const ShowRingDocument = ({ document, ringId }: ShowRingDocumentProps) =>
  document.signed ?
    <SignedContractPreview documentId={document.signed.id} tymbe={document.signed.tymbe} /> :
    <ContractPreview ringId={ringId} documentId={document.id} tymbe={document.tymbe} />;

const selectDocuments = compose(ringDocumentsSelector, componentsSelector);
export default connect(
  (state: StoreState, { id, tymbe }: { id: RingDocument['id'], tymbe: boolean }) => ({
    document: ringDocumentSelector(selectDocuments(state), id, tymbe)!,
  }),
)(ShowRingDocument);
