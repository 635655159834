import { logoutSuccess } from '../actions/logout';
import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeaderCentered } from './Card/CardHeader';
import { Button } from './Forms/Button';
import { Error, LabeledInput } from './Forms/LabeledInput';
import { validatePassword } from './Forms/validations';
import { H2 } from './Styles/BasicElements';
import { Center } from './Styles/Center';
import { Form, FormState } from 'informed';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../reducers';
import api from '../services/api';
import { ApiError } from '../types/Tymbe';

type FormData = {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

type ChangePasswordFormProps = {
  onSave: () => void;
  onCancel: () => void;
};
const ChangePasswordForm = ({ onCancel, onSave }: ChangePasswordFormProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { email } = useSelector(authSelector);
  const [error, setError] = useState<string | null>(null);
  const onSubmit = (formState: FormState) => {
    const formData = formState.values as FormData;
    setError(null);
    api.changePassword(formData.currentPassword, formData.newPassword)
      .then(() => onSave())
      .catch((err: ApiError) => {
        err.status === 401 && dispatch(logoutSuccess(email || ''));
        setError(err.message);
      });
  };
  return (
    <Card style={{ minWidth: '300px' }}>
      <Form onSubmit={onSubmit}>
        <CardHeaderCentered><H2>{intl.formatMessage({
          defaultMessage: 'Změna hesla',
          description: 'Nadpis sekce pro změnu hesla',
        })}</H2></CardHeaderCentered>
        <CardContent background={'white'}>
          <LabeledInput
            id={'currentPassword'}
            name={'currentPassword'}
            label={intl.formatMessage({
              defaultMessage: 'Současné heslo',
              description: 'Název formulařového prvku pro zadání aktuálního hesla',
            })}
            type={'password'}
          />
          <LabeledInput
            id={'newPassword'}
            name={'newPassword'}
            label={intl.formatMessage({
              defaultMessage: 'Nové heslo',
              description: 'Název formulařového prvku pro zadání nového hesla',
            })}
            type={'password'}
            validate={(val, vals) => {
              console.log(val);
              validatePassword('newPasswordRepeat')(val, vals);
            }}
            validateOn="submit"
            validateWhen={['newPasswordRepeat']}
          />
          <LabeledInput
            id={'newPasswordRepeat'}
            name={'newPasswordRepeat'}
            label={intl.formatMessage({
              defaultMessage: 'Potvrdit nové heslo',
              description: 'Název formulařového prvku pro ověření nového hesla',
            })}
            type={'password'}
            validate={validatePassword('newPassword')}
            validateOn="blur"
            validateWhen={['newPassword']}
          />
          {error && <Center><Error>{error}</Error></Center>}
        </CardContent>
        <CardFooter>
          <Button type={'button'} background={'secondary'} onClick={onCancel}>{intl.formatMessage({
            defaultMessage: 'Zrušit',
            description: 'Tlačítko pro zrušení operace změny hesla',
          })}</Button>
          <Button type={'submit'}>{intl.formatMessage({
            defaultMessage: 'Uložit',
            description: 'Tlačítko pro uložení zadaného nového hesla',
          })}</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default ChangePasswordForm;
