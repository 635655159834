import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

export interface FootNotesContext {
  addFootNote: (footNote?: ReactNode, key?: string) => number | undefined;
}

export const footNotesContext = createContext<FootNotesContext>({
  addFootNote: () => undefined,
});

export interface FootNote {
  key: string;
  text: ReactNode;
}

export const useAddFootNote = (text?: ReactNode, key?: string) => {
  const [ index, setIndex ] = useState<number>();
  const { addFootNote } = useContext(footNotesContext);

  useEffect(() => {
    const id = addFootNote(text, key);

    setIndex(id);
  }, [ text, key, addFootNote ]);

  return index;
};
