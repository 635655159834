import { CzechSalaryTaxAdjustment } from '@tymbe/legislatives/czechia/CzechSalaryTaxAdjustment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { revertNumber } from '../../utils';
import { useAddFootNote } from './FootNotesContext';
import { getTaxAdjustmentTranslation } from './translations';
import { WageDetailRow } from './WageDetailRow';

type AdjustmentRowProps = {
  adjustment: CzechSalaryTaxAdjustment;
}
export const AdjustmentRow = ({ adjustment }: AdjustmentRowProps) => {
  const intl = useIntl();
  const footNoteTrans = getTaxAdjustmentTranslation(adjustment.type, 'footnote');
  const footNote = useMemo(() => {
    return footNoteTrans && intl.formatMessage(
      footNoteTrans,
      {
        a: link => <a
          href="https://www.vzp.cz/platci/informace/stat/za-koho-plati-pojistne-stat"
          target="_blank"
          rel="noreferrer"
        >{link}</a>,
      },
    );
  }, [ footNoteTrans, intl ]);

  const footNoteIndex = useAddFootNote(footNote, footNoteTrans?.id);

  return (
    <WageDetailRow
      name={intl.formatMessage(getTaxAdjustmentTranslation(adjustment.type))}
      value={revertNumber(adjustment.totalAmount)}
      footNoteIndex={footNoteIndex}
      key={adjustment.name}
    />
  );
};
