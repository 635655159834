import { ContractToShorten } from '@tymbe/legislatives/ContractToShorten';
import { CzechContractType } from '@tymbe/legislatives/czechia/CzechContractType';
import { CzechWorkShiftCheckResult } from '@tymbe/legislatives/czechia/CzechWorkShiftCheckResult';
import { WorkShiftCheckFlags } from '@tymbe/legislatives/WorkShiftCheckFlags';
import { WorkShiftCheckIssue } from '@tymbe/legislatives/WorkShiftCheckIssue';
import moment from 'moment-timezone';
import { ReactNode, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import ClaimRingButton from './ClaimRingButton';
import ConnShowRingDocument from './ShowRingDocument';
import ConnSignDocumentButton from './SignDocumentButton';
import { styled } from '../../../styledComponents';
import { Ring, RingDocument as IRingDocument, RingShiftClaimPrecheck } from '../../../types/Tymbe';
import { formatNumber } from '../../../utils';
import { Error } from '../../Error';
import { Button } from '../../Forms/Button';
import TestModal from '../../Modal/TestModal';
import { H1, H2 } from '../../Styles/BasicElements';
import { Center } from '../../Styles/Center';
import { HoursBar } from '../../Styles/HoursBar';
import { Icon } from '../../Styles/Icon';

const PillButton = styled.button<{ active?: boolean, selected?: boolean }>`
  display: inline-block;
  padding: 1rem;
  background-color: ${({ theme, active, selected }) =>
    // eslint-disable-next-line no-nested-ternary
    (selected ? theme.colors.danger : (active ? theme.colors.primary : theme.colors.gray4))};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0;
  line-height: 0;
  width: 2.3em;
  height: 2.3em;
  border: none;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
`;

const RingDocumentsContainer = styled.div`
  display: flex;
  flex-flow: column;
  background-color: ${({ theme }) => theme.colors.gray2};
  @media (max-width: 925px) {
    padding: 1rem 1rem 0 !important;
  }
`;

const Separator = styled(HoursBar).attrs({ hours: 2 })`
display: inline-block;
text-align: center;
  color: ${({ theme }) => theme.colors.gray4};
  margin: 0 0.2rem;
`;

const PillsContainer = styled.div`
  display: grid;
  align-items: center;
  grid-area: pills;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(3rem, 1fr));
`;

const ButtonsContainer = styled.div`
  display: inline-block;
  grid-area: buttons;
  text-align: right;
  *:last-child {
    margin: 0;
  }
`;

const DocumentSelector = styled.div`
  display: grid;
  grid-template-columns: 50% auto 1fr auto;
  grid-template-areas: 'pills header . buttons';
  margin-bottom: 2rem;

  ${H1} {
    grid-area: header;
  }

  @media (max-width: 925px) {
    grid-template-columns: 60% auto;
    grid-template-areas:
      'pills buttons'
      'header header';
    ${H1} {
      margin: 1rem 0 0 !important;
    }
  }
`;

const WarningHeader = styled.div`
  color: #BC4B0B;
  display: flex;
  font-size: 1.3rem;
  margin: auto;
  max-width: 100%;
  background: #FFF1CF;
  justify-content: center;
  font-weight: bold;
`;

export type WarningBoxProps = {
  color?: string;
  backgroundColor?: string;
};

const WarningBox = styled.div<WarningBoxProps>`
display: inline-block;
border-radius: 1rem;
padding: 1rem;
background-color: ${({ backgroundColor }) => backgroundColor || '#FFF1CF'};
color: ${({ color }) => color || '#BC4B0B'};
text-align: center;
max-width: 100%;
width: 600px;
margin-bottom: 15px;
font-weight: normal;
`;

const StyledMeter = styled.meter`
width: 400px;
height: 25px;
`;

const Tooltip = styled.div`
text-align: center;
border-radius: 3px;
cursor: pointer;
`;

const TooltipText = styled.div`
position: absolute;
left: -50px;
bottom: calc(100% + 10px);
visibility: hidden;
color: transparent;
background-color: transparent;
width: 100px;
padding: 5px 5px;
border-radius: 4px;
`;

const TooltipCard = styled.div`
  position: relative;

  & ${Tooltip}:hover + ${TooltipText} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 150px;
    padding: 8px 8px;
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`;

const linkToLocker = (chunks: any) => <Link className="underline text-primary contents" to="/locker">{chunks}</Link>;

const Issues = ({ issues }: { issues: WorkShiftCheckIssue[] }) => {
  const intl = useIntl();
  const intlParams = {
    link: linkToLocker,
  };

  return (
    <Error error={null}>
      {issues.map((issue) => {
        let message: ReactNode = '';

        if (issue.flag === WorkShiftCheckFlags.CONTRACTS_DOWNGRADE) {
          message = intl.formatMessage({
            defaultMessage: 'Kvůli přechodu mezi smlouvami se nemůžeš přihlásit na tuto směnu.',
            description: 'Zamítnutí přihlášení na směnu na přelomu různých smluv.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.CONTRACTS_BREAK_OVERLAP) {
          message = intl.formatMessage({
            defaultMessage: 'Tohle je jen pauza po skončení smlouvy – zasloužený odpočinek! Ale žádné obavy, příští směny už budou v pohodě a můžeš se do toho pustit naplno.',
            description: 'Zamítnutí přihlášení na směnu, která je hned den po skončení (nebo den před začátkem) platnosti podepsané DPČ.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.MULTIPLE_DECLINES) {
          message = intl.formatMessage({
            defaultMessage: 'Bylo zváženo několik druhů pracovních úvazků, ale pod žádným nebylo možné se přihlásit.',
            description: 'Souhrnné zamítnutí přihlášení na směnu s několika smlouvami.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.OFFER_HPP) {
          message = intl.formatMessage({
            defaultMessage: 'Na DPČ nemůžeš překročit průměr odpracovaných 20 hodin za týden, ale na této pozici je možné pracovat i na pracovní poměr. Napiš nám, pokud se ti tato možnost zamlouvá.',
            description: 'Zamítnutí přihlášení na směnu, kdy je potřeba kontaktovat podporu pro aktivaci HPP.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.OFFER_SALARY_UNLIMIT) {
          message = intl.formatMessage({
            defaultMessage: 'Touto směnou bys překročil rozhodný příjem, avšak máš zaplou funkci hlídání jeho překročení. Pokud na směnu opravdu chceš, můžeš si hlídání vypnout ve svém <link>profilu</link>.',
            description: 'Zamítnutí přihlášení na směnu, kterou by došlo k překročení limitu DPČ.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.SHIFTS_OVERLAP) {
          message = intl.formatMessage({
            defaultMessage: 'V tuto dobu už máš přihlášenou jinou směnu.',
            description: 'Zamítnutí přihlášení na směnu kvůli překryvu s jinou směnou.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.SHIFTS_TOO_CLOSE) {
          message = intl.formatMessage({
            defaultMessage: 'Máš přihlášenou směnu u jiného zaměstnavatele těsně před nebo těsně po této směně.',
            description: 'Zamítnutí přihlášení na směnu, když není dodržen hodinový rozestup mezi zaměstnavateli.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.INSUFFICIENT_BREAK) {
          message = intl.formatMessage({
            defaultMessage: 'Máš přihlášeno příliš mnoho hodin v krátkém čase.',
            description: 'Zamítnutí přihlášení na směnu, když není dodržen povinný odpočinek.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.EMPLOYEE_UNDERAGE_IN_NIGHT_SHIFT) {
          message = intl.formatMessage({
            defaultMessage: 'Na noční směnu se můžeš přihlásit až od 18 let.',
            description: 'Zamítnutí přihlášení na noční směnu u mladistvého.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.EMPLOYEE_UNDER_15) {
          message = intl.formatMessage({
            defaultMessage: 'Do 15 let u nás nemůžeš pracovat.',
            description: 'Zamítnutí přihlášení na směnu u osoby pod 15 let.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.EMPLOYEE_15_BUT_NOT_STUDYING) {
          message = intl.formatMessage({
            defaultMessage: 'Abys mohl pracovat, musíš mít nahrané potvrzení o studiu.',
            description: 'Zamítnutí přihlášení na směnu, protože 15letý nemá potvrzení o studiu.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.EMPLOYEE_FOREIGNER_BUT_NO_INFO_CARD) {
          message = intl.formatMessage({
            defaultMessage: 'Vypadá to, že se snažíš podepsat Dohodu o provedení práce (DPP), která vyžaduje dokument nazvaný "informační karta" podle zákonů o zaměstnávání cizinců v ČR. <br/> <strong>Můžeš si vybrat jinou brigádu a to na Dohodu o pracovní činnosti (DPČ), kde tento dokument není potřeba</strong>, nebo nás kontaktuj přes Live chat, kde ti poskytneme více informací.',
            description: 'Zamítnutí přihlášení na směnu, protože cizinec nemá informační kartu.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.EMPLOYEE_FOREIGNER_BUT_NO_FOREIGNER_ORIGIN) {
          message = intl.formatMessage({
            defaultMessage: 'Abys mohl pracovat, musíš mít vyplněný důvod pobytu cizince.',
            description: 'Zamítnutí přihlášení na směnu, protože cizinec nemá vyplněný důvod pobytu cizince.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED) {
          message = intl.formatMessage({
            defaultMessage: 'Na směnu se nemůžeš přihlásit, protože bys překročil měsíční limit odměny.',
            description: 'Zamítnutí přihlášení na směnu při překročení hodinového fondu.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.TIME_LIMIT_EXCEEDED) {
          message = intl.formatMessage({
            defaultMessage: 'Na směnu se nemůžeš přihlásit, protože bys překročil hodinový limit na smlouvě.',
            description: 'Zamítnutí přihlášení na směnu při překročení pěnežního fondu.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.MISSING_PERK_CACHIER) {
          message = intl.formatMessage({
            defaultMessage: 'Na tuto směnu je potřebné buď školení nebo předešlá zkušenost na pokladně. Školící směny můžeš najít jako samostatnou brigádu v ringu. Pokud jsi už na pokladně někdy pracoval, můžeš si nahrát potvrzení do svého profilu, nebo nám dej vědět na support a směnu ti uvolníme.',
            description: 'Zamítnutí přihlášení na směnu při nesplnění podmínky.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.MISSING_PERK_BILLA_BAKERY) {
          message = intl.formatMessage({
            defaultMessage: 'Na tuto brigádu je potřebné školení. Školící směny najdeš v ringu jako samostatné směny.',
            description: 'Zamítnutí přihlášení na směnu při nesplnění podmínky.',
          }, intlParams);
        } else if (issue.flag === WorkShiftCheckFlags.MISSING_PERK_BILLA_SERVICE_DESK) {
          message = intl.formatMessage({
            defaultMessage: 'Na tuto brigádu je potřebné školení. Školící směny najdeš v ringu jako samostatné směny.',
            description: 'Zamítnutí přihlášení na směnu při nesplnění podmínky.',
          }, intlParams);
        } else {
          message = intl.formatMessage({
            defaultMessage: 'Něco se nepovedlo při přihlašování. Napiš nám, abychom to vyřešili.',
            description: 'Zamítnutí přihlášení na směnu, když došlo k chybě, jsou špatná data nebo nebyl ještě vytvořen překlad.',
          }, intlParams);
        }
        return (
          <div key={issue.flag}>
            {message}
            <br />
            <small>{issue.message} {issue.extra}</small>
          </div>
        );
      })}
    </Error>
  );
};

const textForSalaryLimit = (intl: IntlShape, signUp: CzechWorkShiftCheckResult) => {
  if (signUp.type === CzechContractType.DohodaOProvedeniPrace) {
    return intl.formatMessage({
      defaultMessage: 'Limit výdělku na DPP v jednom měsíci',
      description: 'Informace k limitu hrubé odměny za měsíc na DPP.',
    });
  }
  if (signUp.type === CzechContractType.DohodaOPracovniCinnosti) {
    return intl.formatMessage({
      defaultMessage: 'Limit výdělku na DPČ v jednom měsíci (limit je možné vypnout v profilu)',
      description: 'Informace k limitu hrubé odměny za měsíc na DPČ.',
    });
  }
  return '';
};

const textForTimeLimit = (intl: IntlShape, signUp: CzechWorkShiftCheckResult) => {
  if (signUp.type === CzechContractType.DohodaOProvedeniPrace) {
    return intl.formatMessage({
      defaultMessage: 'Limit odpracovaného času na DPP za kalendářní rok',
      description: 'Informace k limitu odpracovaného času v rámci smlouvy na DPP.',
    });
  }
  if (signUp.type === CzechContractType.DohodaOPracovniCinnosti) {
    return intl.formatMessage({
      defaultMessage: 'Limit odpracovaného času na tuto DPČ',
      description: 'Informace k limitu odpracovaného času v rámci smlouvy na DPČ.',
    });
  }
  return '';
};

export interface RingDocumentsProps {
  ring: Ring;
  claimShiftPrecheck: RingShiftClaimPrecheck;
  signDocument: (ringId: Ring['id'], documentId: IRingDocument['id'], tymbe: boolean, signature: string, passed: string, shorten?: ContractToShorten) => void;
}

export const RingDocuments = ({ ring, signDocument, claimShiftPrecheck, ...rest }: RingDocumentsProps) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [openModal, setOpenModal] = useState(false);
  const [evaluation, setEvaluation] = useState('');
  const [testPassed, setTestPassed] = useState({ status: false, points: '' });
  const [document, selectDocument] = useState<IRingDocument>();

  useEffect(
    () => {
      selectDocument(claimShiftPrecheck.contracts.find((doc) => !doc.signed));
    },
    [claimShiftPrecheck, ring],
  );

  useEffect(() => {
    if (document && document.form_definition && !document.signed) {
      setTestPassed({ status: false, points: '' });
    } else {
      setTestPassed({ status: true, points: '' });
    }
  }, [document]);

  const isActiveOrSigned = (doc: IRingDocument) => document === doc || !!doc?.signed;
  const goBack = () => navigate('/ring');

  if (ring.claimed) {
    return <Navigate to="/before-fight" />;
  }

  const { canSignUp, flags, limits } = claimShiftPrecheck.signUp;

  const unsignedDocExists = claimShiftPrecheck.contracts.find((doc) => !doc.signed);

  const onSign = (signature: string) => {
    if (!document) return;
    const { makeContractChanges } = claimShiftPrecheck.signUp;
    const shorten = document.id === Number(makeContractChanges?.sign.templateId) ? makeContractChanges?.shorten : undefined;
    signDocument(ring.id, document.id, document.tymbe, signature, evaluation, shorten);
  };

  const salaryLimitExceededNow = flags.includes(WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED_NOW);
  const exemptFromInsurance = flags.includes(WorkShiftCheckFlags.EXEMPT_FROM_INSURANCE);
  const shiftIsLastSevenDaysInMonth = moment.tz(ring.time.start, 'europe/prague').endOf('month').add(-7, 'days') < moment(ring.time.start);
  const showSalaryLimitExceededWarning = salaryLimitExceededNow && shiftIsLastSevenDaysInMonth && !exemptFromInsurance;

  if (!canSignUp) {
    return <Issues issues={claimShiftPrecheck.signUp.issues} />;
  }

  return (
    <RingDocumentsContainer {...rest}>
      {document && document.form_definition !== null && document.form_definition !== undefined && (
        <TestModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          testPassed={testPassed}
          setTestPassed={setTestPassed}
          testJson={document.form_definition}
          evaluation={evaluation}
          setEvaluation={setEvaluation}
        />
      )}
      <DocumentSelector>
        <PillsContainer>
          {
            claimShiftPrecheck.contracts
              .map((doc, i) => (
                <PillButton
                  key={`${doc.id}_${doc.tymbe}`}
                  active={isActiveOrSigned(doc)}
                  selected={doc === document}
                  onClick={() => selectDocument(doc)}
                >
                  {i + 1}
                </PillButton>
              ))
              .map((doc) => [doc, <Separator key={`${doc.key}_S`} />])
              .flat()
          }
          <PillButton
            active={!unsignedDocExists}
            selected={!document}
            disabled={!!unsignedDocExists}
            onClick={() => selectDocument(undefined)}
          >
            <Icon id="star" color="white" size="1em" />
          </PillButton>
        </PillsContainer>
        <H1 style={{ marginLeft: '2rem' }}>{document?.title}</H1>
        <ButtonsContainer>
          <Button background="secondary" onClick={goBack}>
            {intl.formatMessage({
              defaultMessage: 'Zrušit',
              description: 'Tlačítko potvrzující zrušení přihlášení na směnu',
            })}
          </Button>
          {(document && testPassed.status)
            && (
              <ConnSignDocumentButton onSign={onSign} disabled={!!document?.signed} id={`${document.id}_${document.tymbe}`}>
                <Icon id="sign" size="1.1em" color="white" />&nbsp;
                {!document?.signed
                  ? intl.formatMessage({
                    defaultMessage: 'Podepsat',
                    description: 'Tlačítko pro podepsání smlouvy',
                  })
                  : intl.formatMessage({
                    defaultMessage: 'Podepsáno',
                    description: 'Informace o již podepsané smlouvě',
                  })}
              </ConnSignDocumentButton>
            )}
          {(document && !testPassed.status) && (
            <Button onClick={() => setOpenModal(true)}>
              Vyplnit test
            </Button>
          )}

        </ButtonsContainer>
      </DocumentSelector>
      {document && <ConnShowRingDocument id={document.id} ringId={ring.id} tymbe={document.tymbe} />}
      {!unsignedDocExists && !document && (
        <Center>
          <H1 className="pb-4">
            {intl.formatMessage({
              defaultMessage: 'Vše je připraveno.',
              description: 'Nadpis stranky informující o možnosti se příhlásit na směnu pokud jsou splněny všechny podmínky',
            })}
          </H1>
          {showSalaryLimitExceededWarning ? (
            <WarningBox>
              <WarningHeader>
                <Icon id="exclamation_triangle" color="orange" size="1.3em" className="mx-2" />
                <div>
                  {intl.formatMessage({
                    defaultMessage: 'Ty nezahálíš, ale pozor!',
                    description: 'Ty nezahálíš, ale pozor!',
                  })}
                </div>
              </WarningHeader>
              {intl.formatMessage({
                defaultMessage: 'Odpracováním této směny dosáhneš rozhodný příjem a budeš muset hradit nejen odvody na sociální a zdravotní pojištění, ale i doplatek zdravotního pojištění a dohromady už je to docela dost peněz (pár tisíc). Jak z toho ven? Nahrej doklad ve své šatně, pokud jsi student, v evidenci UP, zkrátka pokud jsi státní pojištěnec nebo si hradíš pojištění jinde. Tvá čistá odměna pak bude o poznání vyšší ;)',
                description: 'Informace o překročení rozhodného limitu.',
              })}
            </WarningBox>
          ) : (
            <H2>
              {intl.formatMessage({
                defaultMessage: 'Nyní se už stačí jen přihlásit.',
                description: 'Informace o možnosti se příhlásit na směnu pokud jsou splněny všechny podmínky.',
              })}
            </H2>
          )}
          {limits && (
            <WarningBox color="#655468" backgroundColor="white">
              {!limits.allowBypassSalaryLimit && (
                <div>
                  <div className="flex justify-center items-center">
                    {intl.formatMessage({
                      defaultMessage: 'Předpokládaná hrubá odměna v daném měsíci (vč. této směny)',
                      description: 'Popis předpokládané hrubé odměny a limitu za měsíc spočtené při přihlašování na směnu.',
                    })}
                    {' '}
                    {intl.formatMessage({
                      defaultMessage: '{total} Kč / {max} Kč',
                      description: 'Předpokládaná hrubá odměna a limit za měsíc spočtená při přihlašování na směnu.',
                    }, {
                      total: formatNumber(intl, limits.totalGrossSalaryThisMonth) || 4500,
                      max: formatNumber(intl, limits.maxGrossMonthlySalary) || 2000,
                    })}
                    <TooltipCard>
                      <Tooltip><Icon id="info" size="1rem" className="ml-5" background="#655468" /></Tooltip>
                      <TooltipText>{textForSalaryLimit(intl, claimShiftPrecheck.signUp)}</TooltipText>
                    </TooltipCard>
                  </div>
                  <StyledMeter
                    max={Math.max(limits.totalGrossSalaryThisMonth, limits.maxGrossMonthlySalary)}
                    value={limits.totalGrossSalaryThisMonth}
                    high={limits.maxGrossMonthlySalary}
                    min={0}
                    low={500}
                    optimum={1}
                  />
                </div>
              )}
              <div>
                <div className="flex justify-center items-center">
                  {intl.formatMessage({
                    defaultMessage: 'Předpokládaný odpracovaný čas v rámci smlouvy (vč. této směny)',
                    description: 'Popis předpokládaného odpracovaného času a limitu v rámci smlouvy spočteného při přihlašování na směnu.',
                  })}
                  {' '}
                  {intl.formatMessage({
                    defaultMessage: '{total} hod. / {max} hod. ',
                    description: 'Předpokladaný odpracovaný čas a limit v rámci smlouvy spočtený při přihlašování na směnu.',
                  }, {
                    total: formatNumber(intl, limits.totalWorkTimeForEntireContractInMinutes / 60),
                    max: formatNumber(intl, limits.maxWorkTimeForEntireContractInMinutes / 60),
                  })}
                  <TooltipCard>
                    <Tooltip><Icon id="info" size="1rem" className="ml-5" background="#655468" /></Tooltip>
                    <TooltipText>{textForTimeLimit(intl, claimShiftPrecheck.signUp)}</TooltipText>
                  </TooltipCard>
                </div>
                <StyledMeter
                  min={0}
                  low={50}
                  optimum={1}
                  max={Math.max(limits.totalWorkTimeForEntireContractInMinutes / 60, limits.maxWorkTimeForEntireContractInMinutes / 60)}
                  value={limits.totalWorkTimeForEntireContractInMinutes / 60}
                  high={limits.maxWorkTimeForEntireContractInMinutes / 60}
                />
              </div>
            </WarningBox>
          )}
          <br />
          <ButtonsContainer>
            {showSalaryLimitExceededWarning && (
              <Button background="secondary" onClick={goBack}>
                {intl.formatMessage({
                  defaultMessage: 'Na směnu nechci',
                  description: 'Tlačítko potvrzující zrušení přihlášení na směnu',
                })}
              </Button>
            )}
            <ClaimRingButton ringId={ring.id} data-cy="acceptShiftButton">
              {intl.formatMessage({
                defaultMessage: 'Přihlásit',
                description: 'Tlačítko pro přihlášení na směnu',
              })}
            </ClaimRingButton>
          </ButtonsContainer>
        </Center>
      )}
    </RingDocumentsContainer>
  );
};
