import { useIntl } from 'react-intl';

import { H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import { shiftLabelsText } from '../utils';

export type PerkListProps = {
  labels: string[];
};

export const LabelsList = ({ labels }: PerkListProps) => {
  const intl = useIntl();
  return (
    <div>
      <H3>
        {intl.formatMessage({
          defaultMessage: 'Vlastnosti',
          description: 'Název sekce (příklad podmínky: Výpis z rejstříku trestů)',
        })}
      </H3>
      {labels.map((label) => (
        <div className="flex gap-1">
          <Icon id={label} size="1.5em" style={{ marginRight: '.5rem' }} />
          <span>{intl.formatMessage(
            {
              defaultMessage: '{labelText}',
              description: 'Název vlastnosti',
            },
            {
              labelText: shiftLabelsText[label],
            },
          )}
          </span>
        </div>
      ))}
    </div>
  );
};
