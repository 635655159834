import { DocumentTypeData } from '@tymbe/schema/document-type.interface';
import { DocumentType } from '@tymbe/schema/enums';
import { InputHTMLAttributes } from 'react';

import { DropDown, DropDownData } from './Forms/DropDown';

type SelectDocumentTypeProps = {
  documentTypes?: DocumentTypeData[],
  label: string,
  name: string,
  id?: string,
} & InputHTMLAttributes<HTMLInputElement>;

const mapDataToInput = (documentTypes: DocumentTypeData[]): DropDownData => {
  const documentTypesDropdown: DropDownData = {};
  documentTypes.forEach((documentType, arrayIndex) => {
    const name = documentType.display_name || documentType.name;
    const index = documentType.type === DocumentType.ID_CARD ? 0 : arrayIndex + 1;
    documentTypesDropdown[documentType.id] = [index, name];
  });
  return documentTypesDropdown;
};

const SelectDocumentType = ({ documentTypes = [], label, name, id, required }: SelectDocumentTypeProps) => (
  <DropDown
    id={id || 'doklad_perkId'}
    name={name}
    label={label}
    data={mapDataToInput(documentTypes)}
    autoComplete="off"
    required={required}
  />
);

export default SelectDocumentType;
