import { Button } from '../components/Forms/Button';
import Spinner from '../components/Spinner';
import A from '../components/Styles/A';
import { Center } from '../components/Styles/Center';
import withLoading from '../components/withLoading';
import withMapData from '../components/withMapData';
import no from '../images/no.png';
import ok from '../images/ok.png';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { componentsSelector, StoreState } from '../reducers';
import { registrationSelector } from '../reducers/components';
import { RegistrationResultState } from '../reducers/components/registration';
import { GetData } from '../types/StoreUtils';
import { ApiError } from '../types/Tymbe';

type RegistrationResultProps = {
  userIsActive: boolean;
  error: ApiError;
};

const Confirm: React.FC = () => {
  const intl = useIntl();
  return (
    <Center style={{ textAlign: 'center' }}>
      <img
        src={ok}
        alt={intl.formatMessage({
          defaultMessage: 'ok',
          description: 'Alt text obrázku znázorňující úspěšnou registraci.',
        })}
      />
      <p><b>{intl.formatMessage({
        defaultMessage: 'Gratulujeme k úspěšnému dokončení registrace a nyní se můžete přihlásit.',
        description: 'Text po úspěšné registraci',
      })}</b></p>
      <Button as={Link} to={'/login'}>{intl.formatMessage({
        defaultMessage: 'Přihlásit se',
        description: 'Odkaz na stránku pro přihlášení',
      })}</Button>
    </Center>
  );
};

const Failed = ({ error }: { error?: ApiError }) => {
  const intl = useIntl();
  return !error ? (
    <Center>
      <img
        src={no}
        alt={intl.formatMessage({
          defaultMessage: 'chyba',
          description: 'Alt text obrázku znázorňující neuspěšnou registraci.',
        })}
      />
      <p><b>{intl.formatMessage({
        defaultMessage: 'Bohužel se něco nepovedlo!',
        description: 'Text po neúspěšné registraci',
      })}</b></p>
      <p><b>{intl.formatMessage(
        {
          defaultMessage: `Abychom mohli Vaši registraci dokončit,{br}
          pošlete nám Váš ofocený občanský průkaz na email: <A>oops@tymbe.cz</A>`,
          description: 'Text po neúspěšné registraci',
        },
        { br: <br />, A: parts => <A as={'a'} href={'mailto:oops@tymbe.cz'}>{parts}</A> },
      )}
      </b></p>
    </Center>
  ) : (
    <Center>
      <img
        src={no}
        alt={intl.formatMessage({
          defaultMessage: 'chyba',
          description: 'Alt text obrázku znázorňující neuspěšnou registraci.',
        })}
      />
      <p>
        <b>{error.message}</b><br />
      </p>
      <Button as={'a'} href={'https://www.facebook.com/tymbecz'}>{intl.formatMessage({
        defaultMessage: 'Facebook stránky Tymbe',
        description: 'Odkaz vedoucí na FB stránky tymbe',
      })}</Button>
    </Center>
  );
};

export const RegistrationResult: React.FC<RegistrationResultProps> = ({ userIsActive, error }) => (
  userIsActive ? <Confirm /> : <Failed error={error} />
);

export default connect(
  (state: StoreState) => ({
    ...registrationSelector(componentsSelector(state)).result,
  }),
)(
  withLoading(Spinner)(
    withMapData((data: GetData<RegistrationResultState>, _, error) => ({ ...data, error }))(
      RegistrationResult,
    ),
  ),
);
