import { isValidBankAccount } from '@tymbe/bank-account';
import { Form, FormState } from 'informed';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeaderCentered } from './Card/CardHeader';
import { Button } from './Forms/Button';
import { DropDown } from './Forms/DropDown';
import { getValuesForPatch } from './Forms/form-util';
import { FormItemGroup } from './Forms/FormItemGroup';
import { FormRowItems } from './Forms/FormRowItems';
import { Label, LabeledInput } from './Forms/LabeledInput';
import { useModalError } from './Modal/ModalError';
import { H2 } from './Styles/BasicElements';
import { useClearStateOnUnmount } from '../store';
import { DataStatus, STATUS_PENDING, STATUS_SUCCESS } from '../types/StoreUtils';
import { Address, ApiError, Tymber } from '../types/Tymbe';
import {
  CountryCodesAndNames,
  blockInvalidCharactersForZip,
  formatOnPasteForZip,
  validateZipCode,
} from '../utils';

export type TymberProfileFormProps = {
  tymber: Pick<Tymber, 'phone' | 'bankNumber' | 'bankAccountNumber' | 'contactAddress' | 'paymentNote'>;
  onSave: () => void;
  onCancel: () => void;
  status: DataStatus;
  error: ApiError;
  saveTymber: (data: Partial<Tymber>, password: string) => void;
};

export const TymberProfileForm = ({ tymber, onSave, onCancel, saveTymber, status, error }: TymberProfileFormProps) => {
  const countryCodesAndNames = CountryCodesAndNames();
  const intl = useIntl();
  const showError = useModalError();

  const handleSubmit = (formState: FormState) => {
    const values = getValuesForPatch(formState);
    const { password, ...data } = values;
    if (data.contactAddress) {
      const contactAddressKeys = Object.keys(data.contactAddress) as (keyof Address)[];
      contactAddressKeys.forEach((field: keyof Address) => {
        const value = data.contactAddress![field];
        if (Array.isArray(value) && (value.length === 0 || value.every((v) => v.length === 0))) {
          delete data.contactAddress![field];
        }
      });
      if (!Object.keys(data.contactAddress).length) {
        delete data.contactAddress;
      }
      if (data.contactAddress?.zip) {
        if (!validateZipCode(data.contactAddress.zip, data.contactAddress?.country ?? '')) {
          showError(
            intl.formatMessage({
              defaultMessage: 'Nesprávný formát PSČ.',
              description: 'Chyba formulářového pole registrace',
            }),
          );
        }
      }
    }
    if (data.bankAccountNumber && !data.bankNumber) {
      data.bankNumber = formState.values.bankNumber;
    }
    if (data.bankNumber && !data.bankAccountNumber) {
      data.bankAccountNumber = formState.values.bankAccountNumber;
    }

    if (!isValidBankAccount(`${formState.values.bankAccountNumber}/${formState.values.bankNumber}`)) {
      showError(
        intl.formatMessage({
          defaultMessage: 'Toto není platné číslo účtu',
          description: 'Chyba formulářového pole registrace',
        }),
      );
    } else {
      saveTymber(data, password);
    }
  };

  useClearStateOnUnmount('tymberProfileForm', []);
  useEffect(() => {
    if (status === STATUS_SUCCESS) {
      onSave();
    }
  }, [onSave, status]);

  return (
    <Card>
      <Form onSubmit={handleSubmit}>
        <CardHeaderCentered>
          <H2>{intl.formatMessage({
            defaultMessage: 'Upravit profil',
            description: 'Titulek dialogu s editací profilu',
          })}
          </H2>
        </CardHeaderCentered>
        <CardContent background="white">
          <LabeledInput
            id="profilePhone"
            name="phone"
            initialValue={tymber.phone}
            autoComplete="off"
            label={intl.formatMessage({
              defaultMessage: 'Telefon',
              description: 'Nazev prvku formuláře',
            })}
          />
          <Label>{intl.formatMessage({
            defaultMessage: 'Kontaktní adresa:',
            description: 'Titulek skupiny prvků formuláře',
          })}
          </Label>
          <FormItemGroup>
            <FormRowItems>
              <LabeledInput
                id="profileAddressStreet1"
                name="contactAddress.street[0]"
                initialValue={tymber.contactAddress?.street?.[0]}
                autoComplete="off"
                label={intl.formatMessage({
                  defaultMessage: 'Ulice',
                  description: 'Nazev prvku formuláře pro zadání adresy.',
                })}
              />
              <LabeledInput
                id="profileAddressStreet2"
                name="contactAddress.street[1]"
                initialValue={tymber.contactAddress?.street?.[1]}
                autoComplete="off"
                label={intl.formatMessage({
                  defaultMessage: 'Číslo',
                  description: 'Nazev prvku formuláře pro zadání adresy. Číslo za nazvem ulice.',
                })}
              />
            </FormRowItems>
            <LabeledInput
              id="profileAddressCity"
              name="contactAddress.city"
              initialValue={tymber.contactAddress?.city}
              autoComplete="off"
              label={intl.formatMessage({
                defaultMessage: 'Město',
                description: 'Nazev prvku formuláře pro zadání adresy',
              })}
            />
            <LabeledInput
              id="profileAddressZip"
              name="contactAddress.zip"
              initialValue={tymber.contactAddress?.zip}
              autoComplete="off"
              label={intl.formatMessage({
                defaultMessage: 'PSČ',
                description: 'Nazev prvku formuláře pro zadání adresy',
              })}
              onPaste={formatOnPasteForZip}
              onKeyDown={blockInvalidCharactersForZip}
            />
            <DropDown
              id="profileAddressState"
              name="contactAddress.country"
              initialValue={tymber.contactAddress?.country}
              data={countryCodesAndNames}
              label={intl.formatMessage({
                defaultMessage: 'Stát',
                description: 'Nazev prvku formuláře pro zadání adresy',
              })}
            />
          </FormItemGroup>
          <LabeledInput
            id="profileBankAccountNumber"
            name="bankAccountNumber"
            initialValue={tymber.bankAccountNumber}
            autoComplete="off"
            label={intl.formatMessage({
              defaultMessage: 'Číslo účtu',
              description: 'Nazev prvku formuláře pro zadání bankovního spojení (bez kódu banky)',
            })}
          />
          <LabeledInput
            id="profileBankNumber"
            name="bankNumber"
            initialValue={tymber.bankNumber}
            autoComplete="off"
            label={intl.formatMessage({
              defaultMessage: 'Kód banky',
              description: 'Nazev prvku formuláře pro zadání bankovního spojení',
            })}
          />
          <LabeledInput
            id="profilePaymentNote"
            name="paymentNote"
            initialValue={tymber.paymentNote}
            autoComplete="off"
            label={intl.formatMessage({
              defaultMessage: 'Volitelná poznámka k platbě',
              description: 'Nazev prvku formuláře pro zadání bankovního spojení',
            })}
          />
          <LabeledInput
            id="profilePassword"
            name="password"
            label={intl.formatMessage({
              defaultMessage: 'Heslo pro potvrzení změn',
              description: 'Nazev prvku formuláře pro potvrzení zadaných změn',
            })}
            type="password"
            autoComplete="off"
            error={error && error.message}
            required
          />
        </CardContent>
        <CardFooter>
          <Button type="button" onClick={onCancel} background="secondary">{intl.formatMessage({
            defaultMessage: 'Zrušit',
            description: 'Tlačítko pro zrušení a zavření dialogu pro úpravu profilu',
          })}
          </Button>
          <Button type="submit" disabled={status === STATUS_PENDING}>{intl.formatMessage({
            defaultMessage: 'Uložit',
            description: 'Tlačítko pro odeslání/uložení a zavření dialogu pro úpravu profilu',
          })}
          </Button>
        </CardFooter>
      </Form>
    </Card>
  );
};
