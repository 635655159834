import { SalaryLimitType } from '@tymbe/schema/enums';
import { personSalaryLimitUpdateRequest } from '../actions/personSalaryLimit';
import { PersonSalaryLimitModal } from '../components/PersonSalaryLimitModal';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { StoreState, componentsSelector, dataSelector } from '../reducers';
import { compose } from 'redux';
import { personSalaryLimitModalSelector } from '../reducers/components';
import { dataPersonSalaryLimitSelector } from '../reducers/data';
import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';

const selectPersonSalaryLimitModal = compose(personSalaryLimitModalSelector, componentsSelector);
const selectPersonSalaryLimit = compose(dataPersonSalaryLimitSelector, dataSelector);

const PersonSalaryLimitModalConnect = connect(
  (state: StoreState) => ({
    ...selectPersonSalaryLimitModal(state),
    personSalaryLimit: selectPersonSalaryLimit(state).data as PersonSalaryLimitData,
  }),
  dispatch => ({
    savePersonSalaryLimit: (limit: SalaryLimitType) =>
      dispatch(personSalaryLimitUpdateRequest(limit)),
  }),
);

export default PersonSalaryLimitModalConnect(
  withMapData((_data, status, error) => ({ status, error }))(
    PersonSalaryLimitModal,
  ),
);
