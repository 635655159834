import { MoreFinishedActions } from '../../../actions/finished';
import { MoreClaimedActions } from '../../../actions/claimed';
import { Actions as ClaimedTypes, CancelClaimedActions, GetClaimedActions } from '../../../actions/claimed';
import { ActionTypes, RingActions, RingClaimActions } from '../../../actions/data/rings';
import { Actions as FinishedTypes, GetFinishedActions } from '../../../actions/finished';
import { Actions as InvitationsTypes, InvitationRejectActions, InvitationsActions } from '../../../actions/invitations';
import { MORE_RINGS_SUCCESS, MoreRingsActions, RINGS_SUCCESS, RingsSuccessAction } from '../../../actions/ring';
import { combineReducers } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../../types/StoreUtils';
import { ApiError, Ring } from '../../../types/Tymbe';

interface RingsByIdState {
  [K: number]: DataStoreState<Ring>;
}

type RingsAllIdsState = Ring['id'][];

export interface RingsState {
  byId: RingsByIdState;
  allIds: RingsAllIdsState;
}

const requestingRing = (state: RingsByIdState, ringId: Ring['id']): RingsByIdState => ({
  ...state,
  [ringId]: initDataStoreState<Ring>(null),
});

const failRing = (state: RingsByIdState, ringId: Ring['id'], error: ApiError): RingsByIdState => ({
  ...state,
  [ringId]: state[ringId] ?
    initDataStoreState<Ring>(STATUS_ERROR, state[ringId].data, error) :
    initDataStoreState<Ring>(STATUS_ERROR, null, error),
});

const addRingData = (state: RingsByIdState, ring: Ring): RingsByIdState => ({
  ...state,
  [ring.id]: initDataStoreState<Ring>(STATUS_SUCCESS, ring),
});

const addRingId = (state: RingsAllIdsState, id: Ring['id']): RingsAllIdsState =>
  state.indexOf(id) + 1 ? state : state.concat(id);

const removeRingData = (state: RingsByIdState, ring: Ring) => {
  if (!state.hasOwnProperty(ring.id)) return state;
  const newState = { ...state };
  delete newState[ring.id];
  return newState;
};

type RingsByIdActions =
  RingActions
  | MoreRingsActions
  | RingClaimActions
  | RingsSuccessAction
  | InvitationsActions
  | InvitationRejectActions
  | GetClaimedActions
  | GetFinishedActions
  | CancelClaimedActions
  | MoreClaimedActions
  | MoreFinishedActions;

const ringsByIdReducer = (state: RingsByIdState = {}, action: RingsByIdActions): RingsByIdState => {
  switch (action.type) {
    case ActionTypes.RING_REQUEST:
      return requestingRing(state, action.ringId);
    case ActionTypes.RING_SUCCESS:
    case ActionTypes.RING_CLAIM_SUCCESS:
    case ClaimedTypes.CANCEL_CLAIMED_SUCCESS:
      return addRingData(state, action.ring);
    case ActionTypes.RING_FAILURE:
    case ActionTypes.RING_CLAIM_FAILURE:
      return failRing(state, action.ringId, action.error);
    case InvitationsTypes.INVITATIONS_SUCCESS:
    case ClaimedTypes.GET_CLAIMED_SUCCESS:
    case FinishedTypes.GET_FINISHED_SUCCESS:
    case FinishedTypes.MORE_FINISHED_SUCCESS:
    case RINGS_SUCCESS:
    case ClaimedTypes.MORE_CLAIMED_SUCCESS:
    case MORE_RINGS_SUCCESS:
      return action.rings.reduce((s, ring) => addRingData(s, ring), state);
    case InvitationsTypes.INVITATION_REJECT_REQUEST:
    case ActionTypes.RING_CLAIM_REQUEST:
    case ClaimedTypes.CANCEL_CLAIMED_REQUEST:
      return { ...state, [action.ringId]: initDataStoreState<Ring>(STATUS_PENDING, { ...state[action.ringId].data! }) };
    case InvitationsTypes.INVITATION_REJECT_SUCCESS:
      return removeRingData(state, action.ring);
    default:
      return state;
  }
};

type RingsAllIdsActions =
  RingActions
  | RingsSuccessAction
  | InvitationsActions
  | InvitationRejectActions
  | GetClaimedActions
  | GetFinishedActions
  | MoreClaimedActions
  | MoreFinishedActions;

const ringsAllIdsReducer =
  (state: RingsAllIdsState = [], action: RingsAllIdsActions): RingsAllIdsState => {
    switch (action.type) {
      case ActionTypes.RING_SUCCESS:
        return addRingId(state, action.ring.id);
      case InvitationsTypes.INVITATIONS_SUCCESS:
      case ClaimedTypes.GET_CLAIMED_SUCCESS:
      case ClaimedTypes.MORE_CLAIMED_SUCCESS:
      case FinishedTypes.GET_FINISHED_SUCCESS:
      case FinishedTypes.MORE_FINISHED_SUCCESS:
      case RINGS_SUCCESS:
        return action.rings.reduce((s, ring) => addRingId(s, ring.id), state);
      case InvitationsTypes.INVITATION_REJECT_SUCCESS:
        return state.filter(id => id !== action.ring.id);
      default:
        return state;
    }
  };

export const ringSelector = (state: RingsState, id: Ring['id']): DataStoreState<Ring> =>
  state.byId[id] || initDataStoreState<Ring>(null);

export const ringsReducer = combineReducers<RingsState>(
  {
    byId: ringsByIdReducer,
    allIds: ringsAllIdsReducer,
  },
);
