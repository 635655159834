import moment from 'moment';
import * as React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import Api from '../services/api';

export let intl: IntlShape;

export const I18n: React.FC = () => {
  intl = useIntl();
  Api.intl = intl;
  moment.locale(intl.locale);
  return null;
};
