import { ringClaimRequest } from '../../../actions/data/rings';
import { Button } from '../../Forms/Button';
import { connect } from 'react-redux';
import { Ring } from '../../../types/Tymbe';

export default connect(
  null,
  (dispatch, { ringId }: { ringId: Ring['id'] }) => ({
    onClick: () => dispatch(ringClaimRequest(ringId)),
  }),
)(Button);
