import { Icon } from '../Styles/Icon';
import { styled } from '../../styledComponents';

const MenuIcon = styled(Icon).attrs({ size: '1.5em' })`
  ${({ theme }) => `
    margin-right: .5rem;
    --color: ${theme.colors.white};
    --background: ${theme.bodyColor};
    .active & {
      --background: ${theme.buttons.primary};
    };
  `}
`;

export default MenuIcon;
