import { downloadTymberDocumentRequest, getTymberDocumentsRequest, moreTymberDocumentsRequest } from '../actions/tymberDocuments';
import { TymberDocuments } from '../components/TymberDocuments';
import withFetch from '../components/withFetch';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { tymberDocumentsSelector } from '../reducers/components';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { TymberDocumentPart } from '../types/Tymbe';

const selectTymberDocuments = compose(tymberDocumentsSelector, componentsSelector);

const TymberDocumentsConnect = connect(
  (state: StoreState) => selectTymberDocuments(state),
  dispatch => ({
    fetch: () => dispatch(getTymberDocumentsRequest()),
    downloadDocument: (id: TymberDocumentPart['id']) => dispatch(downloadTymberDocumentRequest(id)),
    moreDocuments: () => dispatch(moreTymberDocumentsRequest()),
  }),
);

export default TymberDocumentsConnect(
  withFetch(
    withMapData((data, status) => ({ documents: data || [], loading: status === STATUS_PENDING }))(
      TymberDocuments,
    ),
  ),
);
