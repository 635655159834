import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '../../../reducers';
import { useClearStateOnUnmount } from '../../../store';
import { styled } from '../../../styledComponents';
import { STATUS_PENDING } from '../../../types/StoreUtils';
import { Button } from '../../Forms/Button';
import Modal from '../../Modal';
import { SignaturePad } from '../../SignaturePad';
import Spinner from '../../Spinner';

const SignaturePadStyled = styled(SignaturePad)`
  background-color: white;
  border: 1px solid black;
`;

export type SignDocumentButtonProps = {
  onSign: (signature: string) => void;
  message?: string | null;
  disabled?: boolean;
  pending?: boolean;
  children?: React.ReactNode;
  id: string | number;
};

export const SignDocumentButton =
  ({ onSign, message, disabled, pending, id, ...rest }: SignDocumentButtonProps) => {
    const [showSignature, toggleShowSignature] = React.useState(false);

    useClearStateOnUnmount('signDocument', [showSignature]);

    React.useEffect(
      () => {
        if (disabled) toggleShowSignature(false);
      },
      [disabled],
    );
    React.useEffect(
      () => {
        toggleShowSignature(false);
      },
      [id],
    );

    const getSignature = (signature: string) => {
      onSign(signature);
    };

    return (
      <>
        <Button
          onClick={() => toggleShowSignature(!showSignature)}
          disabled={disabled || pending}
          {...rest}
          data-cy="openSignModalButton"
        />
        <Modal open={showSignature}>
          <SignaturePadStyled
            onSign={getSignature}
            onCancel={() => toggleShowSignature(false)}
            disabled={!!disabled || !!pending}
          >
            { pending && <Spinner position="absolute" />}
            {message}
          </SignaturePadStyled>
        </Modal>
      </>
    );
  };

export default connect(
  (state: StoreState) => ({
    pending: state.components.signDocument.status === STATUS_PENDING,
    message: state.components.signDocument.error,
  }),
)(SignDocumentButton);
