import { Property } from 'csstype';
import logoSpinner from '../images/logoSpinner.svg';
import React from 'react';
import { keyframes, styled } from '../styledComponents';

const appear = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const Wrapper = styled.div<{ position?: Property.Position, hideBackground?: boolean }>`
  position: ${({ position = 'fixed' }) => position};
  display: flex;
  top: ${({ position = 'fixed' }) => position === 'fixed' ? '100px' : 0};
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: ${({ hideBackground }) => hideBackground ? `transparent url("${logoSpinner}")no-repeat center center` : `rgba(255, 255, 255, .7) url("${logoSpinner}")no-repeat center center` };
  background-size: contain;
  opacity: 0;
  z-index: 1;
  min-height: 100px;
  animation: 500ms ease-in 1000ms ${appear} forwards;
`;

export type SpinnerProps = {
  position?: Property.Position;
  style?: React.CSSProperties;
  hideBackground?: boolean;
};

const Spinner = (props: SpinnerProps) => <Wrapper {...props} />;

export default Spinner;
