import * as React from 'react';
import { Matching } from 'react-redux';
import { DataStatus, DataStoreState } from '../types/StoreUtils';
import { ApiError } from '../types/Tymbe';
import { Subtract } from 'utility-types';
import { getDisplayName } from '../utils';

type DSS<D> = DataStoreState<D>;

const withMapData =
  <D extends object, DProp extends object>(mapDataToProps?: (data: D, status: DataStatus, error: ApiError | null) => DProp) =>
    <C extends Matching<DProp, C>>
    (WrappedComponent: React.ComponentType<C>): React.FC<Subtract<C, DProp> & DSS<D>> => {
      const WithMapData: React.FC<Subtract<C, DProp> & DSS<D>> = ({ data, status, error, ...rest }) => {
        const mappedProps = mapDataToProps ? mapDataToProps(data as D, status, error) : data as D;
        return <WrappedComponent {...mappedProps} {...rest as C} />;
      };

      WithMapData.displayName = `WithMapData(${getDisplayName(WrappedComponent)})`;
      return WithMapData;
    };

export default withMapData;
