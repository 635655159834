import { useIntl } from 'react-intl';

import { Icon } from './Styles/Icon';
import { styled } from '../styledComponents';
import { Ring, Tymber } from '../types/Tymbe';
import { hasUtilityFulfilled } from '../utils';

const Utility = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export type UtilityProps = {
  title: string;
  tymber: Tymber;
  id: number;
  ring: Ring;
};

const UtilityComponent = ({ title, tymber, id, ring }: UtilityProps) => {
  const intl = useIntl();
  const fulfilled = hasUtilityFulfilled(tymber, id, ring);
  return (
    <Utility>
      {fulfilled
        ? <Icon id="finished" background="primary" size="1.5em" />
        : <Icon id="attention" background="danger" size="1.5em" />}
      {title}
      {!fulfilled && (
        <span>
          {
            intl.formatMessage(
              {
                defaultMessage: '(napiš nám o přidání do profilu)',
                description: 'Text zobrazeny u nesplněné pomůcky,'
                  + ' např: "(!) Pracovní boty (napiš nám o přidání do profilu)"',
              },
            )
          }
        </span>
)}
    </Utility>
  );
};

export { UtilityComponent as Utility };
