import { logoutRequest } from '../actions/logout';
import { Button, ButtonProps } from './Forms/Button';
import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../reducers';

export interface LogoutButtonProps extends ButtonProps {
  logout: () => any;
}

export const LogoutComponent: React.FC<LogoutButtonProps> = ({ logout, ...rest }) =>
  <Button onClick={logout} {...rest} />;

export const LogoutButton = connect(
  (state: StoreState) => ({
    username: state.auth.email,
  }),
  { logoutRequest },
  (stateProps, dispatchProps, ownProps) => ({
    logout: () => dispatchProps.logoutRequest(stateProps.username || ''),
    ...ownProps,
  }),
)(LogoutComponent);
