import { FC } from 'react';
import { useIntl } from 'react-intl';

import RingsGrid from './Styles/RingsGrid';
import MainContractList from '../containers/MainContractList';
import OtherContractList from '../containers/OtherContractList';
import PayrollList from '../containers/PayrollList';
import TymberDocuments from '../containers/TymberDocuments';
import TymberPerkList from '../containers/TymberPerkList';
import TymberProfile from '../containers/TymberProfile';
import TymberUtilities from '../containers/TymberUtilities';

const Locker: FC = () => {
  const intl = useIntl();
  return (
    <RingsGrid>
      <MainContractList
        title={intl.formatMessage({
          defaultMessage: 'Pracovní smlouvy',
          description: 'Název sekce',
        })}
        color="primary"
      />
      <OtherContractList
        title={intl.formatMessage({
          defaultMessage: 'Ostatní dokumenty',
          description: 'Název sekce',
        })}
        color="primary"
      />
      <PayrollList
        title={intl.formatMessage({
          defaultMessage: 'Výplatní lístky DPP',
          description: 'Název sekce',
        })}
        color="gold"
      />
      <TymberUtilities color="blue" />
      <TymberDocuments color="steel" />
      <TymberPerkList color="green" />
      <TymberProfile color="purple" />
    </RingsGrid>
  );
};

export default Locker;
