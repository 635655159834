import { put, select } from '@redux-saga/core/effects';
import { logoutSuccess } from '../actions/logout';
import { AxiosResponse } from 'axios';
import { authSelector } from '../reducers';
import { Action } from 'redux';
import { ApiError } from '../types/Tymbe';

// tslint:disable-next-line:no-any
export function* handleApiError(err: ApiError, action?: Action): any {
  console.debug('[handleApiError]: Starting handling error');

  if (!action) {
    console.debug('[handleApiError]: No Action provided.');
    return err;
  }

  if (err.status === 401) {
    const { email } = yield select(authSelector);
    yield put(logoutSuccess(email));
    return err;
  }

  console.debug('[handleError]: Forwarding error');
  return !!err ? err : {};
}

export function initDownload(response: AxiosResponse, filename: string) {
  const url = URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
  const contentDisposition = response.headers['content-disposition'];
  const link = document.createElement('a');
  link.href = url;
  let fileName = filename;
  if (contentDisposition) {
    const fileMatch = contentDisposition.match(/filename="(.+)"/);
    fileName = fileMatch?.[1] ?? fileName;
  }
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
}
