import A from './A';
import { H1, H2, H3, H4 } from './BasicElements';
import Md from 'markdown-to-jsx';
import { styled } from '../../styledComponents';

const options = {
  overrides: {
    a: { component: A, props: { as: 'a' } },
    h1: { component: H1, props: { as: 'h3' } },
    h2: { component: H2, props: { as: 'h4' } },
    h3: { component: H3, props: { as: 'h5' } },
    h4: { component: H4, props: { as: 'h6' } },
  },
  disableParsingRawHTML: true,
};

const Markdown = styled(Md).attrs({ options })`
  ${H1} {
    font-size: 1.7rem;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0.5em 0;
  }
  ol, ul {
    padding-inline: 2em;
  }
  pre {
    overflow: auto;
  }
  blockquote {
    margin: 1em;
    border-left: 2px solid ${({ theme }) => theme.buttons.primary};
    padding: 0.5em 1em;
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  a {
    font-size: 1em;
  }
`;

export default Markdown;
