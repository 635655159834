import { Icon } from './Styles/Icon';
import React from 'react';
import { styled } from '../styledComponents';

export type DocumentItemProps = {
  name: string;
  onClick: (e: React.MouseEvent) => void;
};

const PayrollItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 2rem 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  grid-gap: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

const Button = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
`;

export const PayrollItem: React.FC<DocumentItemProps> = ({ name, onClick, ...props }) => (
  <PayrollItemWrapper {...props}>
    <Button onClick={onClick}>
      <Icon id={'download'} size={'3rem'} background={'gray4'} color={'secondary'} />
    </Button>
    <div style={{ alignSelf: 'center' }}><strong>{name}</strong></div>
  </PayrollItemWrapper>
);
