import { FieldProps, useField } from 'informed';
import * as React from 'react';
import { InputHTMLAttributes } from 'react';

import { FormItem } from './FormItem';
import 'react-calendar/dist/Calendar.css';
import { RadioGroup } from './RadioGroup';
import { Answer } from '../../types/Tymbe';
import { H3 } from '../Styles/BasicElements';
import { Icon } from '../Styles/Icon';

type ColoredRadioGroupOwnProps = {
  label: string;
  name: string;
  id: string;
  options: Answer[];
  correctAnswer: string;
  style?: React.CSSProperties;
  className?: string;
};
export type ColoredRadioGroupProps = ColoredRadioGroupOwnProps
& Omit<InputHTMLAttributes<HTMLInputElement>, keyof ColoredRadioGroupOwnProps>;
const ColoredRadioGroup = ({ ...props }: FieldProps<ColoredRadioGroupProps>) => {
  const { userProps } = useField<ColoredRadioGroupProps, string>({
    ...props,
    type: 'text',
  });
  const [correct, setCorrect] = React.useState<string | undefined>(undefined);

  const { className, style, id, label, defaultValue, name, options, correctAnswer, ...rest } = userProps;

  const validation = (value: unknown): void => {
    if (correctAnswer === value) {
      setCorrect('correct');
    } else {
      setCorrect('incorrect');
    }
  };
  const containerRef = React.createRef<HTMLDivElement>();

  return (
    <FormItem {...rest} className={className} ref={containerRef} style={style}>
      <H3>{label}</H3>
      <div className="flex justify-between items-end">
        <RadioGroup
          id={id}
          validate={validation}
          validateOn="submit"
          required
          className="flex flex-col"
          name={name}
          options={options}
        />
        <div>
          {correct === 'correct' && <Icon id="finished" color="lightgreen" background="white" size="2em" />}
          {correct === 'incorrect' && <Icon id="false" color="red" background="white" size="2em" />}
        </div>
      </div>

    </FormItem>

  );
};

export default ColoredRadioGroup;
