import { CardBadge } from './CardBadge';
import { CardColor } from './CardColor';
import { CardHeader, CardHeaderCentered } from './CardHeader';
import { styled } from '../../styledComponents';
import ThemeInterface from '../../themes/theme';

export const Card = styled.div<{ color?: keyof ThemeInterface['colors'] }>`
  display: flex;
  flex-flow: column;
  position: relative;
  min-width: 255px;
  //filter: drop-shadow(${({ theme }) => `0 0 0.5rem ${theme.colors.gray5}`});

  ${CardHeader}, ${CardHeaderCentered}, ${CardBadge} {
    background-color: ${({ theme, color }) => theme.colors[color!] ?? theme.colors.primary};
  }

  ${CardColor} {
    color: ${({ theme, color }) => theme.colors[color!] ?? theme.colors.primary};
  }
`;
