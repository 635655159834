import moment from 'moment-timezone';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import RingDocuments from './RingDocuments/RingDocuments.container';
import { ringRequest } from '../../actions/data/rings';
import Ring from '../../containers/Ring';
import { StoreState } from '../../reducers';
import { ringSelector } from '../../reducers/data/rings';
import { useClearStateOnUnmount } from '../../store';
import { createGlobalStyle, styled } from '../../styledComponents';
import { ApiError, Ring as IRing } from '../../types/Tymbe';
import { Error } from '../Error';
import { Main } from '../Main';
import { useModalError } from '../Modal/ModalError';
import Spinner from '../Spinner';
import withFetch from '../withFetch';
import withLoading from '../withLoading';
import withMapData from '../withMapData';

const LeftCol = styled.div``;

const OverideStyles = createGlobalStyle`
  ${Main} {
    max-height: 100%;
  }
`;

const RingDetailContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  flex-grow: 1;
  & > * {
    padding: 2rem 3rem;
    overflow: auto;
  }

  @media (max-width: 925px) {
    grid-template-columns: 1fr;
    ${LeftCol} {
      display: none;
    }
  }
`;

export type RingDetailProps = {
  ring: IRing;
  error?: ApiError;
};

const getNotificationMessage = (ring: IRing) => {
  const application = ring?.userApplication;
  const userIsInvited = !!application?.invitation;
  const shiftAlreadyStarted = moment(ring?.time.start).isBefore(moment().tz('Europe/Prague'));
  const backInTimeInvitation = userIsInvited && moment(ring?.time.start).isBefore(moment(application!.created_at));

  if (backInTimeInvitation && moment().tz('Europe/Prague').diff(moment(application!.created_at), 'hours') >= 24) {
    return 'Tvá pozvánka již není platná';
  }
  if (!backInTimeInvitation && shiftAlreadyStarted) return 'Směna už začala!';
  if (!userIsInvited && ring?.freePositions === 0) return 'Směna je už plná!';
  return undefined;
};

export const RingDetail = ({ ring, error }: RingDetailProps) => {
  useClearStateOnUnmount('ringDocuments');
  const navigate = useNavigate();
  const showError = useModalError();

  useEffect(() => {
    const navigateToRingWithError = (message: string) => {
      navigate('/ring');
      showError(message);
    };
    const message = getNotificationMessage(ring);
    if (message) navigateToRingWithError(message);
    // We want to check only once at the begining, redirect shouldn't be outside of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RingDetailContainer>
      <OverideStyles />
      <LeftCol>
        <Ring id={ring?.id} expanded />
      </LeftCol>
      {error ? <Error error={error} /> : <RingDocuments ring={ring} />}
    </RingDetailContainer>
  );
};

const RingDetailCont = connect(
  (state: StoreState, { id }: { id: IRing['id'] }) => ({
    ...ringSelector(state.data.rings, id),
  }),
  (dispatch, { id }) => ({
    fetch: () => dispatch(ringRequest(id)),
  }),
)(
  withFetch(
    withLoading(Spinner, true)(
      withMapData((data, _, error) => ({ ring: data, error }))(
        RingDetail,
      ),
    ),
  ),
);

export default <P,>(props: Omit<P, 'id'>) => {
  const { ringId } = useParams();
  return <RingDetailCont {...props} id={Number(ringId)} />;
};
