import { call, cancelled, put, takeEvery } from '@redux-saga/core/effects';
import {
  Actions,
  downloadContractFailure,
  DownloadContractRequestAction,
  downloadContractSuccess,
} from '../actions/downloadContract';
import Axios, { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { handleApiError, initDownload } from './utils';
import api from '../services/api';
import { ApiError } from '../types/Tymbe';

export function* fetchContractFile(action: DownloadContractRequestAction): SagaIterator {
  const { contractId, documentId, tymbe } = action;
  const { cancel: cancelRequest, token: cancelToken } = Axios.CancelToken.source();
  try {
    const response: AxiosResponse<Blob> = yield call(api.getSignedDocumentFile, contractId, tymbe, {
      cancelToken,
    });
    initDownload(response, `smlouva_${contractId}.pdf`);
    yield put(downloadContractSuccess(contractId, documentId, tymbe));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(downloadContractFailure(contractId, documentId, tymbe, error));
    }
  } finally {
    if (yield cancelled()) {
      cancelRequest();
    }
  }
}

export function* watchDownloadContract() {
  yield takeEvery(Actions.DOWNLOAD_CONTRACT_REQUEST, fetchContractFile);
}
