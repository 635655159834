import { getCreditsSuccess } from '../actions/credits';
import {
  Actions,
  getLiabilitiesFailure,
  GetLiabilitiesRequestAction,
  getLiabilitiesSuccess,
  payLiabilitiesFailure,
  PayLiabilitiesRequestAction,
  payLiabilitiesSuccess,
} from '../actions/liabilities';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, Liability, PayLiabilitiesResponse } from '../types/Tymbe';

export function* fetchLiabilities(action: GetLiabilitiesRequestAction): SagaIterator {
  try {
    const response: AxiosResponse<Liability[]> = yield call(api.getLiabilities);
    yield put(getLiabilitiesSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getLiabilitiesFailure(error));
    }
  }
}

export function* requestPayLiabilities(action: PayLiabilitiesRequestAction): SagaIterator {
  const { password } = action;
  try {
    const response: AxiosResponse<PayLiabilitiesResponse> = yield call(api.payLiabilities, password);
    yield put(payLiabilitiesSuccess(response.data.liabilities));
    yield put(getCreditsSuccess(response.data.credits));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(payLiabilitiesFailure(error));
    }
  }
}

export function* watchGetLiabilitiesRequest() {
  yield takeEvery(Actions.GET_LIABILITIES_REQUEST, fetchLiabilities);
}

export function* watchPayLiabilitiesRequest() {
  yield takeEvery(Actions.PAY_LIABILITIES_REQUEST, requestPayLiabilities);
}
