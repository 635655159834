import { call, put, takeEvery } from '@redux-saga/core/effects';
import {
  Actions,
  getMainContractsFailure,
  GetMainContractsRequest,
  getMainContractsSuccess,
} from '../actions/mainContracts';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, RingDocument } from '../types/Tymbe';

export function* fetchMainContracts(action: GetMainContractsRequest): SagaIterator {
  const { month, year } = action;
  try {
    const documents: AxiosResponse<RingDocument[]> =
      yield call(api.getSignedDocuments, { month, year, main: true });
    yield put(getMainContractsSuccess(documents.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getMainContractsFailure(error));
    }
  }
}

export function* watchGetMainContractsRequest() {
  yield takeEvery(Actions.GET_MAIN_CONTRACTS_REQUEST, fetchMainContracts);
}
