import ThemeInterface from './theme';
// #dd6429
const theme: ThemeInterface = {
  bodyColor: '#655468',
  bodyBg: '#fff',
  colors: {
    primary: '#B6CD1E',
    secondary: '#28367B',
    danger: '#EC8900',
    danger2: '#E84427',
    white: '#fff',
    gray1: '#f7f7f7',
    gray2: '#f0f0f0',
    gray3: '#e3e3e3',
    gray4: '#d6d6d6',
    gray5: '#c6c6c6',
    black: '#000',
    green: '#627d65',
    gold: '#cad160',
    steel: '#6d7e76',
    purple: '#665669',
    blue: '#577471',
  },
  buttons: {
    primary: '#B6CD1E',
    secondary: '#28367B',
    danger: '#EC8900',
    white: '#fff',
    gray1: '#f7f7f7',
    gray2: '#f0f0f0',
    gray3: '#e3e3e3',
    gray4: '#d6d6d6',
    gray5: '#c6c6c6',
    black: '#000',
    green: '#4a5f4d',
    gold: '#969159',
    steel: '#4f5c56',
    purple: '#524555',
    blue: '',
  },
  breakpoints: {
    desktop: 900,
    tablet: 600,
    phone: 360,
  },
};

export default theme;
