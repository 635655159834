import { call, put, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';

import { handleApiError } from './utils';
import {
  ActionTypes,
  documentFailure,
  DocumentRequestAction,
  documentSignFailure,
  DocumentSignRequestAction,
  documentSignSuccess,
  documentSuccess,
} from '../actions/data/documents';
import {
  Actions,
  getRingDocumentsFailure,
  getRingDocumentsRequest,
  GetRingDocumentsRequestAction,
  getRingDocumentsSuccess,
} from '../actions/ringDocuments';
import api from '../services/api';
import { ApiError, RingDocument, RingShiftClaimPrecheck } from '../types/Tymbe';

export function* fetchRingDocuments(action: GetRingDocumentsRequestAction): SagaIterator {
  const { ringId } = action;
  try {
    const claimShiftPrecheck: AxiosResponse<RingShiftClaimPrecheck> = yield call(api.precheckShiftClaim, ringId);
    yield put(getRingDocumentsSuccess(claimShiftPrecheck.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getRingDocumentsFailure(error));
    }
  }
}

export function* fetchDocument(action: DocumentRequestAction): SagaIterator {
  const { documentId, tymbe } = action;
  try {
    const document: AxiosResponse<RingDocument> = yield call(api.getDocument, documentId, tymbe);
    yield put(documentSuccess(document.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(documentFailure(documentId, tymbe, error));
    }
  }
}

export function* signDocument(action: DocumentSignRequestAction): SagaIterator {
  const { ringId, documentId, tymbe, signature, passed, shorten } = action;
  try {
    if (shorten) {
      yield call(api.shortenDocument, ringId, shorten);
    }
    let document: AxiosResponse<RingDocument>;
    if (passed !== '') {
      document = yield call(api.signRingDocument, ringId, documentId, tymbe, signature, passed);
    } else {
      document = yield call(api.signRingDocument, ringId, documentId, tymbe, signature);
    }

    yield put(documentSignSuccess(document.data));
    yield put(documentSuccess(document.data));
    yield put(getRingDocumentsRequest(ringId));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(documentSignFailure(documentId, tymbe, error.message));
    }
  }
}

export function* watchDocumentRequest() {
  yield takeLatest(ActionTypes.DOCUMENT_REQUEST, fetchDocument);
}

export function* watchDocumentSignRequest() {
  yield takeLatest(ActionTypes.DOCUMENT_SIGN_REQUEST, signDocument);
}

export function* watchGetRingDocumentsRequest() {
  yield takeLatest(Actions.GET_RING_DOCUMENTS_REQUEST, fetchRingDocuments);
}
