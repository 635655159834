import { Icon } from './Styles/Icon';

type RingBadgeProps = {
  icon: string,
  title?: string,
  backgroundColor?: string,
  iconFg?: string,
  iconBg?: string,
  className?: string
};

const RingBadge = ({
  icon,
  title,
  backgroundColor,
  iconFg = 'white',
  iconBg = 'transparent',
  className,
  ...rest
}: RingBadgeProps) => (
  <div
    className={`${backgroundColor || 'bg-primary'} w-[3rem] h-[4rem] px-[5px] rounded-tr-[1rem] rounded-br-[1rem] shadow-lg ${className}`}
    title={title}
    {...rest}
  >
    <Icon
      id={icon}
      color={iconFg}
      background={iconBg}
      size="100%"
    />
  </div>
);

export default RingBadge;
