import { useIntl } from 'react-intl';
import { WageDetailRow } from './WageDetailRow';
import { getPaySupplementTranslation } from './translations';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';

type PaySupplementRowProps = {
  type: PaySupplementType;
  amount: number;
}

export const PaySupplementRow = ({ type, amount }: PaySupplementRowProps) => {
  const intl = useIntl();

  return (
    <WageDetailRow
      name={intl.formatMessage(getPaySupplementTranslation(type))}
      value={amount}
    />
  );
};
