import { useIntl } from 'react-intl';

import { H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import { Utility } from './Utility';
import { CompanyUtility, Ring, Tymber } from '../types/Tymbe';

export type UtilityListProps = {
  utilities: CompanyUtility[];
  tymber: Tymber;
  ring: Ring;
};

export const UtilityList = ({ utilities, tymber, ring }: UtilityListProps) => {
  const intl = useIntl();
  return (
    <div>
      <H3>
        <Icon id="utilities" size="1.5em" style={{ marginRight: '.5rem' }} />
        {intl.formatMessage({
          defaultMessage: 'Vyžadované pomůcky',
          description: 'Seznam vyžadovaných pomůcek na brigádě (tričko, boty, ...)',
        })}
      </H3>
      {utilities.map((utility) => (
        <Utility
          key={utility.id}
          id={utility.id}
          title={utility.title}
          tymber={tymber}
          ring={ring}
        />
      ))}
    </div>
  );
};
