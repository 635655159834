import { styled } from '../../styledComponents';
import ThemeInterface from '../../themes/theme';

export const CardContent = styled.div<{background?: keyof ThemeInterface['colors'] | string}>`
  display: flex;
  position: relative;
  flex-flow: column;
  background-color: ${({ theme, background }) => background || theme.colors.gray1};
  padding: 2rem;
  height: 100%;

  & > * {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: initial;
    }
  }
`;
