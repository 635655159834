import { all, fork } from '@redux-saga/core/effects';
import { watchAuth } from './auth';
import { watchCancelClaimedRequest, watchGetClaimedRequest, watchMoreClaimedRequest } from './claimed';
import { watchContractPreviewRequest } from './contractPreview';
import { watchGetCreditsRequest, watchPayoutCreditsRequest } from './credits';
import { watchDownloadContract } from './downloadContract';
import { watchGetFinishedRequest, watchMoreFinishedRequest } from './finished';
import { watchRequestPasswordReset } from './forgotPassword';
import { watchInvitationRejectRequest, watchInvitationsRequest, watchMoreInvitationsRequest } from './invitation';
import { watchGetLiabilitiesRequest, watchPayLiabilitiesRequest } from './liabilities';
import { watchGetLocalizationRequest } from './localization';
import { watchGetMainContractsRequest } from './mainContracts';
import { watchGetOtherContractsRequest } from './otherContracts';
import { watchDownloadPayroll, watchGetPayrollsRequest } from './payrolls';
import { watchPerksRequest } from './perks';
import { watchGetRegistrationRequest, watchSaveRegistrationRequest } from './registration';
import { watchMoreRingsRequest, watchRingClaimRequest, watchRingRequest, watchRingsRequest } from './ring';
import { watchDocumentRequest, watchDocumentSignRequest, watchGetRingDocumentsRequest } from './ringDocuments';
import { watchGetSurveyRequest } from './surveys';
import { watchTymberDataRequest, watchTymberDataUpdateRequest } from './tymber';
import {
  watchDownloadTymberDocument,
  watchGetTymberDocumentsRequest,
  watchMoreTymberDocumentsRequest,
  watchInvalidateTymberDocumentRequest,
  watchSaveTymberDocumentRequest,
} from './tymberDocuments';
import { watchGetTymberUtilitiesRequest } from './tymberUtilities';
import { watchVacationRequest } from './vacation';
import { watchGetWagesRequest, watchPayoutWageRequest } from './wages';
import { watchBucketFileRequest } from './bucketFile';
import { watchPersonSalaryLimitRequest, watchPersonSalaryLimitUpdateRequest } from './personSalaryLimit';

export default function* root() {
  yield all([
    fork(watchAuth),
    fork(watchRingsRequest),
    fork(watchMoreRingsRequest),
    fork(watchGetRingDocumentsRequest),
    fork(watchInvitationsRequest),
    fork(watchInvitationRejectRequest),
    fork(watchMoreInvitationsRequest),
    fork(watchGetClaimedRequest),
    fork(watchMoreClaimedRequest),
    fork(watchCancelClaimedRequest),
    fork(watchGetFinishedRequest),
    fork(watchMoreFinishedRequest),
    fork(watchRingRequest),
    fork(watchRingClaimRequest),
    fork(watchDocumentRequest),
    fork(watchDocumentSignRequest),
    fork(watchGetMainContractsRequest),
    fork(watchGetOtherContractsRequest),
    fork(watchGetPayrollsRequest),
    fork(watchDownloadPayroll),
    fork(watchGetRegistrationRequest),
    fork(watchSaveRegistrationRequest),
    fork(watchGetTymberDocumentsRequest),
    fork(watchMoreTymberDocumentsRequest),
    fork(watchDownloadTymberDocument),
    fork(watchInvalidateTymberDocumentRequest),
    fork(watchSaveTymberDocumentRequest),
    fork(watchGetTymberUtilitiesRequest),
    fork(watchTymberDataRequest),
    fork(watchTymberDataUpdateRequest),
    fork(watchPersonSalaryLimitRequest),
    fork(watchPersonSalaryLimitUpdateRequest),
    fork(watchDownloadContract),
    fork(watchGetWagesRequest),
    fork(watchPayoutWageRequest),
    fork(watchGetCreditsRequest),
    fork(watchPayoutCreditsRequest),
    fork(watchGetSurveyRequest),
    fork(watchGetLiabilitiesRequest),
    fork(watchPayLiabilitiesRequest),
    fork(watchPerksRequest),
    fork(watchContractPreviewRequest),
    fork(watchRequestPasswordReset),
    fork(watchGetLocalizationRequest),
    fork(watchBucketFileRequest),
    fork(watchVacationRequest),
  ]);
}
