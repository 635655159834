import { CzechSalaryTax } from '@tymbe/legislatives/czechia/CzechSalaryTax';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { revertNumber } from '../../utils';
import { useAddFootNote } from './FootNotesContext';
import { Separator } from './Styled';
import { getTaxTranslation } from './translations';
import { WageDetailRow } from './WageDetailRow';
import { WageDetailTitleRow } from './WageDetailTitleRow';

type TaxRowProps = {
  tax: CzechSalaryTax;
}
export const TaxRow = ({ tax }: TaxRowProps) => {
  const intl = useIntl();
  const footNoteTrans = getTaxTranslation(tax.type, 'footnote');
  const footNote = useMemo(() => {
    return footNoteTrans && intl.formatMessage(
      footNoteTrans,
      {
        a: link => <a
          className="underline text-primary"
          href="https://tymbe.cz/faq.html"
          rel="noreferrer"
          target="_blank"
        >
          {link}
        </a>,
      },
    );
  }, [ footNoteTrans, intl ]);

  const footNoteIndex = useAddFootNote(footNote, footNoteTrans?.id);

  return (
    <>
      <Separator />
      <WageDetailTitleRow
        name={intl.formatMessage(getTaxTranslation(tax.type))}
        footNoteIndex={footNoteIndex}
        value={revertNumber(tax.finalAmount)}
      />
      <WageDetailRow
        name={intl.formatMessage({
          defaultMessage: 'Základní',
          description: 'Název u hodnoty základní daně v detailu výplat. Například za zdravotní pojištění zaplatí 882,-, z toho "základní" je 727,-.',
        })}
        value={revertNumber(tax.baseAmount)}
      />
    </>
  );
};
