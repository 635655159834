import { Actions, GetCreditsActions, PayoutCreditsActions } from '../../actions/credits';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { Credit } from '../../types/Tymbe';

export type CreditsState = DataStoreState<Credit[]>;

const initState: CreditsState = initDataStoreState<Credit[]>(null, []);

type ReducerActions = GetCreditsActions | PayoutCreditsActions;
export const creditsReducer: Reducer<CreditsState, ReducerActions> = (state = initState, action) => {
  switch (action.type) {
    case Actions.GET_CREDITS_REQUEST:
      return initDataStoreState<Credit[]>(STATUS_PENDING, state.data);
    case Actions.GET_CREDITS_SUCCESS:
      return initDataStoreState<Credit[]>(STATUS_SUCCESS, action.credits);
    case Actions.GET_CREDITS_FAILURE:
      return initDataStoreState<Credit[]>(STATUS_ERROR, state.data, action.error);
    case Actions.PAYOUT_CREDITS_SUCCESS:
      return initDataStoreState<Credit[]>(STATUS_SUCCESS, state.data?.map((credit) => {
        if (credit.id === action.creditsId) {
          return { ...credit, processing: true };
        }
        return credit;
      }));
    default:
      return state;
  }
};
