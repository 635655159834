import { Actions, GetCreditsActions, PayoutCreditsActions } from '../../actions/credits';
import { generateDataStoreItemsById } from '../utils';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { Credit } from '../../types/Tymbe';

export type CreditsState = Indexable<DataStoreState<Credit>>;

const initState: CreditsState = {};

type ReducerActions = GetCreditsActions | PayoutCreditsActions;

export const creditsReducer: Reducer<CreditsState, ReducerActions> = (state = initState, action) => {
  switch (action.type) {
    case Actions.GET_CREDITS_SUCCESS:
      return generateDataStoreItemsById(action.credits);
    case Actions.PAYOUT_CREDITS_REQUEST:
      return {
        ...state,
        [action.creditsId]: initDataStoreState<Credit>(STATUS_PENDING, state[action.creditsId]?.data),
      };
    case Actions.PAYOUT_CREDITS_SUCCESS:
      return {
        ...state,
        [action.creditsId]: initDataStoreState<Credit>(STATUS_SUCCESS, {
          ...state[action.creditsId]?.data!,
          processing: true,
        }),
      };
    case Actions.PAYOUT_CREDITS_FAILURE:
      return {
        ...state,
        [action.creditsId]: initDataStoreState<Credit>(STATUS_ERROR, state[action.creditsId]?.data, action.error),
      };
    default:
      return state;
  }
};

export const creditsSelector = (state: CreditsState, id: Credit['id']) => state[id] || initDataStoreState(null);
