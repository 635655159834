import { tymberDataRequest } from '../actions/tymberData';
import { getTymberUtilitiesRequest } from '../actions/tymberUtilities';
import { Error } from '../components/Error';
import Spinner from '../components/Spinner';
import { TymberUtilities } from '../components/TymberUtilities';
import withError from '../components/withError';
import withFetch, { withFetchNew } from '../components/withFetch';
import { withLoaderNew } from '../components/withLoading';
import withMapData from '../components/withMapData';
import React from 'react';
import { connect } from 'react-redux';
import { componentsSelector, StoreState, tymberSelector } from '../reducers';
import { tymberUtilitiesSelector } from '../reducers/components';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { Tymber, TymberUtility } from '../types/Tymbe';

const selectUtilities = compose(tymberUtilitiesSelector, componentsSelector);

const TymberUtilitiesConnect = connect(
  (state: StoreState) => ({
    ...selectUtilities(state),
    tymber: tymberSelector(state).data as Tymber,
  }),
  dispatch => ({
    fetch: () => dispatch(getTymberUtilitiesRequest()),
  }),
);

export default TymberUtilitiesConnect(
  withFetch(
    withError(Error)(
      withMapData((data: TymberUtility[], status) => ({ utilities: data ?? [], loading: status === STATUS_PENDING }))(
        withFetchNew(tymberSelector, tymberDataRequest)(
          withLoaderNew(tymberSelector, <Spinner />, true)(
            TymberUtilities,
          ),
        ),
      ),
    ),
  ),
);
