import { ActionTypes, DocumentSignActions } from '../../../actions/data/documents';
import { Reducer } from 'redux';
import { CLEAR_STATE, ClearStateAction } from '../../../store';
import { DataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../../types/StoreUtils';
import { RingDocument } from '../../../types/Tymbe';

export type SignDocumentState = {
  documentId: RingDocument['id'] | null;
  status: DataStoreState['status'];
  error: string | null;
};

const initState: SignDocumentState = {
  documentId: null,
  status: null,
  error: null,
};

export const signDocumentReducer: Reducer<SignDocumentState, DocumentSignActions | ClearStateAction> =
  (state = initState, action) => {
    switch (action.type) {
      case ActionTypes.DOCUMENT_SIGN_REQUEST:
        return { status: STATUS_PENDING, documentId: action.documentId, error: null };
      case ActionTypes.DOCUMENT_SIGN_SUCCESS:
        return { ...state, status: STATUS_SUCCESS };
      case ActionTypes.DOCUMENT_SIGN_ERROR:
        return { ...state, status: STATUS_ERROR, error: action.error };
      case CLEAR_STATE:
        return action.state === 'signDocument' ? initState : state;
      default:
        return state;
    }
  };
