import { styled } from '../styledComponents';

export const Main = styled.div.attrs({ id: 'Main' })<{ fitHeight?: boolean }>`
  display: flex;
  flex-flow: row;
  position: relative;
  min-height: 100%;
  ${({ fitHeight }) => fitHeight && 'max-height: 100%'};
  background-color: ${({ theme }) => theme.colors.gray3};
  padding-top: 100px;
`;
