import * as React from 'react';
import SPad from 'signature_pad';

import { Button } from './Forms/Button';
import { intl } from './I18n';
import { H1 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import { styled } from '../styledComponents';

export type SignaturePadProps = {
  onSign: (signature: string) => void;
  onCancel: () => void;
  setTestPassed?: ({ show, points }: { show: boolean, points: string }) => void;
  disabled: boolean;
  children?: React.ReactNode;
};

const Canvas = styled.canvas`
  cursor: crosshair;
  display: block;
  border: 1px solid black;
  box-shadow: 0 0 5px black;
  margin: 5px;
  grid-area: signature;
`;

const Error = styled.div`
  display: block;
  grid-area: error;
  min-height: 1.2em;
`;

const SignatureWrapper = styled.div`
  position: relative;
  display: grid;
  grid-column: auto;
  grid-gap: 2rem;
  padding: 3rem;
  grid-template-areas:
    'heading heading'
    'signature signature'
    'error error'
    'cancel sign';
`;

const Cancel = styled(Button)`
  grid-area: cancel;
  justify-self: start;
`;

const Sign = styled(Button)`
  grid-area: sign;
  justify-self: end;
`;

const Close = styled(Button).attrs({ background: 'secondary' })`
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin-right: 0;
`;

export class SignaturePad extends React.Component<
SignaturePadProps,
{ isEmpty: boolean; }
> {
  private canvasRef = React.createRef<HTMLCanvasElement>();

  private signaturePad?: SPad;

  state = { isEmpty: true };

  componentDidMount(): void {
    this.signaturePad = new SPad(this.canvasRef.current!);
    this.signaturePad.penColor = 'rgb(66, 133, 244)';

    this.signaturePad.addEventListener('endStroke', () => {
      let signaturePoints = 0;
      this.signaturePad?.toData().forEach((item) => {
        signaturePoints += item.points.length;
      });
      if (signaturePoints > 15) {
        this.setState({ isEmpty: false });
      }
    });
  }

  sign = () => {
    const { onSign } = this.props;

    if (!this.signaturePad) return;
    if (this.signaturePad.isEmpty()) return;
    onSign(this.signaturePad.toDataURL());
  };

  clear = () => {
    this.setState({ isEmpty: true });
    this.signaturePad?.clear();
  };

  render() {
    const { disabled, onSign, onCancel, children, ...rest } = this.props;
    return (
      <SignatureWrapper {...rest}>
        <Close onClick={onCancel}>X</Close>
        <H1 style={{ gridArea: 'heading' }}>
          <Icon id="sign" size="3em" color="primary" />{' '}
          {intl.formatMessage({
            defaultMessage: 'Zde se podepište:',
            description:
              'Titulek nad plochou pro podpis. Zobrazováno během podpisu smlouvy.',
          })}
        </H1>
        <Canvas ref={this.canvasRef} data-cy="signCanvas" />
        <Error>{children}</Error>
        <Cancel onClick={this.clear} background="secondary">
          {intl.formatMessage({
            defaultMessage: 'Smazat',
            description:
              'Tlačítko pro vymazání zadaného podpisu. Zobrazováno během podpisu smlouvy.',
          })}
        </Cancel>
        <Sign onClick={this.sign} disabled={disabled || this.state.isEmpty} data-cy="signDocumentButton">
          {intl.formatMessage({
            defaultMessage: 'Podepsat',
            description:
              'Tlačítko pro potvrzení zadaného podpisu. Zobrazováno během podpisu smlouvy.',
          })}
        </Sign>
      </SignatureWrapper>
    );
  }
}
