import { call, put, takeLatest } from '@redux-saga/core/effects';
import { Actions, getLocalizationRequest, GetLocalizationRequest, getLocalizationSuccess } from '../actions/localization';
import { REHYDRATE } from 'redux-persist';
import { SagaIterator } from 'redux-saga';
import { loadLocaleData } from '../utils';

// tslint:disable-next-line:no-any
type RehydrateAction<Payload = any> = {
  type: typeof REHYDRATE,
  key: string,
  payload: Payload,
};

export function* requestLocalization(action: GetLocalizationRequest | RehydrateAction): SagaIterator {
  if (action.type === REHYDRATE && action.key !== 'localization') {
    return;
  }

  if (action.type === REHYDRATE && !action.payload?.locale) {
    return yield put(getLocalizationRequest('cs'));
  }

  const locale = action.type === REHYDRATE ? action.payload.locale : action.locale;
  const messages = yield call(loadLocaleData, locale);
  yield put(getLocalizationSuccess(messages.default));
  // eslint-disable-next-line no-restricted-globals
  if (action.type !== REHYDRATE) location.reload();
}

export function* watchGetLocalizationRequest() {
  yield takeLatest([REHYDRATE, Actions.GET_LOCALIZATION_REQUEST], requestLocalization);
}
