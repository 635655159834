import { CzechSalaryType } from '@tymbe/legislatives/czechia/CzechSalaryType';
import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { SalaryCalculationData } from '@tymbe/schema/salary-calculation.interface';
import { useMutation } from 'react-query';
import { Card } from './Card';
import { CardColor } from './Card/CardColor';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import { Button } from './Forms/Button';
import { FormItem } from './Forms/FormItem';
import { FormRowItems } from './Forms/FormRowItems';
import Spinner from './Spinner';
import { H2, H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import { Form, FormState, Option, Select } from 'informed';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { WageDetailModal } from './Modal/WageDetailModal';
import { PayrollItem } from './PayrollItem';
import WageDetail from './WageDetail';
import api from '../services/api';

export type DocumentListProps = {
  wages: PayoutInfoData[];
  title: string;
  loading: boolean;
} & GetProps<typeof Card>;

type DocumentListForm = {
  month: string;
  year: number;
};

export const WageDetailList: React.FC<DocumentListProps> =
  ({ wages, title, loading, ...p }) => {
    const intl = useIntl();
    const [modalWage, setModalWage] = useState<PayoutInfoData>();
    const [modalDetail, setModalDetail] = useState<SalaryCalculationData>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [yearmonth, setYearmonth] = useState<string>();

    const { mutateAsync: getWageDetail } = useMutation(
      async (wage: PayoutInfoData) => (await api.getWageDetail(wage.start, wage.company_id, wage.type)).data,
    );

    const onSubmit = (formState: FormState) => {
      const { month, year } = formState.values as DocumentListForm;
      setYearmonth(moment.tz([year, Number(month) - 1, 1], 'europe/prague').toISOString());
    };

    const showDetail = async (wage: PayoutInfoData) => {
      setOpenModal(true);
      setModalWage(wage);
      setModalDetail(undefined); // prevents old data from being displayed
      setModalDetail(await getWageDetail(wage));
    };

    const display = wages.filter((wage) => wage.withheldAmount + wage.blockedAmount + wage.payableAmount + wage.paidAmount + wage.processingAmount > 0 && wage.start === yearmonth);

    return (
      <Card {...p}>
        {loading && <Spinner position={'absolute'} />}
        <CardHeader>
          <Icon style={{ float: 'right' }} id={'money'} color={'white'} size={'2em'} />
          <H2>{title}</H2>
        </CardHeader>
        <CardContent>
          <CardColor as={H3}>{intl.formatMessage({
            defaultMessage: 'Vybrat období:',
            description: 'Uvození formulaře pro výběr obdobi (měsíc/rok)',
          })}</CardColor>
          <Form onSubmit={onSubmit}>
            <FormRowItems $wrap>
              <FormItem>
                <Select name={'month'} initialValue={moment().month() + 1}>
                  {moment.months(intl.locale === 'cs' ? 'D MMMM' : 'MMMM').map((month, i) => <Option key={i} value={i + 1}>{month}</Option>)}
                </Select>
              </FormItem>
              <FormItem>
                <Select name={'year'} initialValue={moment().year()}>
                  {getYears().map(year => <Option key={year} value={year}>{year}</Option>)}
                </Select>
              </FormItem>
              <Button type={'submit'}>{intl.formatMessage({
                defaultMessage: 'Zobrazit',
                description: 'Text tlačítka pro potvrzení vybraného období',
              })}</Button>
            </FormRowItems>
          </Form>
          {display.map((wage) => {
            const typeName = `${wage.type === CzechSalaryType.DPP ? intl.formatMessage({
              defaultMessage: '(DPP)',
              description: 'Přívlastek výplaty za DPP',
            }) : ''}${wage.type === CzechSalaryType.DPC_HPP ? intl.formatMessage({
              defaultMessage: '(DPČ+HPP)',
              description: 'Přívlastek výplaty za DPČ+HPP',
            }) : ''}`;
            return (
              <PayrollItem
                key={`${wage.start}/${wage.company_id}/${wage.type}`}
                name={`${(wage.company?.name ?? '...')} ${typeName}`}
                onClick={() => {
                  showDetail(wage);
                }}
              />
            );
          })}
        </CardContent>
        <CardFooter />
        <WageDetailModal
          onCancel={() => setOpenModal(false)}
          disable={loading}
          title={intl.formatMessage({
            defaultMessage: 'Detail odměny k vyplacení',
            description: 'Titulek dialogu pro zobrazení detailu odměny k vyplacení',
          })}
          open={openModal}
        >
          {modalWage && modalDetail && (
            <WageDetail payoutInfoData={modalWage} salaryCalculation={modalDetail} />
          )}
        </WageDetailModal>
      </Card>
    );
  };

const getYears = () => {
  const years = [];
  for (let year = 2017; year <= moment().year(); year += 1) {
    years.push(year);
  }
  return years;
};
