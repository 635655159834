import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeaderCentered } from './Card/CardHeader';
import { Button } from './Forms/Button';
import { Radio } from './Forms/Radio';
import { LabeledInput } from './Forms/LabeledInput';
import { intl } from './I18n';
import { H2, H3 } from './Styles/BasicElements';
import { Checkbox, Form, FormState } from 'informed';
import React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '../styledComponents';
import { Survey as SurveyData, SurveyAnswer, SurveyQuestion, SurveyQuestionType } from '../types/Tymbe';
import { RadioGroup } from './Forms/RadioGroup';

export type SurveyProps = SurveyData & {
  onSubmit: (data: SurveyAnswer, password: string) => void;
  onCancel: () => void;
  showError?: string;
  disabled: boolean;
};

type FormData = { [K: string]: string | number | [] } & { password: string };

export const Survey = ({ title, questions, onSubmit, id, onCancel, showError, disabled }: SurveyProps) => {
  // tslint:disable-next-line:no-shadowed-variable we need to use this name because of automation
  const intl = useIntl();
  const handleSubmit = (formState: FormState) => {
    const { password, ...formData } = formState.values as FormData;
    onSubmit(
      {
        id,
        answers: formData,
      },
      password,
    );
  };

  return (
    <Card>
      <CardHeaderCentered><H2>{title}</H2></CardHeaderCentered>
      <Form onSubmit={handleSubmit}>
        {({ formState }: { formState: FormState }) => (
          <>
            <CardContent>
              <div>{intl.formatMessage({
                defaultMessage: `<h2>Ahoj Tymbere,</h2>
                  <p>
                    vážíme si Tvého názoru a tak bychom Tě rádi odměnili za to, že nám řekneš, jaká byla Tvá brigáda.
                  </p>
                  <h3>Kdo uvidí, jak odpovíš:</h3>
                  <p>Žádný strach, vedoucí ani jiný pracovník zadavatele nemá k dotazníkům přístup, data jsou pouze pro
                    nás – pro Tymbe, takže se nikdo neurazí, když budeš k zadavateli příliš kritický, právě naopak,
                    chceme, aby se Tobě i ostatním brigády líbily, aby vše klapalo, jak má.</p>
                  <h3>Jak bude s dotazníkem naloženo?</h3>
                  <p>Uděláme z něj anonymizovanou statistiku a tam, kde se budou sbíhat kritické hlasy, navrhneme
                    zadavateli zlepšení. Díky tomu se i Ty můžeš podílet na zlepšování kvality našich brigád!</p>`,
                description: 'Úvodní informace v dialogu s anketou.',
              })}</div>
              <H3>
                {intl.formatMessage({
                  defaultMessage: 'Pojďme na to:',
                  description: 'Titulek před samotným formulářem ankety',
                })}
              </H3>

              {questions.map(question => getQuestionInput(question, formState))}
              <LabeledInput
                id={'survey_password'}
                name={'password'}
                type={'password'}
                label={intl.formatMessage({
                  defaultMessage: 'Potvrď svým heslem',
                  description: 'Posledni pole formuláře ankety. Uživatel musí vyplnění ankety potvrdit zadaním hesla.',
                })}
                required
              />
              {showError && <div>{showError}</div>}
            </CardContent>
            <CardFooter style={{ textAlign: 'right' }}>
              <Button type={'button'} onClick={onCancel} background={'secondary'}>
                {intl.formatMessage({
                  defaultMessage: 'Zrušit',
                  description: 'Tlačítko pro zrušení a zavření dialogu s anketou',
                })}
              </Button>
              <Button type={'submit'} disabled={disabled}>
                {intl.formatMessage({
                  defaultMessage: 'Vyplatit kredity',
                  description: 'Tlačítko pro odeslání a zavření dialogu s anketou. ' +
                    'Vyplněním ankety je podmíněno vyplacení vydělaných kreditu.',
                })}
              </Button>
            </CardFooter>
          </>
        )}
      </Form>
    </Card>
  );
};

const RadioGroupStyled = styled(RadioGroup)`
  & label {
    display: block;
  }
`;

const Fieldset = styled.fieldset`
  & label {
    display: block;
  }
`;

const getQuestionInput = (question: SurveyQuestion, formState: FormState) => {
  const checkBoxValidation = (scope: string) => (value: unknown, values: Record<string, unknown>) => {
    const checkboxes = values[scope] as boolean[];
    return checkboxes.every(val => !val) && !value
      ? intl.formatMessage({
        defaultMessage: 'Musíte vybrat alespoň jednu z možností',
        description: 'Chyba prvku formuláře ankety',
      })
      : undefined;
  };

  const firstError = (errors: unknown) => {
    const error = Array.isArray(errors) && errors.find(e => e);
    return error ? error : undefined;
  };

  const getNotifications = (id: number, scope: string): string[] => {
    const notification = [`${scope}[0]`, `${scope}[1]`, `${scope}[2]`, `${scope}[3]`, `${scope}[4]`];
    notification.splice(id, 1);
    return notification;
  };

  switch (question.type) {
    case SurveyQuestionType.TEXT:
    case SurveyQuestionType.TEXT_AREA:
      return (
        <LabeledInput
          key={question.id}
          id={`question_${question.id}`}
          name={`${question.id}`}
          label={question.question}
          autoComplete={'off'}
        />
      );
    case SurveyQuestionType.RADIO:
      return (
        <RadioGroupStyled key={question.id} name={`${question.id}`} required>
          <legend>{question.question}</legend>
          {question.options.map((option) =>
            <label key={option.id}><Radio value={String(option.id)} />{option.name}</label>)}
        </RadioGroupStyled>
      );
    case SurveyQuestionType.CHECKBOX:
      return (
        <Fieldset key={question.id}>
          <legend>{question.question}</legend>

          {question.options.map((option) => (
            <label key={option.id}>
              <Checkbox
                name={`${question.id}[${option.id}]`}
                initialValue={false}
                validate={checkBoxValidation(`${question.id}`)}
                validateWhen={getNotifications(option.id, `${question.id}`)}
                validateOn={'change'}
              />
              {option.name}
            </label>
          ))}
          {firstError(formState.errors?.[`${question.id}`]) &&
            <div>{firstError(formState.errors?.[`${question.id}`])}</div>}
        </Fieldset>
      );
  }
};
