import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export interface ProtectedRouteProps {
  authenticated?: boolean;
  children: JSX.Element;
}


// authenticated ?
//     children :
//     <Navigate to="/login" state={{ referrer: rest.path || '/' }} />;

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ authenticated, children }) => {
  const { pathname } = useLocation();
  if (!authenticated) {
    return <Navigate to={'/login'} state={{ referrer: pathname }} />;
  }
  return children;
};
