import { styled } from '../../styledComponents';
import ThemeInterface from '../../themes/theme';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  background?: keyof ThemeInterface['buttons'];
  color?: keyof ThemeInterface['colors'];
}

export const Button = styled.button<ButtonProps>`
  color: ${({ theme, color }) => theme.colors[color!] ?? theme.colors.white};
  background-color: ${({ theme, background }) => theme.buttons[background!] ?? theme.buttons.primary};
  border-radius: 1rem 0 1rem 0;
  border: none;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 3px;
  margin-right: 5px;
  padding: 0.5em 1em;
  vertical-align: top;
  transition: all .5s;
  text-decoration: none;

  &.secondary {
    background-color: ${({ theme }) => theme.buttons.secondary};
  }

  &[disabled] {
    background: #888888 !important;
  }

  &:not([disabled]):hover {
    color: white;
    border-radius: 0 1rem 0 1rem;
    box-shadow: 0 5px 5px ${({ theme, background }) => theme.colors[background!] ?? theme.colors.primary}FF ;
    &.secondary {
      box-shadow: 0 5px 5px ${({ theme }) => theme.colors.secondary};
    }
  }
  &:not([disabled]).selected:hover {
    color: initial;
  }
  &:not([disabled]):active, &:not([disabled]).selected {
    transform: scale(1);
    transition-duration: .1s;
    box-shadow: 0 3px 5px 0 ${({ theme }) => theme.colors.primary};
    &.secondary {
      box-shadow: 0 3px 5px 0 ${({ theme }) => theme.colors.secondary};
    }

    &.disabled {
    }
  }
  &:focus {
    outline: none;
  }
`;
