import { call, cancel, cancelled, fork, put, take } from '@redux-saga/core/effects';
import { LOGIN_FAILURE, LOGIN_REQUEST, loginFailure, loginSuccess } from '../actions/login';
import { LOGOUT_REQUEST, LOGOUT_SUCCESS, logoutRequest, logoutSuccess } from '../actions/logout';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError } from '../types/Tymbe';

export function* login(username: string, password: string): SagaIterator {
  try {
    const response: AxiosResponse = yield call(api.login, username, password);
    const { data: { auth, ...data } } = response;
    yield put(loginSuccess(data));
  } catch (error) {
    yield put(loginFailure(username, error as ApiError));
  } finally {
    if (yield cancelled()) {
      yield put(logoutSuccess(username));
    }
  }
}

export function* logout(username: string): SagaIterator {
  try {
    yield call(api.logout);
    yield put(logoutSuccess(username));
  } catch (error) {
    const apiErr = yield handleApiError(error as ApiError, logoutRequest(username));
    console.log('Got error on logout:', apiErr);
    if (apiErr) {
      yield put(logoutSuccess(username));
    }
  }
}

export function* watchAuth(): SagaIterator {
  while (true) {
    console.debug('[watchAuth]: watching for Login or Logout request');
    const { email, password, type } = yield take([LOGIN_REQUEST, LOGOUT_REQUEST]);
    if (type === LOGOUT_REQUEST) {
      console.debug('[watchAuth]: handling logout request');
      yield fork(logout, email);
      continue;
    }
    console.debug('[watchAuth]: handling login request');
    const task = yield fork(login, email, password);
    console.debug('[watchAuth]: login task created');
    console.debug('[watchAuth]: watching for login failure or logout request/success');
    const action = yield take([LOGIN_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS]);
    if (action.type === LOGOUT_REQUEST) {
      console.debug('[watchAuth]: logout request, canceling task');
      yield cancel(task);
      console.debug('[watchAuth]: calling logout');
      yield fork(logout, email);
    }
  }
}
