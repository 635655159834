import { action } from '../../utils';
import { Action } from 'redux';
import { ApiError, Ring } from '../../../types/Tymbe';

export enum ActionTypes {
  RING_REQUEST = 'RING_REQUEST',
  RING_SUCCESS = 'RING_SUCCESS',
  RING_FAILURE = 'RING_FAILURE',
  RING_CLAIM_REQUEST = 'RING_CLAIM_REQUEST',
  RING_CLAIM_SUCCESS = 'RING_CLAIM_SUCCESS',
  RING_CLAIM_FAILURE = 'RING_CLAIM_FAILURE',
}

export interface RingRequestAction extends Action<ActionTypes.RING_REQUEST> {
  ringId: Ring['id'];
}

export interface RingSuccessAction extends Action<ActionTypes.RING_SUCCESS> {
  ring: Ring;
}

export interface RingFailureAction extends Action<ActionTypes.RING_FAILURE> {
  ringId: Ring['id'];
  error: ApiError;
}

export interface RingClaimRequestAction extends Action<ActionTypes.RING_CLAIM_REQUEST> {
  ringId: Ring['id'];
}

export interface RingClaimSuccessAction extends Action<ActionTypes.RING_CLAIM_SUCCESS> {
  ring: Ring;
}

export interface RingClaimFailureAction extends Action<ActionTypes.RING_CLAIM_FAILURE> {
  ringId: Ring['id'];
  error: ApiError;
}

export type RingActions = RingRequestAction | RingSuccessAction | RingFailureAction;
export type RingClaimActions = RingClaimRequestAction | RingClaimSuccessAction | RingClaimFailureAction;

export const ringRequest = (ringId: Ring['id']): RingRequestAction => ({
  type: ActionTypes.RING_REQUEST,
  ringId,
});

export const ringSuccess = (ring: Ring): RingSuccessAction => ({
  type: ActionTypes.RING_SUCCESS,
  ring,
});

export const ringFailure = (ringId: Ring['id'], error: ApiError) => ({
  type: ActionTypes.RING_FAILURE,
  ringId,
  error,
});

export const ringClaimRequest = (ringId: Ring['id']): RingClaimRequestAction =>
  action(ActionTypes.RING_CLAIM_REQUEST, { ringId });
export const ringClaimSuccess = (ring: Ring): RingClaimSuccessAction =>
  action(ActionTypes.RING_CLAIM_SUCCESS, { ring });
export const ringClaimFailure = (ringId: Ring['id'], error: ApiError): RingClaimFailureAction =>
  action(ActionTypes.RING_CLAIM_FAILURE, { ringId, error });
