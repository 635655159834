import * as React from 'react';
import { keyframes, styled } from '../styledComponents';
import { Keyframes } from 'styled-components';

export interface ToggleableProps {
  toggled: boolean;
  onClick?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
  maxHeight?: number;
}

const downAnimation = (height?: number) => keyframes`
  0% {
    height: 0;
    overflow: hidden;
  }
  100% {
    height: ${height}px;
  }
`;

const upAnimation = (height?: number) => keyframes`
  0% {
    height: ${height}px;
    overflow: hidden;
  }
  100% {
    height: 0;
  }
`;
type ContainerProps = Omit<ToggleableProps, 'toggled'> & { toggle: boolean, anim?: Keyframes, maxHeight?: number };
const Container = styled.div<ContainerProps>`
  animation-name: ${({ anim }) => anim};
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  height: ${({ toggle }) => toggle ? 'auto' : 0};
  max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}px`};
  overflow: ${({ toggle }) => toggle ? null : 'hidden'};
  outline: none;
`;

export const ToggleableComponent: React.FC<ToggleableProps> = ({ toggled, ...props }) => {
  let innerRef: HTMLDivElement;
  const [anim, changeAnim] = React.useState<Keyframes>();
  const [state, toggle] = React.useState(toggled);
  const [initial, initialRan] = React.useState(true);
  const fireCallback = () => {
    toggled ? props.onOpen?.() : props.onClose?.();
  };
  const getHeight = () => {
    const minHeight = Math.min(Number(props.maxHeight), innerRef.scrollHeight);
    return !minHeight && innerRef.scrollHeight || minHeight;
  };

  React.useEffect(
    () => {
      !initial && changeAnim(toggled ? downAnimation(getHeight()) : upAnimation(getHeight()));
      initialRan(false);
      toggle(toggled);
    },
    [toggled]);
  return (
    <Container
      {...props}
      anim={anim}
      onAnimationEnd={fireCallback}
      toggle={state}
      tabIndex={-1}
      maxHeight={props.maxHeight}
      ref={(ref: HTMLDivElement) => innerRef = ref}
    />
  );
};

export const Toggleable = styled(ToggleableComponent)``;
