// TODO Update to 2.x after we update to React 18
import { WingbotChat as WingbotChatComponent } from 'wingbot-chat-ui';

export type WingbotChatProps = {
  userId: string | undefined;
  userJwt: string | null;
};

export const WingbotChat = ({ userId, userJwt }: WingbotChatProps) => {
  if (!userId || !userJwt) {
    return null;
  }

  return (
    <WingbotChatComponent
      api="https://chat-api.wingbot.ai"
      websocket="wss://chat-ws.wingbot.ai"
      pageId={import.meta.env.CLIENT_WINGBOT_PAGE_ID}
      contextUserAgentProp="ua"
      contextScreenResolutionProp="sr"
      context={{ userId, userJwt }}
    />
  );
};
