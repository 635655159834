import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeaderCentered } from './Card/CardHeader';
import { H1, H2 } from './Styles/BasicElements';
import { WellBox } from './Styles/WellBox';
import CreditItem from '../containers/CreditItem';
import React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '../styledComponents';
import { Credit } from '../types/Tymbe';

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BigWellBox = styled(WellBox)`
  padding: 1rem 1.5rem;
  font-size: 2em;
`;

export type CreditsProps = {
  credits: Credit[],
  disabled?: boolean;
};

export const Credits = ({ credits, disabled }: CreditsProps) => {
  const intl = useIntl();

  const countCredits = (list: Credit[]) =>
    +list
      .map(credit => credit.credits)
      .reduce((sum, val) => sum + val, 0).toFixed(2);

  const processingCredits = credits.filter(credit => credit.processing);
  const payoutCredits = credits.filter(credit => !credit.processing && credit.credits > 0);
  const liabilities = countCredits(credits.filter(credit => !credit.processing && credit.credits < 0));
  const payoutCreditsSum = countCredits(payoutCredits) + liabilities;

  return (
    <Card>
      <CardHeaderCentered><H1>{intl.formatMessage({
        defaultMessage: 'Kredity',
        description: 'Nadpis sekce na strance výplat',
      })}</H1></CardHeaderCentered>
      <CardContent>
        <Heading>
          <H2>{intl.formatMessage({
            defaultMessage: 'Celkem k proplacení:',
            description: 'Uvozuje součet kreditů za dotazníky a jíž přiřazených kreditů. Pokud je součet kreditů záporný, je zobrazena 0. např: "Celkem k proplacení: 299.66,-',
          })}</H2>
          <BigWellBox color={'gray4'} inline>{payoutCreditsSum > 0 ? payoutCreditsSum : 0},-</BigWellBox>
        </Heading>

        <Heading>
          <strong>{intl.formatMessage({
            defaultMessage: 'Čeká na zpracování platby:',
            description: 'Uvozuje celkovou hodnotu kreditů ve zpracování, např: "Čeká na zpracování bankou: 135,-"',
          })}</strong>
          <strong>{countCredits(processingCredits)},-</strong>
        </Heading>

        {liabilities ? <Heading>
          <strong>{intl.formatMessage({
            defaultMessage: 'Neuhrazené závazky:',
            description: 'Celková neuhrazená částka všech závazků',
          })}</strong>
          <strong>{liabilities * -1},-</strong>
        </Heading> : null}
        <hr />
        {payoutCredits.map(credit => <CreditItem key={credit.id} id={credit.id} disabled={disabled} />)}
      </CardContent>
      <CardFooter />
    </Card>
  );
};
