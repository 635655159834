import { connect } from 'react-redux';
import { compose } from 'redux';

import { moreRingsRequest, ringsRequest } from '../actions/ring';
import { tymberDataRequest } from '../actions/tymberData';
import { Error } from '../components/Error';
import { RingList } from '../components/RingList';
import Spinner from '../components/Spinner';
import withError from '../components/withError';
import withFetch, { withFetchNew } from '../components/withFetch';
import withLoading, { withLoaderNew } from '../components/withLoading';
import withMapData from '../components/withMapData';
import { componentsSelector, StoreState, tymberSelector } from '../reducers';
import { ringsSelector } from '../reducers/components';
import { STATUS_PENDING } from '../types/StoreUtils';
import { Tymber } from '../types/Tymbe';

const selectRing = compose(ringsSelector, componentsSelector);

const RingsContainer = connect(
  (state: StoreState) => ({
    ...selectRing(state),
    tymber: tymberSelector(state).data as Tymber,
  }),
  (dispatch) => ({
    fetch: () => dispatch(ringsRequest()),
    filterRings: (filters?: object) => dispatch(ringsRequest(filters)),
    moreRings: (filters?: object) => dispatch(moreRingsRequest(filters)),
  }),
);

export default RingsContainer(
  withFetch(
    withLoading(Spinner, true)(
      withError(Error)(
        withMapData((data, status) => ({ rings: data, loading: status === STATUS_PENDING }))(
          withFetchNew(tymberSelector, tymberDataRequest)(
            withLoaderNew(tymberSelector, <Spinner />)(
              RingList,
            ),
          ),
        ),
      ),
    ),
  ),
);
