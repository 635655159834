import { MoreFinishedActions } from '../../actions/finished';
import { Actions, GetFinishedActions } from '../../actions/finished';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { Ring } from '../../types/Tymbe';

export type FinishedState = DataStoreState<Ring['id'][]>;

const initialState: FinishedState = initDataStoreState<Ring['id'][]>(null);

type ClaimedReducerActions = GetFinishedActions | MoreFinishedActions;

export const finishedReducer = (state: FinishedState = initialState, action: ClaimedReducerActions): FinishedState => {
  switch (action.type) {
    case Actions.GET_FINISHED_REQUEST:
      return { ...state, status: STATUS_PENDING };
    case Actions.GET_FINISHED_SUCCESS:
      return { ...initialState, status: STATUS_SUCCESS, data: action.rings.map(ring => ring.id) };
    case Actions.GET_FINISHED_FAILURE:
      return { ...initialState, status: STATUS_ERROR, error: action.error };
    case Actions.MORE_FINISHED_REQUEST:
      return { ...state, status: STATUS_PENDING };
    case Actions.MORE_FINISHED_SUCCESS:
      return { ...initialState, status: STATUS_SUCCESS, data: [...state.data || [], ...action.rings.map(ring => ring.id)] };
    case Actions.MORE_FINISHED_FAILURE:
      return { ...initialState, status: STATUS_ERROR, error: action.error };
    default:
      return state;
  }
};
