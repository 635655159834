import bankCodes from './bankCodes';

const bankAccountCZRegex = /^([0-9]{0,6})?([0-9]{2,10})\/([0-9]{4})$/;
const prefixWeights = [ 10, 5, 8, 4, 2, 1 ];
const baseWeights = [ 6, 3, 7, 9, 10, 5, 8, 4, 2, 1 ];

function normalizeAccountNumber(bankAccount: string): string {
  if (bankAccount.includes('-')) {
    const [ prefix, rest ] = bankAccount.split('-');
    return prefix.padStart(6, '0') + rest.padStart(15, '0');
  } else {
    return bankAccount.padStart(21, '0');
  }
}

export const isValidBankAccount = (bankAccount?: unknown): boolean => {
  if (!bankAccount || typeof bankAccount !== 'string') return false;

  const bankAccountPadded = normalizeAccountNumber(bankAccount);
  const [ bankNumber , prefix, base, bankCode ] = bankAccountPadded.match(bankAccountCZRegex) || [];

  if (!bankNumber) return false;

  if (bankCode.length !== 4) return false;

  const bankCodeKeys = Object.keys(bankCodes);
  if (!bankCodeKeys.includes(bankCode)) return false;

  if (prefix) {
    const checksum = prefix.padStart(6, '0').split('')
      .reduce((sum, val, i) => sum + +val * prefixWeights[i], 0);
    if (checksum % 11 !== 0) return false;
  }

  const checksum = base.padStart(10, '0').split('')
    .reduce((sum, val, i) => sum + +val * baseWeights[i], 0);

  return checksum % 11 === 0;
};
