import { action } from './utils';
import { Action } from 'redux';
import { ApiError } from '../types/Tymbe';

export type LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_REQUEST: LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export type LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_SUCCESS: LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export type LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_FAILURE: LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export interface LogoutRequestAction extends Action<LOGOUT_REQUEST> {
  email: string;
}

export interface LogoutSuccessAction extends Action<LOGOUT_SUCCESS> {
  email: string;
}

export interface LogoutFailureAction extends Action<LOGOUT_FAILURE> {
  email: string;
  error: ApiError;
}

export type LogoutActions = LogoutRequestAction | LogoutSuccessAction | LogoutFailureAction;

export const logoutRequest =
  (email: string): LogoutRequestAction =>
    action(LOGOUT_REQUEST, { email });

export const logoutSuccess =
  (email: string): LogoutSuccessAction =>
    action(LOGOUT_SUCCESS, { email });

export const logoutFailure =
  (email: string, error: ApiError): LogoutFailureAction =>
    action(LOGOUT_FAILURE, { email, error });

export default {
  REQUEST: LOGOUT_REQUEST,
  SUCCESS: LOGOUT_SUCCESS,
  FAILURE: LOGOUT_FAILURE,
  request: logoutRequest,
  success: logoutSuccess,
  failure: logoutFailure,
};
