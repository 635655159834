import React from 'react';
import { styled } from '../../styledComponents';

const Bar = styled.div`
  color: ${({ theme }) => theme.bodyBg};
  font-size: .8rem;
  & > span {
    margin-right: 0.2em;
  }
`;

export type HoursBarProps = {
  hours: number;
};

export const HoursBar = ({ hours, ...rest }: HoursBarProps) => {
  // tslint:disable-next-line:prefer-array-literal
  const bullets = new Array(~~hours).fill(true);
  return (
    <Bar {...rest}>
      {bullets.map((_, i) => <span key={i}>●</span>)}
    </Bar>
  );
};
