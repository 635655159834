import { tymberDataUpdateRequest } from '../actions/tymberData';
import { TymberProfileForm } from '../components/TymberProfileForm';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState, tymberSelector } from '../reducers';
import { tymberProfileFormSelector } from '../reducers/components';
import { compose } from 'redux';
import { Tymber } from '../types/Tymbe';

const selectTymberProfileForm = compose(tymberProfileFormSelector, componentsSelector);

const TymberProfileFormConnect = connect(
  (state: StoreState) => ({
    ...selectTymberProfileForm(state),
    tymber: tymberSelector(state).data as Tymber,
  }),
  dispatch => ({
    saveTymber: (data: Partial<Tymber>, password: string) => dispatch(tymberDataUpdateRequest(data, password)),
  }),
);

export default TymberProfileFormConnect(
  withMapData((_data, status, error) => ({ status, error }))(
    TymberProfileForm,
  ),
);
