import { ShiftType } from '@tymbe/schema/enums';

import { Button, ButtonProps } from './Forms/Button';
import RingsGridWithFilter from './Ring/RingsGridWithFilter';
import Spinner from './Spinner';
import { H2 } from './Styles/BasicElements';
import Ring from '../containers/Ring';
import moment from 'moment';
import React, { useState, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { transformApplicationFilter } from '../services/api/transformations';
import { styled } from '../styledComponents';
import { LAST_MINUTE_CANCELLATION_MSECS } from '../types/consts';
import { Ring as RingData } from '../types/Tymbe';

export type ClaimedListProps = {
  rings: RingData['id'][];
  moreClaimed: (filters?: object) => void;
  filterClaimed: (filters: object) => void;
  loading: boolean;
};

type CancelClaimedButtonProps = {
  ringStart: string | Date;
  shiftType: ShiftType;
  background: ButtonProps['background'];
} & NavLinkProps;

const Cancel = styled(NavLink)`
  display: inline-block;
  text-decoration: none;
`;

const CancelClaimedButton = React.memo(({ ringStart, shiftType, ...rest }: CancelClaimedButtonProps) => {
  const intl = useIntl();
  const timeToRingStart = moment(ringStart).diff(moment());
  const [isLastMinute, setIsLastMinute] = React.useState(timeToRingStart <= LAST_MINUTE_CANCELLATION_MSECS);
  const [hasAlreadyStarted, setHasAlreadyStarted] = React.useState(timeToRingStart <= 0);

  React.useEffect(() => {
    if (isLastMinute) return undefined;
    const timeToLastMinute = timeToRingStart - LAST_MINUTE_CANCELLATION_MSECS;
    const timer = setTimeout(
      () => setIsLastMinute(true),
      // {2147483647} is the max 32bit value, it's the highest value setTimeout can take
      timeToLastMinute < 2147483647 ? timeToLastMinute : 2147483647,
    );

    return () => clearTimeout(timer);
  });

  React.useEffect(() => {
    /*
        setTimeout has implementation flaw resulting in immediate execution of delayed function
        when delay is bigger than SIGN_INT32
    */
    if (hasAlreadyStarted || timeToRingStart > 0x7FFFFFFF) return undefined;

    const timer = setTimeout(() => setHasAlreadyStarted(true), timeToRingStart);
    return () => clearTimeout(timer);
  });

  return !hasAlreadyStarted ? (
    <Button as={Cancel} {...rest}>{
      isLastMinute && shiftType !== ShiftType.VACATION
        ? intl.formatMessage({
          defaultMessage: 'Zrušit s omluvenkou',
          description: 'Tlačitko pro odhlášení ze směny s nutností nahrát omluvenku',
        })
        : intl.formatMessage({
          defaultMessage: 'Zrušit',
          description: 'Tlačitko pro odhlášení ze směny',
        })
    }</Button>
  ) : null;
});

const ClaimedList: React.FC<ClaimedListProps> = ({ rings, moreClaimed, filterClaimed, loading }) => {
  const intl = useIntl();
  const [filters, setFilters] = useState<object>();

  const applyFilter = (filter: object) => {
    setFilters(filter);
    filterClaimed(filter);
  }

  const loadMoreRings = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.currentTarget.blur();
    moreClaimed(filters);
  };

  return (
    <RingsGridWithFilter onFilter={(filter) => applyFilter(transformApplicationFilter(filter))}>
      {loading && <Spinner />}
      {rings.map((ringId) => (
        <Ring key={ringId} id={ringId} data-cy={`shiftItem${ringId}`}>
          {(ring: RingData) => (
            <div style={{ textAlign: 'center' }}>
              <CancelClaimedButton
                ringStart={ring.time.start}
                to={`/before-fight/${ringId}`}
                shiftType={ring.type}
                background="danger"
              />
            </div>
          )}
        </Ring>
      ))}
      {!!rings.length && !(rings.length % 20) && <LoadMore onClick={loadMoreRings}>
      {intl.formatMessage({
        defaultMessage: 'Načíst další',
        description: 'Tlačítko pro načtení dalších pozvánek',
      })}
      </LoadMore>}
      {!rings.length && <H2 style={{ textAlign: 'center' }}>{intl.formatMessage({
        defaultMessage: 'Nenalezeny žádné ringy.',
        description: 'Zobrazí se v případě prázdného seznamu přihlašených směn.',
      })}</H2>}
    </RingsGridWithFilter>
  );
};

export default ClaimedList;

const LoadMore = styled(Button)`
  grid-column: 1 / -1;
`;
