import { getClaimedRequest, moreClaimedRequest } from '../actions/claimed';
import ClaimedList from '../components/ClaimedList';
import { Error } from '../components/Error';
import Spinner from '../components/Spinner';
import withError from '../components/withError';
import withFetch from '../components/withFetch';
import withLoading from '../components/withLoading';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { claimedSelector } from '../reducers/components';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';

const selectClaimed = compose(claimedSelector, componentsSelector);

const ClaimedContainer = connect(
  (state: StoreState) => ({
    ...selectClaimed(state).claimed,
  }),
  dispatch => ({
    filterClaimed: (filter: object) => dispatch(getClaimedRequest(filter)),
    moreClaimed: (filter?: object) => dispatch(moreClaimedRequest(filter)),
    fetch: () => dispatch(getClaimedRequest()),
  }),
);

export default ClaimedContainer(
  withFetch(
    withLoading(Spinner, true)(
      withError(Error)(
        withMapData((data, status) => ({ rings: data, loading: status === STATUS_PENDING }))(
          ClaimedList,
        ),
      ),
    ),
  ),
);
