import { requestPasswordReset } from '../actions/forgotPassword';
import { Button } from './Forms/Button';
import { LabeledInput } from './Forms/LabeledInput';
import { validateEmail } from './Forms/validations';
import FrontWrapper from './FrontWrapper';
import { LogoHorizontal } from './Logo';
import A from './Styles/A';
import { H3 } from './Styles/BasicElements';
import { Center } from './Styles/Center';
import { Form, FormState } from 'informed';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { css, styled } from '../styledComponents';

const LogoCss = css`
  width: 50%;
  margin-bottom: 2rem;
`;

const Back = Button.withComponent(NavLink);

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export type ForgotPasswordProps = {
  requestReset: (email: string) => void;
};

export const ForgotPassword = ({ requestReset }: ForgotPasswordProps) => {
  const intl = useIntl();
  const [requestSent, setRequest] = React.useState(false);

  const resetPassword = (formState: FormState) => {
    const data = formState.values as { email: string };
    setRequest(true);
    requestReset(data.email);
  };

  return (
    <FrontWrapper>
      <LogoHorizontal css={LogoCss} />
      {!requestSent ?
        <Form onSubmit={resetPassword} style={{ backgroundColor: 'inherit' }}>
          <LabeledInput
            label={intl.formatMessage({
              defaultMessage: 'Email',
              description: 'Popisek formulářového prvku pro zadání emailové adresy',
            })}
            id={'email'}
            name={'email'}
            validate={validateEmail}
            validateOn="blur"
          />
          <Buttons>
            <Button type={'submit'}>{intl.formatMessage({
              defaultMessage: 'Odeslat',
              description: 'Tlačítko pro odeslání formuláře pro zapomenuté heslo',
            })}</Button>
            <A to={'/login'}>{intl.formatMessage({
              defaultMessage: 'Zpět na přihlášení',
              description: 'Odkaz vedoucí na přihlašovací stránku',
            })}</A>
          </Buttons>
        </Form> :
        <Center>
          <H3>{intl.formatMessage({
            defaultMessage: 'Na zadanou emailovou adresu byl odeslán email s instrukcemi',
            description: 'Text zobrazený po odeslaní formuláře pro zapomenuté heslo',
          })}</H3>
          <br />
          <br />
          <Back to={'/'}>{intl.formatMessage({
            defaultMessage: 'Zpět',
            description: 'Odkaz vedoucí na úvodní stránku',
          })}</Back>
        </Center>
      }
    </FrontWrapper>
  );
};

export default connect(
  undefined,
  { requestReset: requestPasswordReset },
)(ForgotPassword);
