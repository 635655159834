import { ActionTypes, DocumentActions } from '../../../actions/data/documents';
import { Actions as MainContractActions, GetMainContractsSuccess } from '../../../actions/mainContracts';
import { Actions as OtherContractActions, GetOtherContractsSuccess } from '../../../actions/otherContracts';
import { combineReducers, Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../../types/StoreUtils';
import { ApiError, RingDocument } from '../../../types/Tymbe';

type DocumentsByIdState = {
  [K: number]: DataStoreState<RingDocument>,
};

type DocumentsAllIdsState = RingDocument['id'][];

export type DocumentsState = {
  byId: DocumentsByIdState,
  allIds: DocumentsAllIdsState,
};

const requestingDocument = (state: DocumentsByIdState, documentId: RingDocument['id']) => ({
  ...state,
  [documentId]: initDataStoreState<RingDocument>(STATUS_PENDING),
});

const failDocument = (state: DocumentsByIdState, documentId: RingDocument['id'], error: ApiError) => ({
  ...state,
  [documentId]: initDataStoreState<RingDocument>(STATUS_ERROR, null, error),
});

const addDocumentData = (state: DocumentsByIdState, document: RingDocument) => ({
  ...state,
  [document.id]: initDataStoreState<RingDocument>(STATUS_SUCCESS, document),
});

// tslint:disable-next-line:no-any
const addDocumentId = (state: DocumentsAllIdsState, id: any) => {
  const docId = parseInt(id, 10);
  return state.indexOf(docId) < 0 ? state.concat(docId) : state;
};

type ReducerActions = DocumentActions | GetMainContractsSuccess | GetOtherContractsSuccess;

const documentsByIdReducer: Reducer<DocumentsByIdState, ReducerActions> =
  (state = {}, action): DocumentsByIdState => {
    switch (action.type) {
      case ActionTypes.DOCUMENT_REQUEST:
        return requestingDocument(state, action.documentId);
      case ActionTypes.DOCUMENT_SUCCESS:
        return addDocumentData(state, action.document);
      case ActionTypes.DOCUMENT_FAILURE:
        return failDocument(state, action.documentId, action.error);
      case MainContractActions.GET_MAIN_CONTRACTS_SUCCESS:
      case OtherContractActions.GET_OTHER_CONTRACTS_SUCCESS:
        return action.documents.reduce((s, document) => addDocumentData(s, document), state);
      default:
        return state;
    }
  };

const documentsAllIdsReducer: Reducer<DocumentsAllIdsState, ReducerActions> =
  (state = [], action): DocumentsAllIdsState => {
    switch (action.type) {
      case ActionTypes.DOCUMENT_SUCCESS:
        return addDocumentId(state, action.document.id);
      case MainContractActions.GET_MAIN_CONTRACTS_SUCCESS:
      case OtherContractActions.GET_OTHER_CONTRACTS_SUCCESS:
        return action.documents.reduce((s, document) => addDocumentId(s, document.id), state);
      default:
        return state;
    }
  };

export const documentsReducer = combineReducers<DocumentsState>(
  {
    byId: documentsByIdReducer,
    allIds: documentsAllIdsReducer,
  },
);

export const documentSelector = (state: DocumentsState, documentId: RingDocument['id']) =>
  state.byId[documentId] || initDataStoreState<RingDocument>(null);
