import RingsGridWithFilter from './Ring/RingsGridWithFilter';
import Spinner from './Spinner';
import { H2 } from './Styles/BasicElements';
import Ring from '../containers/Ring';
import React, { useState, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { transformApplicationFilter } from '../services/api/transformations';
import styled from 'styled-components';
import { Ring as RingData } from '../types/Tymbe';
import { Button } from './Forms/Button';

export type FinishedListProps = {
  rings: RingData['id'][];
  filterFinished: (filters: object) => void;
  moreFinished: (filters?: object) => void;
  loading: boolean;
};

const FinishedList: React.FC<FinishedListProps> = ({ rings, moreFinished, filterFinished, loading }) => {
  const intl = useIntl();
  const [filters, setFilters] = useState<object>();

  const applyFilter = (f: object) => {
    setFilters(f);
    filterFinished(f);
  };

  const loadMoreFinished = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.currentTarget.blur();
    moreFinished(filters);
  };

  return (
    <RingsGridWithFilter onFilter={filter => applyFilter(transformApplicationFilter(filter))}>
      {loading && <Spinner />}
      {rings.map(ringId => <Ring key={ringId} id={ringId} />)}
      {!rings.length && <H2 style={{ textAlign: 'center' }}>
        {intl.formatMessage({
          defaultMessage: 'Nenalezeny žádné odbojované ringy.',
          description: 'Pokud nebyly nalezeny žádné výsledky v seznamu odpracovaných brigády',
        })}
      </H2>}
        {!!rings.length && !(rings.length % 20) && <LoadMore onClick={loadMoreFinished}>
      {intl.formatMessage({
        defaultMessage: 'Načíst další',
        description: 'Tlačítko pro načtení dalších pozvánek',
      })}
      </LoadMore>}
    </RingsGridWithFilter>
  );
};

export default FinishedList;

const LoadMore = styled(Button)`
  grid-column: 1 / -1;
`;
