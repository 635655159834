import * as React from 'react';
import { styled } from '../styledComponents';
import { Address as AddressType } from '../types/Tymbe';

export interface AddressProps extends AddressType {
  nowrap?: boolean;
}

const StyledAddress = styled.address<Omit<AddressProps, keyof AddressType>>`
  ${({ nowrap, theme }) => `
    ${nowrap ? 'white-space: nowrap;' : null};
    color: ${theme.bodyColor};
  `};
  font-style: normal;
  text-decoration: underline;
  margin-left: 1rem;
  margin-top: 1rem;
`;

export const Address: React.FC<AddressProps> = ({ street, city, zip, nowrap }) => (
  <StyledAddress nowrap={nowrap}>
    {street[0]} {street.slice(1).join('/')}<br />
    {zip} {city}
  </StyledAddress>
);
