import { useIntl } from 'react-intl';

import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import Spinner from './Spinner';
import { H2 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import { TymberUtilityItem } from './TymberUtilityItem';
import { Tymber, TymberUtility } from '../types/Tymbe';

export type TymberUtilitiesProps = {
  utilities: TymberUtility[];
  tymber: Tymber;
  loading?: boolean;
} & GetProps<typeof Card>;

export const TymberUtilities = ({ utilities, loading, tymber, ...props }: TymberUtilitiesProps) => {
  const intl = useIntl();
  return (
    <Card {...props}>
      <CardHeader>
        <Icon style={{ float: 'right' }} id="utilities" color="white" size="2em" />
        <H2>
          {intl.formatMessage({
            defaultMessage: 'Pracovní pomůcky',
            description: 'Titulek sekce v šatně',
          })}
        </H2>
      </CardHeader>
      <CardContent>
        <div>
          {utilities.map((utility) => <TymberUtilityItem key={utility.id} utility={utility} />)}
          {loading && <Spinner position="absolute" />}
        </div>
      </CardContent>
      <CardFooter />
    </Card>
  );
};
