import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { Actions, GetWagesActions, PayoutWageActions } from '../../actions/wages';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';

export type WageState = DataStoreState<PayoutInfoData[]>;

const initState = initDataStoreState<PayoutInfoData[]>(null);

type ReducerActions = GetWagesActions | PayoutWageActions;

export const wageReducer: Reducer<WageState, ReducerActions> = (state = initState, action) => {
  switch (action.type) {
    case Actions.GET_WAGES_SUCCESS:
      return initDataStoreState<PayoutInfoData[]>(STATUS_SUCCESS, action.wages);
    case Actions.PAYOUT_WAGE_REQUEST:
      return initDataStoreState<PayoutInfoData[]>(STATUS_PENDING, state.data);
    case Actions.PAYOUT_WAGE_SUCCESS:
      return initDataStoreState<PayoutInfoData[]>(STATUS_SUCCESS, state.data);
    case Actions.PAYOUT_WAGE_FAILURE:
      return initDataStoreState<PayoutInfoData[]>(STATUS_ERROR, null, action.error);
    default:
      return state;
  }
};
