import { Card } from './Card';
import { CardColor } from './Card/CardColor';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import Modal from './Modal';
import Spinner from './Spinner';
import { H2, H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import AddTymberDocument from '../containers/AddTymberDocument';
import TymberDocumentItem from '../containers/TymberDocumentItem';
import React, { MouseEvent} from 'react';
import { useIntl } from 'react-intl';
import { TymberDocument, TymberDocumentPart } from '../types/Tymbe';
import { Button } from './Forms/Button';
import { MoreTymberDocumentsRequestAction } from '../actions/tymberDocuments';

export type TymberDocumentsProps = {
  documents: TymberDocument['id'][];
  downloadDocument: (id: TymberDocumentPart['id']) => void;
  moreDocuments: () => MoreTymberDocumentsRequestAction;
  loading: boolean;
} & GetProps<typeof Card>;

export const TymberDocuments: React.FC<TymberDocumentsProps> =
  ({ documents, downloadDocument, moreDocuments, loading, ...p }) => {
    const intl = useIntl();
    const [newDocument, setNewDocument] = React.useState(false);

    const loadMoreDocuments = (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.currentTarget.blur();
      moreDocuments();
    };

    return (
      <Card {...p}>
        <CardHeader>
          <Icon style={{ float: 'right' }} id={'documents'} color={'white'} size={'2em'} />
          <H2>{intl.formatMessage({
            defaultMessage: 'Doklady a oprávnění',
            description: 'Titulek sekce v šatně',
          })}</H2>
        </CardHeader>
        <CardContent>
          <CardColor as={H3}>{intl.formatMessage({
            defaultMessage: 'S papírama nejdál dojdeš:',
            description: 'Úvodní nadpis v sekci s doklady',
          })}</CardColor>
          <p>{intl.formatMessage({
            defaultMessage: 'Každý doklad o fous rozšíří nabídku brigád, kam se budeš moci přihlásit. A to se vyplatí.',
            description: 'Úvodní text v sekci s doklady',
          })}</p>
          <div>
            <Button onClick={() => setNewDocument(true)}>
              <Icon id={'upload'} color={'white'} background={'none'} size={'1.5em'} />{intl.formatMessage({
                defaultMessage: 'Vložit doklad',
                description: 'Tlačítko pro vložení nového dokladu',
              })}
            </Button>
          </div>
          <div>
            {loading && <Spinner position={'static'} />}
            {documents.map(document => (
              <TymberDocumentItem key={document} id={document} />
            ))}
          </div>
          {!(documents.length % 3) && (
          <Button onClick={loadMoreDocuments}>
            {intl.formatMessage({
              defaultMessage: 'Načíst další',
              description: 'Tlačítko pro načtení dalších dokumentů',
            })}
          </Button>
        )}
          <Modal open={newDocument}>
            <AddTymberDocument onCancel={() => setNewDocument(false)} onSave={() => setNewDocument(false)} />
          </Modal>
        </CardContent>
        <CardFooter />
      </Card>
    );
  };
