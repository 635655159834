import { Actions, PersonSalaryLimitUpdateActions } from '../../actions/personSalaryLimit';
import { Reducer } from 'redux';
import { CLEAR_STATE, ClearStateAction } from '../../store';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';

export type PersonSalaryLimitModalState = DataStoreState;

const initState = initDataStoreState(null);

export const personSalaryLimitModalReducer: Reducer<PersonSalaryLimitModalState, PersonSalaryLimitUpdateActions | ClearStateAction> =
  (state = initState, action) => {
    switch (action.type) {
      case Actions.PERSON_SALARY_LIMIT_UPDATE_REQUEST:
        return initDataStoreState(STATUS_PENDING);
      case Actions.PERSON_SALARY_LIMIT_UPDATE_SUCCESS:
        return initDataStoreState(STATUS_SUCCESS);
      case Actions.PERSON_SALARY_LIMIT_UPDATE_FAILURE:
        return initDataStoreState(STATUS_ERROR, null, action.error);
      case CLEAR_STATE:
        return action.state === 'personSalaryLimitModal' ? initState : state;
      default:
        return state;
    }
  };
