import { call, put, takeEvery } from '@redux-saga/core/effects';
import { getTymberDocumentsFailure } from '../actions/tymberDocuments';
import { Actions, GetTymberUtilitiesRequestAction, getTymberUtilitiesSuccess } from '../actions/tymberUtilities';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, TymberUtility } from '../types/Tymbe';

export function* fetchTymberUtilities(action: GetTymberUtilitiesRequestAction): SagaIterator {
  try {
    const response: AxiosResponse<TymberUtility[]> = yield call(api.getTymberUtilities);
    yield put(getTymberUtilitiesSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getTymberDocumentsFailure(error));
    }
  }
}

export function* watchGetTymberUtilitiesRequest() {
  yield takeEvery(Actions.GET_TYMBER_UTILITIES_REQUEST, fetchTymberUtilities);
}
