import { action } from './utils';
import { Action } from 'redux';
import { ApiError, ApiValidationError, Login } from '../types/Tymbe';

export type LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST: LOGIN_REQUEST = 'LOGIN_REQUEST';
export type LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS: LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export type LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_FAILURE: LOGIN_FAILURE = 'LOGIN_FAILURE';

export interface LoginRequestAction extends Action<LOGIN_REQUEST> {
  email: string;
  password: string;
}

export interface LoginSuccessAction extends Action<LOGIN_SUCCESS>, Login {
}

export interface LoginFailureAction extends Action<LOGIN_FAILURE> {
  email: string;
  error: ApiError | ApiValidationError;
}

export type LoginActions = LoginRequestAction | LoginSuccessAction | LoginFailureAction;

export const loginRequest =
  (email: string, password: string): LoginRequestAction =>
    action(LOGIN_REQUEST, { email, password });

export const loginSuccess =
  (data: Login): LoginSuccessAction =>
    action(LOGIN_SUCCESS, data);

export const loginFailure =
  (email: string, error: ApiError): LoginFailureAction =>
    action(LOGIN_FAILURE, { email, error });

export default {
  REQUEST: LOGIN_REQUEST,
  SUCCESS: LOGIN_SUCCESS,
  FAILURE: LOGIN_FAILURE,
  request: loginRequest,
  success: loginSuccess,
  failure: loginFailure,
};
