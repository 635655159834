import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Ring } from '../types/Tymbe';

export type RINGS_REQUEST = 'RINGS_REQUEST';
export const RINGS_REQUEST: RINGS_REQUEST = 'RINGS_REQUEST';
export type RINGS_SUCCESS = 'RINGS_SUCCESS';
export const RINGS_SUCCESS: RINGS_SUCCESS = 'RINGS_SUCCESS';
export type RINGS_FAILURE = 'RINGS_FAILURE';
export const RINGS_FAILURE: RINGS_FAILURE = 'RINGS_FAILURE';
export type MORE_RINGS_REQUEST = 'MORE_RINGS_REQUEST';
export const MORE_RINGS_REQUEST: MORE_RINGS_REQUEST = 'MORE_RINGS_REQUEST';
export type MORE_RINGS_SUCCESS = 'MORE_RINGS_SUCCESS';
export const MORE_RINGS_SUCCESS: MORE_RINGS_SUCCESS = 'MORE_RINGS_SUCCESS';
export type MORE_RINGS_FAILURE = 'MORE_RINGS_FAILURE';
export const MORE_RINGS_FAILURE: MORE_RINGS_FAILURE = 'MORE_RINGS_FAILURE';

export interface RingsRequestAction extends Action<RINGS_REQUEST> {
  filters?: object;
}

export interface RingsSuccessAction extends Action<RINGS_SUCCESS> {
  rings: Ring[];
}

export interface RingsFailureAction extends Action<RINGS_FAILURE> {
  error: ApiError;
}

export interface MoreRingsRequestAction extends Action<MORE_RINGS_REQUEST> {
  filters?: object;
}

export interface MoreRingsSuccessAction extends Action<MORE_RINGS_SUCCESS> {
  rings: Ring[];
}

export interface MoreRingsFailureAction extends Action<MORE_RINGS_FAILURE> {
  error: ApiError;
}

export type RingsActions = RingsRequestAction | RingsSuccessAction | RingsFailureAction;
export type MoreRingsActions = MoreRingsRequestAction | MoreRingsSuccessAction | MoreRingsFailureAction;

export const ringsRequest = (filters?: object): RingsRequestAction => action(RINGS_REQUEST, { filters });

export const ringsSuccess = (rings: Ring[]) => action(RINGS_SUCCESS, { rings });

export const ringsFailure = (error: ApiError) => action(RINGS_FAILURE, { error });

export const moreRingsRequest = (filters?: object): MoreRingsRequestAction => action(MORE_RINGS_REQUEST, { filters });

export const moreRingsSuccess = (rings: Ring[]): MoreRingsSuccessAction => action(MORE_RINGS_SUCCESS, { rings });

export const moreRingsError = (error: ApiError): MoreRingsFailureAction => action(MORE_RINGS_FAILURE, { error });

export default {
  REQUEST: RINGS_REQUEST,
  SUCCESS: RINGS_SUCCESS,
  FAILURE: RINGS_FAILURE,
  request: ringsRequest,
  success: ringsSuccess,
  failure: ringsFailure,
};
