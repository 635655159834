import { call, put, takeLatest } from '@redux-saga/core/effects';
import { VacationData } from '@tymbe/schema/vacation.interface';
import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';

import { handleApiError } from './utils';
import { Actions, vacationFailure, vacationSuccess } from '../actions/vacations';
import api from '../services/api';
import { ApiError } from '../types/Tymbe';

export function* fetchVacations(action: Action): SagaIterator {
  try {
    const response: AxiosResponse<VacationData[]> = yield call(api.getVacations);
    yield put(vacationSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(vacationFailure(error));
    }
  }
}

export function* watchVacationRequest() {
  yield takeLatest(Actions.VACATION_REQUEST, fetchVacations);
}
