import { loginRequest } from '../actions/login';
import { Button } from '../components/Forms/Button';
import { LabeledInput } from '../components/Forms/LabeledInput';
import FrontWrapper from '../components/FrontWrapper';
import LocaleSwitcher from '../components/LocaleSwitcher';
import { LogoHorizontal } from '../components/Logo';
import A from '../components/Styles/A';
import { Form, FormState } from 'informed';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { StoreState } from '../reducers';
import { css, styled } from '../styledComponents';
import { ApiError, ApiValidationError } from '../types/Tymbe';
import { useLocation } from 'react-router-dom';

const LogoCss = css`
  width: 50%;
  margin-bottom: 2rem;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
`;

export interface LoginProps {
  authenticate: (username: string, password: string) => void;
  authorizing?: boolean;
  email?: string | null;
  token?: string | null;
  className?: string;
  error?: ApiError | ApiValidationError | null;
}

export interface LoginData {
  username: string;
  password: string;
}

export const Login: React.FC<LoginProps> =
  ({ authenticate, email, token, error, authorizing, className }) => {
    const intl = useIntl();
    const { state } = useLocation();
    const login = (formState: FormState): void => {
      const data = formState.values as { username?: string; password?: string };
      if (!data.username || !data.password) {
        return;
      }
      authenticate(data.username.trim(), data.password.trim());
    };
    if (token) {
      return <Navigate to={state?.referrer || '/'} />;
    }
    return (
      <FrontWrapper>
        <LogoHorizontal css={LogoCss} />
        <Form
          initialValues={{ username: email, password: null }}
          style={{ background: 'inherit' }}
          onSubmit={login}
          className={className}
        >
          <LabeledInput
            label={intl.formatMessage({
              defaultMessage: 'E-mail',
              description: 'Název formulářového pole',
            })}
            id={'username'}
            name={'username'}
            data-cy="username"
            disabled={authorizing}
          />
          <LabeledInput
            label={intl.formatMessage({
              defaultMessage: 'Heslo',
              description: 'Název formulářového pole',
            })}
            id={'password'}
            data-cy="password"
            type={'password'}
            name={'password'}
            disabled={authorizing}
          />
          <FlexBox>
            <A to={`${import.meta.env.CLIENT_ADMIN_URL}/forgot-password`}>
              {intl.formatMessage({
                defaultMessage: 'Zapomenuté heslo',
                description: 'Odkaz',
              })}
            </A>
            <Button type={'submit'} disabled={authorizing} data-cy="submit">
              {intl.formatMessage({
                defaultMessage: 'Přihlásit',
                description: 'Tlačítko',
              })}
            </Button>
          </FlexBox>
          <FlexBox>
            <LocaleSwitcher />
          </FlexBox>
          {error && error.message}
          {error?.data?.note && intl.formatMessage(
            {
              defaultMessage: 'Stav: {status}; Poznámka: {note}',
              description: 'Informace o chybě',
            },
            { status: error.status, note: error.message },
          )}
        </Form>
      </FrontWrapper>
    );
  };

const LoginCont = connect(
  (state: StoreState) => ({ ...state.auth }),
  { authenticate: loginRequest },
)(Login);

export default LoginCont;
