import * as React from 'react';

export interface MapLinkProps {
  searchLocation: string;
}

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

export const MapLink: React.FC<MapLinkProps> = ({ searchLocation, children }) => (
  <a
    onClick={stopPropagation}
    target={'_blank'}
    href={`//www.google.com/maps/search/?api=1&query=${searchLocation}`}
  >
    {children}
  </a>
);
