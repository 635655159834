import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Ring } from '../types/Tymbe';

export enum Actions {
  GET_FINISHED_REQUEST = 'GET_FINISHED_REQUEST',
  GET_FINISHED_SUCCESS = 'GET_FINISHED_SUCCESS',
  GET_FINISHED_FAILURE = 'GET_FINISHED_FAILURE',
  MORE_FINISHED_REQUEST = 'MORE_FINISHED_REQUEST',
  MORE_FINISHED_SUCCESS = 'MORE_FINISHED_SUCCESS',
  MORE_FINISHED_FAILURE = 'MORE_FINISHED_FAILURE',
}

export interface GetFinishedRequestAction extends Action<Actions.GET_FINISHED_REQUEST> {
  filters?: object;
}

export interface GetFinishedSuccessAction extends Action<Actions.GET_FINISHED_SUCCESS> {
  rings: Ring[];
}

export interface GetFinishedFailureAction extends Action<Actions.GET_FINISHED_FAILURE> {
  error: ApiError;
}

export interface MoreFinishedRequestAction extends Action<Actions.MORE_FINISHED_REQUEST> {
  filters?: object;
}

export interface MoreFinishedSuccessAction extends Action<Actions.MORE_FINISHED_SUCCESS> {
  rings: Ring[];
}

export interface MoreFinishedFailureAction extends Action<Actions.MORE_FINISHED_FAILURE> {
  error: ApiError;
}

export type GetFinishedActions = GetFinishedRequestAction | GetFinishedSuccessAction | GetFinishedFailureAction;
export type MoreFinishedActions = MoreFinishedRequestAction | MoreFinishedSuccessAction | MoreFinishedFailureAction;

export const getFinishedRequest = (filters?: object): GetFinishedRequestAction =>
  action(Actions.GET_FINISHED_REQUEST, { filters });
export const getFinishedSuccess = (rings: Ring[]): GetFinishedSuccessAction =>
  action(Actions.GET_FINISHED_SUCCESS, { rings });
export const getFinishedFailure = (error: ApiError): GetFinishedFailureAction =>
  action(Actions.GET_FINISHED_FAILURE, { error });

export const moreFinishedRequest = (filters?: object): MoreFinishedRequestAction =>
  action(Actions.MORE_FINISHED_REQUEST, { filters });
export const moreFinishedSuccess = (rings: Ring[]): MoreFinishedSuccessAction =>
  action(Actions.MORE_FINISHED_SUCCESS, { rings });
export const moreFinishedFailure = (error: ApiError): MoreFinishedFailureAction =>
  action(Actions.MORE_FINISHED_FAILURE, { error });
