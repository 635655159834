import { styled } from '../../styledComponents';

interface CardBadgeProps {
  order?: number
}

export const CardBadge = styled.div<CardBadgeProps>`
  display: block;
  position: absolute;
  width: 3rem;
  height: 4rem;
  left: 100%;
  top: ${({order = 0}) => `calc(2rem + ${order} * 5rem)`};
  padding: 0 5px;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: inset 10px 0 10px -10px rgba(0,0,0,.3);
`;
