import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Liability } from '../types/Tymbe';

export enum Actions {
  GET_LIABILITIES_REQUEST = 'GET_LIABILITIES_REQUEST',
  GET_LIABILITIES_SUCCESS = 'GET_LIABILITIES_SUCCESS',
  GET_LIABILITIES_FAILURE = 'GET_LIABILITIES_FAILURE',
  PAY_LIABILITIES_REQUEST = 'PAY_LIABILITIES_REQUEST',
  PAY_LIABILITIES_SUCCESS = 'PAY_LIABILITIES_SUCCESS',
  PAY_LIABILITIES_FAILURE = 'PAY_LIABILITIES_FAILURE',
}

export interface GetLiabilitiesRequestAction extends Action<Actions.GET_LIABILITIES_REQUEST> {
}

export interface GetLiabilitiesSuccessAction extends Action<Actions.GET_LIABILITIES_SUCCESS> {
  liabilities: Liability[];
}

export interface GetLiabilitiesFailureAction extends Action<Actions.GET_LIABILITIES_FAILURE> {
  error: ApiError;
}

export interface PayLiabilitiesRequestAction extends Action<Actions.PAY_LIABILITIES_REQUEST> {
  password: string;
}

export interface PayLiabilitiesSuccessAction extends Action<Actions.PAY_LIABILITIES_SUCCESS> {
  liabilities: Liability[];
}

export interface PayLiabilitiesFailureAction extends Action<Actions.PAY_LIABILITIES_FAILURE> {
  error: ApiError;
}

export type GetLiabilitiesActions =
  GetLiabilitiesRequestAction
  | GetLiabilitiesSuccessAction
  | GetLiabilitiesFailureAction;

export type PayLiabilitiesActions =
  PayLiabilitiesRequestAction
  | PayLiabilitiesSuccessAction
  | PayLiabilitiesFailureAction;

export const getLiabilitiesRequest = (): GetLiabilitiesRequestAction =>
  action(Actions.GET_LIABILITIES_REQUEST);
export const getLiabilitiesSuccess = (liabilities: Liability[]): GetLiabilitiesSuccessAction =>
  action(Actions.GET_LIABILITIES_SUCCESS, { liabilities });
export const getLiabilitiesFailure = (error: ApiError): GetLiabilitiesFailureAction =>
  action(Actions.GET_LIABILITIES_FAILURE, { error });

export const payLiabilitiesRequest = (password: string): PayLiabilitiesRequestAction =>
  action(Actions.PAY_LIABILITIES_REQUEST, { password });
export const payLiabilitiesSuccess = (liabilities: Liability[]): PayLiabilitiesSuccessAction =>
  action(Actions.PAY_LIABILITIES_SUCCESS, { liabilities });
export const payLiabilitiesFailure = (error: ApiError): PayLiabilitiesFailureAction =>
  action(Actions.PAY_LIABILITIES_FAILURE, { error });
