import { MainNavigation } from './MainNavigation';
import MenuItem from './Menu/MenuItem';
import VerticalMenu from './Menu/VerticalMenu';
import React from 'react';
import { keyframes, styled } from '../styledComponents';

const sideBarIn = keyframes`
  from {
    transform: translateX(100%);
    box-shadow: 0 0 0 #555;
  }
  to {
    transform: translateX(0);
    box-shadow: 0 0 1rem #555;
    visibility: visible;
  }
`;

const sideBarOut = keyframes`
  from {
    transform: translateX(0);
    box-shadow: 0 0 1rem #555;
  }
  to {
    transform: translateX(100%);
    box-shadow: 0 0 0 #555;
  }
`;

const SideBar = styled(VerticalMenu)<MainSideBarProps>`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 200;
  background-color: ${({ theme }) => theme.bodyBg};
  box-shadow: 0 0 0 #555;
  padding: 1rem;
  transform: translateX(100%);
  animation-name: ${({ toggled }) => toggled ? sideBarIn : sideBarOut};
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  & ${MenuItem} {
    margin-bottom: 1rem;
  }
`;

const Backdrop = styled.div<MainSideBarProps>`
  display: ${({ toggled }) => toggled ? '' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 100;
`;

export type MainSideBarProps = {
  toggled: boolean;
  onClose?: (e: React.MouseEvent) => void;
};

export default ({ toggled, onClose }: MainSideBarProps) => (
  <>
    <SideBar toggled={toggled}>
      <MainNavigation onClick={onClose} />
    </SideBar>
    <Backdrop toggled={toggled} onClick={onClose} />
  </>
);
