import { addModalError, clearModalError } from '../../actions/modalError';
import { Card } from '../Card';
import { CardContent } from '../Card/CardContent';
import { CardFooter } from '../Card/CardFooter';
import { CardHeader } from '../Card/CardHeader';
import { Button } from '../Forms/Button';
import Modal from './index';
import { H1 } from '../Styles/BasicElements';
import React, { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { StoreState } from '../../reducers';
import { styled } from '../../styledComponents';

export type ModalErrorComponentProps = {
  errors: { message: ReactNode, title?: string, onClose?: () => void }[];
  hide: (id: number) => void;
};

const ErrorCard = styled(Card)`
  min-width: 30%;
`;

const ModalErrorComponent = ({ errors, hide }: ModalErrorComponentProps) => {
  const intl = useIntl();
  const { message, title, onClose } = errors[0] ?? {};

  const dismissError = () => {
    hide(0);
    onClose?.();
  };

  return (
    <Modal open={!!message}>
      <ErrorCard>
        <CardHeader><H1>{title || intl.formatMessage({
          defaultMessage: 'Chyba',
          description: 'Nadpis dialogu',
        })}</H1></CardHeader>
        <CardContent>
          <p>{message}</p>
        </CardContent>
        <CardFooter>
          <Button onClick={dismissError}>{intl.formatMessage({
            defaultMessage: 'Zavřít',
            description: 'Tlačítko pro zavření chybového dialogu',
          })}</Button>
        </CardFooter>
      </ErrorCard>
    </Modal>
  );
};

export const useModalError = () => {
  const dispatch = useDispatch();
  return useCallback(
    (error: ReactNode, title?: string, onClose?: () => void) =>
      dispatch(addModalError(error, title, onClose)),
    [dispatch],
  );
};

const ModalError = connect(
  (state: StoreState) => ({
    errors: state.modalError,
  }),
  {
    hide: clearModalError,
  },
)(ModalErrorComponent);

export default ModalError;
