const bankCodes = {
  '0100': {
    bankName: 'Komerční banka, a.s.',
  },
  '0300': {
    bankName: 'ČSOB, a.s.',
  },
  '0600': {
    bankName: 'MONETA Money Bank, a.s.',
  },
  '0710': {
    bankName: 'Česká národní banka',
  },
  '0800': {
    bankName: 'Česká spořitelna, a.s.',
  },
  2010: {
    bankName: 'Fio banka, a.s.',
  },
  2020: {
    bankName: 'MUFG Bank (Europe) N.V. Prague Branch',
  },
  2030: {
    bankName: 'AKCENTA, spořitelní a úvěrní družstvo',
  },
  2060: {
    bankName: 'Citfin, spořitelní družstvo',
  },
  2070: {
    bankName: 'Moravský Peněžní Ústav – spořitelní družstvo',
  },
  2100: {
    bankName: 'Hypoteční banka, a.s.',
  },
  2200: {
    bankName: 'Peněžní dům, spořitelní družstvo',
  },
  2220: {
    bankName: 'Artesa, spořitelní družstvo',
  },
  2240: {
    bankName: 'Poštová banka, a.s., pobočka Česká republika',
  },
  2250: {
    bankName: 'Banka CREDITAS a.s.',
  },
  2260: {
    bankName: 'ANO spořitelní družstvo',
  },
  2310: {
    bankName: 'ZUNO BANK AG, organizační složka',
  },
  2600: {
    bankName: 'Citibank Europe plc, organizační složka',
  },
  2700: {
    bankName: 'UniCredit Bank Czech Republic and Slovakia, a.s.',
  },
  3030: {
    bankName: 'Air Bank a. s.',
  },
  3050: {
    bankName: 'BNP Paribas Personal Finance SA, odštěpný závod',
  },
  3060: {
    bankName: 'PKO BP S.A., Czech Branch',
  },
  3500: {
    bankName: 'ING Bank N.V.',
  },
  4000: {
    bankName: 'Expobank CZ a.s.',
  },
  4300: {
    bankName: 'Českomoravská záruční a rozvojová banka, a.s.',
  },
  5500: {
    bankName: 'Raiffeisenbank, a.s.',
  },
  5800: {
    bankName: 'J&T Banka, a.s.',
  },
  6000: {
    bankName: 'PPF banka a.s.',
  },
  6100: {
    bankName: 'Equa bank a. s.',
  },
  6200: {
    bankName: 'COMMERZBANK Aktiengesellschaft, pobočka Praha',
  },
  6210: {
    bankName: 'mBank S.A., organizační složka',
  },
  6300: {
    bankName: 'BNP Paribas Fortis SA/NV, pobočka Česká republika',
  },
  6363: {
    bankName: 'Partners Banka, a.s.',
  },
  6700: {
    bankName: 'Všeobecná úverová banka a.s., pobočka Praha',
  },
  6800: {
    bankName: 'Sberbank CZ, a.s.',
  },
  7910: {
    bankName: 'Deutsche Bank A.G. Filiale Prag',
  },
  7940: {
    bankName: 'Waldviertler Sparkasse Bank AG',
  },
  7950: {
    bankName: 'Raiffeisen stavební spořitelna a.s.',
  },
  7960: {
    bankName: 'Českomoravská stavební spořitelna a. s.',
  },
  7970: {
    bankName: 'Wüstenrot - stavební spořitelna a.s.',
  },
  7980: {
    bankName: 'Wüstenrot hypoteční banka, a.s.',
  },
  7990: {
    bankName: 'Modrá pyramida stavební spořitelna, a.s.',
  },
  8030: {
    bankName: 'Raiffeisenbank im Stiftland Waldsassen eG pobočka Cheb, odštěpný závod',
  },
  8040: {
    bankName: 'Oberbank AG pobočka Česká republika',
  },
  8060: {
    bankName: 'Stavební spořitelna České spořitelny, a.s.',
  },
  8090: {
    bankName: 'Česká exportní banka, a.s.',
  },
  8150: {
    bankName: 'HSBC Bank plc - pobočka Praha',
  },
  8200: {
    bankName: 'PRIVAT BANK AG der Raiffeisenlandesbank Oberösterreich v České republice',
  },
  8220: {
    bankName: 'Payment Execution s.r.o.',
  },
  8230: {
    bankName: 'EEPAYS s. r. o.',
  },
  8240: {
    bankName: 'Družstevní záložna Kredit',
  },
  8250: {
    bankName: 'Bank of China (Hungary) Close Ltd. Prague branch, odštěpný závod',
  },
};
export default bankCodes;
