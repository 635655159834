import { downloadPayroll, getPayrollsRequest } from '../actions/payrolls';
import { Error } from '../components/Error';
import { PayrollList } from '../components/PayrollList';
import withError from '../components/withError';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { payrollsSelector } from '../reducers/components';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { CompanyRef } from '../types/Tymbe';

const selectPayrolls = compose(payrollsSelector, componentsSelector);

const PayrollsListContainer = connect(
  (state: StoreState) => selectPayrolls(state),
  {
    getPayrolls: getPayrollsRequest,
    downloadPayroll,
  },
);

export default PayrollsListContainer(
  withError(Error)(
    withMapData(
      (data: CompanyRef[], status) => ({
        payrolls: data || [],
        loading: status === STATUS_PENDING,
      }))(
      PayrollList,
    ),
  ),
);
