import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';
import { personSalaryLimitRequest } from '../actions/personSalaryLimit';
import { tymberDataRequest } from '../actions/tymberData';
import Spinner from '../components/Spinner';
import { TymberProfile } from '../components/TymberProfile';
import { withFetchNew } from '../components/withFetch';
import { withLoaderNew } from '../components/withLoading';
import React from 'react';
import { connect } from 'react-redux';
import { StoreState, dataSelector, tymberSelector } from '../reducers';
import { dataPersonSalaryLimitSelector } from '../reducers/data';
import { compose } from 'redux';
import { Tymber } from '../types/Tymbe';

const selectPersonSalaryLimit = compose(dataPersonSalaryLimitSelector, dataSelector);

const tymberProfileConnect = connect(
  (state: StoreState) => ({
    tymber: tymberSelector(state).data as Tymber,
    personSalaryLimit: selectPersonSalaryLimit(state).data as PersonSalaryLimitData,
  }),
);

export default tymberProfileConnect(
  withFetchNew(selectPersonSalaryLimit, personSalaryLimitRequest)(
    withLoaderNew(selectPersonSalaryLimit, <Spinner />, true)(
      withFetchNew(tymberSelector, tymberDataRequest)(
        withLoaderNew(tymberSelector, <Spinner />, true)(
          TymberProfile,
        ),
      ),
    ),
  ),
);
