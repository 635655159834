import { Actions as ClaimedActions, CancelClaimedSuccessAction } from '../actions/claimed';
import { Actions as InvitationActions, InvitationRejectSuccessAction } from '../actions/invitations';
import { Actions, TymberDataActions, TymberDataUpdateActions } from '../actions/tymberData';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../types/StoreUtils';
import { Tymber } from '../types/Tymbe';

export type TymberState = DataStoreState<Partial<Tymber>>;

const initialState: TymberState = initDataStoreState(null, {});
type ReducerActions =
  TymberDataActions
  | TymberDataUpdateActions
  | CancelClaimedSuccessAction
  | InvitationRejectSuccessAction;
export const tymberReducer: Reducer<TymberState, ReducerActions> =
  (state = initialState, action) => {
    switch (action.type) {
      case Actions.TYMBER_DATA_REQUEST:
      case Actions.TYMBER_DATA_UPDATE_REQUEST:
        return {
          ...state,
          status: STATUS_PENDING,
          data: { ...state.data },
          error: null,
        };
      case Actions.TYMBER_DATA_SUCCESS:
      case Actions.TYMBER_DATA_UPDATE_SUCCESS:
        return {
          ...state,
          status: STATUS_SUCCESS,
          data: { ...state.data, ...action.tymber },
        };
      case Actions.TYMBER_DATA_FAILURE:
      case Actions.TYMBER_DATA_UPDATE_FAILURE:
        return {
          ...state,
          status: STATUS_ERROR,
          data: { ...state.data },
          error: action.error,
        };
      case ClaimedActions.CANCEL_CLAIMED_SUCCESS:
        return {
          ...state,
          data: {
            ...state.data,
            claimed: state.data?.claimed ? state.data.claimed - 1 : 0,
          },
        };
      case InvitationActions.INVITATION_REJECT_SUCCESS:
        return {
          ...state,
          data: {
            ...state.data,
            invitations: state.data?.invitations ? state.data?.invitations - 1 : 0,
          },
        };
      default:
        return state;
    }
  };
