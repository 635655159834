import { ApiError } from './Tymbe';

export type STATUS_PENDING = 'pending';
export const STATUS_PENDING = 'pending';
export type STATUS_SUCCESS = 'success';
export const STATUS_SUCCESS = 'success';
export type STATUS_ERROR = 'error';
export const STATUS_ERROR = 'error';

export type DataStatus = STATUS_PENDING | STATUS_SUCCESS | STATUS_ERROR | null;

export interface DataStoreState<D = null> {
  status: DataStatus;
  data: D | null;
  error: ApiError | null;
}

export type GetData<Data> = Data extends DataStoreState<infer D> ? D : never;

export const initDataStoreState = <D = null>(
  status: DataStatus = STATUS_PENDING,
  data: D | null = null,
  error: ApiError | null = null,
): DataStoreState<D> => ({
  status,
  data,
  error,
});
