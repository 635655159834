import { invalidateTymberDocumentRequest } from '../actions/tymberDocuments';
import { TymberDocumentItem } from '../components/TymberDocumentItem';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { dataSelector, StoreState } from '../reducers';
import { dataTymberDocumentsSelector } from '../reducers/data';
import { tymberDocumentSelector } from '../reducers/data/tymberDocuments';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { TymberDocument } from '../types/Tymbe';

const selectTymberDocuments = compose(dataTymberDocumentsSelector, dataSelector);

export type TymberDocumentItemProps = {
  id: TymberDocument['id'];
};

const TymberDocumentItemConnect = connect(
  (state: StoreState, { id }: TymberDocumentItemProps) => ({
    ...tymberDocumentSelector(selectTymberDocuments(state), id),
  }),
  dispatch => ({
    invalidateDocument: (id: TymberDocument['id']) => dispatch(invalidateTymberDocumentRequest(id)),
  }),
);

export default TymberDocumentItemConnect(
  withMapData((data, status, error) => ({
    document: data,
    error,
    loading: status === STATUS_PENDING,
  }))(TymberDocumentItem),
);
