import React from 'react';
import { Error } from '../components/Error';
import withError from '../components/withError';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { wagesSelector } from '../reducers/components';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { WageDetailList } from '../components/WageDetailList';
import { getWagesRequest } from '../actions/wages';
import Spinner from '../components/Spinner';
import { withLoaderNew } from '../components/withLoading';
import withFetch from '../components/withFetch';

const selectWages = compose(wagesSelector, componentsSelector);

const WageDetailsListContainer = connect(
  (state: StoreState) => ({
    ...selectWages(state),
    force: true,
  }),
  { fetch: getWagesRequest },
);

export default WageDetailsListContainer(
  withFetch(
    withLoaderNew(selectWages, <Spinner position="relative" hideBackground />)(
      withError(Error)(
        withMapData((data, status) => ({
          wages: data || [],
          loading: status === STATUS_PENDING,
        }))(
          WageDetailList,
        ),
      ),
    ),
  ),
);
