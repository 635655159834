import { VacationData } from '@tymbe/schema/vacation.interface';
import React, { MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import { Button } from './Forms/Button';
import Modal from './Modal';
import RingsGridWithFilter from './Ring/RingsGridWithFilter';
import Spinner from './Spinner';
import { H2 } from './Styles/BasicElements';
import Vacations from './Vacations';
import Ring from '../containers/Ring';
import { transformApplicationFilter } from '../services/api/transformations';
import { Ring as RingData, Tymber } from '../types/Tymbe';
import { hasAllPerksFulfilled } from '../utils';

export type InvitationListProps = {
  rings: RingData['id'][];
  moreInvitations: (filters?: object) => void;
  filterInvitations: (filter: object) => void;
  revokeInvitation: (ringId: RingData['id']) => void;
  loading: boolean;
  vacations: VacationData[];
  tymber: Tymber;
};

const LoadMore = styled(Button)`
  grid-column: 1 / -1;
`;

export const InvitationList =
  ({ rings, vacations, moreInvitations, filterInvitations, loading, revokeInvitation, tymber }: InvitationListProps) => {
    const intl = useIntl();
    const [showConfirmId, setShowConfirmId] = React.useState<number | null>(null);
    const navigation = useNavigate();
    const [filters, setFilters] = useState<object>();

    const accept = (ring: number) => () => {
      navigation(`/ring/${ring}`);
    };
    const revoke = (ring: number | null) => {
      setShowConfirmId(null);
      if (ring) revokeInvitation(ring);
    };

    const applyFilter = (f: object) => {
      setFilters(f);
      filterInvitations(f);
    };

    const loadMoreRings = (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.currentTarget.blur();
      moreInvitations(filters);
    };

    const confirmRevoke = (ringId: number) => (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setShowConfirmId(ringId);
    };

    return (
      <div className="flex flex-col w-full">
        <div>
          <RingsGridWithFilter onFilter={(filter) => applyFilter(transformApplicationFilter(filter))}>
            {loading && <Spinner />}
            {rings.map((ring) => (
              <Ring key={ring} id={ring} data-cy={`shiftItem${ring}`}>
                {(ringD: RingData) => (
                  <>
                    <Button
                      onClick={accept(ring)}
                      data-cy="selectShiftButton"
                      disabled={!hasAllPerksFulfilled(tymber, ringD)}
                    >
                      {intl.formatMessage({
                        defaultMessage: 'Přijímám',
                        description: 'Tlačítko pro přijetí pozvánky na brigádu',
                      })}
                    </Button>
                    <Button onClick={confirmRevoke(ring)}>{intl.formatMessage({
                      defaultMessage: 'Odmítám',
                      description: 'Tlačítko pro odmítnutí pozvánky na brigádu',
                    })}
                    </Button>
                  </>
                )}
              </Ring>
            ))}
            {!!rings.length && !(rings.length % 20) && (
              <LoadMore onClick={loadMoreRings}>
                {intl.formatMessage({
                  defaultMessage: 'Načíst další',
                  description: 'Tlačítko pro načtení dalších pozvánek',
                })}
              </LoadMore>
            )}
            {!rings.length
              && (
                <H2 style={{ textAlign: 'center' }}>
                  {intl.formatMessage({
                    defaultMessage: 'Nenalezeny žádné pozvánky.',
                    description: 'Zobrazeno namísto seznamu pozvánek',
                  })}
                </H2>
              )}
            <Modal open={!!showConfirmId}>
              <Card>
                <CardHeader>
                  <H2>{intl.formatMessage({
                    defaultMessage: 'Odmítnutí pozvánky',
                    description: 'Titulek okna pro potvrzení odmitnutí pozvánky',
                  })}
                  </H2>
                </CardHeader>
                <CardContent>
                  <p>
                    {intl.formatMessage({
                      defaultMessage: 'Opravdu chcete odmítnout pozvánku?',
                      description: 'Text okna pro potvrzení odmítnutí pozvánky',
                    })}
                  </p>
                </CardContent>
                <CardFooter>
                  <Button onClick={() => revoke(showConfirmId)}>
                    {intl.formatMessage({
                      defaultMessage: 'Ano',
                      description: 'Tlačítko potvrzující odmítnutí pozvánky',
                    })}
                  </Button>
                  <Button onClick={() => setShowConfirmId(null)}>
                    {intl.formatMessage({
                      defaultMessage: 'Ne',
                      description: 'Tlačítko zruší potvrzení o odmítnutí pozvánky',
                    })}
                  </Button>
                </CardFooter>
              </Card>
            </Modal>
          </RingsGridWithFilter>
        </div>
        {vacations.length > 0 && <Vacations vacations={vacations} />}
      </div>
    );
  };
