import { rootReducer, StoreState } from '../reducers';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import createSagaMiddleware, { END, SagaMiddleware } from 'redux-saga';

interface AppStore extends Store<StoreState & PersistPartial> {
  runSaga: SagaMiddleware['run'];
  close: () => END;
}

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    composeWithDevTools({
      trace: true
    })(applyMiddleware(sagaMiddleware)),
  ) as AppStore;

  const persistor = persistStore(store);

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return { store, persistor };
};
