import { styled } from '../../styledComponents';

export const CardHeader = styled.div`
  position: relative;
  padding: 1rem 2rem;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem 1rem 0 0;
`;

export const CardHeaderCentered = styled(CardHeader)`
  text-align: center;
`;
