import { action } from './utils';
import { Action } from 'redux';
import { ApiError, RingDocument } from '../types/Tymbe';

export enum Actions {
  GET_MAIN_CONTRACTS_REQUEST = 'GET_MAIN_CONTRACTS_REQUEST',
  GET_MAIN_CONTRACTS_SUCCESS = 'GET_MAIN_CONTRACTS_SUCCESS',
  GET_MAIN_CONTRACTS_FAILURE = 'GET_MAIN_CONTRACTS_FAILURE',
}

export interface GetMainContractsRequest extends Action<Actions.GET_MAIN_CONTRACTS_REQUEST> {
  month: number;
  year: number;
}

export interface GetMainContractsSuccess extends Action<Actions.GET_MAIN_CONTRACTS_SUCCESS> {
  documents: RingDocument[];
}

export interface GetMainContractsFailure extends Action<Actions.GET_MAIN_CONTRACTS_FAILURE> {
  error: ApiError;
}

export type GetMainContractsActions = GetMainContractsRequest | GetMainContractsSuccess | GetMainContractsFailure;

export const getMainContractsRequest = (month: number, year: number): GetMainContractsRequest =>
  action(Actions.GET_MAIN_CONTRACTS_REQUEST, { month, year });
export const getMainContractsSuccess = (documents: RingDocument[]): GetMainContractsSuccess =>
  action(Actions.GET_MAIN_CONTRACTS_SUCCESS, { documents });
export const getMainContractsFailure = (error: ApiError): GetMainContractsFailure =>
  action(Actions.GET_MAIN_CONTRACTS_FAILURE, { error });
