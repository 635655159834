import { H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import { useIntl } from 'react-intl';
import { PaySupplement } from '@tymbe/legislatives/PaySupplement';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';

export type SurchargeListProps = {
  surcharges: PaySupplement[];
};

export const SurchargeList = ({ surcharges }: SurchargeListProps) => {
  const intl = useIntl();

  const getSurchargeValue = (surcharge: PaySupplement) => {
    return surcharge.fixedHourlyRate
      ? `${surcharge.fixedHourlyRate} Kč/h`
      : `${surcharge.percentageIncrease} %`;
  };

  const getSurchargeType = (surcharge: PaySupplement) => {
    switch (surcharge.type) {
      case PaySupplementType.Holiday:
        return 'svátek';
      case PaySupplementType.Night:
        return 'práci v noci';
      case PaySupplementType.Saturday:
        return 'sobotu';
      case PaySupplementType.Sunday:
        return 'neděli';
    }
  };

  const currencyTranslation = (surcharge: PaySupplement) => intl.formatMessage(
    {
      defaultMessage: '+ {surchargeRate} příplatek za {surchargeType}.',
    },
    {
      surchargeRate: getSurchargeValue(surcharge),
      surchargeType: getSurchargeType(surcharge)
    }
  );

  return (
    <div>
      <H3>
        <Icon id={'money'} size={'1.5em'} style={{ marginRight: '.5rem' }} />
        {intl.formatMessage({
          defaultMessage: 'Příplatky',
          description:
            'Název sekce (příklad podmínky: Výpis z rejstříku trestů)',
        })}
      </H3>
      {surcharges.map((surcharge, i) => (
        <div key={i + surcharge.type}>{currencyTranslation(surcharge)}</div>
      ))}
    </div>
  );
};
