import { styled } from '../../styledComponents';

export type RowProps = {
  grow?: boolean;
  overflow?: boolean;
  alignItems?: AlignSetting;
};

export const Row = styled.div<RowProps>`
  display: flex;
  position: relative;
  flex-flow: row;
  flex-grow: ${({ grow }) => grow && 1};
  align-self: stretch;
  align-items: ${({ alignItems }) => alignItems};
  overflow: ${({ overflow }) => overflow && 'auto'};
`;
