import { Actions, GetPayrollsActions } from '../../actions/payrolls';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_SUCCESS } from '../../types/StoreUtils';
import { CompanyRef } from '../../types/Tymbe';

export type PayrollsState = DataStoreState<CompanyRef[]>;

const initialState = initDataStoreState<CompanyRef[]>(null);

export const payrollsReducer: Reducer<PayrollsState, GetPayrollsActions> =
  (state = initialState, action) => {
    switch (action.type) {
      case Actions.GET_PAYROLLS_REQUEST:
        return initDataStoreState<CompanyRef[]>();
      case Actions.GET_PAYROLLS_SUCCESS:
        return initDataStoreState<CompanyRef[]>(STATUS_SUCCESS, action.payrolls);
      case Actions.GET_PAYROLLS_FAILURE:
        return initDataStoreState<CompanyRef[]>(STATUS_ERROR, null, action.error);
      default:
        return state;
    }
  };
