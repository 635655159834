import { FieldProps, RadioGroupProps, useField } from 'informed';
import { Radio } from './Radio';
import { RadioGroupContext } from './RadioGroupContext';
import { styled } from '../../styledComponents';

export const Error = styled.small`
  color: red;
  top: 100%;
  left: 0;
`;

export const RadioGroup = (props: FieldProps<RadioGroupProps>) => {
  const { fieldApi, fieldState, userProps } = useField(props);

  const groupContext = {
    radioGroupApi: fieldApi,
    radioGroupState: fieldState,
    ...props
  };

  const { label, id, options, children, className } = userProps;
  const { showError, error } = fieldState;

  return (
    <RadioGroupContext.Provider value={groupContext}>
      <fieldset aria-describedby={`${id}-error`} className={className}>
        {label ? <legend>{label}</legend> : null}
        {options
          ? options.map(option => (
            <label key={option.value}>
              <Radio value={option.value} /> {option.label}
            </label>
          ))
          : children}
        {showError ? (
          <Error role="alert" id={`${id}-error`}>
            {error as string}
          </Error>
        ) : null}
      </fieldset>
    </RadioGroupContext.Provider>
  );
};
