import { styled } from '../../styledComponents';

export const H1 = styled.h1`
  font-size: 2em;
  margin: 0;
  font-weight: 900;
`;

export const H2 = styled.h2`
  font-size: 1.5em;
  margin: 0;
  font-weight: 900;
`;

export const H3 = styled.h3`
  font-size: 1.3em;
  margin: 0;
  font-weight: 900;
`;

export const H4 = styled.h4`
  font-size: 1em;
  margin: 0;
  font-weight: 900;
`;

export const H5 = styled.h5`
  margin: 0;
  font-weight: 900;
`;
