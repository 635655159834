import { call, put, takeEvery } from '@redux-saga/core/effects';
import {
  Actions,
  contractPreviewFailure,
  ContractPreviewRequestAction,
  contractPreviewSuccess,
} from '../actions/contractPreview';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError } from '../types/Tymbe';

export function* fetchContractPreview(action: ContractPreviewRequestAction): SagaIterator {
  const { ringId, documentId, tymbe } = action;
  try {
    const response: AxiosResponse<Blob> = action.type === Actions.CONTRACT_PREVIEW_REQUEST ?
      yield call(api.getDocumentPreview, ringId, documentId, tymbe) :
      yield call(api.getSignedDocumentFile, documentId, tymbe);

    const data = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    yield put(contractPreviewSuccess(data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(contractPreviewFailure(error));
    }
  }
}

export function* watchContractPreviewRequest() {
  yield takeEvery(
    [Actions.CONTRACT_PREVIEW_REQUEST, Actions.SIGNED_CONTRACT_PREVIEW_REQUEST],
    fetchContractPreview);
}
