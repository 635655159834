import styled from 'styled-components';
import { ImgHTMLAttributes } from 'react';

export interface CompanyLogoProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string,
  alt?: string,
}

export const CompanyLogo = styled(({ src, ...rest }: CompanyLogoProps) =>
  <img
    src={src}
    {...rest}
  />)`height: 5rem;
  margin-right: 1rem`;
