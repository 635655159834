import { ringRequest } from '../actions/data/rings';
import { tymberDataRequest } from '../actions/tymberData';
import { Error } from '../components/Error';
import { Ring, RingSpinner } from '../components/Ring';
import withError from '../components/withError';
import withFetch, { withFetchNew } from '../components/withFetch';
import withLoading, { withLoaderNew } from '../components/withLoading';
import withMapData from '../components/withMapData';
import React from 'react';
import { connect } from 'react-redux';
import { StoreState, tymberSelector } from '../reducers';
import { ringSelector } from '../reducers/data/rings';
import { STATUS_PENDING } from '../types/StoreUtils';
import { Ring as RingData, Tymber } from '../types/Tymbe';

export interface OwnProps {
  id: RingData['id'];
}

const RingCont = connect(
  (state: StoreState, ownProps: OwnProps) => ({
    ...ringSelector(state.data.rings, ownProps.id),
    tymber: tymberSelector(state).data as Tymber,
  }),
  (dispatch, { id }) => ({
    fetch: () => dispatch(ringRequest(id)),
  }),
);

export default RingCont(
  withFetch(
    withLoading(RingSpinner, true)(
      withError(Error)(
        withMapData((data: RingData, status) => ({ data, loading: status === STATUS_PENDING }))(
          withFetchNew(tymberSelector, tymberDataRequest)(
            withLoaderNew(tymberSelector, <RingSpinner />)(
              Ring,
            ),
          ),
        ),
      ),
    ),
  ),
);
