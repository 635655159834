import { Actions, GetOtherContractsActions } from '../../actions/otherContracts';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_SUCCESS } from '../../types/StoreUtils';
import { RingDocument } from '../../types/Tymbe';

export type OtherContractsState = DataStoreState<RingDocument[]>;

const initialState: OtherContractsState = initDataStoreState<RingDocument[]>(null);

export const otherContractsReducer: Reducer<OtherContractsState, GetOtherContractsActions> =
  (state = initialState, action): OtherContractsState => {
    switch (action.type) {
      case Actions.GET_OTHER_CONTRACTS_REQUEST:
        return initDataStoreState<RingDocument[]>();
      case Actions.GET_OTHER_CONTRACTS_SUCCESS:
        return initDataStoreState<RingDocument[]>(STATUS_SUCCESS, action.documents);
      case Actions.GET_OTHER_CONTRACTS_FAILURE:
        return initDataStoreState<RingDocument[]>(STATUS_ERROR, null, action.error);
      default:
        return state;
    }
  };
