import { action } from './utils';
import { AxiosRequestConfig } from 'axios';
import { Action } from 'redux';
import { ApiError, SaveTymberDocumentDataRequest, TymberDocument, TymberDocumentPart } from '../types/Tymbe';

export enum Actions {
  GET_TYMBER_DOCUMENTS_REQUEST = 'GET_TYMBER_DOCUMENTS_REQUEST',
  GET_TYMBER_DOCUMENTS_SUCCESS = 'GET_TYMBER_DOCUMENTS_SUCCESS',
  GET_TYMBER_DOCUMENTS_FAILURE = 'GET_TYMBER_DOCUMENTS_FAILURE',
  MORE_TYMBER_DOCUMENTS_REQUEST = 'MORE_TYMBER_DOCUMENTS_REQUEST',
  MORE_TYMBER_DOCUMENTS_SUCCESS = 'MORE_TYMBER_DOCUMENTS_SUCCESS',
  MORE_TYMBER_DOCUMENTS_FAILURE = 'MORE_TYMBER_DOCUMENTS_FAILURE',
  DOWNLOAD_TYMBER_DOCUMENT_REQUEST = 'DOWNLOAD_TYMBER_DOCUMENT_REQUEST',
  DOWNLOAD_TYMBER_DOCUMENT_SUCCESS = 'DOWNLOAD_TYMBER_DOCUMENT_SUCCESS',
  DOWNLOAD_TYMBER_DOCUMENT_FAILURE = 'DOWNLOAD_TYMBER_DOCUMENT_FAILURE',
  INVALIDATE_TYMBER_DOCUMENT_REQUEST = 'INVALIDATE_TYMBER_DOCUMENT_REQUEST',
  INVALIDATE_TYMBER_DOCUMENT_SUCCESS = 'INVALIDATE_TYMBER_DOCUMENT_SUCCESS',
  INVALIDATE_TYMBER_DOCUMENT_FAILURE = 'INVALIDATE_TYMBER_DOCUMENT_FAILURE',
  SAVE_TYMBER_DOCUMENT_REQUEST = 'SAVE_TYMBER_DOCUMENT_REQUEST',
  SAVE_TYMBER_DOCUMENT_SUCCESS = 'SAVE_TYMBER_DOCUMENT_SUCCESS',
  SAVE_TYMBER_DOCUMENT_FAILURE = 'SAVE_TYMBER_DOCUMENT_FAILURE',
  SAVE_TYMBER_DOCUMENT_CANCEL = 'SAVE_TYMBRE_DOCUMENT_CANCEL',
}

export interface GetTymberDocumentsRequestAction extends Action<Actions.GET_TYMBER_DOCUMENTS_REQUEST> {
}

export interface GetTymberDocumentsSuccessAction extends Action<Actions.GET_TYMBER_DOCUMENTS_SUCCESS> {
  documents: TymberDocument[];
}

export interface GetTymberDocumentsFailureAction extends Action<Actions.GET_TYMBER_DOCUMENTS_FAILURE> {
  error: ApiError;
}

export interface MoreTymberDocumentsRequestAction extends Action<Actions.MORE_TYMBER_DOCUMENTS_REQUEST> {
}

export interface MoreTymberDocumentsSuccessAction extends Action<Actions.MORE_TYMBER_DOCUMENTS_SUCCESS> {
  documents: TymberDocument[];
}

export interface MoreTymberDocumentsFailureAction extends Action<Actions.MORE_TYMBER_DOCUMENTS_FAILURE> {
  error: ApiError;
}

export interface DownloadTymberDocumentRequestAction extends Action<Actions.DOWNLOAD_TYMBER_DOCUMENT_REQUEST> {
  id: TymberDocumentPart['id'];
}

export interface DownloadTymberDocumentSuccessAction extends Action<Actions.DOWNLOAD_TYMBER_DOCUMENT_SUCCESS> {
  id: TymberDocumentPart['id'];
}

export interface DownloadTymberDocumentFailureAction extends Action<Actions.DOWNLOAD_TYMBER_DOCUMENT_FAILURE> {
  id: TymberDocumentPart['id'];
  error: ApiError;
}

export interface InvalidateTymberDocumentRequestAction extends Action<Actions.INVALIDATE_TYMBER_DOCUMENT_REQUEST> {
  id: TymberDocument['id'];
}

export interface InvalidateTymberDocumentSuccessAction extends Action<Actions.INVALIDATE_TYMBER_DOCUMENT_SUCCESS> {
  document: TymberDocument;
}

export interface InvalidateTymberDocumentFailureAction extends Action<Actions.INVALIDATE_TYMBER_DOCUMENT_FAILURE> {
  id: TymberDocument['id'];
  error: ApiError;
}

export interface SaveTymberDocumentRequestAction extends Action<Actions.SAVE_TYMBER_DOCUMENT_REQUEST> {
  data: SaveTymberDocumentDataRequest;
  config?: AxiosRequestConfig;
}

export interface SaveTymberDocumentSuccessAction extends Action<Actions.SAVE_TYMBER_DOCUMENT_SUCCESS> {
  document: TymberDocument;
}

export interface SaveTymberDocumentFailureAction extends Action<Actions.SAVE_TYMBER_DOCUMENT_FAILURE> {
  error: ApiError;
}

export interface SaveTymberDocumentCancelAction extends Action<Actions.SAVE_TYMBER_DOCUMENT_CANCEL> {
}

export type GetTymberDocumentsActions =
  GetTymberDocumentsRequestAction
  | GetTymberDocumentsSuccessAction
  | GetTymberDocumentsFailureAction;

export type MoreTymberDocumentsActions =
  MoreTymberDocumentsRequestAction
  | MoreTymberDocumentsSuccessAction
  | MoreTymberDocumentsFailureAction;

export type DownloadTymberDocumentActions =
  DownloadTymberDocumentRequestAction
  | DownloadTymberDocumentSuccessAction
  | DownloadTymberDocumentFailureAction;

export type InvalidateTymberDocumentActions =
  InvalidateTymberDocumentRequestAction
  | InvalidateTymberDocumentSuccessAction
  | InvalidateTymberDocumentFailureAction;

export type SaveTymberDocumentActions =
  SaveTymberDocumentRequestAction
  | SaveTymberDocumentSuccessAction
  | SaveTymberDocumentFailureAction
  | SaveTymberDocumentCancelAction;

export const getTymberDocumentsRequest = (): GetTymberDocumentsRequestAction =>
  action(Actions.GET_TYMBER_DOCUMENTS_REQUEST);
export const getTymberDocumentsSuccess = (documents: TymberDocument[]): GetTymberDocumentsSuccessAction =>
  action(Actions.GET_TYMBER_DOCUMENTS_SUCCESS, { documents });
export const getTymberDocumentsFailure = (error: ApiError): GetTymberDocumentsFailureAction =>
  action(Actions.GET_TYMBER_DOCUMENTS_FAILURE, { error });

export const moreTymberDocumentsRequest = (): MoreTymberDocumentsRequestAction =>
    action(Actions.MORE_TYMBER_DOCUMENTS_REQUEST);
export const moreTymberDocumentsSuccess = (documents: TymberDocument[]): MoreTymberDocumentsSuccessAction =>
  action(Actions.MORE_TYMBER_DOCUMENTS_SUCCESS, { documents });
export const moreTymberDocumentsFailure = (error: ApiError): MoreTymberDocumentsFailureAction =>
  action(Actions.MORE_TYMBER_DOCUMENTS_FAILURE, { error });

export const downloadTymberDocumentRequest = (id: TymberDocumentPart['id']): DownloadTymberDocumentRequestAction =>
  action(Actions.DOWNLOAD_TYMBER_DOCUMENT_REQUEST, { id });
export const downloadTymberDocumentSuccess = (id: TymberDocumentPart['id']): DownloadTymberDocumentSuccessAction =>
  action(Actions.DOWNLOAD_TYMBER_DOCUMENT_SUCCESS, { id });
export const downloadTymberDocumentFailure =
  (id: TymberDocumentPart['id'], error: ApiError): DownloadTymberDocumentFailureAction =>
    action(Actions.DOWNLOAD_TYMBER_DOCUMENT_FAILURE, { id, error });

export const invalidateTymberDocumentRequest = (id: TymberDocument['id']): InvalidateTymberDocumentRequestAction =>
  action(Actions.INVALIDATE_TYMBER_DOCUMENT_REQUEST, { id });
export const invalidateTymberDocumentSuccess = (document: TymberDocument): InvalidateTymberDocumentSuccessAction =>
  action(Actions.INVALIDATE_TYMBER_DOCUMENT_SUCCESS, { document });
export const invalidateTymberDocumentFailuer =
  (id: TymberDocument['id'], error: ApiError): InvalidateTymberDocumentFailureAction =>
    action(Actions.INVALIDATE_TYMBER_DOCUMENT_FAILURE, { id, error });

export const saveTymberDocumentRequest =
  (data: SaveTymberDocumentDataRequest, config?: AxiosRequestConfig): SaveTymberDocumentRequestAction =>
    action(Actions.SAVE_TYMBER_DOCUMENT_REQUEST, { data, config });
export const saveTymberDocumentSuccess = (document: TymberDocument): SaveTymberDocumentSuccessAction =>
  action(Actions.SAVE_TYMBER_DOCUMENT_SUCCESS, { document });
export const saveTymberDocumentFailure = (error: ApiError): SaveTymberDocumentFailureAction =>
  action(Actions.SAVE_TYMBER_DOCUMENT_FAILURE, { error });
export const saveTymberDOcumentCancel = (): SaveTymberDocumentCancelAction =>
  action(Actions.SAVE_TYMBER_DOCUMENT_CANCEL);
