import { Perk } from './Perk';
import { H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import React from 'react';
import { useIntl } from 'react-intl';
import { Perk as PerkData, Perks, Ring, Tymber } from '../types/Tymbe';

export type PerkListProps = {
  perks: PerkData[];
  tymber: Tymber;
  ring?: Ring;
};

export const PerkList = ({ perks, tymber, ring }: PerkListProps) => {
  // this place show only documents, if we decide to show perks here, we should add a check for is_visible
  const visiblePerks = perks.filter((p) => ![Perks.EU_ONLY, Perks.NON_EU_ONLY, Perks.NON_UA_PROTECTED].includes(p.id));
  const intl = useIntl();
  return (
    <div>
      <H3>
        <Icon id={'perks'} size={'1.5em'} style={{ marginRight: '.5rem' }} />
        {intl.formatMessage({
          defaultMessage: 'Podmínky',
          description: 'Název sekce (příklad podmínky: Výpis z rejstříku trestů)',
        })}
      </H3>
      {visiblePerks.map(perk => <Perk
        key={perk.id}
        {...perk}
        tymber={tymber}
        ring={ring}
        hideLink={[Perks.MAN_ONLY, Perks.WOMAN_ONLY, Perks.NON_EU_ONLY].includes(perk.id)}
      />)}
    </div>
  );
};
