import { getCreditsRequest } from '../actions/credits';
import { Credits } from '../components/Credits';
import { Error } from '../components/Error';
import Spinner from '../components/Spinner';
import withError from '../components/withError';
import withFetch from '../components/withFetch';
import withLoading from '../components/withLoading';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { creditsSelector, liabilitiesSelector } from '../reducers/components';
import { compose } from 'redux';

const selectCredits = compose(creditsSelector, componentsSelector);
const selectLiabilities = compose(liabilitiesSelector, componentsSelector);

const CreditsConnect = connect(
  (state: StoreState) => ({
    ...selectCredits(state),
    disabled: selectLiabilities(state).data?.length! > 0 ?? true,
    force: true,
  }),
  { fetch: getCreditsRequest },
);

export default CreditsConnect(
  withFetch(
    withLoading(Spinner)(
      withError(Error)(
        withMapData(data => ({ credits: data }))(
          Credits,
        ),
      ),
    ),
  ),
);
