import { VacationData } from '@tymbe/schema/vacation.interface';
import { useIntl } from 'react-intl';

import { H2 } from './Styles/BasicElements';
import Vacation from './Vacation';

interface VactionsProps {
  vacations: VacationData[];
}

const Vacations = ({ vacations }: VactionsProps) => {
  const intl = useIntl();

  if (!vacations) {
    return <></>;
  }

  return (
    <div className="px-4 pr-[4rem] md:pr-[9rem] sm:px-16">
      <H2>
        {intl.formatMessage({
          defaultMessage: 'Dovolenkové směny',
          description: 'Nadpis sekce pro výběr dovolené',
        })}
      </H2>
      <div className="grid grid-cols-[repeat(auto-fill,minmax(260px,1fr))] grow gap-[5rem]">
        {vacations.map((vacation) => <Vacation vacation={vacation} key={vacation.person_document_id} />)}
      </div>
    </div>
  );
};

export default Vacations;
