import { connect } from 'react-redux';
import withFetch from '../components/withFetch';
import { CompanyLogo, CompanyLogoProps } from '../components/CompanyLogo';
import { bucketFileSelector } from '../reducers/data/bucket';
import { dataSelector, StoreState } from '../reducers';
import { bucketFileRequest } from '../actions/data/bucket';
import defaultLogo from '../images/no_logo.png';
import withMapData from '../components/withMapData';
import { BucketFile } from '../types/Tymbe';
import { dataBucketFileSelector } from '../reducers/data';
import { compose } from 'redux';

type ProvidedProps = {
  src: string;
}
export interface OwnProps extends Omit<CompanyLogoProps, keyof ProvidedProps> {
  path: string | null;
}

const bucketFileStateSelector = compose(dataBucketFileSelector, dataSelector);

const CompanyLogoCont = connect(
  (state: StoreState, ownProps: OwnProps) => bucketFileSelector(bucketFileStateSelector(state), ownProps.path ?? undefined),
  (dispatch, { path }) => ({
    fetch: () => path ? dispatch(bucketFileRequest(path)) : null,
  }),
);

export default CompanyLogoCont(
  withFetch(
    withMapData((data: BucketFile): ProvidedProps => ({ src: data?.url || defaultLogo }))(
      CompanyLogo,
    ),
  ),
);
