import { payoutCreditsRequest } from '../actions/credits';
import { CreditItem } from '../components/CreditItem';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { dataSelector, StoreState } from '../reducers';
import { dataCreditsSelector } from '../reducers/data';
import { creditsSelector } from '../reducers/data/credits';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { Credit, SurveyAnswer } from '../types/Tymbe';

const selectCreditData = compose(dataCreditsSelector, dataSelector);

export type CreditItemProps = {
  id: Credit['id'];
};

const CreditItemConnect = connect(
  (state: StoreState, { id }: CreditItemProps) => creditsSelector(selectCreditData(state), id),
  dispatch => ({
    payoutCredits: (creditId: Credit['id'], survey: SurveyAnswer, password: string) =>
      dispatch(payoutCreditsRequest(creditId, survey, password)),
  }),
);

export default CreditItemConnect(
  withMapData((data, status, error) => ({ credit: data, loading: status === STATUS_PENDING, error }))(
    CreditItem,
  ),
);
