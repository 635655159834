import { NavLink } from 'react-router-dom';
import { styled } from '../../styledComponents';

export default styled(NavLink)`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  color: ${({ theme }) => theme.bodyColor};
  font-weight: 900;
  text-transform: lowercase;
  text-decoration: none;
  font-size: 1.4rem;
  outline: none;

  &.active {
    color: ${({ theme }) => theme.buttons.primary};
  }
`;
