import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeaderCentered } from './Card/CardHeader';
import { H1, H2 } from './Styles/BasicElements';
import { WellBox } from './Styles/WellBox';
import WageItem from '../containers/WageItem';
import React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '../styledComponents';

export type WagesProps = {
  wages: PayoutInfoData[];
};

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BigWellBox = styled(WellBox)`
  padding: 1rem 1.5rem;
  font-size: 2em;
`;

export const Wages = ({ wages }: WagesProps) => {
  const intl = useIntl();
  const processing = wages.reduce((sum, wage) => sum + wage.processingAmount, 0);
  const payout = wages.reduce((sum, wage) => sum + wage.payableAmount, 0);
  const display = wages.filter((wage) => wage.payableAmount > 0 || wage.withheldAmount > 0 || wage.blockedAmount > 0);

  return (
    <Card color={'gold'}>
      <CardHeaderCentered><H1>{intl.formatMessage({
        defaultMessage: 'Peníze',
        description: 'Nadpis sekce na strance výplat',
      })}</H1></CardHeaderCentered>
      <CardContent>
        <Heading>
          <H2 style={{ display: 'inline' }}>{intl.formatMessage({
            defaultMessage: 'Celkem k proplacení:',
            description: 'Uvozuje celkovou hodnotu neproplacených brigád, např: "Celkem k proplacení: 952,-"',
          })}</H2>
          <BigWellBox id='complete_payable_net_wage' data-cy="completePayableNetWage" color={'gray4'} inline>{payout},–</BigWellBox>
        </Heading>

        <Heading>
          <strong>{intl.formatMessage({
            defaultMessage: 'Čeká na zpracování platby:',
            description: 'Uvozuje celkovou hodnotu ve zpracování, např: "Čeká na zpracování bankou: 135,-"',
          })}</strong>
          <strong id='payments_being_processed_net_wage' data-cy="paymentsBeingProcessedNetWage">{processing},–</strong>
        </Heading>
        <hr />
        {display.map((wage) => <WageItem key={`${wage.start}/${wage.company_id}/${wage.type}`} wage={wage} />)}
      </CardContent>
      <CardFooter />
    </Card>
  );
};
