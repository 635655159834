import { VacationData } from '@tymbe/schema/vacation.interface';
import { Action } from 'redux';

import { action } from './utils';
import { ApiError } from '../types/Tymbe';

export enum Actions {
  VACATION_REQUEST = 'VACATION_REQUEST',
  VACATION_SUCCESS = 'VACATION_SUCCESS',
  VACATION_FAILURE = 'VACATION_FAILURE',
}

export interface VacationSuccessAction extends Action<Actions.VACATION_SUCCESS> {
  vacation: VacationData[];
}

export interface VacationFailureAction extends Action<Actions.VACATION_FAILURE> {
  error: ApiError;
}

export type VacationActions = VacationSuccessAction | VacationFailureAction | Action<Actions.VACATION_REQUEST>;

export const vacationRequest = (): Action<Actions.VACATION_REQUEST> =>
  action(Actions.VACATION_REQUEST);

export const vacationSuccess = (vacation: VacationData[]): VacationSuccessAction =>
  action(Actions.VACATION_SUCCESS, { vacation });

export const vacationFailure = (error: ApiError): VacationFailureAction =>
  action(Actions.VACATION_FAILURE, { error });
