import { LogoutButton } from '../components/LogoutButton';
import { Icon } from '../components/Styles/Icon';
import React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../reducers';
import { styled } from '../styledComponents';


const UserMenuWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  color: ${({ theme }) => theme.bodyColor};
  font-weight: 900;
  text-transform: lowercase;
  text-decoration: none;
  font-size: 1.4rem;
  outline: none;

  &.active {
    color: ${({ theme }) => theme.buttons.primary};
  }

  @media (max-width: 1434px) {
    margin: 0 0 7rem 0;
  }
`;

const UserNameId = styled.div`
  display: inline-flex;
  flex-flow: column;
`;

const Id = styled.span`
  text-transform: uppercase;
  font-size: 1rem;
  align-self: center;
`;

type UserMenuComponentProps = {
  title: string;
  id: number;
};

const UserMenuComponent = ({ title, id, ...rest }: UserMenuComponentProps) => (
   <UserMenuWrapper {...rest}>
    <UserNameId><span>{title}</span><Id>{`ID-${id}`}</Id></UserNameId>
    <LogoutButton background={'gray5'} style={{ marginLeft: '1rem' }}>
      <Icon id={'logout'} size={'1.4em'} color={'secondary'} />
    </LogoutButton>
  </UserMenuWrapper>
);

export const UserMenu = connect((state: StoreState) => ({
  title: state.auth.email || '',
  id: state?.tymber?.data?.id || 0,
}))(UserMenuComponent);
