import {
  Actions,
  InvitationRejectActions,
  InvitationsActions,
  MoreInvitationsActions,
} from '../../actions/invitations';
import {
  DataStoreState,
  initDataStoreState,
  STATUS_ERROR,
  STATUS_PENDING,
  STATUS_SUCCESS,
} from '../../types/StoreUtils';
import { Ring } from '../../types/Tymbe';

export type InvitationsState = DataStoreState<Ring['id'][]>;

const initialState: InvitationsState = initDataStoreState<Ring['id'][]>(null);

const removeId = (ringId: Ring['id'], data: InvitationsState['data']) =>
  (Array.isArray(data) ? data.filter((id) => id !== ringId) : data);


type ReducerActions = InvitationsActions | InvitationRejectActions | MoreInvitationsActions;
export const invitationsReducer =
  // eslint-disable-next-line @typescript-eslint/default-param-last
  (state: InvitationsState = initialState, action: ReducerActions): InvitationsState => {
    switch (action.type) {
      case Actions.INVITATIONS_REQUEST:
        return { ...state, status: STATUS_PENDING };
      case Actions.INVITATIONS_SUCCESS:
        return { ...initialState, status: STATUS_SUCCESS, data: action.rings.map(ring => ring.id) };
      case Actions.INVITATIONS_FAILURE:
        return { ...initialState, status: STATUS_ERROR, error: action.error };
      case Actions.INVITATION_REJECT_SUCCESS:
        return { ...state, data: removeId(action.ring.id, state.data) };
      case Actions.MORE_INVITATIONS_REQUEST:
        return { ...state, status: STATUS_PENDING };
      case Actions.MORE_INVITATIONS_SUCCESS:
        return {
          ...initialState,
          status: STATUS_SUCCESS,
          data:[
            ...state.data!,
            ...action.rings.map(ring => ring.id),
          ]
        };
      case Actions.MORE_INVITATIONS_FAILURE:
        return { ...state, status: STATUS_ERROR, error: action.error };
      default:
        return state;
    }
  };
