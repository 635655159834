import * as React from 'react';
import { Selector, useSelector } from 'react-redux';

import { DataStoreState, STATUS_PENDING } from '../types/StoreUtils';
import { Falsey } from 'utility-types';
import { getDisplayName } from '../utils';

// tslint:disable-next-line:no-any
export default (LoadingComponent: React.ComponentType<any>, passIfData?: boolean) =>
  <C, D>(WrappedComponent: React.ComponentType<C>): React.ComponentType<C & DataStoreState<D>> => {
    const WithLoading = (props: C & DataStoreState<D>) =>
      [STATUS_PENDING, null].includes(props.status)
        ? (passIfData && props.data ? <WrappedComponent {...props} /> : <LoadingComponent />)
        : <WrappedComponent {...props} />;

    WithLoading.displayName = `WithLoading(${getDisplayName(WrappedComponent)})`;
    return WithLoading;
  };

export const withLoaderNew = <S, D = null>(
  selector: Selector<S, DataStoreState<D>>,
  loader?: React.ReactNode,
  passIfData?: boolean,
) => <C extends object>
(WrappedComponent: React.ComponentType<C>): React.FC<typeof passIfData extends Falsey ? C : Omit<C, 'loading'>> =>
  // @ts-ignore
  (props: C) => {
    const { status, data } = useSelector(selector);
    return [STATUS_PENDING, null].includes(status)
      ? (passIfData && data ? <WrappedComponent {...props} loading /> : <>{loader}</>)
      : <WrappedComponent {...props} />;
  };
