import { downloadTymberDocumentRequest } from '../actions/tymberDocuments';
import { TymberDocumentFile } from '../components/TymberDocumentFile';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { dataSelector, StoreState } from '../reducers';
import { dataTymberDocumentsSelector } from '../reducers/data';
import { tymberDocumentPartSelector } from '../reducers/data/tymberDocuments';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { TymberDocumentPart } from '../types/Tymbe';

const selectDocumentFile = compose(dataTymberDocumentsSelector, dataSelector);

export type TymberDocumentFileProps = {
  id: TymberDocumentPart['id'];
};

const TymberDocumentFileConnect = connect(
  (state: StoreState, { id }: TymberDocumentFileProps) => tymberDocumentPartSelector(selectDocumentFile(state), id),
  dispatch => ({
    download: (id: TymberDocumentPart['id']) => dispatch(downloadTymberDocumentRequest(id)),
  }),
);

export default TymberDocumentFileConnect(
  withMapData((data, status, error) => ({
    file: data,
    loading: status === STATUS_PENDING,
    error,
  }))(
    TymberDocumentFile,
  ),
);
