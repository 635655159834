import { FormItem } from './FormItem';
import { inputCss } from './Input';
import { FieldProps, useField } from 'informed';
import React, { CSSProperties, HTMLProps, InputHTMLAttributes } from 'react';
import { styled } from '../../styledComponents';

interface LabeledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  id: string;
  error?: string;
  className?: string;
  style?: CSSProperties;
}

export const Input = styled.input`
  ${inputCss};
  width: 100%;
  margin-top: .2rem;
`;

export const Label = styled.label`
  left: 0;
  top: 1rem;
  padding: 0.2rem;
  font-size: 1.2rem;
  font-weight: 900;
  margin-bottom: 0;
  white-space: nowrap;
`;

export const AnimatedLabel = styled(Label)`
  ${inputCss};
  border-color: transparent !important;
  //background-color: rgba(255,255,255,.5);
  position: absolute;
  top: .3rem;
  left: 0.5em;
  margin: .5em 2px;
  padding: 0;
  font-weight: inherit;
  input:not(:placeholder-shown) + &, input:focus + & {
    font-size: 1rem;
    top: -1em;
  }
`;

export const Error = styled.small`
  color: red;
  top: 100%;
  left: 0;
`;

export const LabeledInputClassic = ({ className, style, label, ...props }: HTMLProps<HTMLInputElement>) => (
  <FormItem className={className} style={style}>
    <Input {...props as GetProps<typeof Input>} placeholder={label} />
  </FormItem>
);

export const LabeledInput = (props: FieldProps<LabeledInputProps>) => {
  const { fieldState, fieldApi, render, ref, userProps, informed } = useField<LabeledInputProps, string | number>(props);
  const { value, touched, showError } = fieldState;
  const { setValue, setTouched, validate } = fieldApi;
  const { className, style, id, label, error, ...rest } = userProps;
  return render(
    <FormItem className={className} style={style}>
      <Input
        {...informed}
        id={id}
        ref={ref}
        value={value as string ?? ''}
        onChange={(e) => {
          setValue(e.target.value);
          touched && validate();
        }}
        onBlur={(e) => {
          setTouched(true);
          informed.onBlur && informed.onBlur(e);
        }}
        placeholder={label}
        {...rest}
      />
      {showError && fieldState.error && <Error>{fieldState.error as string}</Error>}
      {error && <div><Error>{error}</Error></div>}
    </FormItem>,
  );
};
