import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Ring, RingShiftClaimPrecheck } from '../types/Tymbe';

export enum Actions {
  GET_RING_DOCUMENTS_REQUEST = 'GET_RING_DOCUMENTS_REQUEST',
  GET_RING_DOCUMENTS_SUCCESS = 'GET_RING_DOCUMENTS_SUCCESS',
  GET_RING_DOCUMENTS_FAILURE = 'GET_RING_DOCUMENTS_FAILURE',
}

export interface GetRingDocumentsRequestAction extends Action<Actions.GET_RING_DOCUMENTS_REQUEST> {
  ringId: Ring['id'];
}

export interface GetRingDocumentsSuccessAction extends Action<Actions.GET_RING_DOCUMENTS_SUCCESS> {
  claimShiftPrecheck: RingShiftClaimPrecheck;
}

export interface GetRingDocumentsFailureAction extends Action<Actions.GET_RING_DOCUMENTS_FAILURE> {
  error: ApiError;
}

export type GetRingDocumentsActions =
  GetRingDocumentsRequestAction
  | GetRingDocumentsSuccessAction
  | GetRingDocumentsFailureAction;

export const getRingDocumentsRequest = (ringId: Ring['id']): GetRingDocumentsRequestAction =>
  action(Actions.GET_RING_DOCUMENTS_REQUEST, { ringId });
export const getRingDocumentsSuccess = (claimShiftPrecheck: RingShiftClaimPrecheck): GetRingDocumentsSuccessAction =>
  action(Actions.GET_RING_DOCUMENTS_SUCCESS, { claimShiftPrecheck });
export const getRingDocumentsFailure = (error: ApiError): GetRingDocumentsFailureAction =>
  action(Actions.GET_RING_DOCUMENTS_FAILURE, { error });
