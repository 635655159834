import { call, put, takeLatest, takeLeading } from '@redux-saga/core/effects';
import {
  GET_REGISTRATION_REQUEST,
  getRegistrationFailure,
  GetRegistrationRequestAction,
  getRegistrationSuccess,
  SAVE_REGISTRATION_REQUEST,
  saveRegistrationFailure,
  SaveRegistrationRequestAction,
  saveRegistrationSuccess,
} from '../actions/registration';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, RegistrationData } from '../types/Tymbe';

export function* fetchRegistration(action: GetRegistrationRequestAction): SagaIterator {
  const { regId } = action;
  try {
    const regData: AxiosResponse<RegistrationData> = yield call(api.getRegistration, regId);
    yield put(getRegistrationSuccess(regData.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getRegistrationFailure(error));
    }
  }
}

export function* saveRegistration(action: SaveRegistrationRequestAction): SagaIterator {
  const { regId, data } = action;
  try {
    const result: AxiosResponse<{ userIsActive: boolean }> = yield call(api.saveRegistration, regId, data);
    yield put(saveRegistrationSuccess(result.data.userIsActive));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(saveRegistrationFailure(error));
    }
  }
}

export function* watchGetRegistrationRequest() {
  yield takeLatest(GET_REGISTRATION_REQUEST, fetchRegistration);
}

export function* watchSaveRegistrationRequest() {
  yield takeLeading(SAVE_REGISTRATION_REQUEST, saveRegistration);
}
