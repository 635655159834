import { Button } from './Forms/Button';
import { Error, LabeledInput } from './Forms/LabeledInput';
import { validatePassword } from './Forms/validations';
import FrontWrapper from './FrontWrapper';
import { LogoHorizontal } from './Logo';
import { H3 } from './Styles/BasicElements';
import { Center } from './Styles/Center';
import { Form, FormState } from 'informed';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import api from '../services/api';
import { css } from '../styledComponents';

const LogoCss = css`
  width: 50%;
  margin-bottom: 2rem;
`;

const Back = Button.withComponent(NavLink);

export const ResetPassword = () => {
  const intl = useIntl();
  const [requestSent, setRequest] = React.useState(false);
  const [formDisabled, setFormDisabled] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const query = new URLSearchParams(useLocation().search);

  const handleReset = (formState: FormState) => {
    const data = formState.values as { password: string, password2: string };
    setError(null);
    if (!data.password) {
      setError(intl.formatMessage({
        defaultMessage: 'Musíte zadat heslo',
        description: 'Text chyby formuláře',
      }));
      return;
    }

    if (data.password !== data.password2) {
      setError(intl.formatMessage({
        defaultMessage: 'Hesla se musí shodovat',
        description: 'Text chyby formuláře',
      }));
      return;
    }

    setFormDisabled(true);
    api.resetPassword(data.password, query.get('token'))
      .then(() => setRequest(true))
      .catch(() => setError(intl.formatMessage({
        defaultMessage: 'Při změně hesla došlo k chybě',
        description: 'Chyba při ukládání nového hesla',
      })))
      .finally(() => setFormDisabled(false));
  };

  return (
    <FrontWrapper>
      <LogoHorizontal css={LogoCss} />
      {!requestSent ?
        <Form onSubmit={handleReset} onInvalid={a => console.log('Invalid', a)} style={{ backgroundColor: 'inherit' }}>
          <LabeledInput
            label={intl.formatMessage({
              defaultMessage: 'Heslo',
              description: 'Popisek formulářového prvku',
            })}
            id={'password'}
            name={'password'}
            type={'password'}
            validate={validatePassword()}
            validateOn="blur"
            validateWhen={['password2']}
          />
          <LabeledInput
            label={intl.formatMessage({
              defaultMessage: 'Potvrdit heslo',
              description: 'Popisek formulářového prvku',
            })}
            id={'password2'}
            name={'password2'}
            type={'password'}
            validate={validatePassword('password')}
            validateOn="blur"
            validateWhen={['password']}
          />
          {error && <Center><Error>{error}</Error></Center>}
          <Center style={{ marginTop: '4rem' }}>
            <Button type={'submit'} disabled={formDisabled}>{intl.formatMessage({
              defaultMessage: 'Změnit heslo',
              description: 'Tlačítko pro odeslání formuláře pro změnu hesla',
            })}</Button>
          </Center>
        </Form> :
        <Center>
          <H3>{intl.formatMessage({
            defaultMessage: 'Heslo bylo úspěšně změněno. Nyní se můžete přihlásit.',
            description: 'Text zobrazený po úspěšné změně hesla',
          })}</H3>
          <br />
          <br />
          <Back to={'/'}>{intl.formatMessage({
            defaultMessage: 'Přihlásit',
            description: 'Odkaz na stránku pro příhlášení',
          })}</Back>
        </Center>
      }
    </FrontWrapper>
  );
};
