import React from 'react';
import { styled } from '../styledComponents';

const ProgressContainer = styled.div`
  box-shadow: 0 0 3px ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
`;

const Progressbar = styled.div`
  height: 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.primary};
  transition: width 1s linear;
`;

export type ProgressBarProps = {
  start?: number;
  end?: number;
  position: number;
};

export const ProgressBar = ({ start = 0, end = 1, position }: ProgressBarProps) => (
  <ProgressContainer>
    <Progressbar style={{ width: `${Math.min(100, position / ((end - start) / 100))}%` }} />
  </ProgressContainer>
);
