import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LoginActions } from '../actions/login';
import { LOGOUT_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS, LogoutActions } from '../actions/logout';
import { Reducer } from 'redux';
import { ApiError, Genders } from '../types/Tymbe';

export interface AuthState {
  id: number | null;
  birthDate: string | null;
  email: string | null;
  gender: Genders | null;
  token: string | null;
  authorizing: boolean;
  error: ApiError | null;
}

const initialState: AuthState = {
  id: null,
  birthDate: null,
  email: null,
  gender: null,
  token: null,
  authorizing: false,
  error: null,
};

type AuthReducerActions = LoginActions | LogoutActions;

export const authReducer: Reducer<AuthState, AuthReducerActions> = (state = initialState, action): AuthState => {
  const { type, ...data } = action;
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...initialState, email: action.email, authorizing: true };
    case LOGIN_SUCCESS:
      return { ...initialState, authorizing: false, ...data };
    case LOGIN_FAILURE:
      return { ...initialState, email: action.email, authorizing: false, token: null, error: action.error };
    case LOGOUT_REQUEST:
      return { ...state, authorizing: true };
    case LOGOUT_SUCCESS:
      return { ...initialState, email: action.email };
    case LOGOUT_FAILURE:
      return { ...state, authorizing: false };
  }
  return state;
};
