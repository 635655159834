import { styled } from '../../styledComponents';
import { Icon } from '../Styles/Icon';
import RingsGrid from '../Styles/RingsGrid';
import { Card } from '../Card';
import { CardContent } from '../Card/CardContent';
import { CardFooter } from '../Card/CardFooter';

export const TaxDetailBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TaxShiftList = styled(RingsGrid)`
  grid-template-columns: repeat(auto-fill, minMax(220px, 1fr));
  grid-gap: 3rem;
  padding: 3rem 1rem 3rem 1rem;
  align-items: center;

  @media (max-width: 418px) {
    padding: 3rem 1rem 3rem 1rem;
  }

  ${Card} {
    min-width: 220px;

    ${CardContent} {
      padding: 1rem 1rem 0;
    }

    ${CardFooter} {
      padding-top: 0;
    }
  }
`;

export const TaxSummaryBox = styled.div`
  display: inline-grid;
  column-gap: 1rem;
  grid-template-columns: [name] auto [value] 1fr [end];
  grid-auto-rows: min-content;
  margin: 0.5rem 0;
  align-self: flex-end;
  align-items: center;
`;

export const Separator = styled.hr`
  grid-column-start: name;
  grid-column-end: end;
  margin: 0.5rem 0;

  & + & {
    margin-top: -0.4rem;
  }
`;

export const ToggleIcon = styled(Icon).attrs({ id: 'toggleIndicator' }) <{ toggled?: boolean }>`
  display: inline-block;
  height: 1.2rem;
  width: 1.2rem;
  transition: transform .5s;
  --color: ${({ theme }) => theme.bodyColor};

  ${({ toggled }) => toggled && 'transform: rotate(90deg);'}
`;

export const Name = styled.div`
  grid-column-start: name;
  text-align: right;
`;

export const Value = styled.div`
  grid-column-start: value;
  text-align: right;
`;

export const FootNote = styled.div`
  font-size: 0.9rem;
`
