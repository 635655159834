import { useModalError } from './Modal/ModalError';
import Spinner from './Spinner';
import { H4 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import React from 'react';
import Moment from 'react-moment';
import { styled } from '../styledComponents';
import { ApiError, RingDocument, RingDocumentSigned } from '../types/Tymbe';

export type DocumentItemProps = {
  document: RingDocument,
  loading: boolean,
  error: ApiError,
  downloadDocument: (id: RingDocumentSigned['id'], documentId: RingDocument['id'], tymbe: boolean) => void;
};

const DocumentItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  padding: 2rem 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  grid-gap: 1rem;

  &:last-child {
    border-bottom: none;
  }

  ${H4} {
    grid-column: 1 / 3;
    margin-bottom: 1rem;
  }

  time {
    align-self: center;
  }
`;

const Button = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
`;

export const DocumentItem: React.FC<DocumentItemProps> = ({ document, downloadDocument, error, loading, ...props }) => {
  const showError = useModalError();
  if (error) showError(error.message);
  return (
    <DocumentItemWrapper {...props}>
      <Button onClick={() => downloadDocument(document.signed.id, document.id, document.tymbe)} disabled={loading}>
        {loading &&
        <Spinner position={'relative'} style={{ width: '3rem', minHeight: '3rem' }} /> ||
        <Icon id={'download'} size={'3rem'} background={'gray4'} color={'secondary'} />
        }
      </Button>
      <Moment format={'L'}>{document.signed.time.start}</Moment>
      <div style={{ alignSelf: 'center' }}><strong>{document.title}</strong></div>
    </DocumentItemWrapper>
  );
};
