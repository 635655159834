import { LOGOUT_SUCCESS } from '../actions/logout';
import { authReducer, AuthState } from './auth';
import { componentsReducer, ComponentsState } from './components';
import { modalErrorReducer, ModalErrorState } from './components/modalError';
import { dataReducer, DataState } from './data';
import { localizationReducer, LocalizationState } from './localization';
import { tymberReducer, TymberState } from './tymber';
import { combineReducers, Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

export interface StoreState {
  auth: AuthState & PersistPartial;
  components: ComponentsState;
  data: DataState;
  tymber: TymberState;
  modalError: ModalErrorState;
  localization: LocalizationState & PersistPartial;
}

export const authSelector = (state: StoreState) => state.auth;
export const componentsSelector = (state: StoreState) => state.components;
export const dataSelector = (state: StoreState) => state.data;
export const tymberSelector = (state: StoreState) => state.tymber;
export const modalErrorSelector = (state: StoreState) => state.modalError;

const appReducer = combineReducers<StoreState>(
  {
    auth: persistReducer({ key: 'auth', storage, blacklist: ['token', 'authorizing', 'error'] }, authReducer),
    components: componentsReducer,
    data: dataReducer,
    tymber: tymberReducer,
    modalError: modalErrorReducer,
    localization: persistReducer({ key: 'localization', storage, whitelist: ['locale'] }, localizationReducer),
  },
);

export const rootReducer: Reducer = (state, action) => {
  let newState = state;
  if (action.type === LOGOUT_SUCCESS) {
    newState = {
      auth: { ...state.auth },
      modalError: [...state.modalError],
      localization: { ...state.localization },
    };
  }
  return appReducer(newState, action);
};
