import {
  Actions,
  getCreditsFailure,
  GetCreditsRequestAction,
  getCreditsSuccess,
  payoutCreditsFailure,
  PayoutCreditsRequestAction,
  payoutCreditsSuccess,
} from '../actions/credits';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, Credit } from '../types/Tymbe';

export function* fetchCredits(action: GetCreditsRequestAction): SagaIterator {
  try {
    const response: AxiosResponse<Credit[]> = yield call(api.getCredits);
    yield put(getCreditsSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getCreditsFailure(error));
    }
  }
}

export function* requestPayoutCredits(action: PayoutCreditsRequestAction) {
  const { creditsId, password, survey } = action;
  try {
    yield call(api.payoutCredits, creditsId, survey, password);
    yield put(payoutCreditsSuccess(creditsId));
  } catch (e) {
    const error: ApiError = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(payoutCreditsFailure(creditsId, error));
    }
  }
}

export function* watchGetCreditsRequest() {
  yield takeEvery(Actions.GET_CREDITS_REQUEST, fetchCredits);
}

export function* watchPayoutCreditsRequest() {
  yield takeLeading(Actions.PAYOUT_CREDITS_REQUEST, requestPayoutCredits);
}
