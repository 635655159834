import { Actions, GetRingDocumentsActions } from '../../actions/ringDocuments';
import { Reducer } from 'redux';
import { CLEAR_STATE, ClearStateAction } from '../../store';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_SUCCESS } from '../../types/StoreUtils';
import { RingDocument, RingShiftClaimPrecheck } from '../../types/Tymbe';

export type RingDocumentsState = DataStoreState<RingShiftClaimPrecheck>;

const initialState: RingDocumentsState = initDataStoreState<RingShiftClaimPrecheck>(null);

export const ringDocumetsReducer: Reducer<RingDocumentsState, GetRingDocumentsActions | ClearStateAction> =
  (state = initialState, action) => {
    switch (action.type) {
      case Actions.GET_RING_DOCUMENTS_REQUEST:
        return initDataStoreState<RingShiftClaimPrecheck>();
      case Actions.GET_RING_DOCUMENTS_SUCCESS:
        return initDataStoreState<RingShiftClaimPrecheck>(STATUS_SUCCESS, action.claimShiftPrecheck);
      case Actions.GET_RING_DOCUMENTS_FAILURE:
        return initDataStoreState<RingShiftClaimPrecheck>(STATUS_ERROR, null, action.error);
      case CLEAR_STATE:
        if (action.state === 'ringDocuments') {
          return initialState;
        }
      default:
        return state;
    }
  };

export const ringDocumentSelector = (state: RingDocumentsState, documentId: RingDocument['id'], tymbe: boolean) =>
  (state.data
    ? state.data.contracts.find(document => document.id === documentId && document.tymbe === tymbe)
    : null);
