import moment from 'moment';
import { useIntl } from 'react-intl';

import { H4 } from './Styles/BasicElements';
import { Row } from './Styles/Row';
import { styled } from '../styledComponents';
import { TymberUtility as UtilityItem } from '../types/Tymbe';

export type TymberUtilityItemProps = {
  utility: UtilityItem;
};

const TymberUtility = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary}
`;

export const TymberUtilityItem = ({ utility }: TymberUtilityItemProps) => {
  const intl = useIntl();
  return (
    <TymberUtility>
      <Row className="gap-1 justify-between">
        <H4>{utility.title}</H4>
        <div>{utility.company.name}</div>
        <div>
          {intl.formatDate(
            moment(utility.issued).toDate(),
            { day: '2-digit', month: '2-digit', year: 'numeric' },
          )}
        </div>
      </Row>
    </TymberUtility>
  );
};
