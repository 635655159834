import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';

import { logoutRequest } from '../actions/logout';
import { tymberDataRequest } from '../actions/tymberData';
import { Main } from '../components/Main';
import MainMenu from '../components/MainTopBar';
import { useModalError } from '../components/Modal/ModalError';
import Spinner from '../components/Spinner';
import { withFetchNew } from '../components/withFetch';
import { withLoaderNew } from '../components/withLoading';
import { StoreState, tymberSelector } from '../reducers';
import { TymberStates } from '../types/Tymbe';

const App = () => {
  const intl = useIntl();
  const state = useSelector((s: StoreState) => tymberSelector(s).data?.state);
  const dispatch = useDispatch();
  const setError = useModalError();

  useEffect(
    () => {
      if (!state) return;
      switch (state) {
        case TymberStates.REGISTRATION:
          setError(
            intl.formatMessage({
              defaultMessage: 'Zatím nemáte zadaný schválený doklad totožnosti, bez dokladu totožnosti nemůžete jít na brigádu',
              description: 'Text chyby',
            }),
            intl.formatMessage({
              defaultMessage: 'Omezený přístup',
              description: 'Titulek chybového dialogu',
            }),
          );
          break;
        case TymberStates.BANNED:
          setError(
            intl.formatMessage({
              defaultMessage: 'Máte ban',
              description: 'Text chyby',
            }),
            intl.formatMessage({
              defaultMessage: 'Omezený přístup',
              description: 'Titulek chybového dialogu',
            }),
          );
          break;
        case TymberStates.NO_VALID_ID:
        case TymberStates.ACTIVE:
          break;
        default:
          setError(
            intl.formatMessage({
              defaultMessage: 'Nemáte aktivovaný účet',
              description: 'Text chyby',
            }),
            intl.formatMessage({
              defaultMessage: 'Chyba',
              description: 'Titulek chybového dialogu',
            }),
          );
          dispatch(logoutRequest(''));
      }
    },
    [state, setError, dispatch, intl],
  );

  return (
    <Main>
      <MainMenu />
      <Outlet />
    </Main>
  );
};

const AppConnected = withFetchNew(tymberSelector, tymberDataRequest)(
  withLoaderNew(tymberSelector, <Spinner />, true)(
    App,
  ),
);

export { AppConnected as App };
