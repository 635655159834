import { Actions, TymberDataUpdateActions } from '../../actions/tymberData';
import { Reducer } from 'redux';
import { CLEAR_STATE, ClearStateAction } from '../../store';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_SUCCESS } from '../../types/StoreUtils';

export type TymberProfileFormState = DataStoreState;

const initState = initDataStoreState(null);

export const tymberProfileFormReducer: Reducer<TymberProfileFormState, TymberDataUpdateActions | ClearStateAction> =
  (state = initState, action) => {
    switch (action.type) {
      case Actions.TYMBER_DATA_UPDATE_REQUEST:
        return initDataStoreState();
      case Actions.TYMBER_DATA_UPDATE_SUCCESS:
        return initDataStoreState(STATUS_SUCCESS);
      case Actions.TYMBER_DATA_UPDATE_FAILURE:
        return initDataStoreState(STATUS_ERROR, null, action.error);
      // @ts-ignore
      case CLEAR_STATE:
        if (action.state === 'tymberProfileForm') {
          return initState;
        }
      default:
        return state;
    }
  };
