import { RadioProps } from 'informed';
import { useContext } from 'react';
import { RadioGroupContext } from './RadioGroupContext';

export const Radio = ({ label, value, ...props }: RadioProps) => {
  const { radioGroupApi, radioGroupState } = useContext(RadioGroupContext);

  const { setValue, setTouched } = radioGroupApi;

  const { value: groupValue, showError } = radioGroupState;

  return (
    <input
      {...props}
      aria-invalid={showError}
      value={value}
      checked={groupValue === value}
      onChange={e => {
        if (!e.target.checked) {
          return;
        }
        setValue(value, e);
      }}
      onBlur={e => {
        setTouched(true, e);
      }}
      type="radio"
    />
  );
};
