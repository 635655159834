import type { Paginated, Service } from '@feathersjs/feathers';
import { AddressData } from '@tymbe/schema/address.interface';
import { ApplicationExcuseData } from '@tymbe/schema/application-excuse.interface';
import { ApplicationData } from '@tymbe/schema/application.interface';
import { AttendanceData } from '@tymbe/schema/attendance.interface';
import { BranchofficeData } from '@tymbe/schema/branchoffice.interface';
import { CompanyBlockedUserData } from '@tymbe/schema/company-blocked-user.interface';
import { CompanyPersonData } from '@tymbe/schema/company-person.interface';
import { CompanyData } from '@tymbe/schema/company.interface';
import { ContractTemplateData } from '@tymbe/schema/contract-template.interface';
import { CreditTransactionData } from '@tymbe/schema/credit-transaction.interface';
import { DocumentTypeData } from '@tymbe/schema/document-type.interface';
import { LoginData } from '@tymbe/schema/login.interface';
import { ManShiftData } from '@tymbe/schema/man-shift.interface';
import { MonthlyWageData } from '@tymbe/schema/monthly-wage.interface';
import { PaymentAuthorizedData } from '@tymbe/schema/payment-authorized.interface';
import { PaymentRequestData } from '@tymbe/schema/payment-request.interface';
import { PaymentStatusData } from '@tymbe/schema/payment-status.interface';
import { PaymentTransactionData } from '@tymbe/schema/payment-transaction.interface';
import { PerkData } from '@tymbe/schema/perk.interface';
import { PersonAccountStateData } from '@tymbe/schema/person-account-state.interface';
import { PersonBanData } from '@tymbe/schema/person-ban.interface';
import { PersonContactData } from '@tymbe/schema/person-contact.interface';
import { PersonDataData } from '@tymbe/schema/person-data.interface';
import { PersonDocumentFileData } from '@tymbe/schema/person-document-file.interface';
import { PersonDocumentData } from '@tymbe/schema/person-document.interface';
import { PersonLiabilityData } from '@tymbe/schema/person-liability.interface';
import { PersonNoteData } from '@tymbe/schema/person-note.interface';
import { PersonData } from '@tymbe/schema/person.interface';
import { PositionData } from '@tymbe/schema/position.interface';
import { RoleData } from '@tymbe/schema/role.interface';
import { ShiftStatisticsData } from '@tymbe/schema/shift-statistics.interface';
import { ShiftTemplateData } from '@tymbe/schema/shift-template.interface';
import { ShiftData } from '@tymbe/schema/shift.interface';
import { SurveyAnswerData } from '@tymbe/schema/survey-answer.interface';
import { SurveyOptionGroupData } from '@tymbe/schema/survey-option-group.interface';
import { SurveyQuestionOptionData } from '@tymbe/schema/survey-question-option.interface';
import { SurveyQuestionData } from '@tymbe/schema/survey-question.interface';
import { SurveyData } from '@tymbe/schema/survey.interface';
import { VacationData } from '@tymbe/schema/vacation.interface';
import type { RequiredKeys } from 'utility-types';

declare module '@feathersjs/authentication' {
  // type User = Pick<DeepModelObject<Required<Login>>, RequiredKeys<DeepModelObject<Login>> | 'person' | 'role'>;
  type User = Pick<Required<LoginData>, RequiredKeys<LoginData> | 'person' | 'role'>;

  export interface AuthenticationResult {
    // type of result returned by app.authenticate() and app.reAuthenticate()
    user: User;
  }
}

export interface ServiceTypes {
  'address': Service<AddressData>;
  'application': Service<ApplicationData>;
  'application-excuse': Service<ApplicationExcuseData>;
  'attendance': Service<AttendanceData>;
  'branchoffice': Service<BranchofficeData>;
  'company': Service<CompanyData>;
  'company-blocked-user': Service<CompanyBlockedUserData>;
  'company-person': Service<CompanyPersonData>;
  'contract-template': Service<ContractTemplateData>;
  'credit-transaction': Service<CreditTransactionData>;
  'document-type': Service<DocumentTypeData>;
  'login': Service<LoginData>;
  'man-shift': Service<ManShiftData>;
  'monthly-wage': Service<MonthlyWageData>;
  'payment-authorized': Service<PaymentAuthorizedData>;
  'payment-request': Service<PaymentRequestData>;
  'payment-status': Service<PaymentStatusData>;
  'payment-transaction': Service<PaymentTransactionData>;
  'perk': Service<PerkData>;
  'person': Service<PersonData>;
  'person-account-state': Service<PersonAccountStateData>;
  'person-ban': Service<PersonBanData>;
  'person-contact': Service<PersonContactData>;
  'person-data': Service<PersonDataData>;
  'person-document': Service<PersonDocumentData>;
  'person-document-file': Service<PersonDocumentFileData>;
  'person-liability': Service<PersonLiabilityData>;
  'person-note': Service<PersonNoteData>;
  'position': Service<PositionData>;
  'role': Service<RoleData>;
  'shift': Service<ShiftData>;
  'shift-statistics': Service<ShiftStatisticsData>;
  'shift-template': Service<ShiftTemplateData>;
  'survey': Service<SurveyData>;
  'survey-answer': Service<SurveyAnswerData>;
  'survey-option-group': Service<SurveyOptionGroupData>;
  'survey-question': Service<SurveyQuestionData>;
  'survey-question-option': Service<SurveyQuestionOptionData>;
  'vacation': Service<VacationData>;
  [T: string]: Service<any>;
}

export const getResponseArray = <T extends object>(response: T | T[] | Paginated<T> | undefined): T[] =>
  // eslint-disable-next-line no-nested-ternary
  (!response
    ? []
    // eslint-disable-next-line no-nested-ternary
    : (Array.isArray(response)
      ? response
      : Object.hasOwn(response, 'data')
        ? response.data
        : [response]));
