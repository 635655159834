import { MessageFormatElement } from '@formatjs/icu-messageformat-parser/types';
import moment from 'moment';
import * as React from 'react';
import { IntlProvider, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { App } from './App';
import Claimed from './Claimed';
import Finished from './Finished';
import Invitations from './Invitations';
import Login from './Login';
import Payments from './Payments';
import Registration from './Registration';
import Rings from './Rings';
import ForgotPassword from '../components/ForgotPassword';
import { I18n } from '../components/I18n';
import Locker from '../components/Locker';
import ModalError from '../components/Modal/ModalError';
import OnlyActiveTymberRoute from '../components/OnlyActiveTymberRoute';
import OnlyActiveWithIDRoute from '../components/OnlyActiveWithIDRoute';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { ResetPassword } from '../components/ResetPassword';
import RingCancel from '../components/Ring/RingCancel';
import RingDetail from '../components/Ring/RingDetail';
import { H2, H3 } from '../components/Styles/BasicElements';
import { Currency } from '../components/Styles/Currency';
import FrontGlobalStyles from '../components/Styles/FrontGlobalStyles';
import { NoWrap } from '../components/Styles/NoWrap';
import { WingbotChat } from '../components/WingbotChat';
import { StoreState } from '../reducers';

interface RootProps {
  authenticated: boolean;
  locale: string;
  messages: Record<string, MessageFormatElement[]> | null;
  id: number | null;
}

const defaultRichTextElements: IntlShape['defaultRichTextElements'] = {
  strong: (chunks) => <strong>{chunks}</strong>,
  currency: (chunk) => <Currency>{chunk}</Currency>,
  h2: (chunk) => <H2>{chunk}</H2>,
  h3: (chunk) => <H3>{chunk}</H3>,
  p: (chunk) => <p>{chunk}</p>,
  nowrap: (chunk) => <NoWrap>{chunk}</NoWrap>,
  em: (chunk) => <em>{chunk}</em>,
};

export const RootComponent: React.FC<RootProps> = ({ authenticated, locale, messages, id }) => {
  if (!messages) return null;
  const jwt = localStorage.getItem('feathers-react-jwt');
  moment.locale(locale);
  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      defaultLocale="cs"
      defaultRichTextElements={defaultRichTextElements}
    >
      {import.meta.env.CLIENT_WINGBOT_PAGE_ID && !!id && <WingbotChat userId={`${id}`} userJwt={jwt} />}
      <FrontGlobalStyles />
      <I18n />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/registration/:regId" element={<Registration />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route
          path="/"
          element={(
            <ProtectedRoute authenticated={authenticated}>
              <App />
            </ProtectedRoute>
          )}
        >
          <Route index element={<Navigate to="/ring" />} />
          <Route
            path="/ring"
            element={(
              <OnlyActiveTymberRoute>
                <Rings force />
              </OnlyActiveTymberRoute>
            )}
          />
          <Route
            path="/ring/:ringId"
            element={(
              <OnlyActiveWithIDRoute>
                <RingDetail />
              </OnlyActiveWithIDRoute>
            )}
          />
          <Route
            path="/invitation"
            element={(
              <OnlyActiveTymberRoute>
                <Invitations force />
              </OnlyActiveTymberRoute>
            )}
          />
          <Route
            path="/before-fight"
            element={(
              <OnlyActiveTymberRoute>
                <Claimed force />
              </OnlyActiveTymberRoute>
            )}
          />
          <Route
            path="/before-fight/:ringId"
            element={(
              <OnlyActiveTymberRoute>
                <RingCancel />
              </OnlyActiveTymberRoute>
            )}
          />
          <Route path="/finished" element={<Finished force />} />
          <Route path="/payment" element={<Payments />} />
          <Route path="/locker" element={<Locker />} />
          <Route path={'/*'} element={<Navigate to="/ring" />} />
        </Route>
      </Routes>
      <ModalError />
    </IntlProvider>
  );
};

export const Root = connect(
  (state: StoreState) => ({
    locale: state.localization.locale,
    messages: state.localization.messages,
    authenticated: !!state.auth.id,
    id: state.auth.id,
  }),
)(RootComponent);
