import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.vite';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { styled } from '../../styledComponents';

export type PdfFrameProps = {
  url: string;
  className?: string;
};

export const PdfFrame = styled(({ url, className }: PdfFrameProps) => {
  const doc = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [pages, setPages] = useState<number>(0);
  const onLoad = (pdf: PDFDocumentProxy) => {
    setPages(pdf.numPages);
    setTimeout(updateWidth, 1);
  };

  const updateWidth = () => {
    setWidth((doc.current?.childNodes[0] as HTMLDivElement)?.clientWidth);
  };

  useEffect(
    () => {
      window.addEventListener('resize', updateWidth);
      return () => {
        window.removeEventListener('resize', updateWidth);
      };
    },
  );

  const getPages = (w: number) => {
    let i;
    const list = [];
    for (i = 1; i <= pages; i += 1) {
      list.push(<Page pageNumber={i} key={i} width={w} className={'pdf_page'} />);
    }
    return list;
  };

  return (
    <div className={className} ref={doc}>
      <Document file={url} onLoadSuccess={onLoad}>
        {getPages(width)}
      </Document>
    </div>
  );
})`
  height: 100%;
  background-color: #555;
  padding: 1rem;
  flex-grow: 1;
  overflow: auto;
  & .pdf_page + .pdf_page {
    margin-top: 0.5rem;
  }
`;
