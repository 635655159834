import { getSurveyRequest } from '../actions/survey';
import { Error } from '../components/Error';
import Spinner from '../components/Spinner';
import { Survey } from '../components/Survey';
import withError from '../components/withError';
import withFetch from '../components/withFetch';
import withLoading from '../components/withLoading';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { dataSelector, StoreState } from '../reducers';
import { dataSurveysSelector } from '../reducers/data';
import { surveySelector } from '../reducers/data/surveys';
import { compose } from 'redux';
import { Survey as SurveyData } from '../types/Tymbe';

export type SurveyProps = {
  surveyId: SurveyData['id'];
};

const selectSurveyData = compose(dataSurveysSelector, dataSelector);

const SurveyConnect = connect(
  (state: StoreState, { surveyId }: SurveyProps) => surveySelector(selectSurveyData(state), surveyId),
  (dispatch, { surveyId }) => ({
    fetch: () => dispatch(getSurveyRequest(surveyId)),
  }),
);

export default SurveyConnect(
  withFetch(
    withLoading(Spinner)(
      withError(Error)(
        withMapData((data: SurveyData) => ({ ...data }))(
          Survey,
        ),
      ),
    ),
  ),
);
