import { lengthOfVacationShift } from '@tymbe/schema/constants';
import { VacationData, VacationSlot } from '@tymbe/schema/vacation.interface';
import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import { CardSubHeader } from './Card/CardSubHeader';
import { Button } from './Forms/Button';
import { RingToggleIcon } from './Ring';
import { H2, H3 } from './Styles/BasicElements';
import { HoursBar } from './Styles/HoursBar';
import { Icon } from './Styles/Icon';
import { Row } from './Styles/Row';
import { Time } from './Styles/Time';
import { WellBox } from './Styles/WellBox';
import { Toggleable } from './Toggleable';
import CompanyLogo from '../containers/CompanyLogo';
import api from '../services/api';

interface VactionProps {
  vacation: VacationData;
}

const Vacation = ({ vacation: vacationData }: VactionProps) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [claimShiftButtonDisabled, setClaimShiftButtonDisabled] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<VacationSlot>(vacationData.slots[0]);
  const navigation = useNavigate();

  function getFormattedTime(slot: VacationSlot) {
    const startTime = moment(slot.start_time);
    const endTime = moment(slot.end_time);
    const startHour = startTime.format('HH:mm');
    const endHour = endTime.format('HH:mm');
    const date = endTime.format('DD.MM. YYYY');

    return `${startHour} - ${endHour} ${date}`;
  }

  const { mutateAsync: vacationClaim } = useMutation(
    ['vacation-claim'],
    async ({ slot, vacation }: { slot: VacationSlot, vacation: VacationData }) => api.claimVacation(slot, vacation),
    {
      onError: () => {
        // todo nice alert
        // eslint-disable-next-line no-alert
        alert('Nepodařilo se přihlásit se na dovolenou');
        setClaimShiftButtonDisabled(false);
      },
      onSuccess: () => {
        navigation('/before-fight');
      },
    },
  );

  async function claimVacation(vacation: VacationData) {
    setClaimShiftButtonDisabled(true);
    await vacationClaim({ slot: selectedSlot, vacation });
  }

  function selectSlot(event: React.ChangeEvent<HTMLSelectElement>, vacation: VacationData) {
    const selectedStartTime = event.target.value;
    const newSlot = vacation.slots.find((slot) => slot.start_time === selectedStartTime);
    if (newSlot) {
      setSelectedSlot(newSlot);
    } else {
      // eslint-disable-next-line no-alert
      alert('Nepodařilo se vybrat tento termín');
    }
  }

  return (
    <div className="max-w-[450px] min-w-[255px]">
      <CardHeader className="flex bg-[#28367b]" onClick={() => setExpanded(!expanded)}>
        <RingToggleIcon toggled={expanded} />
        <H2>
          {intl.formatMessage({
            defaultMessage: 'Dovolená',
            description: 'Nadpis kartičky pro výběr dovolené',
          })}
        </H2>
      </CardHeader>
      <CardSubHeader className="flex flex-row [&>*]:grow [&>*:last-child]:grow-0">
        <HoursBar hours={lengthOfVacationShift} />
        <Time>
          <Icon id="clock" color="black" />
          {intl.formatMessage(
            {
              defaultMessage: '{worked_hours} h',
              description: 'Délka dovolenkové směny',
            },
            { worked_hours: lengthOfVacationShift },
          )}
        </Time>
      </CardSubHeader>
      <div className="flex flex-col p-[2rem] relative bg-[#f7f7f7]">
        <Row alignItems="center">
          <CompanyLogo
            path={vacationData.company.logo}
            alt={vacationData.company.name}
          />
          <span>{vacationData.company.display_name ?? vacationData.company.name}</span>
        </Row>

        <div className="self-center">
          <div className="clearFix" style={{ margin: '.1rem .5rem' }}>
            <strong style={{ float: 'left' }}>
              {intl.formatMessage(
                {
                  defaultMessage: '{money_shift} Kč/směna',
                  description: 'Výdělek za směnu. Napr: "585 Kč/směna"',
                },
                {
                  money_shift: intl.formatNumber(Number(selectedSlot.payment_base) * lengthOfVacationShift, {
                    style: 'decimal',
                  }),
                },
              )}
            </strong>
          </div>
        </div>

        <div className="text-center">
          <WellBox inline color="gray3" onClick={() => setExpanded(!expanded)}>
            {intl.formatMessage(
              {
                defaultMessage: '<currency>{money_hour}</currency> Kč/h*',
                description: 'Vydělek za hodinu. Např: "58,50 Kč/h"',
              },
              {
                money_hour: intl.formatNumber(Number(selectedSlot.payment_base), {
                  style: 'decimal',
                }),
              },
            )}
          </WellBox>
          <div className="text-xs mt-1">
            {intl.formatMessage({
              defaultMessage: '* hodinová sazba není finální a může se lišit na základě vybraného datumu',
              description: 'Poznámka k dovolenkovým směnám',
            })}
          </div>
        </div>

        <Toggleable toggled={expanded}>
          <hr className="!mt-2" />
          <H3>
            {intl.formatMessage({
              defaultMessage: 'Výběr datumu',
              description: 'Výběr datumu dovolenkové směny',
            })}
          </H3>
          <div>
            <select className="border rounded-md px-3" onChange={(event) => selectSlot(event, vacationData)}>
              {vacationData.slots.map((slot) => (
                <option key={slot.start_time} value={slot.start_time}>
                  {getFormattedTime(slot)}
                </option>
              ))}
            </select>
          </div>
          <hr />
          <div>
            <Button
              disabled={claimShiftButtonDisabled}
              onClick={() => claimVacation(vacationData)}
              data-cy="acceptVacationButton"
            >
              {intl.formatMessage({
                defaultMessage: 'Přijímám',
                description: 'Tlačítko pro přijetí dovolené',
              })}
            </Button>
          </div>
        </Toggleable>
      </div>
      <CardFooter className="mb-[2rem]" />
    </div>
  );
};

export default Vacation;
