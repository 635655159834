import { getRegistrationRequest, saveRegistrationRequest } from '../actions/registration';
import { CardContent } from '../components/Card/CardContent';
import { CardFooter } from '../components/Card/CardFooter';
import { CardHeaderCentered } from '../components/Card/CardHeader';
import { ErrorCard } from '../components/Error';
import { Button } from '../components/Forms/Button';
import { FormItemGroup } from '../components/Forms/FormItemGroup';
import { FormRowItems } from '../components/Forms/FormRowItems';
import { Input } from '../components/Forms/Input';
import { LabeledInput, LabeledInputClassic } from '../components/Forms/LabeledInput';
import { validateDate, validateRequired } from '../components/Forms/validations';
import FrontWrapper from '../components/FrontWrapper';
import { intl } from '../components/I18n';
import { LogoHorizontal } from '../components/Logo';
import Spinner from '../components/Spinner';
import { H1 } from '../components/Styles/BasicElements';
import RightFirst from '../components/Styles/RightFirst';
import withFetch from '../components/withFetch';
import withLoading from '../components/withLoading';
import withMapData from '../components/withMapData';
import RegistrationResult from './RegistrationResult';
import idCardImg from '../images/new_cz_op.png';
import idCardSkImg from '../images/new_sk_op.png';
import idCardOldImg from '../images/old_cz_op.png';
import { Form, FormProps, FormState, InformedProps } from 'informed';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { registrationSelector } from '../reducers/components';
import { css, styled } from '../styledComponents';
import { ApiError, Genders, RegistrationData, RegistrationDataRequest } from '../types/Tymbe';
import { Diff } from 'utility-types';
import { isValidBankAccount } from '@tymbe/bank-account';
import { useParams } from 'react-router-dom';

const BankAccountNum = styled(LabeledInput)`
  width: 100%;
`;
const BankNum = styled(LabeledInput)`
  flex-shrink: 10;
  input {
    width: 15%;
    min-width: 6.2em;
  }
`;

type RegistrationIdBankAccountProps = InformedProps<FormProps>;
const validateRequiredDate = (value?: unknown) =>
  validateRequired(intl.formatMessage({
    defaultMessage: 'Vyplňte prosím platnost občanského průkazu',
    description: 'Chyba formulářového pole registrace',
  }))(value) ||
  validateDate(
    'DD.MM.YYYY',
    intl.formatMessage({
      defaultMessage: 'Zadejte datum ve formatu DD.MM.RRRR',
      description: 'Chyba formulářového pole registrace',
    }))(value as string);

const validateRequiredBankNumber = (value?: unknown) =>
  validateRequired(intl.formatMessage({
    defaultMessage: 'Vyplňte prosím číslo účtu',
    description: 'Chyba formulářového pole registrace',
  }))(value) ||
  isValidBankAccount(value)
    ? undefined
    : intl.formatMessage({
      defaultMessage: 'Toto není platné číslo účtu',
      description: 'Chyba formulářového pole registrace',
    });

const validateRequiredBankId = (value?: unknown) =>
  validateRequired(intl.formatMessage({
    defaultMessage: 'Vyplňte prosím číslo banky',
    description: 'Chyba formulářového pole registrace',
  }))(value) ||
  ((value as string).length !== 4
    ? intl.formatMessage({
      defaultMessage: 'Neplatný kód banky',
      description: 'Chyba formulářového pole registrace',
    })
    : undefined);

const RegistrationIDBankAccount: React.FC<RegistrationIdBankAccountProps> = props => (
  <Form {...props}>
    <LabeledInputClassic
      label={intl.formatMessage({
        defaultMessage: 'Jméno a příjmení',
        description: 'Název formulářového pole registrace',
      })}
      id={'name'}
      value={`${props.initialValues?.sureName} ${props.initialValues?.familyName}`}
      disabled
    />
    <LabeledInputClassic
      label={intl.formatMessage({
        defaultMessage: 'Číslo občanského průkazu',
        description: 'Název formulářového pole registrace',
      })}
      value={props.initialValues?.idCardNumber as string}
      id={'idCardNumber'}
      disabled
    />
    <FormItemGroup>

      <LabeledInput
        id={'idExpirationDate'}
        name={'idExpirationDate'}
        label={intl.formatMessage({
          defaultMessage: 'Platnost občanského průkazu ',
          description: 'Název formulářového pole registrace',
        })}
        validate={validateRequiredDate}
        validateOn="blur"
      />
      <FormRowItems>
        <BankAccountNum
          label={intl.formatMessage({
            defaultMessage: 'Číslo bankovního účtu',
            description: 'Název formulářového pole registrace',
          })}
          id={'bankAccountNumber'}
          name={'bankAccountNumber'}
          validate={validateRequiredBankNumber}
          validateOn="blur"
        />
        <BankNum
          label={intl.formatMessage({
            defaultMessage: 'Kód banky',
            description: 'Název formulářového pole registrace',
          })}
          id={'bankNumber'}
          name={'bankNumber'}
          validate={validateRequiredBankId}
          validateOn="blur"
        />
      </FormRowItems>
      <LabeledInput
        id={'paymentNote'}
        name={'paymentNote'}
        label={intl.formatMessage({
          defaultMessage: 'Volitelná poznámka k platbě',
          description: 'Název formulářového pole registrace',
        })}
      />
    </FormItemGroup>
    <RightFirst><Button type={'submit'}>{intl.formatMessage({
      defaultMessage: 'Další',
      description: 'Tlačítko pro pokračování na další část registrace',
    })}</Button></RightFirst>
  </Form>
);

type IDMRZCodeCheckProps = InformedProps<FormProps & {
  onBack: () => void;
  data: RegistrationData
}>;

const MrzCheckForm = styled.div`
  position: relative;
  margin: auto;
  overflow: visible;
`;

const MrzCodeWrapper = styled.svg`
  position: absolute;
  bottom: 5%;
  left: 20%;
  right: 5%;
  font-family: Inconsolata, monospace;
  font-size: 17.8px;
  line-height: 1.3;
  overflow: visible;
`;

const MrzInput = styled(Input)`
  font-size: inherit;
  margin: 0 0 0 -2px;
  padding: 0;
  width: calc(1ch + 3px);
  height: 1.2em;
  border: 1px solid red !important;
  -moz-appearance: textfield;
  font-family: inherit;
  text-align: center;
  line-height: inherit;
  margin: 0 -1px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const LogoCss = css`
  width: 14rem;
  margin-bottom: 2rem;
`;

const numberOnly = (value: string) => Number(value) === Number(value) ? `${Number(value)}` : '';

type MrzCodeProps = {
  idCardNumber: string;
  idExpirationDate: string;
  birthDate: string;
  familyName: string;
  sureName: string;
  gender: number;
};
const MrzCode = ({ idCardNumber, idExpirationDate, birthDate, familyName, sureName, gender }: MrzCodeProps) => {
  const lineLength = 30;
  const sureNameNorm = sureName
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove diacritics
    .toUpperCase().replace(' ', '<');
  const familyNameNorm = familyName
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .toUpperCase().replace(' ', '<');
  const genderChar = gender === Genders.MAN ? 'M' : 'F';

  const firstLine = [
    `IDCZE${idCardNumber.padEnd(9, '<')}`, // 14
  ];
  firstLine.push(''.padEnd(lineLength - firstLine.reduce((n, val) => n + val.length + 1, 0), '<'));

  const secondLine = [
    `${moment(birthDate).format('YYMMDD')}`,
    `${genderChar}${moment(idExpirationDate, 'DD.MM.YYYY', true).format('YYMMDD')}`,
    'CZE',
  ];
  secondLine.push(''.padEnd(lineLength - secondLine.reduce((n, val) => n + val.length + 1, 0), '<'));

  const thirdLine = `${familyNameNorm}>>${sureNameNorm}`.padEnd(lineLength, '<').substr(0, lineLength);

  return (
    <MrzCodeWrapper viewBox={'0 0 300 69'}>
      <foreignObject width={'100%'} height={'100%'} letterSpacing={1}>
        {firstLine[0]}
        <MrzInput id={'idNumberCheck'} name={'idNumberCheck'} maxLength={1} mask={numberOnly} required />
        {firstLine[1]}<br />

        {secondLine[0]}
        <MrzInput id={'birthDateCheck'} name={'birthDateCheck'} maxLength={1} mask={numberOnly} required />
        {secondLine[1]}
        <MrzInput id={'expiryDateCheck'} name={'expiryDateCheck'} maxLength={1} mask={numberOnly} required />
        {secondLine[2]}
        {secondLine[3]}
        <MrzInput id={'finalCheck'} name={'finalCheck'} maxLength={1} mask={numberOnly} required /><br />

        {thirdLine}
      </foreignObject>
    </MrzCodeWrapper>
  );
};

const MrzCodeOld = ({ idCardNumber, idExpirationDate, birthDate, familyName, sureName, gender }: MrzCodeProps) => {
  const lineLength = 36;
  const sureNameNorm = sureName
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove diacritics
    .toUpperCase().replace(' ', '<');
  const familyNameNorm = familyName
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .toUpperCase().replace(' ', '<');
  const genderChar = gender === Genders.MAN ? 'M' : 'F';
  const firstLine = `IDCZE${familyNameNorm}<<${sureNameNorm}`
    .padEnd(lineLength, '<') // fill rest of line with `<`
    .substring(0, lineLength); // truncate to max line length
  const secondLine = [
    `${idCardNumber.substring(0, 9)}`,
    `CZE${moment(birthDate).format('YYMMDD')}`,
    `${genderChar}${moment(idExpirationDate, 'DD.MM.YYYY', true).format('YYMMDD')}`,
  ];
  secondLine.push(''.padEnd(lineLength - 1 - secondLine.reduce((n, val) => n + val.length + 1, 0), '<'));

  return (
    <MrzCodeWrapper viewBox={'0 0 360 46'}>
      <foreignObject width={'100%'} height={'100%'} letterSpacing={1}>
        {firstLine}<br />
        {secondLine[0]}
        <MrzInput id={'idNumberCheck'} name={'idNumberCheck'} maxLength={1} mask={numberOnly} required />
        {secondLine[1]}
        <MrzInput id={'birthDateCheck'} name={'birthDateCheck'} maxLength={1} mask={numberOnly} required />
        {secondLine[2]}
        <MrzInput id={'expiryDateCheck'} name={'expiryDateCheck'} maxLength={1} mask={numberOnly} required />
        {secondLine[3]}
        <MrzInput id={'finalCheck'} name={'finalCheck'} maxLength={1} mask={numberOnly} required />
      </foreignObject>
    </MrzCodeWrapper>
  );
};

const MrzCodeSVK = ({ idCardNumber, idExpirationDate, birthDate, familyName, sureName, gender }: MrzCodeProps) => {
  const lineLength = 30;
  const sureNameNorm = sureName
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove diacritics
    .toUpperCase().replace(' ', '<');
  const familyNameNorm = familyName
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .toUpperCase().replace(' ', '<');
  const genderChar = gender === Genders.MAN ? 'M' : 'F';
  let birthCode = moment(birthDate).format('YYMMDD');
  if (gender === Genders.WOMAN) {
    const int = birthCode.split('');
    int.splice(2, 1, birthCode[2] + 5);
    birthCode = int.join('');
  }
  const firstLine = [
    `IDSVK${idCardNumber.padEnd(9, '<')}`, // 14
    `${birthCode.padEnd(11, '?')}`, // 11
  ];
  firstLine.push(''.padEnd(lineLength + 1 - firstLine.reduce((n, val) => n + val.length + 1, 0), '<'));

  const secondLine = [
    `${moment(birthDate).format('YYMMDD')}`,
    `${genderChar}${moment(idExpirationDate, 'DD.MM.YYYY', true).format('YYMMDD')}`,
    'SVK',
  ];
  secondLine.push(''.padEnd(lineLength - secondLine.reduce((n, val) => n + val.length + 1, 0), '<'));

  const thirdLine = `${familyNameNorm}>>${sureNameNorm}`.padEnd(lineLength, '<').substr(0, lineLength);

  return (
    <MrzCodeWrapper viewBox={'0 0 300 69'}>
      <foreignObject width={'100%'} height={'100%'} letterSpacing={1}>
        {firstLine[0]}
        <MrzInput id={'idNumberCheck'} name={'idNumberCheck'} maxLength={1} mask={numberOnly} required />
        {firstLine[1]}
        {firstLine[2]}

        <br />

        {secondLine[0]}
        <MrzInput id={'birthDateCheck'} name={'birthDateCheck'} maxLength={1} mask={numberOnly} required />
        {secondLine[1]}
        <MrzInput id={'expiryDateCheck'} name={'expiryDateCheck'} maxLength={1} mask={numberOnly} required />
        {secondLine[2]}
        {secondLine[3]}
        <MrzInput id={'finalCheck'} name={'finalCheck'} maxLength={1} mask={numberOnly} required />

        <br />

        {thirdLine}
      </foreignObject>
    </MrzCodeWrapper>
  );
};

const IDMRZCodeCheck: React.FC<IDMRZCodeCheckProps> = ({ onBack, data, ...props }) => {
  const oldID = Number(data.idExpirationDate.split('.')[2]) <= 2021;
  const isSK = data.country === 'SK';
  return (
    <Form {...props}>
      <p>{intl.formatMessage(
        {
          defaultMessage: `Toto je poslední krok, vezměte si občanský průkaz
            a doplňte z něj čísla do políček {sample}.`,
          description: 'Úvodní text posledniho kroku registrace. {sample} je nahrazeno příkladem políčka',
        },
        { sample: <MrzInput name={''} disabled /> },
      )}</p>
      <MrzCheckForm>
        <img
          src={isSK ? idCardSkImg : oldID ? idCardOldImg : idCardImg}
          alt={intl.formatMessage({
            defaultMessage: 'Občanský průkaz',
            description: 'Alt text pro obrázek vzorového občanského průkazu',
          })}
          width={'100%'}
        />
        {isSK
          ? <MrzCodeSVK
            idCardNumber={data.idCardNumber}
            idExpirationDate={data.idExpirationDate}
            birthDate={data.birthDate}
            familyName={data.familyName}
            sureName={data.sureName}
            gender={data.gender}
          />
          : oldID
            ? <MrzCodeOld
              idCardNumber={data.idCardNumber}
              idExpirationDate={data.idExpirationDate}
              birthDate={data.birthDate}
              familyName={data.familyName}
              sureName={data.sureName}
              gender={data.gender}
            />
            : <MrzCode
              idCardNumber={data.idCardNumber}
              idExpirationDate={data.idExpirationDate}
              birthDate={data.birthDate}
              familyName={data.familyName}
              sureName={data.sureName}
              gender={data.gender}
            />
        }
      </MrzCheckForm>
      <br />
      <RightFirst>
      <Button onClick={onBack}>{intl.formatMessage({
        defaultMessage: 'Zpět',
        description: 'Tlačítko pro návrat ne předchozí krok registrace',
      })}</Button>
      <Button type={'submit'}>{intl.formatMessage({
        defaultMessage: 'Odeslat',
        description: 'Tlačítko pro uložení a dokončení registrace.',
      })}</Button>
      </RightFirst>
    </Form>
  );
};

export type RegistrationProps = {
  regData: RegistrationData;
  error?: ApiError;
  finishRegistration: (regData: RegistrationData, mrzCheckSum: Diff<RegistrationDataRequest, RegistrationData>) => void;
};

const Registration: React.FC<RegistrationProps> = ({
  regData,
  error,
  finishRegistration,
}) => {
  const [regFormState, changeRegFormState] = React.useState({
    ...regData,
    idExpirationDate: regData.idExpirationDate ? moment(regData.idExpirationDate).format('DD.MM.YYYY') : '',
  });

  const [page, changePage] = React.useState(1);
  const prevPage = () => changePage(page > 1 ? page - 1 : page);

  const handleRegIdBankAcc = (formState: FormState) => {
    const data = formState.values as typeof regFormState;
    changeRegFormState({ ...regFormState, ...data });
    changePage(2);
  };
  const handleMrzCodeCheck = (formState: FormState) => {
    const data = formState.values as Diff<RegistrationDataRequest, RegistrationData>;
    const formData = {
      ...regFormState,
      idExpirationDate: moment(regFormState.idExpirationDate, 'DD.MM.YYYY', true).toISOString(),
    };
    finishRegistration(formData, data);
    changePage(3);
  };

  const getFormPage = () => {
    switch (page) {
      default:
      case 1:
        return <RegistrationIDBankAccount initialValues={regFormState} onSubmit={handleRegIdBankAcc} />;
      case 2:
        return <IDMRZCodeCheck onSubmit={handleMrzCodeCheck} onBack={prevPage} data={regFormState} />;
      case 3:
        return <RegistrationResult />;
    }
  };

  if (page !== 3 && error) {
    return (
      <FrontWrapper style={{ maxWidth: 600 }}>
        <LogoHorizontal css={LogoCss} />
        <ErrorCard color={'danger'}>
          <CardHeaderCentered><H1>{intl.formatMessage({
            defaultMessage: 'Registrace neexistuje',
            description: 'Titulek okna chyby',
          })}</H1></CardHeaderCentered>
          <CardContent>
            {error.message}
          </CardContent>
          <CardFooter style={{ textAlign: 'right' }}>
            <Button as={'a'} href={'https://tymbe.cz/pro-brigadniky/registrace'}>{intl.formatMessage({
              defaultMessage: 'Registrovat',
              description: 'Odkaz vedouci na stranku s registrací.',
            })}</Button>
          </CardFooter>
        </ErrorCard>
      </FrontWrapper>
    );
  }

  return (
    <FrontWrapper style={{ maxWidth: 600 }}>
      <LogoHorizontal css={LogoCss} />
      {getFormPage()}
    </FrontWrapper>
  );
};

const RegistrationCont = connect(
  (state: StoreState) => ({
    ...registrationSelector(componentsSelector(state)).data,
  }),
  (dispatch, ownProps:{ regId: string }) => ({
    fetch: () => dispatch(getRegistrationRequest(ownProps.regId)),
    finishRegistration: (regData: RegistrationData, mrzChecksums: Diff<RegistrationDataRequest, RegistrationData>) =>
      dispatch(saveRegistrationRequest(ownProps.regId, { ...regData, ...mrzChecksums })),
  }),
)(
  withFetch(
    withLoading(Spinner)(
      withMapData((data: RegistrationData, _, error) => ({ regData: data, error }))(Registration),
    ),
  ),
);

export default <P,>(props: Omit<P, 'regId'>) => {
  const { regId = '' } = useParams();
  return <RegistrationCont {...props} regId={regId} />;
}
