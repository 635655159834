import { styled } from '../../styledComponents';

const RightFirst = styled.div`
  display: flex;
  justify-content: space-between;
  & > *:only-child {
    margin-left: auto;
  }
`;

export default RightFirst;
