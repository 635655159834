import { action } from './utils';
import { Action } from 'redux';
import { ApiError, CompanyRef } from '../types/Tymbe';

export enum Actions {
  GET_PAYROLLS_REQUEST = 'GET_PAYROLLS_REQUEST',
  GET_PAYROLLS_SUCCESS = 'GET_PAYROLLS_SUCCESS',
  GET_PAYROLLS_FAILURE = 'GET_PAYROLLS_FAILURE',
  DOWNLOAD_PAYROLL = 'DOWNLOAD_PAYROLL',
}

export interface GetPayrollsRequest extends Action<Actions.GET_PAYROLLS_REQUEST> {
  month: number;
  year: number;
}

export interface GetPayrollsSuccess extends Action<Actions.GET_PAYROLLS_SUCCESS> {
  payrolls: CompanyRef[];
}

export interface GetPayrollsFailure extends Action<Actions.GET_PAYROLLS_FAILURE> {
  error: ApiError;
}

export interface DownloadPayrollAction extends Action<Actions.DOWNLOAD_PAYROLL> {
  companyId: CompanyRef['id'];
  oz?: number | null;
  month: number;
  year: number;
}

export type GetPayrollsActions = GetPayrollsRequest | GetPayrollsSuccess | GetPayrollsFailure;

export const getPayrollsRequest = (month: number, year: number): GetPayrollsRequest =>
  action(Actions.GET_PAYROLLS_REQUEST, { month, year });
export const getPayrollsSuccess = (payrolls: CompanyRef[]): GetPayrollsSuccess =>
  action(Actions.GET_PAYROLLS_SUCCESS, { payrolls });
export const getPayrollsFailure = (error: ApiError): GetPayrollsFailure =>
  action(Actions.GET_PAYROLLS_FAILURE, { error });

export const downloadPayroll =
  (companyId: CompanyRef['id'], month: number, year: number, oz?: number | null): DownloadPayrollAction =>
    action(Actions.DOWNLOAD_PAYROLL, { companyId, month, year, oz });
