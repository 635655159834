import React from 'react';
import { styled, ThemedStyledComponent } from '../../styledComponents';

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 5000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 1rem;
`;

const ModalContainer: ThemedStyledComponent<'div'> = styled.div`
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  min-height: 100%;
  min-width: 100%;
`;

const ModalBackdrop: ThemedStyledComponent<'div'> = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;

const ModalContent: ThemedStyledComponent<'div'> = styled.div`
  z-index: 1001;
`;

export type InnerModalProps = {
  onBackdrop?: () => void;
};

const InnerModal: React.FC<InnerModalProps> = ({ onBackdrop, children }) => (
  <ModalWrapper>
    <ModalContainer>
      <ModalBackdrop onClick={() => onBackdrop && onBackdrop()} />
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  </ModalWrapper>
);

export default InnerModal;
