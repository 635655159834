import { getLocalizationRequest } from '../actions/localization';
import MenuIcon from './Menu/MenuIcon';
import MenuItem from './Menu/MenuItem';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

const LocaleSwitcher: React.FC = ({ ...props }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const switchLang = () => {
    if (intl.locale === 'cs') {
      dispatch(getLocalizationRequest('uk'));
    } else {
      dispatch(getLocalizationRequest('cs'));
    }

    return true;
  };

  return (
    <MenuItem as={'a'} onMouseUp={switchLang} {...props}>
      {
        intl.locale === 'cs'
          ? <><MenuIcon id={'uk'} />українська</>
          : <><MenuIcon id={'cz'} />česky</>}
    </MenuItem>
  );
};

export default LocaleSwitcher;
