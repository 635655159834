import { Button } from './Forms/Button';
import Modal from './Modal';
import { H3 } from './Styles/BasicElements';
import Survey from '../containers/Survey';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '../styledComponents';
import { ApiError, Credit, SurveyAnswer } from '../types/Tymbe';
import { PasswordModal } from './Modal/PasswordModal';

const Wage = styled.div`
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Heading = styled(H3)`
  color: ${({ theme }) => theme.colors.steel};
  margin-bottom: 1rem;
`;

const Left = styled.div`
  float: left;
`;

const Right = styled.div`
  float: right;
`;

export type CreditItemProps = {
  credit: Credit;
  disabled?: boolean;
  error: ApiError;
  payoutCredits: (creditId: Credit['id'], survey: SurveyAnswer, password: string) => void;
  loading: boolean;
};

export const CreditItem = ({ credit, disabled, payoutCredits, error, loading }: CreditItemProps) => {
  const intl = useIntl();
  const [survey, setSurvey] = React.useState(false);
  const [payout, setPayout] = React.useState(false);

  const showSurvey = () => setSurvey(true);
  const hideSurvey = () => setSurvey(false);
  const showPayout = () => setPayout(true);
  const hidePayout = () => setPayout(false);

  const handleSurvey = (surveyData: SurveyAnswer, password: string) => {
    payoutCredits(credit.id, surveyData, password);
  };

  return (
    <Wage>
      <Heading>{credit.company.id
        ? intl.formatMessage(
          {
            defaultMessage: '{company_name} ({worked_hours, number, ::.# unit/hour})',
            description: 'Název firma s počtem odpracovaných hodin včetně přesčasu, např: "Zásilkovna a.s. (7,5h)"',
          },
          {
            company_name: credit.company.name,
            worked_hours: (credit.workedHours + Number(credit.overTime)).toFixed(2),
          },
        )
        : intl.formatMessage(
          {
            defaultMessage: '{company_name}',
            description: 'Název firma, např: "Zásilkovna a.s."',
          },
          { company_name: credit.company.name },
        )}</Heading>
      {!credit.company.id ? null : <div className={'clearFix'}>
        <div>{intl.formatMessage(
          {
            defaultMessage: '<strong>Datum:</strong> {date, date, ::ddMMyyyy}',
            description: 'Datum začátku směny, např: "Datum: 20. 11. 2021"',
          },
          { date: moment(credit.time.start) },
        )}</div>
        <Left>
          <div>{intl.formatMessage(
            {
              defaultMessage: '<strong>Od:</strong> {start, time, ::HHm}',
              description: 'Čas začátku směny, např: "Od: 06:00"',
            },
            { start: moment(credit.time.start) },
          )}</div>
          <div>{intl.formatMessage(
            {
              defaultMessage: '<strong>Do:</strong> {end, time, short}',
              description: 'Čas konce směny, např: "Do: 14:00"',
            },
            { end: moment(credit.time.end) },
          )}</div>
        </Left>
        <Right>
          <div>{intl.formatMessage(
            {
              defaultMessage: '<strong>Přesčas:</strong> {overtime, number, ::.# unit/hour}',
              description: 'Odpracovaný přesčas, např: "Přesčas: 0,5 h"',
            },
            { overtime: credit.overTime },
          )}</div>
          <div>{intl.formatMessage(
            {
              defaultMessage: '<strong>Kredity:</strong> {credits, number, ::.##}',
              description: 'Kreditová odměna, např: "Kredity: 58,5"',
            },
            { credits: credit.credits },
          )}</div>
        </Right>
      </div>}
      <div style={{ textAlign: 'right', marginTop: '1rem' }}>
        { credit.company.id
          ? <Button onClick={showSurvey}>
            {intl.formatMessage(
              {
                defaultMessage: `Vyplnit dotazník za {credits, number, ::.##} {credits, plural,
                  one {kredit}
                  few {kredity}
                  many {kreditů}
                  other {kreditů}
                }`,
                description: 'Text tlačítka zobrazující dotazník, např: "Vyplnit dotazník za 115,65 kreditů"',
              },
              { credits: credit.credits },
            )}
          </Button>
          : <Button disabled={disabled} onClick={showPayout}>
            {intl.formatMessage(
              {
                defaultMessage: `Vyplatit {credits, number, ::.##} {credits, plural,
                  one {kredit}
                  few {kredity}
                  many {kreditů}
                  other {kreditů}
                }`,
                description: 'Text tlačítka pro vyplacení zůstatku kreditů, např: "Vyplatit 115,65 kreditů"',
              },
              { credits: credit.credits },
            )}
          </Button>
        }
      </div>
      <Modal open={survey}>
        <Survey
          surveyId={credit.pollId}
          disabled={loading}
          showError={error?.message}
          onSubmit={handleSurvey}
          onCancel={hideSurvey}
        />
      </Modal>
      <PasswordModal
        onSubmit={(password) => handleSurvey({ id: 0, answers: {} }, password)}
        onCancel={hidePayout}
        disable={false}
        open={payout}
      />
    </Wage>
  );
};
