import { FormItem } from './FormItem';
import { styled } from '../../styledComponents';

export const FormRowItems = styled.div<{ $wrap?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: inherit;
  flex-wrap: ${({ $wrap }) => $wrap && 'wrap'};

  ${FormItem} {
    margin-right: 1rem;
    flex-grow: 1;
  }
`;
