import { FieldProps, useField } from 'informed';
import moment from 'moment';
import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import ReactCalendar, { CalendarProps } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { useIntl } from 'react-intl';

import { FormItem } from './FormItem';
import { Error, Input } from './LabeledInput';
import { validateDate } from './validations';
import { styled } from '../../styledComponents';
import theme from '../../themes/default';
import { Icon } from '../Styles/Icon';

const Calendar = styled(ReactCalendar)`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 10;
  .react-calendar__tile--now {
    background: ${theme.colors.gray5};
  }
.react-calendar__tile--active {
  background: ${theme.colors.primary};
  color: white;
}
`;

const CalendarIcon = styled(Icon).attrs({ id: 'calendar', size: '2rem', color: 'secondary' })`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

type DatePickerOwnProps = {
  label: string;
  id: string;
  formatDate?: string;
  style?: React.CSSProperties;
  className?: string;
};
export type DatePickerProps = DatePickerOwnProps
& Omit<InputHTMLAttributes<HTMLInputElement>, keyof DatePickerOwnProps>
& Omit<CalendarProps, keyof DatePickerOwnProps>;
const DatePicker = ({ formatDate, ...props }: FieldProps<DatePickerProps>) => {
  const { fieldApi, fieldState, ref, userProps, informed } = useField<DatePickerProps, string>({
    ...props,
    type: 'text',
    validate: (value, values) => dateValidation(value) || props.validate?.(value, values),
    validateOn: props.validateOn ?? 'change',
  });
  const { value, touched } = fieldState;
  const { setValue, setTouched, validate, setError } = fieldApi;
  const { onFocus, onBlur, onChange } = informed;
  const { className, style, id, label, defaultValue, ...rest } = userProps;

  const [calendar, changeCalendar] = React.useState(false);
  const containerRef = React.createRef<HTMLDivElement>();
  const intl = useIntl();

  React.useEffect(
    () => {
      if (calendar) {
        document.addEventListener('mousedown', hideCalendar);
      } else {
        document.removeEventListener('mousedown', hideCalendar);
      }
      return () => {
        document.removeEventListener('mousedown', hideCalendar);
      };
    },
    [calendar, containerRef],
  );

  const hideCalendar = (e?: MouseEvent) => {
    if (e && containerRef.current && containerRef.current.contains(e.target as Node)) {
      return;
    }
    changeCalendar(false);
  };

  const showCalendar = () => {
    changeCalendar(true);
  };

  const getCalendarValue = (val: string): Date | undefined => {
    let date;
    try {
      date = moment(val, formatDate || 'YYYY-MM-DD').toDate();
      return !isNaN(date.getTime()) ? date : undefined;
    } catch (e) {
      return undefined;
    }
  };

  const reformatDate = (val: string) => {
    if (!val) {
      setError(undefined);
      return undefined;
    }
    const date = moment(val, formatDate || 'YYYY-MM-DD');
    if (!date.isValid()) {
      return val;
    }
    return date.format(formatDate || 'YYYY-MM-DD');
  };

  const dateValidation = (value: unknown): unknown | undefined => {
    if (value !== undefined) {
      return validateDate(
        formatDate || 'YYYY-MM-DD',
        intl.formatMessage(
          {
            defaultMessage: 'Neplatné datum.\nOčekávaný formát:\n{formatDate}',
            description: 'Chyba při zadání chybného formátu data',
          },
          { formatDate: formatDate || 'YYYY-MM-DD' },
        ),
      )(value as string);
    }
    return undefined;
  };

  const escapePressed = (e: React.KeyboardEvent) => {
    e.keyCode === 27 && hideCalendar();
  };

  const tabPressed = (e: React.KeyboardEvent) => {
    e.keyCode === 9 && hideCalendar();
  };

  return (
    <FormItem className={className} ref={containerRef} style={style} onKeyUp={escapePressed} onKeyDown={tabPressed}>
      <Input
        {...rest}
        id={id}
        ref={ref}
        value={(value as string) ?? ''}
        placeholder={label}
        onClick={showCalendar}
        onChange={(e) => {
          setValue(e.target.value);
          touched && validate();
          onChange && onChange(e);
        }}
        onFocus={(e) => {
          e.target.select();
          onFocus && onFocus(e);
        }}
        onBlur={(e) => {
          setTouched(true);
          setValue(reformatDate(e.target.value));
          onBlur && onBlur(e);
        }}
      />
      <CalendarIcon onClick={showCalendar} />
      {fieldState.showError && fieldState.error && <Error style={{ whiteSpace: 'pre' }}>{fieldState.error as React.ReactNode}</Error>}
      {calendar && (
        <Calendar
          {...rest}
          locale={intl.locale}
          // tabIndex={-1}
          onChange={(date: Value) => {
            hideCalendar();
            setError(undefined);
            setValue(moment(date as Date).format(formatDate || 'YYYY-MM-DD'));
          }}
          value={getCalendarValue(value as string)}
        />
      )}
    </FormItem>
  );
};

export default DatePicker;
