import { Form, FormState, Select, Option } from 'informed';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';

import { Card } from './Card';
import { CardColor } from './Card/CardColor';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import { Button } from './Forms/Button';
import { FormItem } from './Forms/FormItem';
import { FormRowItems } from './Forms/FormRowItems';
import { PayrollItem } from './PayrollItem';
import Spinner from './Spinner';
import { H2, H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import { CompanyRef } from '../types/Tymbe';

export type DocumentListProps = {
  getPayrolls: (month: number, year: number) => void;
  downloadPayroll: (companyId: CompanyRef['id'], year: number, mont: number, oz: CompanyRef['oz']) => void;
  payrolls: CompanyRef[];
  title: string;
  loading: boolean;
} & GetProps<typeof Card>;

type DocumentListForm = {
  monthYear: string;
};

export const PayrollList: React.FC<DocumentListProps> =
  ({ getPayrolls, payrolls, downloadPayroll, title, loading, ...p }) => {
    const intl = useIntl();
    const [date, setDate] = React.useState({ month: 0, year: 0 });

    const dateToDisplayFrom = moment([2024, 0, 1]);

    const monthYears = [];
    for (let index = moment().diff(dateToDisplayFrom, 'months'); index >= 0; index -= 1) {
      const indexedDate = moment().subtract(index, 'months');
      monthYears.push(
        {
          value: indexedDate.format('M YYYY').toString(),
          label: intl.locale === 'cs'
            ? indexedDate.format('D MMMM YYYY').toString()
              .substring(2)
            : indexedDate.format('MMMM YYYY').toString(),
        },
      );
    }
    const onSubmit = (formState: FormState) => {
      const { monthYear } = formState.values as DocumentListForm;
      const splitMonthYear = monthYear.split(' ');
      setDate({ month: Number(splitMonthYear[0]), year: Number(splitMonthYear[1]) });
      getPayrolls(Number(splitMonthYear[0]), Number(splitMonthYear[1]));
    };
    return (
      <Card {...p}>
        {loading && <Spinner position="absolute" />}
        <CardHeader>
          <Icon style={{ float: 'right' }} id="money" color="white" size="2em" />
          <H2>{title}</H2>
        </CardHeader>
        <CardContent>
          <CardColor as={H3}>{intl.formatMessage({
            defaultMessage: 'Vybrat období:',
            description: 'Uvození formulaře pro výběr obdobi (měsíc/rok)',
          })}
          </CardColor>
          <p>{intl.formatMessage({
            defaultMessage: `Tady najdeš pouze výplatní lístky z DPP, 
            výplatní lístky z DPČ najdeš vždy zpětně kolem 20. dne v měsíci na svém e-mailu.`,
            description: 'Informace v sekci výplatními lístky pro DPP',
          })}
          </p>
          <Form onSubmit={onSubmit}>
            <FormRowItems $wrap>
              <FormItem>
                <Select name="monthYear" initialValue={monthYears[monthYears.length - 1].value}>
                  {monthYears
                    .map((item) => <Option key={item.label} value={item.value}>{item.label}</Option>)}
                </Select>
              </FormItem>
              <Button type="submit">{intl.formatMessage({
                defaultMessage: 'Zobrazit',
                description: 'Text tlačítka pro potvrzení vybraného období',
              })}
              </Button>
            </FormRowItems>
          </Form>
          {payrolls.map((payroll) => (
            <PayrollItem
              key={payroll.id}
              name={payroll.name}
              onClick={() => downloadPayroll(payroll.id, date.month, date.year, payroll.oz)}
            />
          ))}
        </CardContent>
        <CardFooter />
      </Card>
    );
  };
