import { MoreFinishedRequestAction, moreFinishedFailure, moreFinishedSuccess } from '../actions/finished';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { Actions, getFinishedFailure, GetFinishedRequestAction, getFinishedSuccess } from '../actions/finished';
import { AxiosResponse } from 'axios';
import { StoreState, componentsSelector } from '../reducers';
import { finishedSelector } from '../reducers/components';
import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, Ring } from '../types/Tymbe';

export function* fetchFinished(action: GetFinishedRequestAction): SagaIterator {
  const { filters } = action;
  try {
    const rings: AxiosResponse<Ring[]> = yield call(api.getFinished, filters);
    yield put(getFinishedSuccess(rings.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getFinishedFailure(error));
    }
  }
}

export function* fetchMoreFinished(action: MoreFinishedRequestAction): SagaIterator {
  const { filters } = action;
  const finishedIds = yield select((state: StoreState) => finishedSelector(componentsSelector(state)));
  const from = finishedIds?.data?.length || 0;
  try {
    const rings: AxiosResponse<Ring[]> = yield call(api.getFinished, { ...filters, $skip: from });
    yield put(moreFinishedSuccess(rings.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(moreFinishedFailure(error));
    }
  }
}

export function* watchGetFinishedRequest() {
  yield takeLatest(Actions.GET_FINISHED_REQUEST, fetchFinished);
}

export function* watchMoreFinishedRequest() {
  yield takeLatest(Actions.MORE_FINISHED_REQUEST, fetchMoreFinished);
}
