import { Actions, ModalErrorActions } from '../../actions/modalError';
import { ReactNode } from 'react';
import { Reducer } from 'redux';

type ModalError = {
  message: ReactNode;
  title?: string;
  onClose?: () => void;
};

export type ModalErrorState = ModalError[];

const initialState: ModalErrorState = [];

export const modalErrorReducer: Reducer<ModalErrorState, ModalErrorActions> =
  (state = initialState, action) => {
    switch (action.type) {
      case Actions.ADD_MODAL_ERROR:
        return state.concat({ message: action.message, title: action.title, onClose: action.onClose });
      case Actions.CLEAR_MODAL_ERROR:
        const newState = [...state];
        newState.splice(action.id);
        return newState;
      default:
        return state;
    }
  };
