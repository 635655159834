import { tymberDataRequest } from '../actions/tymberData';
import Spinner from './Spinner';
import { withFetchNew } from './withFetch';
import { withLoaderNew } from './withLoading';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { tymberSelector } from '../reducers';
import { TymberStates } from '../types/Tymbe';

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const tymber = useSelector(tymberSelector).data;
  return tymber?.state === TymberStates.ACTIVE || tymber?.state === TymberStates.NO_VALID_ID ?
    children :
    <Navigate to={'/locker'} />;
};

export default withFetchNew(tymberSelector, tymberDataRequest)(
  withLoaderNew(tymberSelector, <Spinner />)(
    ProtectedRoute,
  ),
);
