import InnerModal from './innerModal';
import * as React from 'react';
import ReactDOM from 'react-dom';

export type ModalProps = {
  open?: boolean;
  onBackdrop?: () => void;
};

const Modal: React.FC<ModalProps> = ({ open, ...props }) =>
  open ? ReactDOM.createPortal(<InnerModal {...props} />, document.querySelector('#modal')!) : null;

export default Modal;
