import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Tymber } from '../types/Tymbe';

export enum Actions {
  TYMBER_DATA_REQUEST = 'TYMBER_DATA_REQUEST',
  TYMBER_DATA_SUCCESS = 'TYMBER_DATA_SUCCESS',
  TYMBER_DATA_FAILURE = 'TYMBER_DATA_FAILURE',
  TYMBER_DATA_UPDATE_REQUEST = 'TYMBER_DATA_UPDATE_REQUEST',
  TYMBER_DATA_UPDATE_SUCCESS = 'TYMBER_DATA_UPDATE_SUCCESS',
  TYMBER_DATA_UPDATE_FAILURE = 'TYMBER_DATA_UPDATE_FAILURE',
}

export interface TymberDataRequestAction extends Action<Actions.TYMBER_DATA_REQUEST> {
}

export interface TymberDataSuccessAction extends Action<Actions.TYMBER_DATA_SUCCESS> {
  tymber: Tymber;
}

export interface TymberDataFailureAction extends Action<Actions.TYMBER_DATA_FAILURE> {
  error: ApiError;
}

export interface TymberDataUpdateRequestAction extends Action<Actions.TYMBER_DATA_UPDATE_REQUEST> {
  data: Partial<Tymber>;
  password?: string;
}

export interface TymberDataUpdateSuccessAction extends Action<Actions.TYMBER_DATA_UPDATE_SUCCESS> {
  tymber: Tymber;
}

export interface TymberDataUpdateFailureAction extends Action<Actions.TYMBER_DATA_UPDATE_FAILURE> {
  error: ApiError;
}

export type TymberDataActions =
  TymberDataRequestAction
  | TymberDataSuccessAction
  | TymberDataFailureAction;

export type TymberDataUpdateActions =
  TymberDataUpdateRequestAction
  | TymberDataUpdateSuccessAction
  | TymberDataUpdateFailureAction;

export const tymberDataRequest = (): TymberDataRequestAction =>
  action(Actions.TYMBER_DATA_REQUEST);

export const tymberDataSuccess = (tymber: Tymber): TymberDataSuccessAction =>
  action(Actions.TYMBER_DATA_SUCCESS, { tymber });

export const tymberDataFailure = (error: ApiError): TymberDataFailureAction =>
  action(Actions.TYMBER_DATA_FAILURE, { error });

export const tymberDataUpdateRequest = (data: Partial<Tymber>, password?: string): TymberDataUpdateRequestAction =>
  action(Actions.TYMBER_DATA_UPDATE_REQUEST, { data, password });
export const tymberDataUpdateSuccess = (tymber: Tymber): TymberDataUpdateSuccessAction =>
  action(Actions.TYMBER_DATA_UPDATE_SUCCESS, { tymber });
export const tymberDataUpdateFailure = (error: ApiError): TymberDataUpdateFailureAction =>
  action(Actions.TYMBER_DATA_UPDATE_FAILURE, { error });
