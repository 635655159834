import RingsGrid from './Styles/RingsGrid';
import Credits from '../containers/Credits';
import WageDetailsList from '../containers/WageDetailsList';
import Wages from '../containers/Wages';
import { useIntl } from 'react-intl';
import { Tymber } from '../types/Tymbe';
import Spinner from './Spinner';
import PayoutStatus from '../containers/PayoutStatus';

export type PaymentsProps = {
  tymber: Tymber;
  loading: boolean,
}

export const Payments = ({ tymber, loading }: PaymentsProps) => {
  const intl = useIntl();

  return (
    <RingsGrid>
      {loading ? <Spinner /> : <Wages />}
      <Credits />
      <WageDetailsList
        title={intl.formatMessage({
          defaultMessage: 'Detail výplat',
          description: 'Název sekce',
        })}
        color={'gold'}
      />
      <PayoutStatus />
    </RingsGrid>
  );
};
