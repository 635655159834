import { FieldPropsP, Input as Text } from 'informed';
import { ComponentType, InputHTMLAttributes } from 'react';
import { css, styled } from '../../styledComponents';

export const inputCss = css`
  font-size: 1.5rem;
  padding: .5em;
  border-radius: 0.3rem;
  border: 2px solid ${({ theme }) => theme.colors.gray3};
  background-color: inherit;
  color: ${({ theme }) => theme.bodyColor};
  transition: all .3s;

  @media (max-width: 490px) {
    font-size: 1rem;
  }

  &:focus {
    outline: none;
    //border-bottom-color: ${({ theme }) => theme.colors.primary};
    //box-shadow: inset 0 0 5px 1px ${({ theme }) => theme.colors.primary};
  }
`;

type TextT = ComponentType<FieldPropsP<InputHTMLAttributes<HTMLInputElement>>>;

export const Input = styled<TextT>(Text as TextT)`
  ${inputCss};
`;
