import { action } from './utils';
import { Action } from 'redux';
import { ApiError } from '../types/Tymbe';
import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';
import { SalaryLimitType } from '@tymbe/schema/enums';

export enum Actions {
  PERSON_SALARY_LIMIT_REQUEST = 'PERSON_SALARY_LIMIT_REQUEST',
  PERSON_SALARY_LIMIT_SUCCESS = 'PERSON_SALARY_LIMIT_SUCCESS',
  PERSON_SALARY_LIMIT_FAILURE = 'PERSON_SALARY_LIMIT_FAILURE',
  PERSON_SALARY_LIMIT_UPDATE_REQUEST = 'PERSON_SALARY_LIMIT_UPDATE_REQUEST',
  PERSON_SALARY_LIMIT_UPDATE_SUCCESS = 'PERSON_SALARY_LIMIT_UPDATE_SUCCESS',
  PERSON_SALARY_LIMIT_UPDATE_FAILURE = 'PERSON_SALARY_LIMIT_UPDATE_FAILURE',
}

export interface PersonSalaryLimitRequestAction extends Action<Actions.PERSON_SALARY_LIMIT_REQUEST> {}

export interface PersonSalaryLimitSuccessAction extends Action<Actions.PERSON_SALARY_LIMIT_SUCCESS> {
  data: PersonSalaryLimitData;
}

export interface PersonSalaryLimitFailureAction extends Action<Actions.PERSON_SALARY_LIMIT_FAILURE> {
  error: ApiError;
}

export interface PersonSalaryLimitUpdateRequestAction extends Action<Actions.PERSON_SALARY_LIMIT_UPDATE_REQUEST> {
  limit: SalaryLimitType;
}

export interface PersonSalaryLimitUpdateSuccessAction extends Action<Actions.PERSON_SALARY_LIMIT_UPDATE_SUCCESS> {
  data: PersonSalaryLimitData;
}

export interface PersonSalaryLimitUpdateFailureAction extends Action<Actions.PERSON_SALARY_LIMIT_UPDATE_FAILURE> {
  error: ApiError;
}

export type PersonSalaryLimitGetActions =
  PersonSalaryLimitRequestAction
  | PersonSalaryLimitSuccessAction
  | PersonSalaryLimitFailureAction;

export type PersonSalaryLimitUpdateActions =
  PersonSalaryLimitUpdateRequestAction
  | PersonSalaryLimitUpdateSuccessAction
  | PersonSalaryLimitUpdateFailureAction;

export type PersonSalaryLimitActions =
  PersonSalaryLimitGetActions
  | PersonSalaryLimitUpdateActions;

export const personSalaryLimitRequest = (): PersonSalaryLimitRequestAction =>
  action(Actions.PERSON_SALARY_LIMIT_REQUEST);
export const personSalaryLimitSuccess = (data: PersonSalaryLimitData) =>
  action(Actions.PERSON_SALARY_LIMIT_SUCCESS, { data });
export const personSalaryLimitFailure = (error: ApiError) =>
  action(Actions.PERSON_SALARY_LIMIT_FAILURE, { error });

export const personSalaryLimitUpdateRequest = (limit: SalaryLimitType): PersonSalaryLimitUpdateRequestAction =>
  action(Actions.PERSON_SALARY_LIMIT_UPDATE_REQUEST, { limit });
export const personSalaryLimitUpdateSuccess = (data: PersonSalaryLimitData): PersonSalaryLimitUpdateSuccessAction =>
  action(Actions.PERSON_SALARY_LIMIT_UPDATE_SUCCESS, { data });
export const personSalaryLimitUpdateFailure = (error: ApiError): PersonSalaryLimitUpdateFailureAction =>
  action(Actions.PERSON_SALARY_LIMIT_UPDATE_FAILURE, { error });
