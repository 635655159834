import { Link } from 'react-router-dom';
import { styled } from '../../styledComponents';

const A = styled(Link)`
  &, &:visited {
    color: ${({ theme }) => theme.buttons.primary};
    text-decoration: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
`;

export default A;
