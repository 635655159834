import { MessageFormatElement } from '@formatjs/icu-messageformat-parser/types';
import { Actions, LocalizationActions } from '../actions/localization';
import { Reducer } from 'redux';
import { DataStatus, STATUS_PENDING, STATUS_SUCCESS } from '../types/StoreUtils';

export type LocalizationState = {
  locale: string;
  messages: Record<string, MessageFormatElement[]> | null;
  status: DataStatus;
};

const initialState: LocalizationState = {
  locale: '',
  messages: null,
  status: null,
};

export const localizationReducer: Reducer<LocalizationState, LocalizationActions> =
  (state = initialState, action) => {
    switch (action.type) {
      case Actions.GET_LOCALIZATION_REQUEST:
        return { ...state, status: STATUS_PENDING, messages: { ...state.messages }, locale: action.locale };
      case Actions.GET_LOCALIZATION_SUCCESS:
        return { ...state, status: STATUS_SUCCESS, messages: { ...action.messages } };
      default:
        return state;
    }
  };
