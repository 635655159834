import { styled, ThemedStyledComponent } from '../../styledComponents';

const RingsGrid: ThemedStyledComponent<'div'> = styled.div`
  display: grid;
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray3};
  grid-template-columns: repeat(auto-fill, minMax(260px, 1fr));
  grid-auto-rows: max-content;
  grid-gap: 3rem 5rem;
  padding: 3rem 4rem;
  align-items: start;
  flex-grow: 1;

  @media (max-width: 418px) {
    padding: 3rem 4rem 3rem 1rem;
  }
`;

export default RingsGrid;
