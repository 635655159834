import {
  MORE_RINGS_REQUEST, MORE_RINGS_SUCCESS,
  MoreRingsActions,
  RINGS_FAILURE,
  RINGS_REQUEST,
  RINGS_SUCCESS,
  RingsActions,
} from '../../actions/ring';
import {
  DataStoreState,
  initDataStoreState,
  STATUS_ERROR,
  STATUS_PENDING,
  STATUS_SUCCESS,
} from '../../types/StoreUtils';
import { Ring } from '../../types/Tymbe';

export type RingsState = DataStoreState<Ring['id'][]>;

const initialState: RingsState = initDataStoreState(null);

// eslint-disable-next-line @typescript-eslint/default-param-last
export const ringsReducer = (state: RingsState = initialState, action: RingsActions | MoreRingsActions): RingsState => {
  switch (action.type) {
    case RINGS_REQUEST:
      return { ...state, status: STATUS_PENDING };
    case RINGS_SUCCESS:
      return { ...initialState, status: STATUS_SUCCESS, data: action.rings.map((ring) => ring.id) };
    case RINGS_FAILURE:
      return { ...initialState, status: STATUS_ERROR, error: action.error };
    case MORE_RINGS_REQUEST:
      return { ...state, status: STATUS_PENDING };
    case MORE_RINGS_SUCCESS:
      return {
        ...initialState,
        status: STATUS_SUCCESS,
        data: [
          ...state.data!,
          ...action.rings.filter((ring) => !state.data?.includes(ring.id)).map((ring) => ring.id),
        ],
      };
    default:
      return state;
  }
};
