import React, { MouseEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from './Forms/Button';
import RingsGridWithFilter from './Ring/RingsGridWithFilter';
import Spinner from './Spinner';
import { H2 } from './Styles/BasicElements';
import Ring from '../containers/Ring';
import api from '../services/api';
import { transformShiftFilter } from '../services/api/transformations';
import { styled } from '../styledComponents';
import { Ring as RingData, Tymber } from '../types/Tymbe';
import { hasAllPerksFulfilled, hasAllUtilsFulfilled } from '../utils';

export interface RingsProps {
  rings: RingData['id'][];
  filterRings: (filters: object) => void;
  moreRings: (filters?: object) => void;
  tymber: Tymber;
  loading: boolean;
}

const LoadMore = styled(Button)`
  grid-column: 1 / -1;
`;

export const RingList: React.FC<RingsProps> = ({ rings, moreRings, filterRings, tymber, loading }) => {
  const intl = useIntl();
  const navigator = useNavigate();
  const fight = (ringId: number) => () => navigator(`/ring/${ringId}`);
  const [filters, setFilters] = useState<object>();
  const [numberOfRings, setNumberOfRings] = useState<number | undefined>();

  const { mutateAsync: getNumberOfRings } = useMutation(
    ['getNumberOfRings'],
    async (params?: object) =>
      api.getNumberOfRings(params),
  );

  const applyFilter = (f: object) => {
    setFilters(f);
    filterRings(f);
  };
  const loadMoreRings = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.currentTarget.blur();
    moreRings(filters);
  };

  useEffect(() => {
    const getRings = async () => {
      const { data } = await getNumberOfRings(filters);
      setNumberOfRings(data);
    };

    getRings();
  }, [filters]);

  return (
    <>
      {/* <RingEmptyPopup /> */}
      <RingsGridWithFilter onFilter={(filter) => applyFilter(transformShiftFilter(filter))}>
        {loading && <Spinner />}
        {rings.map((ringId) => (
          <Ring key={ringId} id={ringId} data-cy={`shiftItem${ringId}`}>
            {(ring: RingData) => !ring.claimed
              && hasAllPerksFulfilled(tymber, ring)
              && hasAllUtilsFulfilled(tymber, ring)
              && (
                <div style={{ textAlign: 'center' }}>
                  <Button onClick={fight(ringId)} data-cy="selectShiftButton">
                    {intl.formatMessage({
                      defaultMessage: 'Přihlásit se',
                      description: 'Tlačítko pro přihlášení na brigádu',
                    })}
                  </Button>
                </div>
              )}
          </Ring>
        ))}
        {!!numberOfRings && rings.length !== numberOfRings && (
          <LoadMore onClick={loadMoreRings}>
            {intl.formatMessage({
              defaultMessage: 'Načíst další',
              description: 'Tlačítko pro načtení dalších nabídek brigád',
            })}
          </LoadMore>
        )}
        {!rings.length && (
          <H2 style={{ textAlign: 'center' }}>
            {intl.formatMessage({
              defaultMessage: 'Nenalezeny žádné ringy.',
              description: 'Pokud je seznam nabídek brigád prázdný',
            })}
          </H2>
        )}
      </RingsGridWithFilter>
    </>
  );
};
