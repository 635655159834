import React from 'react';
import { keyframes, styled } from '../../styledComponents';

const SidePanel = styled.div<SidePanelProps>`
  position: fixed;
  ${({ position }) => position === 'left' ? 'left: 0' : 'right: 0'};
  transition: all .5s;
  top: 100px;
  bottom: 0;
  transform: translateX(${({ position }) => position === 'left' ? '-100%' : '100%'});
  max-width: 80%;

  &.open {
    transform: translateX(0);
  }
`;

export type SidePanelProps = {
  open?: boolean;
  position?: 'left' | 'right';
  children: React.ReactNode;
  className?: string;
  onOverlayClick?: () => void;
};

const fadeIn = keyframes`
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,.7);
  }
`;

const fadeOut = keyframes`
  from {
    background-color: rgba(0,0,0,.7);
  }
  to {
    background-color: rgba(0,0,0,0);
  }
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  animation: ${fadeOut} .5s both;
  background-color: rgba(0,0,0,0);

  &.show {
    background-color: rgba(0,0,0,.7);
    animation: ${fadeIn} .5s both;
  }
`;

const SidePanelComponent =
  ({ className, open, position = 'left', children, onOverlayClick }: SidePanelProps) => {
    const [overlay, setOverlay] = React.useState(open);
    React.useEffect(
      () => {
        open && setOverlay(true);
      },
      [open],
    );
    const onAnimationEnd = () => setOverlay(open);

    return (
      <>
        {overlay && <Overlay className={open ? 'show z-[1]' : undefined} onClick={onOverlayClick} />}
        <SidePanel className={`${className} ${open && 'open'}`} position={position} onTransitionEnd={onAnimationEnd}>
          {children}
        </SidePanel>
      </>
    );
  };

export { SidePanelComponent as SidePanel };
