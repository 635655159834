import { VacationData } from '@tymbe/schema/vacation.interface';
import { Reducer } from 'redux';

import { Actions, VacationActions } from '../../actions/vacations';
import {
  DataStoreState,
  initDataStoreState,
  STATUS_ERROR,
  STATUS_PENDING,
  STATUS_SUCCESS,
} from '../../types/StoreUtils';
export type VacationsState = DataStoreState<VacationData[]>;

const initState = initDataStoreState<VacationData[]>(null);

export const vacationsReducer: Reducer<VacationsState, VacationActions> =
  // eslint-disable-next-line @typescript-eslint/default-param-last
  (state = initState, action) => {
    switch (action.type) {
      case Actions.VACATION_REQUEST:
        return initDataStoreState<VacationData[]>(STATUS_PENDING, state.data);
      case Actions.VACATION_SUCCESS:
        return initDataStoreState<VacationData[]>(STATUS_SUCCESS, action.vacation);
      case Actions.VACATION_FAILURE:
        return initDataStoreState<VacationData[]>(STATUS_ERROR, null, action.error);
      default:
        return state;
    }
  };
