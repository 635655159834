import { Action } from 'redux';
import {
  Actions,
  personSalaryLimitSuccess,
  personSalaryLimitFailure,
  personSalaryLimitUpdateSuccess,
  personSalaryLimitUpdateFailure,
  PersonSalaryLimitUpdateRequestAction,
} from '../actions/personSalaryLimit';
import { ApiError } from '../types/Tymbe';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { handleApiError } from './utils';
import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';
import { SagaIterator } from 'redux-saga';
import api from '../services/api';
import { AxiosResponse } from 'axios';

export function* fetchPersonSalaryLimit(action: Action): SagaIterator {
  try {
    const response: AxiosResponse<PersonSalaryLimitData> = yield call(api.getPersonSalaryLimit);
    yield put(personSalaryLimitSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(personSalaryLimitFailure(error));
    }
  }
}

export function* updatePersonSalaryLimit(action: PersonSalaryLimitUpdateRequestAction): SagaIterator {
  const { limit } = action;
  try {
    const response: AxiosResponse<PersonSalaryLimitData> = yield call(api.savePersonSalaryLimit, limit);
    yield put(personSalaryLimitUpdateSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(personSalaryLimitUpdateFailure(error));
    }
  }
}


export function* watchPersonSalaryLimitRequest() {
  yield takeLatest(Actions.PERSON_SALARY_LIMIT_REQUEST, fetchPersonSalaryLimit);
}

export function* watchPersonSalaryLimitUpdateRequest() {
  yield takeLatest(Actions.PERSON_SALARY_LIMIT_UPDATE_REQUEST, updatePersonSalaryLimit);
}
