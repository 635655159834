import { signDocumentReducer, SignDocumentState } from '../../components/Ring/RingDocuments/signDocument.reducer';
import { addTymberDocumentReducer, AddTymberDocumentState } from './addTymberDocument';
import { claimedReducer, ClaimedState } from './claimed';
import { contractPreviewReducer, ContractPreviewState } from './contractPreview';
import { creditsReducer, CreditsState } from './credits';
import { finishedReducer, FinishedState } from './finished';
import { invitationsReducer, InvitationsState } from './invitations';
import { liabilitiesReducer, LiabilitiesState } from './liabilities';
import { mainContractsReducer, MainContractsState } from './mainContracts';
import { otherContractsReducer, OtherContractsState } from './otherContracts';
import { payrollsReducer, PayrollsState } from './payrolls';
import { registrationReducer, RegistrationState } from './registration';
import { RingDocumentsState, ringDocumetsReducer } from './ringDocuments';
import { ringsReducer, RingsState } from './rings';
import { tymberDocumentsReducer, TymberDocumentsState } from './tymberDocuments';
import { tymberProfileFormReducer, TymberProfileFormState } from './tymberProfileForm';
import { tymberUtilitiesReducer, TymberUtilitiesState } from './tymberUtilities';
import { wagesReducer, WagesState } from './wages';
import { combineReducers } from 'redux';
import { personSalaryLimitModalReducer, PersonSalaryLimitModalState } from './personSalaryLimitModal';

export type ComponentsState = {
  rings: RingsState,
  ringDocuments: RingDocumentsState,
  registration: RegistrationState,
  invitations: InvitationsState,
  claimed: ClaimedState,
  finished: FinishedState,
  mainContracts: MainContractsState,
  otherContracts: OtherContractsState,
  payrolls: PayrollsState,
  tymberDocuments: TymberDocumentsState,
  addTymberDocument: AddTymberDocumentState,
  tymberUtilities: TymberUtilitiesState,
  tymberProfileForm: TymberProfileFormState,
  personSalaryLimitModal: PersonSalaryLimitModalState,
  wages: WagesState,
  credits: CreditsState,
  liabilities: LiabilitiesState,
  contractPreview: ContractPreviewState,
  signDocument: SignDocumentState,
};

export const ringsSelector = (state: ComponentsState) => state.rings;
export const ringDocumentsSelector = (state: ComponentsState) => state.ringDocuments;
export const registrationSelector = (state: ComponentsState) => state.registration;
export const invitationsSelector = (state: ComponentsState) => state.invitations;
export const claimedSelector = (state: ComponentsState) => state.claimed;
export const finishedSelector = (state: ComponentsState) => state.finished;
export const mainContractsSelector = (state: ComponentsState) => state.mainContracts;
export const otherContractsSelector = (state: ComponentsState) => state.otherContracts;
export const payrollsSelector = (state: ComponentsState) => state.payrolls;
export const tymberDocumentsSelector = (state: ComponentsState) => state.tymberDocuments;
export const addTymberDocumentSelector = (state: ComponentsState) => state.addTymberDocument;
export const tymberUtilitiesSelector = (state: ComponentsState) => state.tymberUtilities;
export const tymberProfileFormSelector = (state: ComponentsState) => state.tymberProfileForm;
export const personSalaryLimitModalSelector = (state: ComponentsState) => state.personSalaryLimitModal;
export const wagesSelector = (state: ComponentsState) => state.wages;
export const creditsSelector = (state: ComponentsState) => state.credits;
export const liabilitiesSelector = (state: ComponentsState) => state.liabilities;
export const contractPreviewSelector = (state: ComponentsState) => state.contractPreview;
export const signDocumentSelector = (state: ComponentsState) => state.signDocument;

export const componentsReducer = combineReducers<ComponentsState>(
  {
    rings: ringsReducer,
    ringDocuments: ringDocumetsReducer,
    registration: registrationReducer,
    invitations: invitationsReducer,
    claimed: claimedReducer,
    finished: finishedReducer,
    mainContracts: mainContractsReducer,
    otherContracts: otherContractsReducer,
    payrolls: payrollsReducer,
    tymberDocuments: tymberDocumentsReducer,
    addTymberDocument: addTymberDocumentReducer,
    tymberUtilities: tymberUtilitiesReducer,
    tymberProfileForm: tymberProfileFormReducer,
    personSalaryLimitModal: personSalaryLimitModalReducer,
    wages: wagesReducer,
    credits: creditsReducer,
    liabilities: liabilitiesReducer,
    contractPreview: contractPreviewReducer,
    signDocument: signDocumentReducer,
  },
);
