import { Icon } from './Icon';
import { styled } from '../../styledComponents';

export const Time = styled.span`
  display: flex;
  & ${Icon} {
    display: block;
    content: "";
    height: 1.1em;
    width: 1.1em;
    align-self: center;
    margin-right: .2em;
  }
`;
