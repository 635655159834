import { contractPreviewRequest } from '../../../actions/contractPreview';
import { Error } from '../../Error';
import { PdfFrame } from '../PdfFrame';
import Spinner from '../../Spinner';
import withError from '../../withError';
import withFetch from '../../withFetch';
import withLoading from '../../withLoading';
import withMapData from '../../withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../../../reducers';
import { contractPreviewSelector } from '../../../reducers/components';
import { compose } from 'redux';
import { Ring, RingDocument } from '../../../types/Tymbe';

const selectContractPreview = compose(contractPreviewSelector, componentsSelector);

export type RingDocumentProps = {
  ringId: Ring['id'],
  documentId: RingDocument['id'],
  tymbe: boolean;
};

const fetchConnect = connect(
  (state: StoreState) => ({
    ...selectContractPreview(state),
    force: true,
  }),
  (dispatch, { ringId, documentId, tymbe }: RingDocumentProps) => ({
    fetch: () => dispatch(contractPreviewRequest(ringId, documentId, tymbe)),
  }),
);

export default fetchConnect(
  withFetch(
    withLoading(Spinner)(
      withError(Error)(
        withMapData((data: { url: string }) => ({ url: data.url }))(
          PdfFrame,
        ),
      ),
    ),
  ),
);
