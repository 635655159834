import { Actions, SaveTymberDocumentActions } from '../../actions/tymberDocuments';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_SUCCESS } from '../../types/StoreUtils';
import { TymberDocument } from '../../types/Tymbe';

type DataType = TymberDocument;
export type AddTymberDocumentState = DataStoreState<DataType>;

const initState: AddTymberDocumentState = initDataStoreState<DataType>(null);

export const addTymberDocumentReducer: Reducer<AddTymberDocumentState, SaveTymberDocumentActions> =
  (state = initState, action) => {
    switch (action.type) {
      case Actions.SAVE_TYMBER_DOCUMENT_REQUEST:
        return initDataStoreState<DataType>();
      case Actions.SAVE_TYMBER_DOCUMENT_FAILURE:
        return initDataStoreState<DataType>(STATUS_ERROR, { ...state.data! }, action.error);
      case Actions.SAVE_TYMBER_DOCUMENT_SUCCESS:
        return initDataStoreState<DataType>(STATUS_SUCCESS, action.document);
      default:
        return state;
    }
  };
