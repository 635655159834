import {
  GET_REGISTRATION_FAILURE,
  GET_REGISTRATION_REQUEST,
  GET_REGISTRATION_SUCCESS,
  GetRegistrationActions,
  SAVE_REGISTRATION_FAILURE,
  SAVE_REGISTRATION_REQUEST,
  SAVE_REGISTRATION_SUCCESS,
  SaveRegistrationActions,
} from '../../actions/registration';
import { combineReducers } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_SUCCESS } from '../../types/StoreUtils';
import { RegistrationData } from '../../types/Tymbe';

export type RegistrationDataState = DataStoreState<RegistrationData>;
export type RegistrationResultState = DataStoreState<{ userIsActive: boolean }>;

export type RegistrationState = {
  data: RegistrationDataState;
  result: RegistrationResultState;
};

const initialDataState: RegistrationDataState = initDataStoreState(null);
const initialResultState: RegistrationResultState = initDataStoreState(null);

const dataReducer =
  (state: RegistrationDataState = initialDataState, action: GetRegistrationActions): RegistrationDataState => {
    switch (action.type) {
      case GET_REGISTRATION_REQUEST:
        return { ...initialDataState };
      case GET_REGISTRATION_SUCCESS:
        return { ...initialDataState, status: STATUS_SUCCESS, data: action.regData };
      case GET_REGISTRATION_FAILURE:
        return { ...initialDataState, status: STATUS_ERROR, data: null, error: action.error };
      default:
        return state;
    }
  };

const resultReducer =
  (state: RegistrationResultState = initialResultState, action: SaveRegistrationActions): RegistrationResultState => {
    switch (action.type) {
      case SAVE_REGISTRATION_REQUEST:
        return { ...initialResultState };
      case SAVE_REGISTRATION_SUCCESS:
        return { ...initialResultState, status: STATUS_SUCCESS, data: { userIsActive: action.userIsActive } };
      case SAVE_REGISTRATION_FAILURE:
        return { ...initialResultState, status: STATUS_ERROR, data: null, error: action.error };
      default:
        return state;
    }
  };

export const registrationReducer = combineReducers<RegistrationState>(
  {
    data: dataReducer,
    result: resultReducer,
  },
);
