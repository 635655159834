import { action } from './utils';
import { Action } from 'redux';
import { ApiError, RegistrationData, RegistrationDataRequest } from '../types/Tymbe';

export type GET_REGISTRATION_REQUEST = 'GET_REGISTRATION_REQUEST';
export const GET_REGISTRATION_REQUEST: GET_REGISTRATION_REQUEST = 'GET_REGISTRATION_REQUEST';
export type GET_REGISTRATION_SUCCESS = 'GET_REGISTRATION_SUCCESS';
export const GET_REGISTRATION_SUCCESS: GET_REGISTRATION_SUCCESS = 'GET_REGISTRATION_SUCCESS';
export type GET_REGISTRATION_FAILURE = 'GET_REGISTRATION_FAILURE';
export const GET_REGISTRATION_FAILURE: GET_REGISTRATION_FAILURE = 'GET_REGISTRATION_FAILURE';

export type SAVE_REGISTRATION_REQUEST = 'SAVE_REGISTRATION_REQUEST';
export const SAVE_REGISTRATION_REQUEST: SAVE_REGISTRATION_REQUEST = 'SAVE_REGISTRATION_REQUEST';
export type SAVE_REGISTRATION_SUCCESS = 'SAVE_REGISTRATION_SUCCESS';
export const SAVE_REGISTRATION_SUCCESS: SAVE_REGISTRATION_SUCCESS = 'SAVE_REGISTRATION_SUCCESS';
export type SAVE_REGISTRATION_FAILURE = 'SAVE_REGISTRATION_FAILURE';
export const SAVE_REGISTRATION_FAILURE: SAVE_REGISTRATION_FAILURE = 'SAVE_REGISTRATION_FAILURE';

export interface GetRegistrationRequestAction extends Action<GET_REGISTRATION_REQUEST> {
  regId: string;
}

export interface GetRegistrationSuccessAction extends Action<GET_REGISTRATION_SUCCESS> {
  regData: RegistrationData;
}

export interface GetRegistrationFailureAction extends Action<GET_REGISTRATION_FAILURE> {
  error: ApiError;
}

export interface SaveRegistrationRequestAction extends Action<SAVE_REGISTRATION_REQUEST> {
  regId: string;
  data: RegistrationDataRequest;
}

export interface SaveRegistrationSuccessAction extends Action<SAVE_REGISTRATION_SUCCESS> {
  userIsActive: boolean;
}

export interface SaveRegistrationFailureAction extends Action<SAVE_REGISTRATION_FAILURE> {
  error: ApiError;
}

export type GetRegistrationActions =
  GetRegistrationRequestAction
  | GetRegistrationSuccessAction
  | GetRegistrationFailureAction;

export type SaveRegistrationActions =
  SaveRegistrationRequestAction
  | SaveRegistrationSuccessAction
  | SaveRegistrationFailureAction;

export const getRegistrationRequest = (regId: string): GetRegistrationRequestAction =>
  action(GET_REGISTRATION_REQUEST, { regId });
export const getRegistrationSuccess = (regData: RegistrationData): GetRegistrationSuccessAction =>
  action(GET_REGISTRATION_SUCCESS, { regData });
export const getRegistrationFailure = (error: ApiError): GetRegistrationFailureAction =>
  action(GET_REGISTRATION_FAILURE, { error });

export const saveRegistrationRequest = (regId: string, data: RegistrationDataRequest): SaveRegistrationRequestAction =>
  action(SAVE_REGISTRATION_REQUEST, { regId, data });
export const saveRegistrationSuccess = (userIsActive: boolean): SaveRegistrationSuccessAction =>
  action(SAVE_REGISTRATION_SUCCESS, { userIsActive });
export const saveRegistrationFailure = (error: ApiError): SaveRegistrationFailureAction =>
  action(SAVE_REGISTRATION_FAILURE, { error });

export default {
  REQUEST: GET_REGISTRATION_REQUEST,
  SUCCESS: GET_REGISTRATION_SUCCESS,
  FAILURE: GET_REGISTRATION_FAILURE,
  request: getRegistrationRequest,
  success: getRegistrationSuccess,
  failure: getRegistrationFailure,
};
