import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Survey } from '../types/Tymbe';

export enum Actions {
  GET_SURVEY_REQUEST = 'GET_SURVEY_REQUEST',
  GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS',
  GET_SURVEY_FAILURE = 'GET_SURVEY_FAILURE',
}

export interface GetSurveyRequestAction extends Action<Actions.GET_SURVEY_REQUEST> {
  surveyId: Survey['id'];
}

export interface GetSurveySuccessAction extends Action<Actions.GET_SURVEY_SUCCESS> {
  survey: Survey;
}

export interface GetSurveyFailureAction extends Action<Actions.GET_SURVEY_FAILURE> {
  surveyId: Survey['id'];
  error: ApiError;
}

export type GetSurveyActions = GetSurveyRequestAction | GetSurveySuccessAction | GetSurveyFailureAction;

export const getSurveyRequest = (surveyId: Survey['id']): GetSurveyRequestAction =>
  action(Actions.GET_SURVEY_REQUEST, { surveyId });
export const getSurveySuccess = (survey: Survey): GetSurveySuccessAction =>
  action(Actions.GET_SURVEY_SUCCESS, { survey });
export const getSurveyFailure = (surveyId: Survey['id'], error: ApiError): GetSurveyFailureAction =>
  action(Actions.GET_SURVEY_FAILURE, { surveyId, error });
