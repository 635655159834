import { ConstantType } from "./ConstantType";

export class ConstantValueNotFound extends Error {
  constructor(
    constName: ConstantType,
    date: Date,
  ) {
    super(`Value was not found for constant '${constName}' for date ${date}`);
    Object.setPrototypeOf(this, ConstantValueNotFound.prototype);
  }
}
