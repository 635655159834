import React from 'react';
import { styled } from '../../styledComponents';

const MenuToggler = styled.button`
  background-color: transparent;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-around;
  width: 3rem;
  height: 3rem;
  border: none;
  align-self: center;
`;

const MenuTogglerLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.buttons.primary};
`;

export type MenuTogglerProps = {
  onClick: (e: React.MouseEvent) => void;
};

export default styled(({ onClick, ...rest }: MenuTogglerProps) => (
  <MenuToggler onClick={onClick} {...rest}>
    <MenuTogglerLine /><MenuTogglerLine /><MenuTogglerLine />
  </MenuToggler>
))``;
