import React from 'react';
import { useIntl } from 'react-intl';
import { Name, Value } from './Styled';

export type WageRowProps = {
  name: string;
  value: number;
  footNoteIndex?: number;
  className?: string;
}
export const WageDetailRow = ({ name, value, footNoteIndex, className }: WageRowProps) => {
  const intl = useIntl();
  return (
    <>
      <Name className={className}>
        {name}{footNoteIndex ? <sup>{'*'.repeat(footNoteIndex)}</sup> : ''}:
      </Name>
      <Value className={className}>{intl.formatMessage({
        defaultMessage: '{value, number, :: precision-currency-standard currency/CZK}',
        description: 'CZK currency format',
      }, { value })}</Value>
    </>
  );
};
