import { combineReducers } from 'redux';

import { bucketFileReducer, BucketFileState } from './bucket';
import { creditsReducer, CreditsState } from './credits';
import { documentsReducer, DocumentsState } from './documents';
import { perksReducer, PerksState } from './perks';
import { personSalaryLimitReducer, PersonSalaryLimitState } from './personSalaryLimit';
import { ringsReducer, RingsState } from './rings';
import { SignedDocumentsState, signedDocumnetsReducer } from './signedDocuments';
import { surveysReducer, SurveysState } from './surveys';
import { tymberDocumentsReducer, TymberDocumentsState } from './tymberDocuments';
import { vacationsReducer, VacationsState } from './vacations';
import { wageReducer, WageState } from './wage';

export interface DataState {
  rings: RingsState;
  bucketFile: BucketFileState;
  documents: DocumentsState;
  signedDocuments: SignedDocumentsState;
  tymberDocuments: TymberDocumentsState;
  wage: WageState;
  credits: CreditsState;
  surveys: SurveysState;
  perks: PerksState;
  salaryLimit: PersonSalaryLimitState;
  vacations: VacationsState;
}

export const dataRingsSelector = (state: DataState) => state.rings;
export const dataBucketFileSelector = (state: DataState) => state.bucketFile;
export const dataDocumentsSelector = (state: DataState) => state.documents;
export const dataTymberDocumentsSelector = (state: DataState) => state.tymberDocuments;
export const dataSignedDocumentsSelector = (state: DataState) => state.signedDocuments;
export const dataWageSelector = (state: DataState) => state.wage;
export const dataCreditsSelector = (state: DataState) => state.credits;
export const dataSurveysSelector = (state: DataState) => state.surveys;
export const dataPerksSelector = (state: DataState) => state.perks;
export const dataPersonSalaryLimitSelector = (state: DataState) => state.salaryLimit;
export const dataVacationsSelector = (state: DataState) => state.vacations;

export const dataReducer = combineReducers<DataState>(
  {
    rings: ringsReducer,
    bucketFile: bucketFileReducer,
    documents: documentsReducer,
    signedDocuments: signedDocumnetsReducer,
    tymberDocuments: tymberDocumentsReducer,
    wage: wageReducer,
    credits: creditsReducer,
    surveys: surveysReducer,
    perks: perksReducer,
    salaryLimit: personSalaryLimitReducer,
    vacations: vacationsReducer,
  },
);
