import { getOtherContractsRequest } from '../actions/otherContracts';
import { DocumentList } from '../components/DocumentList';
import { Error } from '../components/Error';
import withError from '../components/withError';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { otherContractsSelector } from '../reducers/components';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { RingDocument } from '../types/Tymbe';

const selectSignedDocuments = compose(otherContractsSelector, componentsSelector);

const OtherContractListContainer = connect(
  (state: StoreState) => ({
    ...selectSignedDocuments(state),
  }),
  {
    getDocuments: getOtherContractsRequest,
  },
);

export default OtherContractListContainer(
  withError(Error)(
    withMapData(
      (data: RingDocument[], status) => ({
        documents: data || [],
        loading: status === STATUS_PENDING,
      }))(
      DocumentList,
    ),
  ),
);
