import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Ring, RingDocument } from '../types/Tymbe';

export enum Actions {
  CONTRACT_PREVIEW_REQUEST = 'CONTRACT_PREVIEW_REQUEST',
  SIGNED_CONTRACT_PREVIEW_REQUEST = 'SIGNED_CONTRACT_PREVIEW_REQUEST',
  CONTRACT_PREVIEW_SUCCESS = 'CONTRACT_PREVIEW_SUCCESS',
  CONTRACT_PREVIEW_FAILURE = 'CONTRACT_PREVIEW_FAILURE',
  CONTRACT_PREVIEW_CLEAN = 'CONTRACT_PREVIEW_CLEAN',
}

export interface ContractPreviewRequestAction extends Action<Actions.CONTRACT_PREVIEW_REQUEST> {
  ringId: Ring['id'];
  documentId: RingDocument['id'];
  tymbe: boolean;
}

export interface SignedContractPreviewRequestAction extends Action<Actions.SIGNED_CONTRACT_PREVIEW_REQUEST> {
  documentId: RingDocument['id'];
  tymbe: boolean;
}

export interface ContractPreviewSuccessAction extends Action<Actions.CONTRACT_PREVIEW_SUCCESS> {
  data: string;
}

export interface ContractPreviewFailureAction extends Action<Actions.CONTRACT_PREVIEW_FAILURE> {
  error: ApiError;
}

export interface ContractPreviewCleanAction extends Action<Actions.CONTRACT_PREVIEW_CLEAN> {
}

export type ContractPreviewActions =
  ContractPreviewRequestAction
  | SignedContractPreviewRequestAction
  | ContractPreviewSuccessAction
  | ContractPreviewFailureAction
  | ContractPreviewCleanAction;

export const contractPreviewRequest =
  (ringId: Ring['id'], documentId: RingDocument['id'], tymbe: boolean): ContractPreviewRequestAction =>
    action(Actions.CONTRACT_PREVIEW_REQUEST, { ringId, documentId, tymbe });
export const signedContractPreviewRequest =
  (documentId: RingDocument['id'], tymbe: boolean): SignedContractPreviewRequestAction =>
    action(Actions.SIGNED_CONTRACT_PREVIEW_REQUEST, { documentId, tymbe });
export const contractPreviewSuccess =
  (data: string): ContractPreviewSuccessAction =>
    action(Actions.CONTRACT_PREVIEW_SUCCESS, { data });
export const contractPreviewFailure =
  (error: ApiError): ContractPreviewFailureAction =>
    action(Actions.CONTRACT_PREVIEW_FAILURE, { error });
export const contractPreviewClean =
  (): ContractPreviewCleanAction =>
    action(Actions.CONTRACT_PREVIEW_CLEAN);
