import { Form, FormState, Option, Select } from 'informed';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';

import { Card } from './Card';
import { CardColor } from './Card/CardColor';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import { Button } from './Forms/Button';
import { FormItem } from './Forms/FormItem';
import { FormRowItems } from './Forms/FormRowItems';
import { useModalError } from './Modal/ModalError';
import Spinner from './Spinner';
import { H2, H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import DocumentItem from '../containers/DocumentItem';
import { ApiError, RingDocument } from '../types/Tymbe';

export type DocumentListProps = {
  getDocuments: (month: number, year: number) => void;
  documents: RingDocument[];
  title: string;
  loading: boolean;
  error: ApiError | null;
} & GetProps<typeof Card>;

type DocumentListForm = {
  month: number;
  year: number;
};

const getYears = () => {
  const years = [];
  for (let year = 2017; year <= moment().year(); year += 1) {
    years.push(year);
  }
  return years;
};

export const DocumentList: React.FC<DocumentListProps> =
  ({ getDocuments, documents, title, loading, error, ...p }) => {
    const intl = useIntl();
    const showError = useModalError();
    const onSubmit = (formState: FormState) => {
      const { month, year } = formState.values as DocumentListForm;
      getDocuments(month, year);
    };
    if (error) {
      showError(error.message);
    }
    return (
      <Card {...p}>
        {loading && <Spinner position="absolute" />}
        <CardHeader>
          <Icon style={{ float: 'right' }} id="contracts" color="white" size="2em" />
          <H2>{title}</H2>
        </CardHeader>
        <CardContent>
          <CardColor as={H3}>{intl.formatMessage({
            defaultMessage: 'Vybrat období:',
            description: 'Uvození formulaře pro výběr obdobi (měsíc/rok)',
          })}
          </CardColor>
          <Form onSubmit={onSubmit}>
            <FormRowItems $wrap>
              <FormItem>
                <Select name="month" initialValue={moment().month() + 1}>
                  {moment.months(intl.locale === 'cs' ? 'D MMMM' : 'MMMM').map((month, i) =>
                    <Option key={month} value={i + 1}>{month}</Option>)}
                </Select>
              </FormItem>
              <FormItem>
                <Select name="year" initialValue={moment().year()}>
                  {getYears().map((year) => <Option key={year} value={year}>{year}</Option>)}
                </Select>
              </FormItem>
              <Button type="submit">{intl.formatMessage({
                defaultMessage: 'Zobrazit',
                description: 'Text tlačítka pro potvrzení vybraného období',
              })}
              </Button>
            </FormRowItems>
          </Form>
          {documents.map((document) => (
            <DocumentItem
              key={`${document.id}-${document.tymbe}-${document.signed.id}`}
              dId={document.signed.id}
              tymbe={document.tymbe}
            />
          ))}
        </CardContent>
        <CardFooter />
      </Card>
    );
  };
