export enum CzechSalaryTaxType {
  /** Srážková daň z příjmu fyzických osob. */
  SrazkovaDan = "SrazkovaDan",
  /** Zálohová daň z příjmu fyzických osob. */
  ZalohovaDan = "ZalohovaDan",
  /** Odvod na sociální pojištění (část placená zaměstnancem). */
  SocialniPojisteni = "SocialniPojisteni",
  /** Odvod na zdravodní pojištění. */
  ZdravotniPojisteni = "ZdravotniPojisteni",
}
