import { ContractToShorten } from '@tymbe/legislatives/ContractToShorten';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { RingDocuments } from './RingDocuments';
import { documentSignRequest } from '../../../actions/data/documents';
import { getRingDocumentsRequest } from '../../../actions/ringDocuments';
import { componentsSelector, StoreState } from '../../../reducers';
import { ringDocumentsSelector } from '../../../reducers/components';
import { Ring, RingDocument } from '../../../types/Tymbe';
import { Error } from '../../Error';
import Spinner from '../../Spinner';
import withError from '../../withError';
import withFetch from '../../withFetch';
import withLoading from '../../withLoading';
import withMapData from '../../withMapData';

type RingDocumentsProps = { ring: Ring };
const selectDocuments = compose(ringDocumentsSelector, componentsSelector);
const FetchConnect = connect(
  (state: StoreState) => ({
    ...selectDocuments(state),
    force: true,
  }),
  (dispatch, { ring }: RingDocumentsProps) => ({
    fetch: () => dispatch(getRingDocumentsRequest(ring.id)),
    signDocument: (
      ringId: Ring['id'],
      documentId: RingDocument['id'],
      tymbe: boolean,
      signature: string,
      passed: string,
      shorten?: ContractToShorten,
    ) =>
      dispatch(documentSignRequest(ringId, documentId, tymbe, signature, passed, shorten)),
  }),
);

export default FetchConnect(
  withFetch(
    withLoading(Spinner)(
      withError(Error)(
        withMapData((data) => ({ claimShiftPrecheck: data }))(
          RingDocuments,
        ),
      ),
    ),
  ),
);
