import {
  Actions,
  DownloadTymberDocumentActions,
  GetTymberDocumentsActions,
  InvalidateTymberDocumentActions,
  MoreTymberDocumentsActions,
  SaveTymberDocumentSuccessAction,
} from '../../actions/tymberDocuments';
import { generateDataStoreItemsById } from '../utils';
import { combineReducers, Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { TymberDocument, TymberDocumentPart } from '../../types/Tymbe';

type TymberDocumentsById = Record<TymberDocument['id'] ,DataStoreState<TymberDocument>>;
type TymberDocumentPartsById = Record<TymberDocumentPart['id'], DataStoreState<TymberDocumentPart>>;

export type TymberDocumentsState = {
  documentsById: TymberDocumentsById,
  filesById: TymberDocumentPartsById,
};

type ReducerActions =
  GetTymberDocumentsActions
  | MoreTymberDocumentsActions
  | DownloadTymberDocumentActions
  | InvalidateTymberDocumentActions
  | SaveTymberDocumentSuccessAction;

const documentsReducer: Reducer<TymberDocumentsById, ReducerActions> =
  (state = {}, action) => {
    switch (action.type) {
      case Actions.GET_TYMBER_DOCUMENTS_SUCCESS:
      case Actions.MORE_TYMBER_DOCUMENTS_SUCCESS:
        return {
          ...state,
          ...generateDataStoreItemsById(action.documents),
        };
      case Actions.INVALIDATE_TYMBER_DOCUMENT_REQUEST:
        return {
          ...state,
          [action.id]: initDataStoreState<TymberDocument>(STATUS_PENDING, state[action.id]?.data),
        };
      case Actions.INVALIDATE_TYMBER_DOCUMENT_SUCCESS:
        delete state[action.document.id];
        return {
          ...state,
        };
      case Actions.INVALIDATE_TYMBER_DOCUMENT_FAILURE:
        return {
          ...state,
          [action.id]: initDataStoreState<TymberDocument>(STATUS_ERROR, state[action.id]?.data, action.error),
        };
      case Actions.SAVE_TYMBER_DOCUMENT_SUCCESS:
        return {
          ...state,
          [action.document.id]: initDataStoreState<TymberDocument>(STATUS_SUCCESS, action.document),
        };
      default:
        return state;
    }
  };

const filesReducer: Reducer<TymberDocumentPartsById, ReducerActions> =
  (state = {}, action) => {
    switch (action.type) {
      case Actions.GET_TYMBER_DOCUMENTS_SUCCESS:
      case Actions.MORE_TYMBER_DOCUMENTS_SUCCESS:
        const parts = action.documents.map(document => document.parts).flat();
        return {
          ...state,
          ...generateDataStoreItemsById(parts),
        };
      case Actions.SAVE_TYMBER_DOCUMENT_SUCCESS:
        return {
          ...state,
          ...generateDataStoreItemsById(action.document.parts),
        };
      case Actions.DOWNLOAD_TYMBER_DOCUMENT_REQUEST:
        return {
          ...state,
          [action.id]: initDataStoreState(STATUS_PENDING, state[action.id]?.data),
        };
      case Actions.DOWNLOAD_TYMBER_DOCUMENT_SUCCESS:
        return {
          ...state,
          [action.id]: initDataStoreState(STATUS_SUCCESS, state[action.id]?.data),
        };
      case Actions.DOWNLOAD_TYMBER_DOCUMENT_FAILURE:
        return {
          ...state,
          [action.id]: initDataStoreState(STATUS_ERROR, state[action.id]?.data, action.error),
        };
      case Actions.INVALIDATE_TYMBER_DOCUMENT_SUCCESS:
        action.document.parts.forEach(part => delete state[part.id]);
        return {
          ...state,
        };
      default:
        return state;
    }
  };

export const tymberDocumentSelector = (state: TymberDocumentsState, id: TymberDocument['id']) =>
  state.documentsById[id];
export const tymberDocumentPartSelector = (state: TymberDocumentsState, id: TymberDocumentPart['id']) =>
  state.filesById[id];

export const tymberDocumentsReducer = combineReducers<TymberDocumentsState>({
  documentsById: documentsReducer,
  filesById: filesReducer,
});
