/**
 * Kódy států podle ISO 3166-1 alpha-3
 * https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fdevelopers.mpsv.cz%2Fsupport%2FCiselniky_popis_IK_Sdeleni.xlsx&wdOrigin=BROWSELINK
 */
export enum StatKod {
  /** Afghánská islámská republika */
  AFG = 'AFG',
  /** Provincie Alandy */
  ALA = 'ALA',
  /** Albánská republika */
  ALB = 'ALB',
  /** Alžírská demokratická a lidová republika */
  DZA = 'DZA',
  /** Území Americká Samoa */
  ASM = 'ASM',
  /** Americké Panenské ostrovy */
  VIR = 'VIR',
  /** Andorrské knížectví */
  AND = 'AND',
  /** Angolská republika */
  AGO = 'AGO',
  /** Anguilla */
  AIA = 'AIA',
  /** Antarktida */
  ATA = 'ATA',
  /** Antigua a Barbuda */
  ATG = 'ATG',
  /** Argentinská republika */
  ARG = 'ARG',
  /** Arménská republika */
  ARM = 'ARM',
  /** Aruba */
  ABW = 'ABW',
  /** Australské společenství */
  AUS = 'AUS',
  /** Ázerbájdžánská republika */
  AZE = 'AZE',
  /** Bahamské společenství */
  BHS = 'BHS',
  /** Království Bahrajn */
  BHR = 'BHR',
  /** Bangladéšská lidová republika */
  BGD = 'BGD',
  /** Barbados */
  BRB = 'BRB',
  /** Belgické království */
  BEL = 'BEL',
  /** Belize */
  BLZ = 'BLZ',
  /** Běloruská republika */
  BLR = 'BLR',
  /** Beninská republika */
  BEN = 'BEN',
  /** Bermudy */
  BMU = 'BMU',
  /** Bhútánské království */
  BTN = 'BTN',
  /** Mnohonárodní stát Bolívie */
  BOL = 'BOL',
  /** Bonaire, Svatý Eustach a Saba */
  BES = 'BES',
  /** Bosna a Hercegovina */
  BIH = 'BIH',
  /** Botswanská republika */
  BWA = 'BWA',
  /** Bouvetův ostrov */
  BVT = 'BVT',
  /** Brazilská federativní republika */
  BRA = 'BRA',
  /** Britské území v Indickém oceánu */
  IOT = 'IOT',
  /** Britské Panenské ostrovy */
  VGB = 'VGB',
  /** Stát Brunej Darussalam */
  BRN = 'BRN',
  /** Bulharská republika */
  BGR = 'BGR',
  /** Burkina Faso */
  BFA = 'BFA',
  /** Burundská republika */
  BDI = 'BDI',
  /** Cookovy ostrovy */
  COK = 'COK',
  /** Země Curaçao */
  CUW = 'CUW',
  /** Čadská republika */
  TCD = 'TCD',
  /** Černá Hora */
  MNE = 'MNE',
  /** Česká republika */
  CZE = 'CZE',
  /** Čínská lidová republika */
  CHN = 'CHN',
  /** Dánské království */
  DNK = 'DNK',
  /** Dominické společenství */
  DMA = 'DMA',
  /** Dominikánská republika */
  DOM = 'DOM',
  /** Džibutská republika */
  DJI = 'DJI',
  /** Egyptská arabská republika */
  EGY = 'EGY',
  /** Ekvádorská republika */
  ECU = 'ECU',
  /** Stát Eritrea */
  ERI = 'ERI',
  /** Estonská republika */
  EST = 'EST',
  /** Etiopská federativní demokratická republika */
  ETH = 'ETH',
  /** Faerské ostrovy */
  FRO = 'FRO',
  /** Falklandy (Malvíny) */
  FLK = 'FLK',
  /** Fidžijská republika */
  FJI = 'FJI',
  /** Filipínská republika */
  PHL = 'PHL',
  /** Finská republika */
  FIN = 'FIN',
  /** Francouzská republika */
  FRA = 'FRA',
  /** Francouzská Guyana */
  GUF = 'GUF',
  /** Francouzská jižní a antarktická území */
  ATF = 'ATF',
  /** Francouzská Polynésie */
  PYF = 'PYF',
  /** Gabonská republika */
  GAB = 'GAB',
  /** Gambijská republika */
  GMB = 'GMB',
  /** Ghanská republika */
  GHA = 'GHA',
  /** Gibraltar */
  GIB = 'GIB',
  /** Grenada */
  GRD = 'GRD',
  /** Grónsko */
  GRL = 'GRL',
  /** Gruzie */
  GEO = 'GEO',
  /** Region Guadeloupe */
  GLP = 'GLP',
  /** Teritorium Guam */
  GUM = 'GUM',
  /** Guatemalská republika */
  GTM = 'GTM',
  /** Bailiwick Guernsey */
  GGY = 'GGY',
  /** Guinejská republika */
  GIN = 'GIN',
  /** Republika Guinea-Bissau */
  GNB = 'GNB',
  /** Guyanská kooperativní republika */
  GUY = 'GUY',
  /** Republika Haiti */
  HTI = 'HTI',
  /** Heardův ostrov a MacDonaldovy ostrovy */
  HMD = 'HMD',
  /** Honduraská republika */
  HND = 'HND',
  /** Zvláštní administrativní oblast Čínské lidové republiky Hongkong */
  HKG = 'HKG',
  /** Chilská republika */
  CHL = 'CHL',
  /** Chorvatská republika */
  HRV = 'HRV',
  /** Indická republika */
  IND = 'IND',
  /** Indonéská republika */
  IDN = 'IDN',
  /** Irácká republika */
  IRQ = 'IRQ',
  /** Íránská islámská republika */
  IRN = 'IRN',
  /** Irsko */
  IRL = 'IRL',
  /** Islandská republika */
  ISL = 'ISL',
  /** Italská republika */
  ITA = 'ITA',
  /** Stát Izrael */
  ISR = 'ISR',
  /** Jamajka */
  JAM = 'JAM',
  /** Japonsko */
  JPN = 'JPN',
  /** Jemenská republika */
  YEM = 'YEM',
  /** Bailiwick Jersey */
  JEY = 'JEY',
  /** Jihoafrická republika */
  ZAF = 'ZAF',
  /** Jižní Georgie a Jižní Sandwichovy ostrovy */
  SGS = 'SGS',
  /** Jihosúdánská republika */
  SSD = 'SSD',
  /** Jordánské hášimovské království */
  JOR = 'JOR',
  /** Kajmanské ostrovy */
  CYM = 'CYM',
  /** Kambodžské království */
  KHM = 'KHM',
  /** Kamerunská republika */
  CMR = 'CMR',
  /** Kanada */
  CAN = 'CAN',
  /** Kapverdská republika */
  CPV = 'CPV',
  /** Stát Katar */
  QAT = 'QAT',
  /** Republika Kazachstán */
  KAZ = 'KAZ',
  /** Keňská republika */
  KEN = 'KEN',
  /** Republika Kiribati */
  KIR = 'KIR',
  /** Území Kokosové (Keelingovy) ostrovy */
  CCK = 'CCK',
  /** Kolumbijská republika */
  COL = 'COL',
  /** Komorský svaz */
  COM = 'COM',
  /** Konžská demokratická republika */
  COD = 'COD',
  /** Konžská republika */
  COG = 'COG',
  /** Korejská lidově demokratická republika */
  PRK = 'PRK',
  /** Korejská republika */
  KOR = 'KOR',
  /** Kosovská republika */
  XXK = 'XXK',
  /** Kostarická republika */
  CRI = 'CRI',
  /** Kubánská republika */
  CUB = 'CUB',
  /** Kuvajtský stát */
  KWT = 'KWT',
  /** Kyperská republika */
  CYP = 'CYP',
  /** Kyrgyzská republika */
  KGZ = 'KGZ',
  /** Laoská lidově demokratická republika */
  LAO = 'LAO',
  /** Lesothské království */
  LSO = 'LSO',
  /** Libanonská republika */
  LBN = 'LBN',
  /** Liberijská republika */
  LBR = 'LBR',
  /** Libyjský stát */
  LBY = 'LBY',
  /** Lichtenštejnské knížectví */
  LIE = 'LIE',
  /** Litevská republika */
  LTU = 'LTU',
  /** Lotyšská republika */
  LVA = 'LVA',
  /** Lucemburské velkovévodství */
  LUX = 'LUX',
  /** Zvláštní administrativní oblast Čínské lidové republiky Macao */
  MAC = 'MAC',
  /** Madagaskarská republika */
  MDG = 'MDG',
  /** Maďarsko */
  HUN = 'HUN',
  /** Malajsie */
  MYS = 'MYS',
  /** Malawiská republika */
  MWI = 'MWI',
  /** Maledivská republika */
  MDV = 'MDV',
  /** Republika Mali */
  MLI = 'MLI',
  /** Maltská republika */
  MLT = 'MLT',
  /** Ostrov Man */
  IMN = 'IMN',
  /** Marocké království */
  MAR = 'MAR',
  /** Republika Marshallovy ostrovy */
  MHL = 'MHL',
  /** Martinik */
  MTQ = 'MTQ',
  /** Mauricijská republika */
  MUS = 'MUS',
  /** Mauritánská islámská republika */
  MRT = 'MRT',
  /** Departement Mayotte */
  MYT = 'MYT',
  /** Menší odlehlé ostrovy USA */
  UMI = 'UMI',
  /** Spojené státy mexické */
  MEX = 'MEX',
  /** Federativní státy Mikronésie */
  FSM = 'FSM',
  /** Moldavská republika */
  MDA = 'MDA',
  /** Monacké knížectví */
  MCO = 'MCO',
  /** Mongolsko */
  MNG = 'MNG',
  /** Montserrat */
  MSR = 'MSR',
  /** Mosambická republika */
  MOZ = 'MOZ',
  /** Republika Myanmarský svaz */
  MMR = 'MMR',
  /** Namibijská republika */
  NAM = 'NAM',
  /** Republika Nauru */
  NRU = 'NRU',
  /** Spolková republika Německo */
  DEU = 'DEU',
  /** Nepálská federativní demokratická republika */
  NPL = 'NPL',
  /** Nigerská republika */
  NER = 'NER',
  /** Nigerijská federativní republika */
  NGA = 'NGA',
  /** Nikaragujská republika */
  NIC = 'NIC',
  /** Niue */
  NIU = 'NIU',
  /** Nizozemské království */
  NLD = 'NLD',
  /** Území Norfolk */
  NFK = 'NFK',
  /** Norské království */
  NOR = 'NOR',
  /** Nová Kaledonie */
  NCL = 'NCL',
  /** Nový Zéland */
  NZL = 'NZL',
  /** Sultanát Omán */
  OMN = 'OMN',
  /** Pákistánská islámská republika */
  PAK = 'PAK',
  /** Republika Palau */
  PLW = 'PLW',
  /** Palestinská autonomní území */
  PSE = 'PSE',
  /** Panamská republika */
  PAN = 'PAN',
  /** Nezávislý stát Papua Nová Guinea */
  PNG = 'PNG',
  /** Paraguayská republika */
  PRY = 'PRY',
  /** Peruánská republika */
  PER = 'PER',
  /** Pitcairnovy ostrovy */
  PCN = 'PCN',
  /** Republika Pobřeží slonoviny */
  CIV = 'CIV',
  /** Polská republika */
  POL = 'POL',
  /** Portorické společenství */
  PRI = 'PRI',
  /** Portugalská republika */
  PRT = 'PRT',
  /** Rakouská republika */
  AUT = 'AUT',
  /** Region Réunion */
  REU = 'REU',
  /** Republika Rovníková Guinea */
  GNQ = 'GNQ',
  /** Rumunsko */
  ROU = 'ROU',
  /** Ruská federace */
  RUS = 'RUS',
  /** Rwandská republika */
  RWA = 'RWA',
  /** Řecká republika */
  GRC = 'GRC',
  /** Územní společenství Saint Pierre a Miquelon */
  SPM = 'SPM',
  /** Salvadorská republika */
  SLV = 'SLV',
  /** Nezávislý stát Samoa */
  WSM = 'WSM',
  /** Republika San Marino */
  SMR = 'SMR',
  /** Království Saúdská Arábie */
  SAU = 'SAU',
  /** Senegalská republika */
  SEN = 'SEN',
  /** Republika Severní Makedonie */
  MKD = 'MKD',
  /** Společenství Severní Mariany */
  MNP = 'MNP',
  /** Seychelská republika */
  SYC = 'SYC',
  /** Republika Sierra Leone */
  SLE = 'SLE',
  /** Singapurská republika */
  SGP = 'SGP',
  /** Slovenská republika */
  SVK = 'SVK',
  /** Slovinská republika */
  SVN = 'SVN',
  /** Somálská federativní republika */
  SOM = 'SOM',
  /** Stát Spojené arabské emiráty */
  ARE = 'ARE',
  /** Spojené státy americké */
  USA = 'USA',
  /** Srbská republika */
  SRB = 'SRB',
  /** Středoafrická republika */
  CAF = 'CAF',
  /** Súdánská republika */
  SDN = 'SDN',
  /** Surinamská republika */
  SUR = 'SUR',
  /** Svatá Helena, Ascension a Tristan da Cunha */
  SHN = 'SHN',
  /** Svatá Lucie */
  LCA = 'LCA',
  /** Společenství Svatý Bartoloměj */
  BLM = 'BLM',
  /** Federace Svatý Kryštof a Nevis */
  KNA = 'KNA',
  /** Společenství Svatý Martin */
  MAF = 'MAF',
  /** Svatý Martin (NL) */
  SXM = 'SXM',
  /** Demokratická republika Svatý Tomáš a Princův ostrov */
  STP = 'STP',
  /** Svatý Vincenc a Grenadiny */
  VCT = 'VCT',
  /** Svazijské království */
  SWZ = 'SWZ',
  /** Syrská arabská republika */
  SYR = 'SYR',
  /** Šalomounovy ostrovy */
  SLB = 'SLB',
  /** Španělské království */
  ESP = 'ESP',
  /** Špicberky a Jan Mayen */
  SJM = 'SJM',
  /** Šrílanská demokratická socialistická republika */
  LKA = 'LKA',
  /** Švédské království */
  SWE = 'SWE',
  /** Švýcarská konfederace */
  CHE = 'CHE',
  /** Republika Tádžikistán */
  TJK = 'TJK',
  /** Tanzanská sjednocená republika */
  TZA = 'TZA',
  /** Thajské království */
  THA = 'THA',
  /** Tchaj-wan */
  TWN = 'TWN',
  /** Tožská republika */
  TGO = 'TGO',
  /** Tokelau */
  TKL = 'TKL',
  /** Království Tonga */
  TON = 'TON',
  /** Republika Trinidad a Tobago */
  TTO = 'TTO',
  /** Tuniská republika */
  TUN = 'TUN',
  /** Turecká republika */
  TUR = 'TUR',
  /** Turkmenistán */
  TKM = 'TKM',
  /** Ostrovy Turks a Caicos */
  TCA = 'TCA',
  /** Tuvalu */
  TUV = 'TUV',
  /** Ugandská republika */
  UGA = 'UGA',
  /** Ukrajina */
  UKR = 'UKR',
  /** Uruguayská východní republika */
  URY = 'URY',
  /** Republika Uzbekistán */
  UZB = 'UZB',
  /** Území Vánoční ostrov */
  CXR = 'CXR',
  /** Republika Vanuatu */
  VUT = 'VUT',
  /** Vatikánský městský stát */
  VAT = 'VAT',
  /** Spojené království Velké Británie a Severního Irska */
  GBR = 'GBR',
  /** Bolívarovská republika Venezuela */
  VEN = 'VEN',
  /** Vietnamská socialistická republika */
  VNM = 'VNM',
  /** Demokratická republika Východní Timor */
  TLS = 'TLS',
  /** Teritorium Wallisovy ostrovy a Futuna */
  WLF = 'WLF',
  /** Zambijská republika */
  ZMB = 'ZMB',
  /** Saharská arabská demokratická republika */
  ESH = 'ESH',
  /** Zimbabwská republika */
  ZWE = 'ZWE',
}
