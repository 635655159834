import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Ring } from '../types/Tymbe';

export enum Actions {
  GET_CLAIMED_REQUEST = 'GET_CLAIMED_REQUEST',
  GET_CLAIMED_SUCCESS = 'GET_CLAIMED_SUCCESS',
  GET_CLAIMED_FAILURE = 'GET_CLAIMED_FAILURE',
  CANCEL_CLAIMED_REQUEST = 'CANCEL_CLAIMED_REQUEST',
  CANCEL_CLAIMED_SUCCESS = 'CANCEL_CLAIMED_SUCCESS',
  CANCEL_CLAIMED_FAILURE = 'CANCEL_CLAIMED_FAILURE',
  CLEAR_CANCEL_CLAIMED_RESULT = 'CLEAR_CANCEL_CLAIMED_RESULT',
  MORE_CLAIMED_REQUEST = 'MORE_CLAIMED_REQUEST',
  MORE_CLAIMED_SUCCESS = 'MORE_CLAIMED_SUCCESS',
  MORE_CLAIMED_FAILURE = 'MORE_CLAIMED_FAILURE',
}

export interface GetClaimedRequestAction extends Action<Actions.GET_CLAIMED_REQUEST> {
  filters?: object;
}

export interface GetClaimedSuccessAction extends Action<Actions.GET_CLAIMED_SUCCESS> {
  rings: Ring[];
}

export interface GetClaimedFailureAction extends Action<Actions.GET_CLAIMED_FAILURE> {
  error: ApiError;
}

export interface CancelClaimedRequestAction extends Action<Actions.CANCEL_CLAIMED_REQUEST> {
  ringId: Ring['id'];
  isLastMinute: boolean
}

export interface CancelClaimedSuccessAction extends Action<Actions.CANCEL_CLAIMED_SUCCESS> {
  ring: Ring;
}

export interface CancelClaimedFailureAction extends Action<Actions.CANCEL_CLAIMED_FAILURE> {
  error: ApiError;
}

export interface ClearClaimedResultAction extends Action<Actions.CLEAR_CANCEL_CLAIMED_RESULT> {
}

export interface MoreClaimedRequestAction extends Action<Actions.MORE_CLAIMED_REQUEST> {
  filters?: object;
}

export interface MoreClaimedSuccessAction extends Action<Actions.MORE_CLAIMED_SUCCESS> {
  rings: Ring[];
}

export interface MoreClaimedFailureAction extends Action<Actions.MORE_CLAIMED_FAILURE> {
  error: ApiError;
}


export type GetClaimedActions = GetClaimedRequestAction | GetClaimedSuccessAction | GetClaimedFailureAction;
export type CancelClaimedActions = CancelClaimedRequestAction | CancelClaimedSuccessAction | CancelClaimedFailureAction;
export type MoreClaimedActions = MoreClaimedRequestAction | MoreClaimedSuccessAction | MoreClaimedFailureAction;

export const getClaimedRequest = (filters?: object): GetClaimedRequestAction =>
  action(Actions.GET_CLAIMED_REQUEST, { filters });
export const getClaimedSuccess = (rings: Ring[]): GetClaimedSuccessAction =>
  action(Actions.GET_CLAIMED_SUCCESS, { rings });
export const getClaimedFailure = (error: ApiError): GetClaimedFailureAction =>
  action(Actions.GET_CLAIMED_FAILURE, { error });

export const cancelClaimedRequest = (ringId: Ring['id'], isLastMinute: boolean): CancelClaimedRequestAction => {

  return action(Actions.CANCEL_CLAIMED_REQUEST, { ringId, isLastMinute });
}
export const cancelClaimedSuccess = (ring: Ring): CancelClaimedSuccessAction =>
  action(Actions.CANCEL_CLAIMED_SUCCESS, { ring });
export const cancelClaimedFailure = (error: ApiError): CancelClaimedFailureAction =>
  action(Actions.CANCEL_CLAIMED_FAILURE, { error });
export const clearCancelClaimedResult = (): ClearClaimedResultAction =>
  action(Actions.CLEAR_CANCEL_CLAIMED_RESULT);

  export const moreClaimedRequest = (filters?: object): MoreClaimedRequestAction =>
  action(Actions.MORE_CLAIMED_REQUEST, { filters });
export const moreClaimedSuccess = (rings: Ring[]): MoreClaimedSuccessAction =>
  action(Actions.MORE_CLAIMED_SUCCESS, { rings });
export const moreClaimedFailure = (error: ApiError): MoreClaimedFailureAction =>
  action(Actions.MORE_CLAIMED_FAILURE, { error });
