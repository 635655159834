import React, { SVGAttributes } from 'react';

import icons from '../../images/icons.svg';
import { styled } from '../../styledComponents';
import ThemeInterface from '../../themes/theme';

export type IconProps = {
  color?: keyof ThemeInterface['colors'] | string;
  background?: keyof ThemeInterface['colors'] | string;
  id: string;
  className?: string;
  pill?: keyof ThemeInterface['buttons'];
  size?: string;
  style?: React.CSSProperties;
} & SVGAttributes<SVGElement>;

const StyledSvg = styled.svg<Omit<IconProps, 'id'>>`
  // @ts-ignore
  --color: ${({ theme, color }) => theme.colors[color!] ?? color};
  // @ts-ignore
  --background: ${({ theme, background }) => theme.colors[background!] ?? background};
  vertical-align: middle;
  overflow: visible;
  ${({ size, pill }) => `
    width: calc(${size} + ${pill ? '1rem' : '0px'});
    height: calc(${size} + ${pill ? '1rem' : '0px'});
  `}
  ${({ pill, theme }) => pill && `
    background-color: ${theme.buttons[pill]};
    border-radius: 100%;
    padding: .5rem;
  `}
`;

export const Icon = styled(({ id, ...rest }: IconProps) => (
  <StyledSvg {...rest}>
    <use xlinkHref={`${icons}#${id}`} />
  </StyledSvg>
))``;
