import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Perk } from '../types/Tymbe';

export enum Actions {
  PERKS_REQUEST = 'PERKS_REQUEST',
  PERKS_SUCCESS = 'PERKS_SUCCESS',
  PERKS_FAILURE = 'PERKS_FAILURE',
}

export interface PerksRequestAction extends Action<Actions.PERKS_REQUEST> {
}

export interface PerksSuccessAction extends Action<Actions.PERKS_SUCCESS> {
  perks: Perk[];
}

export interface PerksFailureAction extends Action<Actions.PERKS_FAILURE> {
  error: ApiError;
}

export type PerksActions = PerksRequestAction | PerksSuccessAction | PerksFailureAction;

export const perksRequest = (): PerksRequestAction =>
  action(Actions.PERKS_REQUEST);

export const perksSuccess = (perks: Perk[]) =>
  action(Actions.PERKS_SUCCESS, { perks });

export const perksFailure = (error: ApiError) =>
  action(Actions.PERKS_FAILURE, { error });
