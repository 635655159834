import { styled } from '../../styledComponents';
import ThemeInterface from '../../themes/theme';

export type WellBoxProps = {
  inline?: boolean;
  centered?: boolean;
  outline?: boolean;
  color?: keyof ThemeInterface['colors'];
  noWrap?: boolean;
};

export const WellBox = styled.div<WellBoxProps>`
  display: ${({ inline }) => inline && 'inline-block'};
  border-radius: 1rem;
  font-weight: 900;
  padding: 1rem;
  background-color: ${({ theme, color }) => (color && theme.colors[color]) ?? theme.bodyBg};
  white-space: ${({ noWrap }) => noWrap && 'nowrap'};
  color: ${({ color, theme }) => {
    switch (color) {
      case 'gray5':
      case 'primary':
      case 'secondary':
        return theme.bodyBg;
      case 'danger':
        return theme.colors.black;
      default:
        return theme.bodyColor;
    }
  }};
  text-align: ${({ centered }) => centered && 'center'};

  ${({ outline, theme }) => outline && `box-shadow: inset 0 0px 3px 0px ${theme.colors.gray4};`}

  & > & {
    margin: -1rem 1rem -1rem -1rem;
  }
`;
