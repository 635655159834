import { Button } from '../Forms/Button';
import DatePicker from '../Forms/DatePicker';
import { DropDown } from '../Forms/DropDown';
import { LabeledInput } from '../Forms/LabeledInput';
import { H2 } from '../Styles/BasicElements';
import { Icon } from '../Styles/Icon';
import RingsGrid from '../Styles/RingsGrid';
import { Row } from '../Styles/Row';
import { SidePanel } from '../Styles/SidePanel';
import { Form, FormState } from 'informed';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { styled } from '../../styledComponents';
import { locationsPairs } from '../../utils';

const formatDate = 'DD. MM. YYYY';

type FilterData = {
  location?: string;
  company?: string;
  date?: {
    gt?: string;
    lt?: string;
  };
  position?: string;
};

export interface RingsGridWithFilterProps {
  onFilter: (filters: object) => void;
  children: ReactNode;
  defaultFilters?: FilterData;
}

const RingsGridWithFilter = ({ onFilter, children, defaultFilters }: RingsGridWithFilterProps) => {
  const intl = useIntl();
  const [filterPanel, setFilterPanel] = React.useState(false);
  const toggleFilterPanel = () => setFilterPanel(!filterPanel);
  const filterSubmitted = (formState: FormState) => {
    const data = formState.values as FilterData;
    const filter = { ...data, date: { ...data.date } };
    if (filter.date) {
      filter.date.gt = filter.date.gt && moment(filter.date.gt, formatDate).format();
      filter.date.lt = filter.date.lt && moment(filter.date.lt, formatDate).endOf('day').format();
    }

    onFilter(filter);
    toggleFilterPanel();
  };

  return (
    <Container className="!static">
      <RingsGrid>
        {children}
      </RingsGrid>
      <SearchPanel open={filterPanel} position="right" onOverlayClick={toggleFilterPanel}>
        <SearchPanelToggler onClick={toggleFilterPanel} toggled={filterPanel}>
          <Icon id="panelToggle" color="white" className="toggle" />
          <Icon id="search" color="white" className="search" />
        </SearchPanelToggler>
        <FilterContainer>
          <Form
            onSubmit={filterSubmitted}
            style={{ backgroundColor: 'inherit' }}
            autoComplete={'off'}
            initialValues={defaultFilters}
          >
            <H2>
              <Icon id={'search'} size={'20px'} pill={'secondary'} />{' '}
              {intl.formatMessage({
                defaultMessage: 'Filtry',
                description: 'Nadpis postraního panelu s filtry',
              })}
            </H2>
            <DropDown
              name={'location'}
              id={'filter_location'}
              label={intl.formatMessage({
                defaultMessage: 'Okres',
                description: 'Název formulářového pole s vyběrem okresů',
              })}
              data={locationsPairs}
              autoComplete={'off'}
            />
            <LabeledInput name={'company'} id={'filter_company'} label={'Firma'} />
            <DatePicker
              name={'date[gt]'}
              id={'filter_date_from'}
              label={intl.formatMessage({
                defaultMessage: 'Datum od',
                description: 'Název formulářového pole pro zadaní filtru data začátku směny.' +
                  ' Skryje směny začínající dříve než zadané datum.',
              })}
              formatDate={formatDate}
              autoComplete={'off'}
              locale={'cs'}
            />
            <DatePicker
              name={'date[lt]'}
              id={'filter_date_to'}
              label={intl.formatMessage({
                defaultMessage: 'Datum do',
                description: 'Název formulářového pole pro zadaní filtru data začátku směny.' +
                  ' Skryje směny začínající později než zadané datum.',
              })}
              formatDate={formatDate}
              autoComplete={'off'}
              locale={'cs'}
            />
            <LabeledInput
              name={'position'}
              id={'filter_position'}
              label={intl.formatMessage({
                defaultMessage: 'Pozice',
                description: 'Název formulářového pole pro filtrování podle názvu směny',
              })}
              autoComplete={'off'}
            />
            <Button type={'submit'}>{intl.formatMessage({
              defaultMessage: 'Filtrovat',
              description: 'Tlačítko pro aplikaci zadaných filtrů',
            })}</Button>
          </Form>
        </FilterContainer>
      </SearchPanel>
    </Container>
  );
};

const SearchPanel = styled(SidePanel)`
  background-color: ${({ theme }) => theme.bodyBg};
  right: 3rem;
  z-index: 2;

  &.open {
    right: 0;
  }

  & ${H2} {
    margin-bottom: 1rem;
  }
`;

const FilterContainer = styled.div`
  position: static;
  min-height: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 2rem 4rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

const SearchPanelToggler = styled.button.attrs({ type: 'button' }) <{ toggled?: boolean; }>`
  position: absolute;
  background: ${({ theme }) => theme.buttons.primary};
  height: 56px;
  width: 56px;
  border-radius: 9999px;
  border: none;
  padding: 0;
  cursor: pointer;
  left: -32px;
  bottom: 8rem;

  outline: none;

  & ${Icon} {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 1rem .8rem 1rem 1rem;
    transition: transform .5s;
    transform: rotate(${({ toggled }) => toggled ? 0 : 180}deg);
  }
`;

const Container = styled(Row)`
  flex-grow: 1;
  @media (min-width: 430px) {
    ${RingsGrid} {
      padding-right: 9rem;
    }

    .search {
      display: none
    }
  }
  @media (max-width: 429.98px) {
    ${FilterContainer} {
      padding: 1rem;
    }

    ${SearchPanel} {
      right: 0;
    }

    ${SearchPanelToggler} {
      top: auto;
      bottom: 8rem;
      left: -68px;

      ${Icon} {
        transform: none;
      }
    }

    .toggle {
      display: none
    }
  }
`;

export default RingsGridWithFilter;
