import { action } from './utils';
import { Action } from 'redux';
import { ApiError, Credit, SurveyAnswer } from '../types/Tymbe';

export enum Actions {
  GET_CREDITS_REQUEST = 'GET_CREDITS_REQUEST',
  GET_CREDITS_SUCCESS = 'GET_CREDITS_SUCCESS',
  GET_CREDITS_FAILURE = 'GET_CREDITS_FAILURE',
  PAYOUT_CREDITS_REQUEST = 'PAYOUT_CREDITS_REQUEST',
  PAYOUT_CREDITS_SUCCESS = 'PAYOUT_CREDITS_SUCCESS',
  PAYOUT_CREDITS_FAILURE = 'PAYOUT_CREDITS_FAILURE',
}

export interface GetCreditsRequestAction extends Action<Actions.GET_CREDITS_REQUEST> {
}

export interface GetCreditsSuccessAction extends Action<Actions.GET_CREDITS_SUCCESS> {
  credits: Credit[];
}

export interface GetCreditsFailureAction extends Action<Actions.GET_CREDITS_FAILURE> {
  error: ApiError;
}

export interface PayoutCreditsRequestAction extends Action<Actions.PAYOUT_CREDITS_REQUEST> {
  creditsId: Credit['id'];
  password: string;
  survey: SurveyAnswer;
}

export interface PayoutCreditsSuccessAction extends Action<Actions.PAYOUT_CREDITS_SUCCESS> {
  creditsId: Credit['id'];
}

export interface PayoutCreditsFailureAction extends Action<Actions.PAYOUT_CREDITS_FAILURE> {
  creditsId: Credit['id'];
  error: ApiError;
}

export type GetCreditsActions = GetCreditsRequestAction | GetCreditsSuccessAction | GetCreditsFailureAction;
export type PayoutCreditsActions = PayoutCreditsRequestAction | PayoutCreditsSuccessAction | PayoutCreditsFailureAction;

export const getCreditsRequest = (): GetCreditsRequestAction =>
  action(Actions.GET_CREDITS_REQUEST);
export const getCreditsSuccess = (credits: Credit[]): GetCreditsSuccessAction =>
  action(Actions.GET_CREDITS_SUCCESS, { credits });
export const getCreditsFailure = (error: ApiError): GetCreditsFailureAction =>
  action(Actions.GET_CREDITS_FAILURE, { error });

export const payoutCreditsRequest =
  (creditsId: Credit['id'], survey: SurveyAnswer, password: string): PayoutCreditsRequestAction =>
    action(Actions.PAYOUT_CREDITS_REQUEST, { creditsId, survey, password });
export const payoutCreditsSuccess = (creditsId: Credit['id']): PayoutCreditsSuccessAction =>
  action(Actions.PAYOUT_CREDITS_SUCCESS, { creditsId });
export const payoutCreditsFailure = (creditsId: Credit['id'], error: ApiError): PayoutCreditsFailureAction =>
  action(Actions.PAYOUT_CREDITS_FAILURE, { creditsId, error });
