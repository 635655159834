import { Actions, PerksActions } from '../../actions/perk';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_SUCCESS } from '../../types/StoreUtils';
import { Perk } from '../../types/Tymbe';

export type PerksState = DataStoreState<Perk[]>;

const initState = initDataStoreState<Perk[]>(null);

export const perksReducer: Reducer<PerksState, PerksActions> =
  (state = initState, action) => {
    switch (action.type) {
      case Actions.PERKS_REQUEST:
        return initDataStoreState<Perk[]>();
      case Actions.PERKS_SUCCESS:
        return initDataStoreState<Perk[]>(STATUS_SUCCESS, action.perks);
      case Actions.PERKS_FAILURE:
        return initDataStoreState<Perk[]>(STATUS_ERROR, null, action.error);
      default:
        return state;
    }
  };
