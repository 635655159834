import * as React from 'react';
import { DataStoreState as DSS, STATUS_ERROR } from '../types/StoreUtils';
import { ApiError } from '../types/Tymbe';
import { getDisplayName } from '../utils';

export default <D extends object>(ErrorComponent: React.ComponentType<{ error: ApiError | null }>, passIfData?: boolean) =>
  <C extends object>(WrappedComponent: React.ComponentType<C>): React.ComponentType<C & DSS<D>> => {
    const WithError = (props: C & DSS<D>) =>
      props.status === STATUS_ERROR ?
        (
          passIfData && props.data ?
            <WrappedComponent {...props} /> :
            <ErrorComponent error={props.error} />
        ) :
        <WrappedComponent {...props} />;

    WithError.displayName = `WithError(${getDisplayName(WrappedComponent)})`;
    return WithError;
  };
