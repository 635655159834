import { Actions, GetWagesActions, PayoutWageActions } from '../../actions/wages';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { Wage } from '../../types/Tymbe';

export type WagesState = DataStoreState<Wage[]>;

const initState: WagesState = initDataStoreState<Wage[]>(null, []);

type ReducerActions = GetWagesActions | PayoutWageActions;
export const wagesReducer: Reducer<WagesState, ReducerActions> = (state = initState, action) => {
  switch (action.type) {
    case Actions.GET_WAGES_REQUEST:
      return initDataStoreState<Wage[]>(STATUS_PENDING, state.data);
    case Actions.GET_WAGES_SUCCESS:
      return initDataStoreState<Wage[]>(STATUS_SUCCESS, action.wages);
    case Actions.GET_WAGES_FAILURE:
      return initDataStoreState<Wage[]>(STATUS_ERROR, state.data, action.error);
    case Actions.PAYOUT_WAGE_SUCCESS:
      return initDataStoreState<Wage[]>(STATUS_SUCCESS, state.data?.map((wage) => {
        if (wage.id === action.wageId) {
          return { ...wage, processing: true };
        }
        return wage;
      }));
    default:
      return state;
  }
};
