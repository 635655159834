import * as React from 'react';
import * as styledComponents from 'styled-components';
import ThemeInterface from './themes/theme';

// tslint:disable-next-line:no-any
export type ThemedStyledComponent<C extends keyof JSX.IntrinsicElements | React.ComponentType<any>,
  O extends object = {},
  // tslint:disable-next-line:no-any
  A extends keyof any = never> = styledComponents.StyledComponent<C, ThemeInterface, O, A>;

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

export { styled, css, createGlobalStyle, keyframes, ThemeProvider };
