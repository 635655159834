import { Actions, GetSurveyActions } from '../../actions/survey';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_SUCCESS } from '../../types/StoreUtils';
import { Survey } from '../../types/Tymbe';

type Data = Survey;

export type SurveysState = Indexable<DataStoreState<Data>>;

const initState: SurveysState = {};
type ReducerActions = GetSurveyActions;

export const surveysReducer: Reducer<SurveysState, ReducerActions> = (state = initState, action) => {
  switch (action.type) {
    case Actions.GET_SURVEY_REQUEST:
      return {
        ...state,
        [action.surveyId]: initDataStoreState<Data>(),
      };
    case Actions.GET_SURVEY_SUCCESS:
      return {
        ...state,
        [action.survey.id]: initDataStoreState<Data>(STATUS_SUCCESS, action.survey),
      };
    case Actions.GET_SURVEY_FAILURE:
      return {
        ...state,
        [action.surveyId]: initDataStoreState<Data>(STATUS_ERROR, state[action.surveyId]?.data, action.error),
      };
    default:
      return state;
  }
};

const getDefaultSurvey = (state: SurveysState): DataStoreState<Data> => {
  return Object.entries(state)
    .map(entry => entry[1])
    .find(entry => !entry.data ? false : !entry.data.company) ?? initDataStoreState<Data>(null);
};

export const surveySelector = (state: SurveysState, id: keyof SurveysState) =>
  !id ? getDefaultSurvey(state) : state[id] ?? initDataStoreState<Data>(null);
