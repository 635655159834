import { H3 } from '../Styles/BasicElements';
import { Toggleable } from '../Toggleable';
import { WageShift } from '../WageShift';
import React, { Fragment, ReactNode, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { AdjustmentRow } from './AdjustmentRow';
import { FootNote as FootNoteI, footNotesContext } from './FootNotesContext';
import { FootNote, Separator, TaxDetailBox, TaxShiftList, TaxSummaryBox, ToggleIcon } from './Styled';
import { TaxRow } from './TaxRow';
import { WageDetailTitleRow } from './WageDetailTitleRow';
import { WageDetailRowWithNote } from './WageDetailRowWithNote';
import { PaySupplementRow } from './PaySupplementRow';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import { SalaryCalculationData } from '@tymbe/schema/salary-calculation.interface';

export type WageDetailProps = {
  payoutInfoData: PayoutInfoData;
  salaryCalculation: SalaryCalculationData;
};

const WageDetail = ({ payoutInfoData, salaryCalculation }: WageDetailProps) => {
  const intl = useIntl();
  const [ shiftListShown, setShiftListShown ] = useState<boolean>(false);
  const [ footNotes, dispatch ] = useReducer(
    (state: FootNoteI[], newNote: FootNoteI) => {
      if (state.find(note => note.key === newNote.key)) return state;
      return [ ...state, newNote ];
    },
    [],
  );

  const addFootNote = (footNote?: ReactNode, key?: string) => {
    if (!footNote) return;
    key = key || footNotes.length.toString();
    const note = footNotes.find(note => note.key === key || note.text === footNote);
    if (note) return footNotes.indexOf(note) + 1;
    dispatch({ key, text: footNote });
    return footNotes.length + 1;
  };

  const { salary_by_calculation: calc, salary_by_legislation: legi } = salaryCalculation;

  const groupedSupplements = legi.paySupplements.reduce<Record<PaySupplementType, number>>((acc, supplement) => {
    acc[supplement.paySupplement.type] += supplement.totalGrossSalary;
    return acc;
  }, {
    [PaySupplementType.Holiday]: 0,
    [PaySupplementType.Night]: 0,
    [PaySupplementType.Saturday]: 0,
    [PaySupplementType.Sunday]: 0,
  });
  const supplementSum = Object.values(groupedSupplements).reduce((acc, val) => acc + val, 0);

  return (
    <footNotesContext.Provider value={{ addFootNote }}>
      <TaxDetailBox>
        <div>
          <H3 style={{ cursor: 'pointer' }} onClick={() => setShiftListShown(!shiftListShown)}>
            <ToggleIcon toggled={shiftListShown} /> {intl.formatMessage({
            defaultMessage: 'Seznam směn',
            description: 'Titulek nad seznamem směn v detailu výplat',
          })}
          </H3>
          <Toggleable toggled={shiftListShown}>
            <TaxShiftList>
              {legi.shifts.map((shift) => (
                <WageShift key={`${shift.startTime}_${shift.finishTime}`} data={shift} />
              ))}
            </TaxShiftList>
          </Toggleable>
        </div>
        <div className='flex justify-between items-end'>
          <div className='text-xl text-error font-bold'>
            {payoutInfoData?.foreclosureType ?
            `${intl.formatMessage({
              defaultMessage: 'Máte exekuci, vyplatit lze maximálně {value, number, :: precision-currency-standard currency/CZK}',
              description: 'Použito v přehledu výplat',
            }, { value: payoutInfoData?.payableAmount })}`
              : ''}
          </div>
          <TaxSummaryBox>
            <WageDetailTitleRow
              name={intl.formatMessage({
                defaultMessage: 'Hrubá měsíční odměna',
                description: 'Použito v přehledu výplat',
              })}
              value={legi.totalGrossSalary}
            />
            {supplementSum > 0 && <>
              <WageDetailTitleRow name={intl.formatMessage({
                defaultMessage: 'Základní',
                description: 'Použito v přehledu výplat',
              })} value={legi.totalGrossSalary - supplementSum} />
              <WageDetailTitleRow name={intl.formatMessage({
                defaultMessage: 'Příplatky',
                description: 'Použito v přehledu výplat',
              })} value={supplementSum} />
              {Object.entries(groupedSupplements).map(([type, amount]) =>
                amount > 0 && (
                  <PaySupplementRow
                    key={type}
                    type={type as PaySupplementType}
                    amount={amount}
                  />
                )
              )}
            </>}
            {legi.taxes.map((tax) => (
              <Fragment key={tax.name}>
                <TaxRow tax={tax} />
                {legi.taxAdjustments.filter(adj => adj.appliedToTaxType === tax.type).map((adjustment) => (
                  <AdjustmentRow key={adjustment.name} adjustment={adjustment} />
                ))}
              </Fragment>
            ))}
            <Separator />
            <Separator />
            <WageDetailTitleRow
              name={intl.formatMessage({
                defaultMessage: 'Čistá odměna',
                description: 'Použito v přehledu výplat',
              })}
              value={legi.totalNetSalary}
            />
            {legi.totalNetSalaryAdvance !== legi.totalNetSalary && (
              <>
                <WageDetailRowWithNote
                  name={intl.formatMessage({
                    defaultMessage: 'Předběžná alokace odvodů',
                    description: 'Použito v přehledu výplat',
                  })}
                  value={legi.totalNetSalaryAdvance - legi.totalNetSalary}
                  note={intl.formatMessage({
                    defaultMessage: 'Neboj, pokud nepřekročíš rozhodný příjem, předběžnou alokaci Ti systém vrátí k vyplacení',
                    description: 'Použito v přehledu výplat',
                  })}
                />
                <Separator />
                <WageDetailTitleRow
                  name={intl.formatMessage({
                    defaultMessage: 'Dostupná záloha',
                    description: 'Použito v přehledu výplat',
                  })}
                  value={legi.totalNetSalaryAdvance}
                />
              </>
            )}

            {salaryCalculation && (
              <>
                <Separator />
                <WageDetailTitleRow
                  name={intl.formatMessage({
                    defaultMessage: 'Zažádáno',
                    description: 'Použito v přehledu výplat',
                  })}
                  value={calc.requested}
                />
                <WageDetailTitleRow
                  name={intl.formatMessage({
                    defaultMessage: 'Čeká na zpracování',
                    description: 'Použito v přehledu výplat',
                  })}
                  value={calc.pending_processing}
                />
                <WageDetailTitleRow
                  name={intl.formatMessage({
                    defaultMessage: 'Vyplaceno',
                    description: 'Použito v přehledu výplat',
                  })}
                  value={calc.paid}
                />
              </>
            )}

        </TaxSummaryBox>
        </div>
        {footNotes.map((note, id) => (
          <FootNote key={note.key}>
            <sup>{'*'.repeat(id + 1)}</sup> {note.text}
          </FootNote>
        ))}
      </TaxDetailBox>
    </footNotesContext.Provider>
  );
};

export default WageDetail;
