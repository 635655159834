import { PayoutInfoData } from '@tymbe/schema/payout-info.interface';
import {
  Actions,
  getWagesFailure,
  getWagesRequest,
  GetWagesRequestAction,
  getWagesSuccess,
  payoutWageFailure,
  PayoutWageRequestAction,
  payoutWageSuccess,
} from '../actions/wages';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError } from '../types/Tymbe';

export function* fetchWages(action: GetWagesRequestAction): SagaIterator {
  try {
    const response: AxiosResponse<PayoutInfoData[]> = yield call(api.getWages);
    yield put(getWagesSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getWagesFailure(error));
    }
  }
}

export function* requestPayoutWage(action: PayoutWageRequestAction): SagaIterator {
  const { date_time, company_id, salary_type, password } = action;
  try {
    yield call(api.payoutWage, date_time, company_id, salary_type, password);
    yield put(payoutWageSuccess());
    yield put(getWagesRequest());
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(payoutWageFailure(error));
    }
  }
}

export function* watchGetWagesRequest() {
  yield takeEvery(Actions.GET_WAGES_REQUEST, fetchWages);
}

export function* watchPayoutWageRequest() {
  yield takeLeading(Actions.PAYOUT_WAGE_REQUEST, requestPayoutWage);
}
