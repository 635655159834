import { Actions, DownloadContractActions } from '../../actions/downloadContract';
import { Actions as MainContractActions, GetMainContractsActions } from '../../actions/mainContracts';
import { Actions as OtherContractActions, GetOtherContractsActions } from '../../actions/otherContracts';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { RingDocument } from '../../types/Tymbe';

const getDataStoreEntriesById = <D extends RingDocument>(data: D) =>
  [data.signed.id, initDataStoreState<D>(STATUS_SUCCESS, data)];
const generateDataStoreItemsById = <D extends RingDocument>(data: D[]): Indexable<DataStoreState<D>> =>
  Object.fromEntries(data.map((getDataStoreEntriesById)));

export type SignedDocumentsState = {
  company: Indexable<DataStoreState<RingDocument>>;
  tymbe: Indexable<DataStoreState<RingDocument>>;
};

const initState: SignedDocumentsState = {
  company: {},
  tymbe: {},
};
type ReducerActions = GetMainContractsActions | GetOtherContractsActions | DownloadContractActions;
export const signedDocumnetsReducer: Reducer<SignedDocumentsState, ReducerActions> = (state = initState, action) => {
  switch (action.type) {
    case MainContractActions.GET_MAIN_CONTRACTS_SUCCESS:
    case OtherContractActions.GET_OTHER_CONTRACTS_SUCCESS:
      return {
        company: {
          ...state.company,
          ...generateDataStoreItemsById(action.documents.filter(doc => !doc.tymbe)),
        },
        tymbe: {
          ...state.tymbe,
          ...generateDataStoreItemsById(action.documents.filter(doc => doc.tymbe)),
        },
      };
    case Actions.DOWNLOAD_CONTRACT_REQUEST: {
      const part = action.tymbe ? 'tymbe' : 'company';
      return {
        ...state,
        [part]: {
          ...state[part],
          [action.documentId]: initDataStoreState(STATUS_PENDING, state[part][action.documentId]?.data),
        },
      };
    }
    case Actions.DOWNLOAD_CONTRACT_SUCCESS: {
      const part = action.tymbe ? 'tymbe' : 'company';
      return {
        ...state,
        [part]: {
          ...state[part],
          [action.documentId]: initDataStoreState(STATUS_SUCCESS, state[part][action.documentId]?.data),
        },
      };
    }
    case Actions.DOWNLOAD_CONTRACT_FAILURE: {
      const part = action.tymbe ? 'tymbe' : 'company';
      return {
        ...state,
        [part]: {
          ...state[part],
          [action.documentId]: initDataStoreState(STATUS_ERROR, state[part][action.documentId]?.data, action.error),
        },
      };
    }
    default:
      return state;
  }
};

export const signedDocumentSelector = (state: SignedDocumentsState, id: number, tymbe: boolean) =>
  state[tymbe ? 'tymbe' : 'company'][id];
