import React, { useEffect } from 'react';
import { Selector, useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { DataStoreState, STATUS_PENDING } from '../types/StoreUtils';
import { getDisplayName } from '../utils';

export type WithFetchProps<D> = {
  fetch: () => void;
  force?: boolean;
} & DataStoreState<D>;

export default <C, D>(WrappedComponent: React.ComponentType<C>): React.FC<C & WithFetchProps<D>> => {
  const WithFetch = ({ fetch, force, ...props }: C & WithFetchProps<D>) => {
    React.useEffect(
      () => {
        if (!force && props.data) return;
        if (props.status === STATUS_PENDING) return;
        fetch();
      },
      [fetch],
    );
    return <WrappedComponent {...props as C} />;
  };
  WithFetch.displayName = `withFetch(${getDisplayName(WrappedComponent)})`;
  return WithFetch;
};

export const withFetchNew = <P extends object = {}, S = {}, D = null, A extends Action = Action>(
  selector: Selector<S, DataStoreState<D>>,
  actionCreator: (props: P) => A,
  force?: boolean,
) => {
  return <C extends P>(WrappedComponent: React.ComponentType<C>): React.FC<C> => (props: C) => {
    const { data, status } = useSelector(selector);
    const action = actionCreator(props);
    const dispatch = useDispatch();
    useEffect(
      () => {
        if (!force && data && status !== null) return;
        if (status === STATUS_PENDING) return;
        dispatch(action);
      },
      [action, dispatch]);
    return <WrappedComponent {...props} />;
  };
};
