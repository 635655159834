import logoTymbe from '../images/logo.svg';
import logoTymbeHorizontal from '../images/logo2.svg';
import { FlattenSimpleInterpolation } from 'styled-components';
import { styled } from '../styledComponents';

export interface LogoProps {
  css?: FlattenSimpleInterpolation | string;
}

export default styled.img.attrs(() => ({
  src: logoTymbe,
  alt: 'Tymbe a.s.',
}))<LogoProps>`
  display: block;
  margin: 0 auto;
  ${({ css }) => css};
`;

export const LogoHorizontal = styled.img.attrs({
  src: logoTymbeHorizontal,
  alt: 'Tymbe a.s.',
})<LogoProps>`
  display: block;
  margin: 0 auto;
  ${({ css }) => css};
`;
