import { call, put, takeEvery } from '@redux-saga/core/effects';
import {
  Actions,
  getOtherContractsFailure,
  GetOtherContractsRequest,
  getOtherContractsSuccess,
} from '../actions/otherContracts';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, RingDocument } from '../types/Tymbe';

export function* fetchOtherContracts(action: GetOtherContractsRequest): SagaIterator {
  const { month, year } = action;
  try {
    const documents: AxiosResponse<RingDocument[]> =
      yield call(api.getSignedDocuments, { month, year, main: false });
    yield put(getOtherContractsSuccess(documents.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getOtherContractsFailure(error));
    }
  }
}

export function* watchGetOtherContractsRequest() {
  yield takeEvery(Actions.GET_OTHER_CONTRACTS_REQUEST, fetchOtherContracts);
}
