import { action } from './utils';
import { Action } from 'redux';
import { ApiError, RingDocument, RingDocumentSigned } from '../types/Tymbe';

export enum Actions {
  DOWNLOAD_CONTRACT_REQUEST = 'DOWNLOAD_CONTRACT_REQUEST',
  DOWNLOAD_CONTRACT_SUCCESS = 'DOWNLOAD_CONTRACT_SUCCESS',
  DOWNLOAD_CONTRACT_FAILURE = 'DOWNLOAD_CONTRACT_FAILURE',
}

type ContractId = RingDocumentSigned['id'];
type DocumentId = RingDocument['id'];

export interface DownloadContractRequestAction extends Action<Actions.DOWNLOAD_CONTRACT_REQUEST> {
  contractId: ContractId;
  documentId: DocumentId;
  tymbe: boolean;
}

export interface DownloadContractSuccessAction extends Action<Actions.DOWNLOAD_CONTRACT_SUCCESS> {
  contractId: ContractId;
  documentId: DocumentId;
  tymbe: boolean;
}

export interface DownloadContractFailureAction extends Action<Actions.DOWNLOAD_CONTRACT_FAILURE> {
  contractId: ContractId;
  documentId: DocumentId;
  tymbe: boolean;
  error: ApiError;
}

export type DownloadContractActions =
  DownloadContractRequestAction
  | DownloadContractSuccessAction
  | DownloadContractFailureAction;

export const downloadContractRequest =
  (contractId: ContractId, documentId: DocumentId, tymbe: boolean): DownloadContractRequestAction =>
    action(Actions.DOWNLOAD_CONTRACT_REQUEST, { contractId, documentId, tymbe });
export const downloadContractSuccess =
  (contractId: ContractId, documentId: DocumentId, tymbe: boolean): DownloadContractSuccessAction =>
    action(Actions.DOWNLOAD_CONTRACT_SUCCESS, { contractId, documentId, tymbe });
export const downloadContractFailure =
  (contractId: ContractId, documentId: DocumentId, tymbe: boolean, error: ApiError): DownloadContractFailureAction =>
  action(Actions.DOWNLOAD_CONTRACT_FAILURE, { contractId, documentId, tymbe, error });
