import { action } from './utils';
import { Action } from 'redux';

export enum Actions {
  REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
}

export interface RequestPasswordResetAction extends Action<Actions.REQUEST_PASSWORD_RESET> {
  email: string;
}

export const requestPasswordReset = (email: string): RequestPasswordResetAction =>
  action(Actions.REQUEST_PASSWORD_RESET, { email });
