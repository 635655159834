import { ApiError } from '../types/Tymbe';
import { Button } from './Forms/Button';
import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeaderCentered } from './Card/CardHeader';
import { CzechConstantType } from '@tymbe/legislatives/czechia/CzechConstantType';
import { DataStatus, STATUS_PENDING, STATUS_SUCCESS } from '../types/StoreUtils';
import { Form } from 'informed';
import { getConst } from '@tymbe/legislatives/Constants';
import { useClearStateOnUnmount } from '../store';
import { useIntl } from 'react-intl';
import { useModalError } from './Modal/ModalError';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { SalaryLimitType } from '@tymbe/schema/enums';
import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';

const timezone = 'Europe/Prague';

export type PersonSalaryLimitModalProps = {
  error: ApiError;
  onCancel: () => void;
  onSave: () => void;
  personSalaryLimit: PersonSalaryLimitData;
  savePersonSalaryLimit: (limit: SalaryLimitType) => void;
  status: DataStatus;
};

export const PersonSalaryLimitModal = ({
  error,
  onCancel,
  onSave,
  personSalaryLimit,
  savePersonSalaryLimit,
  status,
}: PersonSalaryLimitModalProps) => {
  const intl = useIntl();
  const showError = useModalError();

  const nowFormatted = moment.tz(timezone).toISOString();
  const dpcLimit = getConst(CzechConstantType.DPC_LIMIT, nowFormatted, timezone);

  useEffect(() => {
    if (error) {
      const dpcAdvanceLimit = getConst(CzechConstantType.DPC_ADVANCE_LIMIT, nowFormatted, timezone);
      const message = personSalaryLimit.limit === SalaryLimitType.NONE
        ? intl.formatMessage({
          defaultMessage: 'V tomto měsíci tuto funkci už použít nemůžeš, protože máš přihlášené směny, jejichž výdělek překračuje rozhodný příjem.',
          description: 'Informativní hláška, pokud není možné v aktuálním měsíci zapnout hlídání překročení rozhodného příjmu u DPČ',
        })
        : intl.formatMessage({
          defaultMessage: 'V tomto měsíci tuto funkci už vypnout nemůžeš, protože máš vyplaceno více, než bys po případném překročení rozhodného příjmu měl mít vyplaceno. Vypnout funkci můžeš, pokud v daném měsíci nemáš vyplaceno více než {dpcAdvanceLimit} Kč.',
          description: 'Informativní hláška, pokud není možné v aktuálním měsíci vypnout hlídání překročení rozhodného příjmu u DPČ',
        }, { dpcAdvanceLimit });
      showError(message);
      onCancel();
    }
  }, [ intl, nowFormatted, error, onCancel, showError, personSalaryLimit ]);

  const handleSubmit = () => {
    savePersonSalaryLimit(personSalaryLimit.limit === SalaryLimitType.NONE ? SalaryLimitType.AVOID_TAXES : SalaryLimitType.NONE);
  };

  useClearStateOnUnmount('personSalaryLimitModal', []);
  useEffect(() => {
    if (status === STATUS_SUCCESS) {
      onSave();
    }
  }, [ status, onSave ]);

  return (
    <Card>
      <Form onSubmit={handleSubmit}>
        <CardHeaderCentered>
        </CardHeaderCentered>

        <CardContent>
          {personSalaryLimit.limit === SalaryLimitType.NONE
            ? intl.formatMessage({
              defaultMessage: 'Po zapnutí bude aplikace bránit v přihlášení na směny, kterými bys překročil rozhodný příjem {dpcLimit} Kč na DPČ a nebude tak provádět předběžnou alokaci pro případné odvody na sociálním a zdravotním pojištění.',
              description: 'Popisek modálního okna informující o následcích zapnutí hlídání překročení rozhodného příjmu DPČ',
            }, { dpcLimit })
            : intl.formatMessage({
                defaultMessage: 'Po vypnutí aplikace umožní přihlašování na libovolný počet směn a bude tak do {dpcLimit} Kč výdělku alokovat část čisté odměny na případné překročení rozhodného příjmu, které s sebou nese dodatečné odvody na sociálním a zdravotním pojištění. Pokud nakonec rozhodný příjem nepřekročíš, alokaci dostaneš po zpracování mezd za daný měsíc k vyplacení.',
                description: 'Popisek modálního okna informující o následcích vypnutí hlídání překročení rozhodného příjmu DPČ',
              }, { dpcLimit })
          }
        </CardContent>

        <CardFooter>
          <Button
            disabled={status === STATUS_PENDING}
            onClick={onCancel}
            type={'button'}
            background={'secondary'}
          >
            {intl.formatMessage({
              defaultMessage: 'Zrušit',
              description: 'Tlačítko pro zrušení a zavření dialogu pro úpravu profilu',
            })}
          </Button>
          <Button
            disabled={Boolean(status)}
            type={'submit'}
          >
            {intl.formatMessage({
              defaultMessage: 'Uložit',
              description: 'Tlačítko pro odeslání/uložení a zavření dialogu pro úpravu profilu',
            })}
          </Button>
        </CardFooter>
      </Form>
    </Card>
  );
};
