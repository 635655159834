import { SalaryLimitType } from '@tymbe/schema/enums';
import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Card } from './Card';
import { CardColor } from './Card/CardColor';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import ChangePasswordForm from './ChangePasswordForm';
import { Button } from './Forms/Button';
import { Label } from './Forms/LabeledInput';
import Modal from './Modal';
import Spinner from './Spinner';
import A from './Styles/A';
import { H2, H3, H4 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import { WellBox } from './Styles/WellBox';
import { Toggleable } from './Toggleable';
import { tymberDataUpdateRequest } from '../actions/tymberData';
import PersonSalaryLimitModal from '../containers/PersonSalaryLimitModal';
import TymberProfileForm from '../containers/TymberProfileForm';
import { styled } from '../styledComponents';
import { Tymber } from '../types/Tymbe';
import { getCountryNameFromCode } from '../utils';

export type TymberProfileProps = {
  tymber: Tymber;
  personSalaryLimit: PersonSalaryLimitData;
  loading?: boolean;
} & GetProps<typeof Card>;

const SideIcon = styled(Icon)`
  margin-right: 1rem;
`;
const ToggleIndicator =
  styled(Icon)
    .attrs({ id: 'panelToggle', size: '1.5rem', color: 'secondary' })<{ toggled?: boolean }>`
    transform: rotate(${({ toggled }) => (toggled ? '270deg' : '90deg')});
    transition: transform .5s ease-in-out;
  `;

const TwoColl = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const TymberProfile = ({ tymber, personSalaryLimit, loading, ...props }: TymberProfileProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form, setForm] = React.useState(false);
  const closeForm = () => setForm(false);
  const [password, setPassword] = React.useState(false);
  const closePassword = () => setPassword(false);
  const [showContent, setShowContent] = React.useState(false);
  const toggleShowContent = (state = !showContent) => setShowContent(state);
  const [showSalaryLimitModal, setShowSalaryLimitModal] = React.useState(false);
  const closeSalaryLimitModal = () => setShowSalaryLimitModal(false);

  return (
    <Card {...props}>
      <CardHeader>
        <Icon style={{ float: 'right' }} id="profile" color="white" size="2em" />
        <H2>{intl.formatMessage({
          defaultMessage: 'Profil',
          description: 'Titulek sekce v šatně',
        })}
        </H2>
      </CardHeader>
      <CardContent>
        {loading && <Spinner position="absolute" />}
        <CardColor as={H3}>{tymber.prefixName} {tymber.sureName} {tymber.familyName} {tymber.suffixName}</CardColor>
        <H4 style={{ marginBottom: '1rem' }}>ID-{tymber.id}</H4>
        <div>
          <SideIcon id="email" size="1.5em" color="gray4" />
          <span>
            {intl.formatMessage(
              {
                defaultMessage: '<strong>email:</strong> {email}',
                description: 'Nastavený email profilu',
              },
              { email: tymber.email },
            )}
          </span>
        </div>
        <div>
          <SideIcon id="phone" size="1.5em" color="gray4" />
          <span>
            {intl.formatMessage(
              {
                defaultMessage: '<strong>telefon:</strong> {phone}',
                description: 'Nastavený telefon profilu',
              },
              { phone: tymber.phone },
            )}
          </span>
        </div>
        <WellBox outline centered>
          {intl.formatMessage({
            defaultMessage: `Při změně občanského průkazu, bydliště, jména či pohlaví, nahraj foto přední i zadní strany
            nového dokladu totožnosti do dokladů zde ve svém profilu.`,
            description: 'Infobox v sekci profilu',
          })}
        </WellBox>
        <Toggleable toggled={showContent}>
          <div>
            {intl.formatMessage({
              defaultMessage: '<strong>Adresa:</strong>',
              description: 'Nastavená adresa profilu',
            })}
            <address>
              {tymber.address?.street.join(' ')}<br />
              {[tymber.address?.city, tymber.address?.zip].filter((i) => i).join(', ')}<br />
              {getCountryNameFromCode(tymber.address?.country)}
            </address>
          </div>
          {tymber.contactAddress?.city && (
            <div>
              {intl.formatMessage({
                defaultMessage: '<strong>Kontaktní adresa:</strong>',
                description: 'Nastavená kontaktní adresa profilu',
              })}
              <address>
                {tymber.contactAddress?.street.join(' ')}<br />
                {[tymber.contactAddress?.city, tymber.contactAddress?.zip].filter((i) => i).join(', ')}<br />
                {getCountryNameFromCode(tymber.contactAddress?.country)}
              </address>
            </div>
          )}
          <hr />
          <TwoColl>
            <div>
              {intl.formatMessage(
                {
                  defaultMessage: '<strong>Datum narozeni:</strong> <nowrap>{birth_date, date, ::ddMMyyyy}</nowrap>',
                  description: 'Nastavený email profilu',
                },
                { birth_date: moment(tymber.birthDate) },
              )}
            </div>
            <div>
              {intl.formatMessage(
                {
                  defaultMessage: `<strong>Pohlaví:</strong> {gender, select,
                    1 {Muž}
                    2 {Žena}
                    other {Nespecifikováno}}`,
                  description: 'Nastavené pohlaví profilu',
                },
                { gender: tymber.gender },
              )}
            </div>
            <div>
              {intl.formatMessage(
                {
                  defaultMessage: '<strong>Číslo OP:</strong> {id_card_number}',
                  description: 'Nastavený občanský průkaz profilu',
                },
                { id_card_number: tymber.idCardNumber },
              )}
            </div>
            <div>
              {intl.formatMessage(
                {
                  defaultMessage: '<strong>Platnost OP:</strong> {id_card_valid_until, date, ::ddMMyyyy}',
                  description: 'Platnost občanského průkazu profilu',
                },
                { id_card_valid_until: moment(tymber.idCardValidTo) },
              )}
            </div>
          </TwoColl>
          <hr />
          <TwoColl>
            <div>
              {intl.formatMessage(
                {
                  defaultMessage: '<strong>Číslo účtu:</strong> {bank_acc_number}',
                  description: 'Nastavené číslo účtu profilu',
                },
                { bank_acc_number: tymber.bankAccountNumber },
              )}
            </div>
            <div>
              {intl.formatMessage(
                {
                  defaultMessage: '<strong>Kód banky:</strong> {bank_number}',
                  description: 'Nastavený kód banky profilu',
                },
                { bank_number: tymber.bankNumber },
              )}
            </div>
          </TwoColl>
          <FlexBox>
            <Label
              htmlFor="salaryLimitEnabled"
              className="!whitespace-normal"
            >
              {/* Component `informed.Checkbox` does not work correctly when `preventDefault` is called upon `onChange` event. */}
              <input
                checked={personSalaryLimit?.limit === SalaryLimitType.AVOID_TAXES}
                id="salaryLimitEnabled"
                type="checkbox"
                onChange={(event) => {
                  setShowSalaryLimitModal(true);
                  event.preventDefault();
                }}
              />
              &nbsp;
              {intl.formatMessage({
                defaultMessage: 'Zabránit překročení rozhodného příjmu u DPČ',
                description: 'Popisek přepínače funkce hlídání překročení rozhodného příjmu u DPČ',
              })}
            </Label>
          </FlexBox>
          <FlexBox>
            <Label
              htmlFor="emailNotificationEnabled"
              className="!whitespace-normal"
            >
              <input
                checked={Boolean(tymber.email_notification_allowed)}
                id="emailNotificationEnabled"
                type="checkbox"
                onChange={(event) => {
                  dispatch(tymberDataUpdateRequest({ email_notification_allowed: event.target.checked }));
                }}
              />
              &nbsp;
              {intl.formatMessage({
                defaultMessage: 'Dostávat emaily o pozvánkách na směny',
                description: 'Popisek přepínače funkce posílání mailových notifikací Tymberům',
              })}
            </Label>
          </FlexBox>
          <FlexBox style={{ marginTop: '1rem', textAlign: 'right' }}>
            <A as="span" onClick={() => setPassword(true)}>
              {intl.formatMessage({
                defaultMessage: 'Změnit heslo',
                description: 'Tlačítko pro změnu hesla',
              })}
            </A>
            <Button type="button" onClick={() => setForm(true)}>
              {intl.formatMessage({
                defaultMessage: 'Upravit',
                description: 'Tlačítko pro úpravu profilu',
              })}
            </Button>
          </FlexBox>
        </Toggleable>
        <Modal open={password}>
          <ChangePasswordForm onSave={closePassword} onCancel={closePassword} />
        </Modal>
        <Modal open={form}>
          <TymberProfileForm onSave={closeForm} onCancel={closeForm} />
        </Modal>
        <Modal open={showSalaryLimitModal}>
          <PersonSalaryLimitModal
            onSave={closeSalaryLimitModal}
            onCancel={closeSalaryLimitModal}
          />
        </Modal>
      </CardContent>
      <CardFooter className="clearFix" style={{ textAlign: 'right' }}>
        <Button onClick={() => toggleShowContent()} background="gray4" style={{ padding: '.5rem 1rem' }}>
          <ToggleIndicator toggled={showContent} />
        </Button>
      </CardFooter>
    </Card>
  );
};
