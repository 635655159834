import { Actions, ContractPreviewActions } from '../../actions/contractPreview';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';

type Data = { url: string };

export type ContractPreviewState = DataStoreState<Data>;

const initialState: ContractPreviewState = initDataStoreState<Data>(null);

export const contractPreviewReducer: Reducer<ContractPreviewState, ContractPreviewActions> =
  (state = initialState, action) => {
    switch (action.type) {
      case Actions.CONTRACT_PREVIEW_CLEAN:
        return initialState;
      case Actions.SIGNED_CONTRACT_PREVIEW_REQUEST:
      case Actions.CONTRACT_PREVIEW_REQUEST:
        return initDataStoreState<Data>(STATUS_PENDING);
      case Actions.CONTRACT_PREVIEW_SUCCESS:
        return initDataStoreState<Data>(STATUS_SUCCESS, { url: action.data });
      case Actions.CONTRACT_PREVIEW_FAILURE:
        return initDataStoreState<Data>(STATUS_ERROR, null, action.error);
      default:
        return state;
    }
  };
