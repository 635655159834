import { action } from './utils';
import { Action } from 'redux';
import { ApiError, RingDocument } from '../types/Tymbe';

export enum Actions {
  GET_OTHER_CONTRACTS_REQUEST = 'GET_OTHER_CONTRACTS_REQUEST',
  GET_OTHER_CONTRACTS_SUCCESS = 'GET_OTHER_CONTRACTS_SUCCESS',
  GET_OTHER_CONTRACTS_FAILURE = 'GET_OTHER_CONTRACTS_FAILURE',
}

export interface GetOtherContractsRequest extends Action<Actions.GET_OTHER_CONTRACTS_REQUEST> {
  month: number;
  year: number;
}

export interface GetOtherContractsSuccess extends Action<Actions.GET_OTHER_CONTRACTS_SUCCESS> {
  documents: RingDocument[];
}

export interface GetOtherContractsFailure extends Action<Actions.GET_OTHER_CONTRACTS_FAILURE> {
  error: ApiError;
}

export type GetOtherContractsActions = GetOtherContractsRequest | GetOtherContractsSuccess | GetOtherContractsFailure;

export const getOtherContractsRequest = (month: number, year: number): GetOtherContractsRequest =>
  action(Actions.GET_OTHER_CONTRACTS_REQUEST, { month, year });
export const getOtherContractsSuccess = (documents: RingDocument[]): GetOtherContractsSuccess =>
  action(Actions.GET_OTHER_CONTRACTS_SUCCESS, { documents });
export const getOtherContractsFailure = (error: ApiError): GetOtherContractsFailure =>
  action(Actions.GET_OTHER_CONTRACTS_FAILURE, { error });
