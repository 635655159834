import {
  ActionTypes,
  bucketFileFailure,
  bucketFileRequest,
  bucketFileSuccess,
} from '../actions/data/bucket';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { ApiError } from '../types/Tymbe';
import api from '../services/api';
import { call, put } from 'redux-saga/effects';
import { handleApiError } from './utils';
import { fork, take } from '@redux-saga/core/effects';

export function* fetchBucketFile(path: string): SagaIterator {

  try {
    const response: AxiosResponse<string> = yield call(api.getBucketFile, path);

    yield put (bucketFileSuccess({ name: path, url: response.data}));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, bucketFileRequest(path));
    if (error) {
      yield put(bucketFileFailure(path, error));
    }
  }
}

export function* watchBucketFileRequest() {
  const fetchedPaths = new Set<string>();
  while (true) {
    const { path, type } = yield take([ActionTypes.BUCKET_FILE_REQUEST, ActionTypes.BUCKET_FILE_FAILURE]);
    if (type === ActionTypes.BUCKET_FILE_REQUEST && !fetchedPaths.has(path)) {
      fetchedPaths.add(path);
      yield fork(fetchBucketFile, path);
      continue;
    }
    if (type === ActionTypes.BUCKET_FILE_FAILURE && fetchedPaths.has(path)) {
      fetchedPaths.delete(path);
    }
  }
}
