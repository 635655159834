import { Actions, GetLiabilitiesActions, PayLiabilitiesActions } from '../../actions/liabilities';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { Liability } from '../../types/Tymbe';

type Data = Liability[];
export type LiabilitiesState = DataStoreState<Data>;

const initState: LiabilitiesState = initDataStoreState<Data>(null, []);

type ReducerActions = GetLiabilitiesActions | PayLiabilitiesActions;
export const liabilitiesReducer: Reducer<LiabilitiesState, ReducerActions> = (state = initState, action) => {
  switch (action.type) {
    case Actions.GET_LIABILITIES_REQUEST:
      return initDataStoreState<Data>(STATUS_PENDING, state.data);
    case Actions.GET_LIABILITIES_SUCCESS:
    case Actions.PAY_LIABILITIES_SUCCESS:
      return initDataStoreState<Data>(STATUS_SUCCESS, action.liabilities);
    case Actions.GET_LIABILITIES_FAILURE:
    case Actions.PAY_LIABILITIES_FAILURE:
      return initDataStoreState<Data>(STATUS_ERROR, state.data, action.error);
    default:
      return state;
  }
};
