import { MoreInvitationsRequestAction, moreInvitationsFailure, moreInvitationsSuccess } from '../actions/invitations';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
  Actions,
  invitationRejectFailure,
  InvitationRejectRequestAction,
  invitationRejectSuccess,
  invitationsFailure,
  InvitationsRequestAction,
  invitationsSuccess,
} from '../actions/invitations';
import { AxiosResponse } from 'axios';
import { StoreState, componentsSelector } from '../reducers';
import { invitationsSelector } from '../reducers/components';
import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, Ring } from '../types/Tymbe';

export function* rejectInvitation(action: InvitationRejectRequestAction): SagaIterator {
  const { ringId } = action;
  try {
    const ring: AxiosResponse<Ring> = yield call(api.rejectInvitation, ringId);
    yield put(invitationRejectSuccess(ring.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(invitationRejectFailure(error));
    }
  }
}

export function* fetchInvitations(action: InvitationsRequestAction): SagaIterator {
  const { filters } = action;
  try {
    const rings: AxiosResponse<Ring[]> = yield call(api.getInvitations, filters);
    yield put(invitationsSuccess(rings.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(invitationsFailure(error));
    }
  }
}

export function* fetchMoreInvitations(action: MoreInvitationsRequestAction): SagaIterator {
  const { filters } = action;
  const invIds = yield select((state: StoreState) => invitationsSelector(componentsSelector(state)))
  const from = invIds?.data?.length || 0;
  try {
    const rings: AxiosResponse<Ring[]> = yield call(api.getInvitations, { ...filters, $skip: from });
    yield put(moreInvitationsSuccess(rings.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(moreInvitationsFailure(error));
    }
  }
}

export function* watchInvitationsRequest() {
  yield takeLatest(Actions.INVITATIONS_REQUEST, fetchInvitations);
}

export function* watchInvitationRejectRequest() {
  yield takeLatest(Actions.INVITATION_REJECT_REQUEST, rejectInvitation);
}

export function* watchMoreInvitationsRequest() {
  yield takeLatest(Actions.MORE_INVITATIONS_REQUEST, fetchMoreInvitations)
}
