import { getFinishedRequest, moreFinishedRequest } from '../actions/finished';
import { Error } from '../components/Error';
import FinishedList from '../components/FinishedList';
import Spinner from '../components/Spinner';
import withError from '../components/withError';
import withFetch from '../components/withFetch';
import withLoading from '../components/withLoading';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { finishedSelector } from '../reducers/components';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';

const selectFinished = compose(finishedSelector, componentsSelector);

const ClaimedContainer = connect(
  (state: StoreState) => selectFinished(state),
  dispatch => ({
    filterFinished: (filters: object) => dispatch(getFinishedRequest(filters)),
    moreFinished: (filters?: object) => dispatch(moreFinishedRequest(filters)),
    fetch: () => dispatch(getFinishedRequest()),
  }),
);

export default ClaimedContainer(
  withFetch(
    withLoading(Spinner, true)(
      withError(Error)(
        withMapData((data, status) => ({ rings: data, loading: status === STATUS_PENDING }))(
          FinishedList,
        ),
      ),
    ),
  ),
);
