import { Holiday } from "../Holiday";
import { HolidayCalculator } from "../HolidayCalculator";
import moment, { Moment } from "moment-timezone";

export class CzechHolidayCalculator implements HolidayCalculator {
  constructor(
    private readonly timezone: string = "Europe/Prague",
  ) {}

  public getHolidaysOnDate(date: Moment): Holiday[] {
    date = moment.tz(date, this.timezone);

    const calculateEasterSunday = (date: Moment): Moment => {
      const year = date.year();
      const easterSunday = date.clone();
      easterSunday.startOf('day');
      const a = year % 19;
      const b = (11 * a + 5) % 30;
      const c = b === 0 || (b === 1 && a > 10) ? (b + 1) : b;
      const month = 1 <= c && c <= 19 ? 3 : 2;
      const day = (50 - c) % 31;
      easterSunday.month(month).date(day);
      easterSunday.month(month).date(day + 7 - easterSunday.day());
      return easterSunday;
    };

    const czechPublicHolidays: Holiday[] = [
      { month: 1, day: 1, name: 'Den obnovy samostatného českého státu' },
      { month: 5, day: 8, name: 'Den vítězství' },
      { month: 7, day: 5, name: 'Den slovanských věrozvěstů Cyrila a Metoděje' },
      { month: 7, day: 6, name: 'Den upálení mistra Jana Husa' },
      { month: 9, day: 28, name: 'Den české státnosti' },
      { month: 10, day: 28, name: 'Den vzniku samostatného československého státu' },
      { month: 11, day: 17, name: 'Den boje za svobodu a demokracii' },
      { month: 11, day: 17, name: 'Mezinárodní den studenstva' },
    ];

    const easterSunday = calculateEasterSunday(date);
    const goodFriday = easterSunday.clone().subtract(2, 'day');
    const easterMonday = easterSunday.clone().add(1, 'day');

    const czechOtherHolidays: Holiday[] = [
      { month: 1, day: 1, name: 'Nový rok' },
      { month: goodFriday.month() + 1, day: goodFriday.date(), name: 'Velký pátek' },
      { month: easterMonday.month() + 1, day: easterMonday.date(), name: 'Velikonoční pondělí' },
      { month: 5, day: 1, name: 'Svátek práce' },
      { month: 12, day: 24, name: 'Štědrý den' },
      { month: 12, day: 25, name: '1. svátek vánoční' },
      { month: 12, day: 26, name: '2. svátek vánoční' },
    ];

    const month = date.month() + 1;
    const day = date.date();

    return [
      ...czechPublicHolidays,
      ...czechOtherHolidays,
    ].filter((holiday: Holiday) => month === holiday.month && day === holiday.day);
  }

  public isDayHoliday(date: Moment): boolean {
    return this.getHolidaysOnDate(date).length !== 0;
  }
}
