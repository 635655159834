import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { styled } from '../styledComponents';

const Wrapper = styled.div`
  display: grid;
  height: 100vh;
`;

const Container = styled.div`
  grid-row: 1/2;
  background: ${props => props.theme.bodyBg};
  min-width: 280px;
  max-width: 400px;
  width: 80%;
  padding: 2em;
  margin: auto;
  border-radius: 2em;
  box-shadow: rgba(0,0,0,0.2) 0 0 1em;
  @media (max-width: 490px) {
    padding: 1em;
  }
`;

type FrontWrapperProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

const FrontWrapper = ({ children, ...props }: FrontWrapperProps) => (
  <Wrapper>
    <Container {...props}>
      {children}
    </Container>
  </Wrapper>
);

export default FrontWrapper;
