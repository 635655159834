import { call, cancelled, put, takeLatest, takeLeading } from '@redux-saga/core/effects';
import { LOGIN_SUCCESS } from '../actions/login';
import {
  Actions,
  tymberDataFailure,
  tymberDataSuccess,
  tymberDataUpdateFailure,
  TymberDataUpdateRequestAction,
  tymberDataUpdateSuccess,
} from '../actions/tymberData';
import Axios, { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, Tymber } from '../types/Tymbe';

export function* fetchTymber(action: Action): SagaIterator {
  try {
    const response: AxiosResponse<Tymber> = yield call(api.getTymber);
    yield put(tymberDataSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(tymberDataFailure(error));
    }
  }
}

export function* updateTymber(action: TymberDataUpdateRequestAction): SagaIterator {
  const { data, password } = action;
  const { cancel: cancelRequest, token: cancelToken } = Axios.CancelToken.source();
  try {
    const response: AxiosResponse<Tymber> = yield call(api.saveTymber, data, password || '', { cancelToken });
    yield put(tymberDataUpdateSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(tymberDataUpdateFailure(error));
    }
  } finally {
    if (yield cancelled()) {
      cancelRequest();
    }
  }
}

export function* watchTymberDataRequest() {
  yield takeLeading([LOGIN_SUCCESS, Actions.TYMBER_DATA_REQUEST], fetchTymber);
}

export function* watchTymberDataUpdateRequest() {
  yield takeLatest(Actions.TYMBER_DATA_UPDATE_REQUEST, updateTymber);
}
