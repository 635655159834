import { createContext } from 'react';
import { FieldApi, FieldState } from 'informed';

export type RadioGroupContextModel = {
  radioGroupApi: FieldApi;
  radioGroupState: FieldState;
};
export const RadioGroupContext = createContext<RadioGroupContextModel>({
  radioGroupApi: {} as FieldApi,
  radioGroupState: {} as FieldState,
});
