import {
  Actions,
  GetTymberDocumentsActions,
  InvalidateTymberDocumentActions,
  MoreTymberDocumentsActions,
  SaveTymberDocumentSuccessAction,
} from '../../actions/tymberDocuments';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { TymberDocument } from '../../types/Tymbe';

type Data = TymberDocument['id'][];
export type TymberDocumentsState = DataStoreState<Data>;

const initialState: TymberDocumentsState = initDataStoreState<Data>(null);

type ReducerActions = 
  GetTymberDocumentsActions 
  | MoreTymberDocumentsActions 
  | InvalidateTymberDocumentActions 
  | SaveTymberDocumentSuccessAction;

export const tymberDocumentsReducer: Reducer<TymberDocumentsState, ReducerActions> =
  (state = initialState, action) => {
    switch (action.type) {
      case Actions.GET_TYMBER_DOCUMENTS_REQUEST:
        return initDataStoreState<Data>();
      case Actions.GET_TYMBER_DOCUMENTS_SUCCESS:
        return initDataStoreState<Data>(
          STATUS_SUCCESS,
          action.documents.map(document => document.id),
        );
      case Actions.GET_TYMBER_DOCUMENTS_FAILURE:
        return initDataStoreState<Data>(STATUS_ERROR, null, action.error);
      case Actions.MORE_TYMBER_DOCUMENTS_REQUEST:
        return { ...state, status: STATUS_PENDING };
      case Actions.MORE_TYMBER_DOCUMENTS_SUCCESS:
        return initDataStoreState<Data>(STATUS_SUCCESS, [
          ...state.data!,
          ...action.documents.map((document) => document.id),
        ]);
      case Actions.MORE_TYMBER_DOCUMENTS_FAILURE:
        return initDataStoreState<Data>(STATUS_ERROR, null, action.error);
      case Actions.INVALIDATE_TYMBER_DOCUMENT_SUCCESS:
        return initDataStoreState<Data>(
          STATUS_SUCCESS,
          state.data?.filter(id => action.document.id !== id),
        );
      case Actions.SAVE_TYMBER_DOCUMENT_SUCCESS:
        return initDataStoreState<Data>(
          STATUS_SUCCESS,
          [...state.data!, action.document.id],
        );
      default:
        return state;
    }
  };
