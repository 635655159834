import { downloadContractRequest } from '../actions/downloadContract';
import { DocumentItem } from '../components/DocumentItem';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { dataSelector, StoreState } from '../reducers';
import { dataSignedDocumentsSelector } from '../reducers/data';
import { signedDocumentSelector } from '../reducers/data/signedDocuments';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { RingDocument } from '../types/Tymbe';

export type DocumentItemProps = {
  dId: RingDocument['id'];
  tymbe: boolean;
};

const selectSignedDocuments = compose(dataSignedDocumentsSelector, dataSelector);

const DocumentItemConnect = connect(
  (state: StoreState, { dId, tymbe }: DocumentItemProps) =>
    signedDocumentSelector(selectSignedDocuments(state), dId, tymbe),
  {
    downloadDocument: downloadContractRequest,
  },
);

export default DocumentItemConnect(
  withMapData((data, status, error) => ({
    document: data,
    loading: status === STATUS_PENDING,
    error,
  }))(
    DocumentItem,
  ),
);
