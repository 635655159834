import { Actions, PersonSalaryLimitActions } from '../../actions/personSalaryLimit';
import { Reducer } from 'redux';
import { DataStoreState, initDataStoreState, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../../types/StoreUtils';
import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';

export type PersonSalaryLimitState = DataStoreState<PersonSalaryLimitData>;

const initState = initDataStoreState<PersonSalaryLimitData>(null);

export const personSalaryLimitReducer: Reducer<PersonSalaryLimitState, PersonSalaryLimitActions> =
  (state = initState, action) => {
    switch (action.type) {
      case Actions.PERSON_SALARY_LIMIT_REQUEST:
      case Actions.PERSON_SALARY_LIMIT_UPDATE_REQUEST:
        return initDataStoreState<PersonSalaryLimitData>(STATUS_PENDING, state.data);
      case Actions.PERSON_SALARY_LIMIT_SUCCESS:
      case Actions.PERSON_SALARY_LIMIT_UPDATE_SUCCESS:
        return initDataStoreState<PersonSalaryLimitData>(STATUS_SUCCESS, action.data);
      case Actions.PERSON_SALARY_LIMIT_FAILURE:
        return initDataStoreState<PersonSalaryLimitData>(STATUS_ERROR, null, action.error);
      case Actions.PERSON_SALARY_LIMIT_UPDATE_FAILURE:
        return initDataStoreState<PersonSalaryLimitData>(state.data ? STATUS_SUCCESS : STATUS_PENDING, state.data);
      default:
        return state;
    }
  };

export const personSalaryLimitSelector = (state: PersonSalaryLimitState) => state;
