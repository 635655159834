import { FormItem } from './FormItem';
import { styled } from '../../styledComponents';

export const FormItemGroup = styled.div`
  background-color: ${({ theme }) => theme.colors.gray1};
  border-radius: 1em;
  padding: 1em;
  margin-bottom: 1em;

  input {
    background-color: inherit;
  }
  input:focus {
    //border: none;
  }

  ${FormItem} {
    border-radius: 1em;
    padding: 0;
  }
  ${FormItem}:last-of-type {
    margin-right: 0;
  }
`;
