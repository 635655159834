import { action } from './utils';
import { Action } from 'redux';
import { ApiError, TymberUtility } from '../types/Tymbe';

export enum Actions {
  GET_TYMBER_UTILITIES_REQUEST = 'GET_TYMBER_UTILITIES_REQUEST',
  GET_TYMBER_UTILITIES_SUCCESS = 'GET_TYMBER_UTILITIES_SUCCESS',
  GET_TYMBER_UTILITIES_FAILURE = 'GET_TYMBER_UTILITIES_FAILURE',
}

export interface GetTymberUtilitiesRequestAction extends Action<Actions.GET_TYMBER_UTILITIES_REQUEST> {
}

export interface GetTymberUtilitiesSuccessAction extends Action<Actions.GET_TYMBER_UTILITIES_SUCCESS> {
  utilities: TymberUtility[];
}

export interface GetTymberUtilitiesFailureAction extends Action<Actions.GET_TYMBER_UTILITIES_FAILURE> {
  error: ApiError;
}

export type GetTymberUtilitiesActions =
  GetTymberUtilitiesRequestAction
  | GetTymberUtilitiesSuccessAction
  | GetTymberUtilitiesFailureAction;

export const getTymberUtilitiesRequest = (): GetTymberUtilitiesRequestAction =>
  action(Actions.GET_TYMBER_UTILITIES_REQUEST);
export const getTymberUtilitiesSuccess = (utilities: TymberUtility[]): GetTymberUtilitiesSuccessAction =>
  action(Actions.GET_TYMBER_UTILITIES_SUCCESS, { utilities });
export const getTymberUtilitiesFailure = (error: ApiError): GetTymberUtilitiesFailureAction =>
  action(Actions.GET_TYMBER_UTILITIES_FAILURE, { error });
